import { LegalEntityStatus, ViewType } from "@digits-graphql/frontend/graphql-bearer"
import DoppelgangerAccessLevel from "@digits-shared/session/DGAccessLevel"
import { JWTLegalEntity } from "@digits-shared/session/jwt/jwtLegalEntity"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import moment from "moment"

export enum ViewTypeCode {
  Accrual = "a",
  Ledger = "l",
  AIBookeeper = "k",
}

export interface SessionLegalEntityAttributes {
  readonly id: string
  readonly slug: string
  readonly name: string
  readonly status: LegalEntityStatus
  readonly accountingViewVersions: Map<ViewType, string>
  readonly mutationVersions: Map<ViewType, string>

  readonly organizationId: string
  readonly affiliationId?: string

  readonly aspects: Map<AspectCode, string>
  readonly termsAcceptedAt: Date | undefined
}

/**
 * Simple wrapper for legal entities stored on JWT
 *
 * Provides convenience methods, better naming
 * than what is found on the intentionally abbreviated JWT,
 * and clean types.
 */
export default class SessionLegalEntity {
  private readonly attributes: SessionLegalEntityAttributes

  constructor(organizationId: string, rawEntity?: JWTLegalEntity, affiliationId?: string) {
    if (!rawEntity) {
      this.attributes = {} as SessionLegalEntityAttributes
      return
    }

    const aspects = new Map<AspectCode, string>()
    rawEntity.aspects?.forEach((aspect) => aspects.set(aspect as AspectCode, aspect))

    const terms = rawEntity?.terms
    const termsAcceptedAt = terms ? moment(terms, "YYYY-MM-DD").toDate() : undefined

    const accountingViewVersions = new Map<ViewType, string>()
    if (rawEntity.avvs) {
      Object.entries(rawEntity.avvs).forEach(([vt, avv]) => {
        switch (vt) {
          case ViewTypeCode.Ledger:
            accountingViewVersions.set(ViewType.Ledger, avv)
            break
          case ViewTypeCode.AIBookeeper:
            accountingViewVersions.set(ViewType.AIBookkeeper, avv)
            break
        }
      })
    }

    const mutationVersions = new Map<ViewType, string>()
    if (rawEntity.mvvs) {
      Object.entries(rawEntity.mvvs).forEach(([vt, mvv]) => {
        switch (vt) {
          case ViewTypeCode.Ledger:
            mutationVersions.set(ViewType.Ledger, mvv)
            break
          case ViewTypeCode.AIBookeeper:
            mutationVersions.set(ViewType.AIBookkeeper, mvv)
            break
        }
      })
    }

    this.attributes = {
      ...rawEntity,
      organizationId,
      affiliationId,
      aspects,
      accountingViewVersions,
      mutationVersions,
      termsAcceptedAt,
    }
  }

  /*
    ACCESSORS
  */

  get id() {
    return this.attributes.id
  }

  get slug() {
    return this.attributes.slug
  }

  get name() {
    return this.attributes.name
  }

  get status() {
    return this.attributes.status
  }

  get accountingViewVersions() {
    return this.attributes.accountingViewVersions
  }

  get mutationViewVersions() {
    return this.attributes.mutationVersions
  }

  get organizationId() {
    return this.attributes.organizationId
  }

  get affiliationId() {
    return this.attributes.affiliationId
  }

  get aspects() {
    return this.attributes.aspects
  }

  get termsAcceptedAt() {
    return this.attributes.termsAcceptedAt
  }

  /*
    CONVENIENCE METHODS
  */

  get isApproved() {
    return this.status === LegalEntityStatus.Approved
  }

  get isActive() {
    return this.status === LegalEntityStatus.Active
  }

  hasAccessToAspect(aspect?: AspectCode) {
    return !!aspect && this.aspects.has(aspect)
  }

  hasDashboardAccess(doppelganger?: DoppelgangerAccessLevel) {
    return this.isApproved || this.isActive || doppelganger?.hasDashboardAccess
  }

  /*
    For now, consider Pending and PendingHold to mean the same thing.
   */
  get isPending() {
    return (
      this.status === LegalEntityStatus.Pending || this.status === LegalEntityStatus.PendingHold
    )
  }

  get isNew() {
    return this.status === LegalEntityStatus.New
  }

  get isAffiliated() {
    return !!this.affiliationId
  }
}
