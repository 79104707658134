import * as React from "react"
import { useListActionItemsAndBadgeCountQuery } from "@digits-graphql/frontend/graphql-bearer"
import styled from "styled-components"
import { useActionItemsV2BadgeCountsQueryVariables } from "src/frontend/components/OS/Springboard/hooks/useActionItemsV2BadgeCountsQuery"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import {
  STATUS_OPEN,
  STATUS_RESOLVED,
} from "src/frontend/components/Shared/NavSidebar/ActionItemsV2/constants"
import { ActionItemsEmptyState } from "src/frontend/components/Shared/NavSidebar/ActionItemsV2/EmptyState"
import { LoadingRows } from "src/frontend/components/Shared/NavSidebar/ActionItemsV2/LoadingRows"
import { Row } from "src/frontend/components/Shared/NavSidebar/ActionItemsV2/Row/Row"
import { GroupView } from "src/frontend/components/Shared/NavSidebar/ActionItemsV2/useGroupView"

/*
  STYLES
*/

const Rows = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
  overflow-y: auto;
  padding: 2px 0;
`

/*
  COMPONENTS
*/

export const GroupedActionItemsList: React.FC<{
  groupView: GroupView
  hideSidebar: () => void
}> = ({ groupView, hideSidebar }) => {
  const { data, loading: actionItemsLoading } = useActionItemsQuery(groupView)
  if (actionItemsLoading && !data) {
    return (
      <Rows>
        <LoadingRows />
      </Rows>
    )
  }
  if (!data?.listActionItems.length) {
    return <ActionItemsEmptyState groupView={groupView} />
  }
  return (
    <Rows>
      {data.listActionItems.map((actionItem) => (
        <Row key={actionItem.id} item={actionItem} hideSidebar={hideSidebar} />
      ))}
    </Rows>
  )
}

function useActionItemsQuery(groupView: GroupView) {
  const viewKey = useViewVersion()
  const { filter: badgeCountFilter, ...badgeCountVariables } =
    useActionItemsV2BadgeCountsQueryVariables(viewKey.legalEntityId)
  return useListActionItemsAndBadgeCountQuery({
    variables: {
      ...badgeCountVariables,
      badgeCountFilter,
      listFilter: {
        status: groupView === "Open" ? STATUS_OPEN : STATUS_RESOLVED,
        includeSuperseded: groupView === "Resolved",
      },
      pagination: {
        limit: groupView === "Open" ? 40 : 100,
        offset: 0,
      },
      viewKey,
    },
    fetchPolicy: "cache-and-network",
  })
}
