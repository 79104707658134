import * as React from "react"
import { TransactionRecordType } from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { Date } from "@digits-shared/components/UI/Table/Content"
import { hasExactlyOne, hasFirstElement } from "@digits-shared/helpers/arrayHelper"
import moment from "moment"
import styled from "styled-components"
import {
  CollapsedIcon,
  ExpandedIcon,
} from "src/shared/components/Transactions/TransactionRow/GroupElements"
import {
  TRANSFER_OPACITY,
  TRANSFER_TEXT_STYLES,
} from "src/shared/components/Transactions/TransactionRow/TransferTransaction"
import {
  GroupingActions,
  isGroupExpanded,
  isTransactionGroup,
  TransactionGroupProps,
  TransactionProps,
  TransactionRowProps,
} from "src/shared/components/Transactions/TransactionRow/types"
import { ExpandedGroup } from "src/shared/components/Transactions/transactionsConstants"
import transactionHelper from "src/shared/helpers/transactionHelper"

/*
  STYLES
*/

const GroupDate = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5px;

  ${Date} {
    flex: 1;
  }
`

const TransferDate = styled(Date)`
  font-size: 9px;
  ${TRANSFER_TEXT_STYLES};
  opacity: ${TRANSFER_OPACITY};
`

/*
  INTERFACES
*/

interface DateProps {
  groupingActions: GroupingActions
  expandedGroups: ExpandedGroup
}

/*
  COMPONENTS
*/

export const TransactionDate: React.FC<DateProps & TransactionRowProps> = ({
  data,
  loading,
  groupingActions,
  expandedGroups,
}) => {
  if (loading || !data) {
    return <LoadingBlock width="45px" height="12px" />
  }

  if (isTransactionGroup(data)) {
    return (
      <GroupedTransactionDate
        group={data}
        groupingActions={groupingActions}
        expandedGroups={expandedGroups}
      />
    )
  }

  return <SingleTransactionDate transaction={data} />
}

export const SingleTransactionDate: React.FC<TransactionProps> = ({ transaction }) => {
  const { recordType, timestamp } = transaction

  const date = moment.unix(timestamp).utc().format("MMM DD")
  if (transactionHelper.isTransfer(transaction)) {
    return <TransferDate>{date}</TransferDate>
  }

  let label: React.ReactNode | undefined
  switch (recordType) {
    case TransactionRecordType.ExpectedTransaction:
      label = (
        <>
          EXPECTED
          <br />
        </>
      )
      break
    case TransactionRecordType.MissedTransaction:
      label = (
        <>
          MISSED
          <br />
        </>
      )
      break
  }

  return (
    <Date>
      {label}
      {date}
    </Date>
  )
}

const GroupedTransactionDate: React.FC<DateProps & TransactionGroupProps> = ({
  group,
  groupingActions,
  expandedGroups,
}) => {
  const onExpand = React.useCallback(
    (event: React.MouseEvent<HTMLTableRowElement>) => {
      groupingActions.handleGroupExpand(group, event)
    },
    [group, groupingActions]
  )

  const onCollapse = React.useCallback(
    (event: React.MouseEvent<HTMLTableRowElement>) => {
      groupingActions.handleGroupCollapse(group, event)
    },
    [group, groupingActions]
  )

  const isExpanded = isGroupExpanded(expandedGroups, group)
  if (!isExpanded && hasExactlyOne(group.transactions)) {
    return <SingleTransactionDate transaction={group.transactions[0]} />
  }

  return (
    <GroupDate>
      {hasFirstElement(group.transactions) && (
        <SingleTransactionDate transaction={group.transactions[0]} />
      )}
      {isExpanded && <ExpandedIcon onClick={onCollapse} />}
      {!isExpanded && <CollapsedIcon onClick={onExpand} />}
    </GroupDate>
  )
}
