import * as React from "react"
import {
  Interval,
  Maybe,
  ReportDocumentOptions,
  ReportNumberPrecision,
  ReportOptionComparison,
} from "@digits-graphql/frontend/graphql-bearer"
import { Dropdown } from "@digits-shared/components/UI/Elements/Dropdown/Basic"
import { DayPickerLocation } from "@digits-shared/components/UI/Elements/Inputs/DateInput"
import { Switch } from "@digits-shared/components/UI/Elements/Switch"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import { useReportOptionsContext } from "src/frontend/components/Shared/Layout/Components/Statements/ReportOptionsContext"
import { ColumnOptionKey } from "src/frontend/components/Shared/Reports/Report/Components/Statements/columnTypes"
import { useIsSupportedReportOption } from "src/frontend/components/Shared/Reports/Report/hooks/useReportDocumentOptions"
import {
  DropDownContainer,
  DropDownOptions,
  DropDownOptionsContainer,
  GroupLabel,
  GroupOptions,
  OptionDateField,
  OptionGroupContainer,
  OptionInputContainer,
  OptionInputField,
  OptionLabel,
  OptionRow,
  OptionsDropDownRightWrapper,
  OptionTextAreaField,
  OptionTitle,
} from "src/frontend/components/Shared/Reports/Report/Options/OptionsShared"
import { useReportComponentIntervalOrigin } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/hooks/useReportComponentIntervalOrigin"

const LOOKBACK_PERIOD_COUNTS = [12, 6, 3]

/*
  INTERFACES
*/

type OptionValue = ReportOptionComparison | number | ReportNumberPrecision

export interface OptionsProps {}

interface OptionProps {
  optionKey: ColumnOptionKey
  defaultValue?: OptionValue | null
  disabled?: boolean
}

interface DropDownProps extends OptionProps {
  onChange?: (optionKey: ColumnOptionKey, newValue: OptionValue) => void
  disabled?: boolean
}

interface GroupOptionProps {
  label: string
  optionKeys: ColumnOptionKey[]
  children?: React.ReactNode
}

/*
  COMPONENTS
*/

export const OptionsGroup: React.FC<GroupOptionProps> = ({ label, children }) => (
  <OptionGroupContainer>
    <GroupLabel>{label}</GroupLabel>
    <GroupOptions>{children}</GroupOptions>
  </OptionGroupContainer>
)

export const Option: React.FC<OptionProps> = ({
  optionKey,
  defaultValue,
  disabled: defaultDisabled,
}) => {
  const { options, mutation, loading } = useReportOptionsContext()
  const optionOn = isOptionOn(options, optionKey)
  const optionKeyToLabel = useOptionKeyToLabel(optionKey)
  const disabled = loading || defaultDisabled

  const onChange = React.useCallback(
    (on: boolean) => {
      const newOptions: ReportDocumentOptions = {
        [optionKey]: optionValue(optionKey, on, defaultValue),
      }
      if (optionKey === "deltaMonthOverMonth" && !on) {
        newOptions["deltaMonthOverMonthPeriods"] = 1
      }
      if (optionKey === "yearToDate" && !on) {
        newOptions["deltaYearToDate"] = ReportOptionComparison.InvalidComparison
      }
      return mutation?.(newOptions)
    },
    [defaultValue, mutation, optionKey]
  )
  if (!useIsSupportedReportOption(optionKey)) return null

  return (
    <OptionRow>
      <OptionLabel disabled={disabled}>{optionKeyToLabel}</OptionLabel>
      <Switch on={optionOn} onChange={onChange} disabled={disabled} />
    </OptionRow>
  )
}

export const DropDownGroupOptions: React.FC<GroupOptionProps> = ({ label, optionKeys }) => {
  const { options, mutation, loading } = useReportOptionsContext()

  const defaultOptionKey = optionKeys.find((optionKey) => !!options?.[optionKey])
  const [selectedOptionValue, selectOptionValue] = React.useState<OptionValue | undefined | null>(
    defaultOptionKey && options?.[defaultOptionKey]
  )

  const onChange = React.useCallback(
    (_: ColumnOptionKey, newValue: OptionValue) => {
      selectOptionValue(newValue)

      const newOptions = optionKeys.reduce((vars, optionKey) => {
        if (!options?.[optionKey]) return vars
        return { ...vars, [optionKey]: newValue }
      }, {})
      return mutation?.(newOptions)
    },
    [mutation, optionKeys, options]
  )

  if (!optionKeys.length) return null

  return (
    <OptionsGroup label={label} optionKeys={optionKeys}>
      {optionKeys.map((optionKey) => (
        <Option key={optionKey} optionKey={optionKey} defaultValue={selectedOptionValue} />
      ))}
      {defaultOptionKey && (
        <DropDown optionKey={defaultOptionKey} onChange={onChange} disabled={loading} />
      )}
    </OptionsGroup>
  )
}

export const DropDownOption: React.FC<OptionProps> = ({ optionKey, disabled: defaultDisabled }) => {
  const { mutation, loading } = useReportOptionsContext()
  const disabled = loading || defaultDisabled

  const onChange = React.useCallback(
    (key: ColumnOptionKey, newValue: OptionValue) => mutation?.({ [key]: newValue }),
    [mutation]
  )

  return (
    <GroupOptions>
      <DropDown optionKey={optionKey} onChange={onChange} disabled={disabled} />
    </GroupOptions>
  )
}

export const DropDownWithOptionToggle: React.FC<React.PropsWithChildren<OptionProps>> = ({
  optionKey,
  children,
  disabled: defaultDisabled,
}) => {
  const { options, mutation, loading } = useReportOptionsContext()
  const optionOn = isOptionOn(options, optionKey)
  const disabled = loading || defaultDisabled

  const onChange = React.useCallback(
    (key: ColumnOptionKey, newValue: OptionValue) => mutation?.({ [key]: newValue }),
    [mutation]
  )

  if (!useIsSupportedReportOption(optionKey)) return null

  return (
    <DropDownOptionsContainer expanded={optionOn}>
      <Option optionKey={optionKey} disabled={disabled} />
      {optionOn && (
        <DropDownOptions>
          <DropDown optionKey={optionKey} onChange={onChange} disabled={disabled} />
          {children}
        </DropDownOptions>
      )}
    </DropDownOptionsContainer>
  )
}

export const MonthOverMonthOption: React.FC = () => {
  const momKey: ColumnOptionKey = "deltaMonthOverMonth"
  const countKey: ColumnOptionKey = "deltaMonthOverMonthPeriods"
  const { options, mutation, loading } = useReportOptionsContext()

  const breakdownLabel = useOptionKeyToLabel(countKey)
  const breakdownOn = isOptionOn(options, momKey, ReportOptionComparison.Total)

  const deltaLabel = useOptionKeyToLabel(momKey)
  const deltaOn = isOptionOn(
    options,
    momKey,
    ReportOptionComparison.Amount,
    ReportOptionComparison.Percent
  )

  const disabled = loading

  const onDropdownChange = React.useCallback(
    (key: ColumnOptionKey, newValue: OptionValue) => mutation?.({ [key]: newValue }),
    [mutation]
  )

  const onBreakdownChange = React.useCallback(
    (on: boolean) => {
      const newOptions: ReportDocumentOptions = {
        [momKey]: optionValue(momKey, on, ReportOptionComparison.Total) as ReportOptionComparison,
        [countKey]: 1,
      }
      return mutation?.(newOptions)
    },
    [mutation]
  )

  const { interval } = useReportComponentIntervalOrigin()
  React.useEffect(() => {
    const max = maxBreakdownsPerInterval(interval)
    const currentValue = options?.[countKey] as number
    if (!breakdownOn || isNaN(currentValue) || currentValue <= max) return

    onBreakdownChange(true)
  }, [breakdownOn, interval, onBreakdownChange, options])

  const onDeltaChange = React.useCallback(
    (on: boolean) => {
      const newOptions: ReportDocumentOptions = {
        [momKey]: optionValue(momKey, on, ReportOptionComparison.Percent) as ReportOptionComparison,
        [countKey]: 1,
      }
      return mutation?.(newOptions)
    },
    [mutation]
  )

  if (!useIsSupportedReportOption(momKey)) return null

  return (
    <>
      <DropDownOptionsContainer expanded={breakdownOn}>
        <OptionRow>
          <OptionLabel disabled={disabled}>{breakdownLabel}</OptionLabel>
          <Switch on={breakdownOn} onChange={onBreakdownChange} disabled={disabled} />
        </OptionRow>
        {breakdownOn && (
          <DropDownOptions>
            <DropDown optionKey={countKey} onChange={onDropdownChange} disabled={disabled} />
          </DropDownOptions>
        )}
      </DropDownOptionsContainer>

      <DropDownOptionsContainer expanded={deltaOn}>
        <OptionRow>
          <OptionLabel disabled={disabled}>{deltaLabel}</OptionLabel>
          <Switch on={deltaOn} onChange={onDeltaChange} disabled={disabled} />
        </OptionRow>
        {deltaOn && (
          <DropDownOptions>
            <DropDown optionKey={momKey} onChange={onDropdownChange} disabled={disabled} />
          </DropDownOptions>
        )}
      </DropDownOptionsContainer>
    </>
  )
}

export const YearToDateOption: React.FC = () => {
  const ytdKey: ColumnOptionKey = "yearToDate"
  const previousYTDKey: ColumnOptionKey = "deltaYearToDate"
  const { interval } = useReportComponentIntervalOrigin()
  const { options, mutation, loading } = useReportOptionsContext()
  const ytdOn = isOptionOn(options, ytdKey)
  const previousYTDOn = isOptionOn(options, previousYTDKey)
  const disabled = loading

  const onChange = React.useCallback(
    (key: ColumnOptionKey, newValue: OptionValue) => mutation?.({ [key]: newValue }),
    [mutation]
  )

  if (!useIsSupportedReportOption(ytdKey) || interval === Interval.Year) return null

  return (
    <DropDownOptionsContainer expanded={ytdOn}>
      <Option optionKey={ytdKey} disabled={disabled} />
      {ytdOn && (
        <DropDownOptions>
          <Option
            optionKey={previousYTDKey}
            defaultValue={ReportOptionComparison.Total}
            disabled={disabled}
          />
          {previousYTDOn && (
            <DropDown optionKey="deltaYearToDate" onChange={onChange} disabled={disabled} />
          )}
        </DropDownOptions>
      )}
    </DropDownOptionsContainer>
  )
}

const DropDown: React.FC<DropDownProps> = ({ optionKey, onChange, disabled }) => {
  const { interval } = useReportComponentIntervalOrigin()
  const { options } = useReportOptionsContext()
  const dropDownOptions = dropDownValues(optionKey, interval)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const firstOption = dropDownOptions[0]!
  const label = optionValueToLabel(optionKey, firstOption)
  const selectedOption = options?.[optionKey] ?? firstOption

  const onDropdownChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { currentTarget } = e
      const { value } = currentTarget
      onChange?.(optionKey, value as OptionValue)
    },
    [onChange, optionKey]
  )

  return (
    <DropDownContainer>
      <OptionLabel>{label}</OptionLabel>
      <OptionsDropDownRightWrapper css="width: auto;">
        <Dropdown onChange={onDropdownChange} value={selectedOption} disabled={disabled}>
          {dropDownOptions.map((option) => (
            <option key={option} value={option}>
              {optionValueToOptionLabel(optionKey, option, interval)}
            </option>
          ))}
        </Dropdown>
      </OptionsDropDownRightWrapper>
    </DropDownContainer>
  )
}

export const OptionInput = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ title, ...rest }, ref) => (
  <OptionInputContainer>
    <OptionRow>
      <OptionTitle>{title || "Title"}</OptionTitle>
    </OptionRow>
    <GroupOptions>
      <OptionInputField ref={ref} {...rest} />
    </GroupOptions>
  </OptionInputContainer>
))

export const OptionText = React.forwardRef<
  HTMLTextAreaElement,
  React.TextareaHTMLAttributes<HTMLTextAreaElement>
>(({ title, ...rest }, ref) => (
  <OptionInputContainer>
    <OptionRow>
      <OptionTitle>{title || "Title"}</OptionTitle>
    </OptionRow>
    <GroupOptions>
      <OptionTextAreaField ref={ref} {...rest} />
    </GroupOptions>
  </OptionInputContainer>
))

interface OptionDateProps extends React.InputHTMLAttributes<HTMLInputElement> {
  defaultDate?: Date
  onDateChange?: (date: Date) => Promise<unknown>
  location?: DayPickerLocation
}

export const OptionDate = React.forwardRef<HTMLInputElement, OptionDateProps>(
  ({ title, defaultDate, onDateChange, ...rest }, ref) => (
    <OptionInputContainer>
      <OptionRow>
        <OptionTitle>{title || "Title"}</OptionTitle>
      </OptionRow>
      <GroupOptions>
        <OptionDateField
          {...rest}
          defaultDate={defaultDate}
          onDateChange={onDateChange}
          dayPickerProps={{ showOutsideDays: true }}
        />
      </GroupOptions>
    </OptionInputContainer>
  )
)

function useOptionKeyToLabel(optionKey: ColumnOptionKey) {
  const { interval } = useReportComponentIntervalOrigin()
  switch (optionKey) {
    case "comparedToTotal":
      return "Show percentage of total"
    case "comparedToIncome":
      return "Show percentage of income"
    case "amountPrecision":
      return "Number Format"
    case "deltaMonthOverMonth": {
      switch (interval) {
        case Interval.Year:
          return "Year over Year"
        case Interval.Quarter:
          return "Quarter over Quarter"
        case Interval.Month:
        default:
          return "Month over Month"
      }
    }
    case "deltaYearOverYear":
      return "Year over Year"
    case "yearToDate":
      return "Year to Date (YTD)"
    case "deltaYearToDate":
      return "Previous YTD"
    case "deltaMonthOverMonthPeriods": {
      const adverb = dateTimeHelper.displayNameForIntervalAdverb(interval)
      return `${stringHelper.capitalize(adverb)} Breakdown`
    }
    case "sparklineLookbackPeriods":
      return "Show trendline"
  }
}

function optionValueToLabel(optionKey: ColumnOptionKey, value: OptionValue) {
  switch (optionKey) {
    case "deltaMonthOverMonthPeriods":
      return "Count"
    case "sparklineLookbackPeriods":
      return "Interval"
    case "amountPrecision":
      return "Number Format"
    case "deltaYearOverYear":
    case "yearToDate":
    case "deltaMonthOverMonth":
    case "deltaYearToDate":
      return "Variance"
    case "comparedToTotal":
    case "comparedToIncome":
      return value
  }
}

function isOptionOn(
  options: Maybe<ReportDocumentOptions> | undefined,
  optionKey: ColumnOptionKey,
  ...onValues: OptionValue[]
) {
  const value = options?.[optionKey]
  if (!value || value === optionOffValue(optionKey)) return false
  return onValues?.length ? onValues.includes(value) : true
}

function optionOffValue(optionKey: ColumnOptionKey) {
  switch (optionKey) {
    case "comparedToTotal":
    case "comparedToIncome":
    case "deltaMonthOverMonth":
    case "deltaYearOverYear":
    case "yearToDate":
    case "deltaYearToDate":
      return ReportOptionComparison.InvalidComparison

    case "sparklineLookbackPeriods":
    case "deltaMonthOverMonthPeriods":
    default:
      return null
  }
}

function optionValue(optionKey: ColumnOptionKey, on: boolean, defaultValue?: OptionValue | null) {
  if (!on) return optionOffValue(optionKey)

  if (defaultValue) return defaultValue

  switch (optionKey) {
    case "comparedToTotal":
    case "comparedToIncome":
      return ReportOptionComparison.Percent

    case "deltaMonthOverMonth":
    case "deltaYearOverYear":
    case "yearToDate":
    case "deltaYearToDate":
      return ReportOptionComparison.Total

    case "deltaMonthOverMonthPeriods":
      return 1

    case "amountPrecision":
      return ReportNumberPrecision.Rounded

    case "sparklineLookbackPeriods":
      return LOOKBACK_PERIOD_COUNTS[0]
  }
}

function dropDownValues(optionKey: ColumnOptionKey, interval: Interval): OptionValue[] {
  switch (optionKey) {
    case "comparedToTotal":
      return [ReportOptionComparison.Percent]

    case "comparedToIncome":
      return [ReportOptionComparison.Percent]

    case "amountPrecision":
      return [ReportNumberPrecision.Rounded, ReportNumberPrecision.Decimal]

    case "deltaMonthOverMonth":
      return [ReportOptionComparison.Percent, ReportOptionComparison.Amount]

    case "deltaYearOverYear":
    case "yearToDate":
    case "deltaYearToDate":
      return [
        ReportOptionComparison.Total,
        ReportOptionComparison.Percent,
        ReportOptionComparison.Amount,
      ]

    case "deltaMonthOverMonthPeriods": {
      const max = maxBreakdownsPerInterval(interval)
      return Array(max)
        .fill(null)
        .map((_, i) => i + 1)
    }

    case "sparklineLookbackPeriods":
      return LOOKBACK_PERIOD_COUNTS
  }
}

function maxBreakdownsPerInterval(interval: Interval) {
  switch (interval) {
    case Interval.Month:
      return 11

    case Interval.Year:
    case Interval.Quarter:
      return 9
  }
  return 1
}

function optionValueToOptionLabel(
  optionKey: ColumnOptionKey,
  value: OptionValue,
  interval?: Interval
) {
  switch (value) {
    case ReportOptionComparison.Amount:
      return "Delta"

    case ReportOptionComparison.Total:
      return "None"

    case ReportOptionComparison.Percent:
      return value

    case ReportNumberPrecision.Rounded:
    case ReportNumberPrecision.Decimal:
      return value

    case ReportOptionComparison.InvalidComparison:
      return ReportOptionComparison.Total

    default: {
      if (optionKey === "deltaMonthOverMonthPeriods") return value

      const intervalName = dateTimeHelper.displayNameForInterval(
        interval || Interval.Month,
        value as number
      )
      return `Last ${value} ${stringHelper.capitalize(intervalName)}`
    }
  }
}
