import * as React from "react"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { InputBlock, InputProps, InputVariant } from "./Input"
import { INPUT_INVALID_CLASS_NAME, INPUT_STYLES } from "./InputStyles"
import { InputLabel, InputSaveStatus } from "./Label"

/*
  STYLES
*/

export const TextareaStyled = styled.textarea<{ $variant?: InputVariant }>`
  ${INPUT_STYLES};

  height: 60px;
  resize: none;
`

const CharacterLimit = styled.div<{ isValid: CharacterLimitValid }>`
  color: ${(props) => {
    switch (props.isValid) {
      case CharacterLimitValid.Invalid:
        return colors.orange
      case CharacterLimitValid.Exact:
        return colors.gray
      case CharacterLimitValid.Valid:
        return colors.altoGray
    }
  }};
  font-weight: ${fonts.weight.normal};
  font-size: 13px;
  text-align: right;
`

const TextAreaError = styled.div`
  color: ${colors.orange};
  font-weight: ${fonts.weight.normal};
  font-size: 11px;
  text-align: right;
`

/*
  INTERFACE
*/

enum CharacterLimitValid {
  Invalid = "Invalid",
  Exact = "Exact",
  Valid = "Valid",
}

export type TextareaElementProps = InputProps &
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > & {
    // Using this over native max-length textarea props so we can allow for typing to go over max.
    // With maxLength set, the textarea will no longer accept inputs which is not what we want.
    "data-maxcharacters"?: number
    forwardRef?: React.RefObject<HTMLTextAreaElement>
    errorMessage?: string
  }

/*
  COMPONENT
*/

export const Textarea: React.FC<TextareaElementProps> = (props) => {
  const {
    ref,
    forwardRef,
    isInvalid,
    disabled,
    saveStatus,
    onAnimationEnd,
    label,
    inputWidth,
    className,
    ...rest
  } = props
  const pending = saveStatus === InputSaveStatus.Pending

  return (
    <InputBlock className={isInvalid ? INPUT_INVALID_CLASS_NAME : ""} inputWidth={inputWidth}>
      {label && (
        <InputLabel label={label} saveStatus={saveStatus} onAnimationEnd={onAnimationEnd} />
      )}
      <TextareaStyled
        disabled={disabled || pending}
        ref={forwardRef}
        className={className}
        {...rest}
      />
      {props["data-maxcharacters"] && renderTextareaCharacterLimit(props)}
      {props.errorMessage && renderTextareaError(props)}
    </InputBlock>
  )
}

const renderTextareaCharacterLimit = (props: TextareaElementProps) => {
  if (!props["data-maxcharacters"]) return

  const { length } = (props.value === undefined ? "" : props.value).toString()
  const isValid =
    length < props["data-maxcharacters"]
      ? CharacterLimitValid.Valid
      : length === props["data-maxcharacters"]
        ? CharacterLimitValid.Exact
        : CharacterLimitValid.Invalid

  return (
    <CharacterLimit isValid={isValid}>
      {length} out of {props["data-maxcharacters"]} characters
    </CharacterLimit>
  )
}

const renderTextareaError = (props: TextareaElementProps) => {
  if (!props.errorMessage) return
  return <TextAreaError>{props.errorMessage}</TextAreaError>
}
