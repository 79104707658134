import { InviteClientAction } from "src/frontend/components/OS/Springboard/Applications/ClientPortal/InviteFlow/State/actions"
import {
  ClientStatus,
  ContactWithStatus,
  InviteClientState,
} from "src/frontend/components/OS/Springboard/Applications/ClientPortal/InviteFlow/State/types"
import { InviteClientsModalState } from "src/frontend/components/Shared/Portals/State/types"

export const DEFAULT_STATE: InviteClientState = {
  modalState: InviteClientsModalState.InviteClients,
  invitees: [],
  displayEmailInviteForm: true,
  sendingInvitations: false,
  showAutocomplete: false,
  showViewUsers: false,
  autocompleteUsers: [],
  validClientInForm: undefined,
  inviteFormValid: false,
  arrivalContext: undefined,
  contextObject: undefined,
  userNoun: "user",
  userPluralNoun: "users",
}

export function reducer(curState: InviteClientState, action: InviteClientAction) {
  const nextState: InviteClientState = { ...curState }

  switch (action.type) {
    case "setModalState": {
      nextState.modalState = action.modalState
      return nextState
    }

    case "setDisplayEmailInviteForm": {
      nextState.displayEmailInviteForm = action.display
      return nextState
    }

    case "addInvitee": {
      nextState.invitees = [
        ...nextState.invitees,
        {
          ...action.client,
          status: ClientStatus.Pending,
        },
      ]
      return nextState
    }

    case "deleteInvitee": {
      nextState.invitees = nextState.invitees.filter(
        (c: ContactWithStatus) => c.emailAddress !== action.client.emailAddress
      )
      return nextState
    }

    case "resetInvitees": {
      nextState.invitees = []
      return nextState
    }

    case "updateStatus": {
      const clientIndex = nextState.invitees.findIndex(
        (c: ContactWithStatus) => c.emailAddress === action.client.emailAddress
      )

      if (clientIndex !== -1) {
        nextState.invitees = nextState.invitees.slice()
        nextState.invitees[clientIndex] = {
          ...action.client,
          status: action.status,
        }
      }
      return nextState
    }

    case "setSendingInvitations": {
      nextState.sendingInvitations = action.sendingInvitations
      return nextState
    }

    case "setErrorMessage": {
      nextState.errorMessage = action.message
      return nextState
    }

    case "showAutocomplete": {
      nextState.showAutocomplete = true
      return nextState
    }

    case "hideAutocomplete": {
      nextState.showAutocomplete = false
      return nextState
    }

    case "setAutocompleteUsers": {
      nextState.autocompleteUsers = action.autocompleteUsers
      return nextState
    }

    case "setInviteFormValid": {
      nextState.inviteFormValid = action.valid
      return nextState
    }

    case "setValidClientInForm": {
      nextState.validClientInForm = action.client
      return nextState
    }

    case "setIsAffiliatedSession": {
      nextState.userNoun = action.isAffiliatedSession ? "client" : "colleague"
      nextState.userPluralNoun = action.isAffiliatedSession ? "clients" : "colleagues"
      return nextState
    }

    default: {
      return curState
    }
  }
}
