import * as React from "react"
import {
  EntityCategory,
  EntityParty,
  ObjectEntities,
  PartyRole,
} from "@digits-graphql/frontend/graphql-bearer"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import { useOnScreen } from "@digits-shared/hooks/useOnScreen"
import useRouter from "@digits-shared/hooks/useRouter"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { MatchedComponent } from "src/frontend/components/Shared/Layout/types"
import { CategorySummary } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Components/Search/CategorySummary"
import { PartySummary } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Components/Search/PartySummary"
import { CategoryIconStyled } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopCategoriesList"
import { TopPartyIcon } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopPartiesList"
import {
  ListItem,
  SummaryListData,
  TopList,
} from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/WidgetSummaryList"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

const DEFAULT_KIND_LIMIT = 5

/*
  STYLES
*/

const List = styled(TopList)`
  ${ListItem} {
    grid-template-columns: 2px 18px 3fr 1fr 2px;
    grid-column-gap: 6px;
  }
`
const Title = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
`

const Name = styled.div`
  font-size: 12px;
  font-weight: ${fonts.weight.medium};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const Spacer = styled.div`
  margin: 16px 0;
`

/*
  INTERFACES
*/

export type EntitiesListProps = {
  component: MatchedComponent<"entitiesList">
  kindLimit?: number
  skipSummaries?: boolean
}

/*
  COMPONENTS
*/

const CategoryIcon: React.FC<SummaryListData<EntityCategory>> = ({ data }) => {
  if (!data) return null

  const { displayKey, name } = data
  return <CategoryIconStyled subjectDisplayKey={displayKey || name} />
}
const PartyIcon: React.FC<SummaryListData<EntityParty>> = ({ data }) => {
  if (!data) return null
  return <TopPartyIcon size={IconSize.Small} party={data} />
}

const NameLabel: React.FC<SummaryListData<EntityCategory | EntityParty>> = ({ data }) => {
  if (!data) return null

  const { name } = data
  return <Name>{name}</Name>
}

const EmptySummary: React.FC = () => null

export const EntitiesList: React.FC<EntitiesListProps> = ({
  component,
  kindLimit,
  skipSummaries: alwaysSkipSummaries,
}) => {
  const { history } = useRouter()
  const generatePath = useFrontendPathGenerator()
  const {
    config: { entitiesList: config },
  } = component

  const listRef = React.useRef<HTMLDivElement | null>(null)
  const isVisible = useOnScreen(listRef)
  // Defer summary queries until the components are visible.
  // Avoids unnecesary calls and reduces errors for old components (e.g. with deleted parties).
  const skipSummaries = alwaysSkipSummaries || !isVisible

  const onCategoryClick = React.useCallback(
    (category: EntityCategory) => {
      if (!config) return
      const { interval, year, index, intervalCount } = config.origin

      history.push(
        generatePath(routes.categoryDetails, {
          categoryId: category.id,
          interval,
          year,
          index,
          intervalCount,
        })
      )
    },
    [config, generatePath, history]
  )

  const onPartyClick = React.useCallback(
    (partyRole: PartyRole) => (party: EntityParty) => {
      if (!config) return
      const { interval, year, index, intervalCount } = config.origin
      history.push(
        generatePath(routes.partyDetails, {
          partyId: party.id,
          partyRole: FrontendPartyRole.roleURLKey(partyRole),
          interval,
          year,
          index,
          intervalCount,
        })
      )
    },
    [config, generatePath, history]
  )

  const data = useEntitiesByKind(config?.entities, kindLimit)

  return (
    <div ref={listRef}>
      {data?.categories && (
        <>
          <Title>Categories</Title>
          <List
            IconComponent={CategoryIcon}
            LabelComponent={NameLabel}
            SummaryComponent={skipSummaries ? EmptySummary : CategorySummary}
            loading={false}
            onClick={onCategoryClick}
            activeIndex={undefined}
            data={data.categories}
          />
          <Spacer />
        </>
      )}
      {data?.vendors && (
        <>
          <Title>Vendors</Title>
          <List
            IconComponent={PartyIcon}
            LabelComponent={NameLabel}
            SummaryComponent={skipSummaries ? EmptySummary : PartySummary}
            loading={false}
            onClick={onPartyClick(PartyRole.EntityVendorRole)}
            activeIndex={undefined}
            data={data.vendors}
          />
          <Spacer />
        </>
      )}
      {data?.customers && (
        <>
          <Title>Customers</Title>
          <List
            IconComponent={PartyIcon}
            LabelComponent={NameLabel}
            SummaryComponent={skipSummaries ? EmptySummary : PartySummary}
            loading={false}
            onClick={onPartyClick(PartyRole.EntityCustomerRole)}
            activeIndex={undefined}
            data={data.customers}
          />
          <Spacer />
        </>
      )}
    </div>
  )
}

function useEntitiesByKind(entities?: ObjectEntities, limit?: number) {
  return React.useMemo(() => {
    if (!entities) return undefined

    const limitOrDefault = limit || DEFAULT_KIND_LIMIT

    const { categories } = entities
    const customers = entities.parties?.filter((p) =>
      (p.roles || []).includes(PartyRole.EntityCustomerRole)
    )
    const vendors = entities.parties?.filter((p) =>
      (p.roles || []).includes(PartyRole.EntityVendorRole)
    )

    return {
      categories: categories ? categories.slice(0, limitOrDefault) : undefined,
      customers: customers?.length ? customers.slice(0, limitOrDefault) : undefined,
      vendors: vendors?.length ? vendors.slice(0, limitOrDefault) : undefined,
    }
  }, [entities, limit])
}
