import * as React from "react"
import { DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import useRouter from "@digits-shared/hooks/useRouter"
import routes from "src/frontend/routes"

export const PASSWORD_RESET_TOKEN_PARAM = "t"

export function usePasswordResetToken() {
  const { location } = useRouter()

  return React.useMemo(() => {
    const route: DigitsRoute | undefined = routes[location.name]
    return route?.currentQueryParams().get(PASSWORD_RESET_TOKEN_PARAM) || undefined
  }, [location.name])
}
