import * as React from "react"
import { Role } from "@digits-graphql/frontend/graphql-bearer"
import {
  PointingDirection,
  themedChevronStyles,
} from "@digits-shared/components/UI/Elements/Chevron"
import { PopUpCoordinates } from "@digits-shared/components/UI/Elements/PopUp/PopUp"
import { PickerBubble } from "@digits-shared/components/UI/Picker/Bubble"
import { PickerContainer } from "@digits-shared/components/UI/Picker/Picker"
import { usePickerState } from "@digits-shared/components/UI/Picker/usePickerState"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { UserRoleOption } from "src/frontend/hooks/useUserRole"
import {
  PickerBubbleRoleIcon,
  RolePickerPopUp,
  RoleRowLabel,
} from "src/shared/components/Role/RolePickerPopUp"

/*
  STYLES
*/

const RoleBubblePickerStyled = styled(PickerBubble)<{ isHighlighted?: boolean }>`
  background: ${colors.translucentWhite50};
  transition: border-color 200ms ease;
  color: ${colors.secondary};
  border: 0.5px solid ${colors.secondary30};

  &::after {
    ${({ noChevron }) =>
      themedChevronStyles(PointingDirection.Down, "3px", {
        light: noChevron ? colors.transparent : colors.secondary,
        dark: noChevron ? colors.transparent : colors.secondary,
      })};
  }

  ${({ isDimmed }) =>
    isDimmed &&
    css`
      color: ${colors.translucentSecondary40};
    `}
`

const RolePickerPopUpStyled = styled(RolePickerPopUp)`
  border: 1px solid ${colors.white};
  background: ${colors.secondary05};
`

/*
 INTERFACES
*/

interface Props {
  width: number
  bubbleHeight?: number
  popUpWidth?: number
  popMaxHeight?: number
  chevronDirection?: PointingDirection
  coordinates?: PopUpCoordinates
  currentRole?: UserRoleOption
  roles: UserRoleOption[]
  isDisabled?: boolean
  isLocked?: boolean
  isHighlighted?: boolean
  onRolePick: (role: Role) => Promise<void>
  onPickerActiveChange?: (isActive: boolean) => void
  preventClose?: boolean
  className?: string
}

/*
 COMPONENTS
*/

export const RoleBubblePicker: React.FC<Props> = ({
  width,
  bubbleHeight,
  popUpWidth = 400,
  popMaxHeight,
  chevronDirection,
  coordinates,
  currentRole,
  roles,
  isDisabled,
  isLocked,
  isHighlighted,
  onRolePick,
  onPickerActiveChange,
  preventClose,
  className,
}) => {
  const {
    isVisible: isPickerActive,
    setVisible: showPicker,
    setHidden: hidePicker,
    pickerPosition,
    containerRef: ref,
  } = usePickerState(popMaxHeight || 230)

  React.useEffect(() => {
    onPickerActiveChange?.(isPickerActive)
  }, [isPickerActive, onPickerActiveChange])

  const onPick = React.useCallback(
    (role: UserRoleOption) => {
      onRolePick(role)
        .then(hidePicker)
        .catch((reason: string) => {
          if (reason) console.error(reason)
          hidePicker()
        })
    },
    [hidePicker, onRolePick]
  )

  const onPickerClose = React.useCallback(() => {
    hidePicker()
  }, [hidePicker])

  const isDimmed = isDisabled || isLocked || !currentRole

  return (
    <PickerContainer ref={ref} width={width} className={className}>
      <RoleBubblePickerStyled
        height={bubbleHeight}
        LabelComponent={RoleRowLabel}
        labelProps={{ isLocked }}
        element={currentRole}
        onClick={showPicker}
        isHighlighted={isHighlighted}
        isDimmed={isDimmed}
        isDisabled={isDisabled}
        noChevron={isLocked}
        IconComponent={PickerBubbleRoleIcon}
      />
      {isPickerActive && (
        <RolePickerPopUpStyled
          width={popUpWidth}
          maxHeight={popMaxHeight}
          chevronDirection={chevronDirection}
          pickerPosition={pickerPosition}
          coordinates={
            coordinates || {
              right: 0,
            }
          }
          roles={roles}
          currentRole={currentRole}
          onRolePick={onPick}
          onClose={!preventClose ? onPickerClose : undefined}
        />
      )}
    </PickerContainer>
  )
}
