import React from "react"
import {
  DirectionFromOrigin,
  Interval,
  useListBoostBadgeCountsQuery,
  WorkItemReviewState,
  WorkItemType,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { defined } from "@digits-shared/helpers/filters"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import { OPEN_STATES } from "src/frontend/components/OS/Accountant/Applications/Boost/WorkItems/hooks/boostTypes"
import FrontendSession from "src/frontend/session/FrontendSession"
import SessionLegalEntity from "src/frontend/session/LegalEntity"

export function useTransactionReviewBadgeCountsQuery(legalEntityIds: string[], skip?: boolean) {
  const session = useSession<FrontendSession>()
  const legalEntitiesWithAuditAspect = legalEntityIds
    .map((legalEntityId) => session.findLegalEntityById(legalEntityId))
    .filter<SessionLegalEntity>(defined)
    .filter((legalEntity) => legalEntity.hasAccessToAspect(AspectCode.Audit))
    .map((legalEntity) => legalEntity.id)

  const origin = dateTimeHelper.todayIntervalOrigin(Interval.Month, 2)

  const result = useListBoostBadgeCountsQuery({
    variables: {
      origin,
      direction: DirectionFromOrigin.Past,
      filter: {
        itemStates: OPEN_STATES,
        itemTypes: [WorkItemType.UnassignedCategory],
        legalEntityIds: legalEntitiesWithAuditAspect,
        reviewStatesFilter: {
          reviewStates: [WorkItemReviewState.ClientResponded],
          includeNullReviewState: true,
        },
      },
    },
    skip: skip || !legalEntitiesWithAuditAspect.length,
  })

  return React.useMemo(() => {
    const counts = result?.data?.listBoostBadgeCounts
    return { counts, loading: result.loading }
  }, [result])
}
