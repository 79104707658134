import * as React from "react"
import {
  Interval,
  LayoutComponentType,
  ObjectEntities,
} from "@digits-graphql/frontend/graphql-bearer"
import { CopyToClipboard } from "@digits-shared/components/UI/Elements/CopyToClipboard"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import envHelper from "@digits-shared/helpers/envHelper"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import moment from "moment"
import styled from "styled-components"
import { v4 as generateUUID } from "uuid"
import { useAssistantContext } from "src/frontend/components/Shared/Assistant/Context"
import { AskAgainResponse } from "src/frontend/components/Shared/Assistant/Messages/AskAgainResponse"
import { AssistantMessageHeader } from "src/frontend/components/Shared/Assistant/Messages/Header"
import {
  AssistantLayout,
  AssistantLayoutAttachment,
} from "src/frontend/components/Shared/Assistant/Messages/Layout"
import { TextComponent } from "src/frontend/components/Shared/Assistant/Messages/Layout/Text/TextComponent"
import {
  AssistantDigitsThinking,
  AssistantLoadingMessage,
} from "src/frontend/components/Shared/Assistant/Messages/Loading"
import {
  AssistantMessageBubble,
  AssistantMessageGrid,
} from "src/frontend/components/Shared/Assistant/Messages/Shared"
import { Suggestion } from "src/frontend/components/Shared/Assistant/Messages/Suggestion"
import { AssistantWelcomeMessage } from "src/frontend/components/Shared/Assistant/Messages/Welcome"
import { AssistantMessage } from "src/frontend/components/Shared/Assistant/reducer"
import { MatchedComponent } from "src/frontend/components/Shared/Layout/types"
import FrontendSession from "src/frontend/session"
import { DIGITS_CANONICAL_USER_ID } from "src/shared/config/identifiers"

/*
  STYLES
*/

const DayDivide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: ${fonts.weight.medium};
  color: rgba(50, 73, 127, 0.5);
  margin-bottom: 10px;

  &::before,
  &::after {
    background-color: rgba(50, 73, 127, 0.5);
    width: 14px;
    height: 1px;
    content: "";
  }

  &::before {
    margin-right: 5px;
  }

  &::after {
    margin-left: 5px;
  }
`

const InternalDebug = styled.div<{ isCurrentUser?: boolean }>`
  position: relative;
  top: 8px;
  font-size: 8px;
  color: ${({ isCurrentUser }) =>
    isCurrentUser ? colors.payWhite30 : colors.translucentSecondary30};
  cursor: default;

  &:hover {
    color: ${colors.translucentSecondary80};
  }
`

const Suggestions = styled.div`
  margin-top: 10px;
`

/*
  COMPONENTS
*/

export const AssistantMessages: React.FC = () => {
  const { state } = useAssistantContext()
  const { user } = useSession<FrontendSession>()
  const { messagesByDay, todayTimestamp } = useMessagesByDay()

  if (!state.initialThreadFetch) return <AssistantLoadingMessage />
  if (!state.messages.size && !state.isSubmitting) return <AssistantWelcomeMessage />

  return (
    <>
      {messagesByDay.map(({ timestamp, byDay }) => (
        <React.Fragment key={timestamp}>
          <DayDivide>
            {timestamp === todayTimestamp
              ? "Today"
              : dateTimeHelper.displayNameFromMoment(moment.unix(timestamp).utc(), Interval.Day)}
          </DayDivide>
          {byDay.map((message) => (
            <Message
              key={message.comment.id}
              message={message}
              isCurrentUser={message.comment.authorId === user.id}
              entities={state.threadEntities}
            />
          ))}
        </React.Fragment>
      ))}
      <CurrentUnsavedMessage isMessagesEmpty={!messagesByDay.length} />
      {state.isSubmitting && !state.activeAssistantMessageId && <AssistantDigitsThinking />}
    </>
  )
}

const CurrentUnsavedMessage: React.FC<{
  isMessagesEmpty: boolean
}> = ({ isMessagesEmpty }) => {
  const { state } = useAssistantContext()
  const { user } = useSession<FrontendSession>()

  const message = React.useMemo(
    () => ({
      comment: {
        id: generateUUID(),
        authorId: user.id,
        timestamp: Date.now() / 1000,
        text: state.currentMessageText || "",
      },
    }),
    [state.currentMessageText, user]
  )

  // render the user comment optimistically until it comes in as an event
  if (!state.isSubmitting || !message.comment.text || state.activeAssistantMessageId) {
    return null
  }

  return (
    <>
      {isMessagesEmpty && <DayDivide>Today</DayDivide>}
      <Message message={message} isCurrentUser entities={state.threadEntities} />
    </>
  )
}

const Message: React.FC<{
  isCurrentUser?: boolean
  entities?: ObjectEntities | null
  message: AssistantMessage
}> = ({ isCurrentUser, entities, message }) => (
  <AssistantMessageGrid isCurrentUser={isCurrentUser}>
    <AssistantMessageHeader message={message} />
    <AssistantMessageBubble>
      <AssistantComponent message={message} />
      {!!message.suggestions?.length && (
        <Suggestions>
          {message.suggestions.map((suggestion, index) => (
            <Suggestion message={suggestion} key={index} />
          ))}
        </Suggestions>
      )}
      <AskAgainResponse message={message} />
      <InternalTools isCurrentUser={isCurrentUser} message={message} />
    </AssistantMessageBubble>
  </AssistantMessageGrid>
)

const AssistantComponent: React.FC<{ message: AssistantMessage }> = ({ message }) => {
  if (message.layout) {
    return <AssistantLayout layout={message.layout} />
  }

  if (message.comment.layoutAttachment) {
    return (
      <AssistantLayoutAttachment
        comment={message.comment}
        attachment={message.comment.layoutAttachment}
      />
    )
  }

  return <CommentTextComponent message={message} />
}

const CommentTextComponent: React.FC<{ message: AssistantMessage }> = ({ message }) => {
  const component = React.useMemo(
    (): MatchedComponent<"text"> => ({
      componentId: message.comment.id,
      config: { type: LayoutComponentType.Text, text: { body: message.comment.text } },
    }),
    [message]
  )

  return <TextComponent component={component} toolbarPortalElId={undefined} />
}

const InternalTools: React.FC<{ isCurrentUser?: boolean; message: AssistantMessage }> = ({
  isCurrentUser,
  message,
}) => {
  const { isDigitsEmployee } = useSession<FrontendSession>()
  if (!isDigitsEmployee && envHelper.isProduction()) return null

  return (
    <InternalDebug isCurrentUser={isCurrentUser}>
      <CopyToClipboard value={message.comment.id}>
        {(copied) =>
          copied ? "Comment ID copied to clipboard" : `Comment ID: ${message.comment.id}`
        }
      </CopyToClipboard>
    </InternalDebug>
  )
}

function useMessagesByDay() {
  const { state } = useAssistantContext()

  return React.useMemo(() => {
    const messagesByDayMap = Array.from(state.messages.values()).reduce((acc, message) => {
      const currentDay = currentDateForMessage(message)
      const forDay = acc.get(currentDay) || new Map<string, AssistantMessage>()
      forDay.set(message.comment.id, message)
      acc.set(currentDay, forDay)
      return acc
    }, new Map<number, Map<string, AssistantMessage>>())

    const messagesByDay = Array.from(messagesByDayMap.entries()).map(([timestamp, byDay]) => ({
      timestamp,
      byDay: Array.from(byDay.values()).sort((a: AssistantMessage, b: AssistantMessage) => {
        // sort the comments by timestamp and then by authorId, making sure the reply comes after the user prompt
        if (a.comment.timestamp === b.comment.timestamp) {
          return a.comment.authorId === DIGITS_CANONICAL_USER_ID ? 1 : -1
        }
        return a.comment.timestamp - b.comment.timestamp
      }),
    }))

    return { messagesByDay, todayTimestamp: moment().utc().startOf("day").unix() }
  }, [state.messages])
}

function currentDateForMessage(message: AssistantMessage) {
  return moment.unix(message.comment.timestamp).utc().startOf("day").unix()
}
