import * as React from "react"
import { useEvent, useKeyPressEvent } from "react-use"
import { UseEventOptions, UseEventTarget } from "react-use/lib/useEvent"
import { Handler } from "react-use/lib/useKey"

export function useKeysPressEvent(
  keys: string[],
  keydown?: Handler | null | undefined,
  keyup?: Handler | null | undefined
) {
  useKeyPressEvent((event: KeyboardEvent) => keys.includes(event.key), keydown, keyup)
}

export function useKeyDownEvent<T extends UseEventTarget>(
  keyOrKeys: string | string[],
  keydown?: Handler | null | undefined,
  target?: Window | T | null,
  options?: UseEventOptions<T> | undefined
) {
  useKeyEvent("keydown", keyOrKeys, keydown, target, options)
}

export function useKeyUpEvent<T extends UseEventTarget>(
  keyOrKeys: string | string[],
  keyup?: Handler | null | undefined,
  target?: Window | T | null,
  options?: UseEventOptions<T> | undefined
) {
  useKeyEvent("keyup", keyOrKeys, keyup, target, options)
}

function useKeyEvent<T extends UseEventTarget>(
  event: "keydown" | "keyup",
  keyOrKeys: string | string[],
  handler?: Handler | null | undefined,
  target?: Window | T | null,
  options?: UseEventOptions<T> | undefined
) {
  const onKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      const keys = Array.isArray(keyOrKeys) ? keyOrKeys : [keyOrKeys]
      if (keys.includes(e.key)) {
        handler?.(e)
      }
    },
    [keyOrKeys, handler]
  )
  useEvent(event, onKeyDown, target, options)
}
