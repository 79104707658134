import * as React from "react"
import fonts from "@digits-shared/themes/typography"
import moment from "moment"
import styled from "styled-components"

const Title = styled.div`
  font-style: ${fonts.style.normal};
  text-transform: uppercase;
  flex-shrink: 1;
  font-size: 11px;
`

export const DateTitle: React.FC<{
  occurredAfter?: Date
  occurredBefore?: Date
  className?: string
}> = ({ occurredAfter, occurredBefore, className }) => {
  const after = moment(occurredAfter).utc()
  const before = moment(occurredBefore).utc()

  if (occurredAfter && occurredBefore) {
    return (
      <Title className={className}>
        {after.format("MMMM D")} - {before.format("MMMM D")}, {before.format("YYYY")}
      </Title>
    )
  }

  if (occurredAfter && !occurredBefore) {
    return <Title className={className}>Since {after.format("MMMM D, YYYY")}</Title>
  }

  return <Title className={className}>All time</Title>
}
