import * as React from "react"
import { CategoryChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/CategoryChartComponent"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigLookbackOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import {
  ChartType,
  MatchedComponent,
  SizingProps,
} from "src/frontend/components/Shared/Layout/types"
import { useCategorySummaryByTimeLiveData } from "src/frontend/components/Shared/Portals/hooks/useCategorySummaryByTimeLiveData"

/*
  INTERFACES
*/

interface CategoryChartComponentProps extends SizingProps {
  component: MatchedComponent<"categoryChart">
  componentSize: ComponentSize
  chartType: ChartType
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const CategoryPortalComponent: React.FC<CategoryChartComponentProps> = ({
  component,
  componentSize,
  chartType,
  height,
  skipAnimations,
}) => {
  const config = useConfigLookbackOriginOverride(component.config.categoryChart)

  const { category, dimensionalSummary } = useCategorySummaryByTimeLiveData(
    config,
    component.config.type
  )
  return (
    <CategoryChartComponent
      category={category}
      summaries={dimensionalSummary?.time}
      origin={config.origin}
      component={component}
      chartType={chartType}
      componentSize={componentSize}
      height={height}
      skipAnimations={skipAnimations}
    />
  )
}
