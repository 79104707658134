import * as React from "react"
import * as ReactDOM from "react-dom"
import {
  ListActionItemsAndBadgeCountDocument,
  ReadUserAclDocument,
  useNotificationCreatedSubscription,
  useUpdateNotificationStatusMutation,
  WebNotificationStatus,
} from "@digits-graphql/frontend/graphql-bearer"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import useSession from "@digits-shared/hooks/useSession"
import { AlertsList } from "src/frontend/components/Shared/Alerts/AlertsList"
import { useChromeAlertContext } from "src/frontend/components/Shared/Alerts/ChromeAlertsContext"
import FrontendSession from "src/frontend/session"

/*
  COMPONENTS
*/

export const ChromeAlerts: React.FC = () => {
  const session = useSession<FrontendSession>()
  const alertRoot = useModalRoot()
  const [markReceived] = useUpdateNotificationStatusMutation({
    refetchQueries: [ReadUserAclDocument, ListActionItemsAndBadgeCountDocument],
  })
  const { addAlert, reset } = useChromeAlertContext()

  // When unmounting, ensure that the state held by the context is cleared.
  React.useEffect(() => () => reset(), [reset])

  const { data } = useNotificationCreatedSubscription({
    onData: (options) => {
      const id = options.data.data?.notificationCreated.id
      if (!id) return

      return markReceived({
        variables: {
          id,
          status: WebNotificationStatus.Received,
        },
      })
    },
    skip: !session.hasUserData,
  })

  React.useEffect(
    () => {
      if (data?.notificationCreated?.id) {
        addAlert(data.notificationCreated)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addAlert, data?.notificationCreated?.id]
  )

  return ReactDOM.createPortal(<AlertsList />, alertRoot)
}
