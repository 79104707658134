import * as React from "react"
import { Link } from "react-router-dom"
import { ClassificationSource, UncategorizedReason } from "@digits-graphql/frontend/graphql-bearer"
import { RowContentDescription } from "@digits-shared/components/UI/Table/Content"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import routes from "src/frontend/routes"
import { TransactionProps } from "src/shared/components/Transactions/TransactionRow/types"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

const CategoryName = styled(RowContentDescription)`
  display: inline-block;
  border-radius: ${borders.radius.default}px;
  color: ${colors.rowDescription};
`

export const PredictedTransactionCategoryName = styled(CategoryName)``

const CategoryLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: contents;

  &:hover ${CategoryName} {
    text-decoration: underline;
  }
`

/*
  COMPONENTS
*/

export const TransactionCategory: React.FC<TransactionProps> = ({ transaction }) => {
  const origin = useIntervalOrigin()
  const onClick = React.useCallback((e: React.MouseEvent) => e.stopPropagation(), [])

  const NameComponent =
    transaction.displayCategorySource === ClassificationSource.Digits
      ? PredictedTransactionCategoryName
      : CategoryName

  switch (transaction.displayUncategorizedReason) {
    case UncategorizedReason.New:
      return (
        <NameComponent>
          <i>New</i>
        </NameComponent>
      )
    case UncategorizedReason.Split:
      return (
        <NameComponent>
          <i>Split</i>
        </NameComponent>
      )
  }

  const { displayCategory, product } = transaction
  const productName = product ? ` (${product.name})` : ""
  const categoryName = (
    <NameComponent>
      {displayCategory.name}
      {productName}
    </NameComponent>
  )

  return (
    <CategoryLink
      to={routes.categoryDetails.generateFromCurrentPath({
        ...origin,
        categoryId: displayCategory.id,
      })}
      onClick={onClick}
    >
      {categoryName}
    </CategoryLink>
  )
}
