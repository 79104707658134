import * as React from "react"
import {
  CategoryType,
  DirectionFromOrigin,
  InsightSubjectType,
  Interval,
  useListInsightsQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { defined } from "@digits-shared/helpers/filters"
import useSession from "@digits-shared/hooks/useSession"
import { useMachine } from "@xstate/react"
import styled from "styled-components"
import QueryBuilder from "src/frontend/components/OS/Springboard/Applications/Search/QueryBuilder"
import {
  Chip,
  InsightChip,
  InsightChipHoverCallback,
} from "src/frontend/components/OS/Springboard/Applications/Search/SearchSuggestions/Shared"
import { SuggestionChips } from "src/frontend/components/OS/Springboard/Applications/Search/SearchSuggestions/SuggestionChips"
import {
  suggestionsStateMachine,
  TERMS_HIDDEN_TAG,
} from "src/frontend/components/OS/Springboard/Applications/Search/SearchSuggestions/suggestionsStateMachine"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import FrontendSession from "src/frontend/session"
import { useSuggestionInsights } from "src/shared/components/Insights/useSuggestionInsights"
import { useTerms } from "src/shared/hooks/useTermsQuery"

/*
  STYLES
*/

const SuggestionsContainer = styled.div`
  width: 100%;
  height: 39px;
  overflow: hidden;
`

/*
  INTERFACES
*/

interface SearchSuggestionsProps {
  builder: QueryBuilder
  isSearchActive: boolean
  onHoveredInsightChanged: InsightChipHoverCallback
}

/*
  COMPONENTS
*/

export const SearchSuggestions: React.FC<SearchSuggestionsProps> = ({
  isSearchActive,
  builder,
  onHoveredInsightChanged,
}) => {
  const { currentLegalEntity, doppelganger } = useSession<FrontendSession>()
  // State machine which handles the coordinated display of the suggestions area
  const [currentState, sendEvent] = useMachine(suggestionsStateMachine)
  const searchTermsViewKey = useViewVersion()
  const insightsViewKey = useViewVersion()

  const { data, loading: insightsLoading } = useListInsightsQuery({
    variables: {
      ...dateTimeHelper.todayIntervalOrigin(Interval.Month, 6),
      paginationDirection: DirectionFromOrigin.Past,
      paginationLimit: 10,
      paginationOffset: 0,
      filter: {
        viewKey: insightsViewKey,
        // TODO: Add parties.
        subjectTypes: [InsightSubjectType.Category],
        categoryTypes: [CategoryType.Expenses, CategoryType.Income, CategoryType.CostOfGoodsSold],
      },
    },
    skip: isSearchActive || !currentLegalEntity?.hasDashboardAccess(doppelganger),
    // Prevent batching with other calls until query performance issue is resolved
    // https://teamdigits.slab.com/posts/future-insight-improvements-c1fos59t#h0epc-query-per-period-requested
    context: { noBatch: true },
  })

  const insights = useSuggestionInsights(isSearchActive, data)

  const entityNames = React.useMemo(() => {
    const m = new Map<string, string>()
    const entities = data?.listInsights?.[0]?.entities
    ;(entities?.parties ?? []).forEach((p) => {
      m.set(p.id, p.name)
    })
    ;(entities?.categories ?? []).forEach((c) => {
      m.set(c.id, c.name)
    })
    return m
  }, [data?.listInsights])

  const onHoveredInsightChipChanged = React.useCallback(
    (chip: Chip | undefined) => {
      if (!chip) {
        onHoveredInsightChanged(undefined)
        return
      }

      const insight = insights[chip.index]
      if (!insight) {
        onHoveredInsightChanged(undefined)
        return
      }

      // Enrich the Chip with the Insight that created it
      const insightChip: InsightChip = {
        ...chip,
        insight,
      }
      onHoveredInsightChanged(insightChip)
    },
    [insights, onHoveredInsightChanged]
  )

  // Insight suggestions are stable, don't react to the query changing, and
  // don't impact state decisions, so they're not tracked within the state machine.
  const insightSuggestions = React.useMemo(
    () =>
      insightsLoading ? [] : insights.map((i) => entityNames.get(i.subjectId)).filter(defined),
    [entityNames, insights, insightsLoading]
  )

  const query = builder.build()
  const queryText = query.text.trim()
  React.useEffect(() => {
    sendEvent({ type: "QUERY_CHANGED", query: queryText })
  }, [queryText, sendEvent])

  const { terms, loading: termsLoading } = useTerms(query, searchTermsViewKey)

  React.useEffect(() => {
    if (!termsLoading) {
      sendEvent({ type: "TERMS_CHANGED", terms })
    }
  }, [sendEvent, terms, termsLoading])

  // preload insights icons
  React.useEffect(() => {
    if (!insights?.length) return

    insights.forEach((insight) => {
      if (!insight.subjectIconUrl) return

      const image = new Image()
      image.src = insight.subjectIconUrl
    })
  }, [insights])

  const hideTerms = currentState.hasTag(TERMS_HIDDEN_TAG)
  // term suggestions to be shown are managed by the state machine, and
  // are forced to an empty array while in the dismissTerms state.
  const termsSuggestions = currentState.matches("dismissTerms") ? [] : currentState.context.terms

  return (
    <SuggestionsContainer>
      <SuggestionChips
        labelText="Did you mean?"
        hide={hideTerms}
        suggestions={termsSuggestions || []}
        source="autocomplete"
      />
      <SuggestionChips
        labelText="Suggested"
        hide={isSearchActive}
        suggestions={insightSuggestions}
        onHoveredChipChanged={onHoveredInsightChipChanged}
        source="suggested"
      />
    </SuggestionsContainer>
  )
}
