import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { ColorIconStyled } from "@digits-shared/components/UI/Elements/ColorIcon"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"

export const PartyIconStyled = styled(PartyIcon)`
  margin-top: -1px;

  ${ColorIconStyled} {
    font-size: 8px;
  }
`

export const CategoryIconStyled = styled(SVGIconComponent)`
  padding: 2px;
  background: transparent;
  height: 23px;
  width: 23px;
  ${svgIconStyles(colors.secondary)};
`
