import * as React from "react"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import { useCashSummaryChartLiveData } from "src/frontend/components/Shared/Layout/Components/CashSummary/useCashSummaryChartLiveData"
import { BreakdownChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/BreakdownChartComponent"
import {
  Timeseries,
  toSortedTimeseriesValues,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigLookbackOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import { MatchedComponent, SizingProps } from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface CashSummaryChartComponentProps extends SizingProps {
  component: MatchedComponent<"cashSummaryChart">
  componentSize: ComponentSize
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const CashSummaryChartComponent: React.FC<CashSummaryChartComponentProps> = ({
  component,
  componentSize,
  height,
  skipAnimations,
}) => {
  // TODO: Re-enable step-down logic once there is a monthly/daily toggle on the dashboard.
  // const config = useConfigOriginStepDown(component.config.cashSummaryChart)
  const config = useConfigLookbackOriginOverride(component.config.cashSummaryChart)

  const { data } = useCashSummaryChartLiveData(config, useViewVersion())

  const totalCash = React.useMemo(
    () => toSortedTimeseriesValues(data?.totalCash),
    [data?.totalCash]
  )

  const breakdownTimeseries = React.useMemo<Timeseries[]>(
    () =>
      (data?.breakdownSummaries ?? []).map(({ category, history: summary }) => ({
        label: category.name,
        values: toSortedTimeseriesValues(summary),
      })),
    [data?.breakdownSummaries]
  )

  return (
    <BreakdownChartComponent
      origin={config.origin}
      breakdownTimeseries={breakdownTimeseries}
      totalCash={totalCash}
      componentSize={componentSize}
      height={height}
      skipAnimations={skipAnimations}
    />
  )
}
