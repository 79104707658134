import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgRefreshCw05 } from "@digits-shared/components/SVGIcons/line/RefreshCw05.svg"
import { Pill } from "@digits-shared/components/UI/Elements/Pill"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { useTransactionsTableContext } from "src/shared/components/Transactions/TransactionsTableContext"
import { TransactionProps } from "./types"

/*
  STYLES
*/

export const TransactionRecurrenceSVG = styled(SvgRefreshCw05)`
  width: 12px;
  height: 12px;
  margin-right: 5px;
  ${svgPathStyles(colors.primary, 1.5)};
`

export const TransactionRecurrencePill = styled(Pill)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-transform: uppercase;
  border-color: ${colors.transparent};
  color: ${colors.primary};

  i {
    font-style: normal;
    font-size: 7px;
  }
`

/*
  COMPONENTS
*/

export const TransactionRecurrence: React.FC<TransactionProps> = ({ transaction, className }) => {
  const { noRecurring } = useTransactionsTableContext()

  if (noRecurring) return null

  const { recurrence } = transaction
  if (!recurrence || !recurrence.recurrenceThreadId || recurrence.isHidden) return null

  const recurrencePhrase = (
    <span>
      {recurrence.totalThreadCount}{" "}
      {stringHelper.pluralize(
        recurrence.totalThreadCount,
        recurrence.interval,
        dateTimeHelper.displayNameForInterval(recurrence.interval, recurrence.totalThreadCount)
      )}
    </span>
  )

  return (
    <TransactionRecurrencePill pillStyle="outline">
      <TransactionRecurrenceSVG />
      {recurrencePhrase}
    </TransactionRecurrencePill>
  )
}
