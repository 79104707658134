import * as React from "react"
import { SvgMessageCheckSquare } from "@digits-shared/components/SVGIcons/line/MessageCheckSquare.svg"
import { isMobile } from "@digits-shared/helpers/devicesHelper"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import styled from "styled-components"
import { useActionItemsBadgeCountsQuery } from "src/frontend/components/OS/Springboard/hooks/useActionItemsBadgeCountsQuery"
import { SIDEBAR_PATH_SVG_STYLES } from "src/frontend/components/Shared/NavSidebar/sidebarConstants"
import { SidebarRow } from "src/frontend/components/Shared/NavSidebar/SidebarExpando"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session/FrontendSession"
import { Experience } from "src/frontend/session/personas"

/*
  STYLES
*/

const ActionItemsIcon = styled(SvgMessageCheckSquare)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

/*
  COMPONENT
*/

export const LegacyActionItemsRow: React.FC = () => {
  const session = useSession<FrontendSession>()
  const { currentLegalEntity } = session
  const params = { leSlug: currentLegalEntity?.slug }

  const { accountantFeatures, showAudit } = React.useMemo(
    () => ({
      accountantFeatures: session.currentPrimaryExperience === Experience.AccountantPortal,
      showAudit: session.hasAccessToAspect(AspectCode.Audit),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [session, currentLegalEntity?.id]
  )

  // accountants do not have a badge count for action items as they can't action them (skipped)
  const actionItemCount =
    useActionItemsBadgeCountsQuery(currentLegalEntity.id, accountantFeatures && showAudit)?.count ??
    0

  return (
    isMobile &&
    showAudit && (
      <SidebarRow
        Icon={ActionItemsIcon}
        route={routes.actionItems}
        params={params}
        badge={actionItemCount}
      >
        Action Items
      </SidebarRow>
    )
  )
}
