import * as React from "react"
import {
  EntityLegalEntity,
  EntityTransaction,
  HandleNone,
  ProductArea,
  ReadTransactionDetailsQueryVariables,
  Transaction,
  useReadTransactionDetailsQuery,
  ViewType,
} from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgChevronRight } from "@digits-shared/components/SVGIcons/line/ChevronRight.svg"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import useRouter from "@digits-shared/hooks/useRouter"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { SharedEntityRow } from "src/frontend/components/OS/Springboard/Applications/SharedWithMe/Entities/EntityRows/Shared"
import { useProductArea } from "src/frontend/components/Shared/Contexts/ProductAreaContext"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import { TransactionAmount } from "src/shared/components/Transactions/TransactionRow/TransactionAmount"
import { TransactionBody } from "src/shared/components/Transactions/TransactionRow/TransactionBody"
import { SingleTransactionDate } from "src/shared/components/Transactions/TransactionRow/TransactionDate"
import TransactionPartyIcon from "src/shared/components/Transactions/TransactionRow/TransactionPartyIcon"

/*
  STYLES
*/

const TransactionRow = styled(SharedEntityRow)`
  transition: border 150ms ease-out;
  width: 100%;
  border: unset;
  border-radius: ${borders.radius.default}px;
  border-top: 1px solid ${colors.translucentSecondary10};
  border-bottom: 1px solid ${colors.transparent};
  border-left: 1px solid ${colors.transparent};
  border-right: 1px solid ${colors.transparent};
  margin-top: 0px;

  &:nth-child(2) {
    margin-top: 0;
  }

  margin-bottom: 0;

  &:hover {
    cursor: pointer;
    box-shadow: inset 0 0 30px ${colors.translucentSecondary10};
    border: 1px solid ${colors.translucentSecondary20};
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
`

const PointingRightChevron = styled(SvgChevronRight)`
  width: 15px;
  height: 15px;
  ${svgPathStyles(colors.translucentSecondary20, 1.5)};
  margin-left: 14px;
`

const TransactionBodyStyled = styled(TransactionBody)`
  position: relative;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const RowIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
`

const RowBodyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 54%;
`

const RowAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
`

/*
  COMPONENTS
*/

export const SharedTransactionRow: React.FC<{
  legalEntity: EntityLegalEntity
  transaction: EntityTransaction
  viewType: ViewType | null | undefined
}> = ({ legalEntity, transaction, viewType }) => {
  const { history } = useRouter()
  const generatePath = useFrontendPathGenerator()
  // TODO: Currently the designs for the shared transaction rows call for the recurrence thread for a
  //  transaction to be rendered. This data is not available from the transaction object entities returned
  //  from `ReadUserACL`. We could add this data to the transaction object entity to remove the need for an
  //  additional network call or remove the recurrence threads from the shared transaction rows.
  const { transactionDetail, loading } = useReadTransactionDetail(
    legalEntity.id,
    viewType,
    transaction?.factId
  )

  const onRowClick = React.useCallback(() => {
    history.push(
      generatePath(routes.transactionDetails, {
        leSlug: legalEntity.slug,
        transactionId: transaction.factId,
        productArea: transactionDetail?.displayProductArea,
      })
    )
  }, [
    generatePath,
    history,
    legalEntity.slug,
    transaction.factId,
    transactionDetail?.displayProductArea,
  ])

  return (
    <TransactionRow>
      <Row onClick={onRowClick}>
        <TransactionDate loading={loading} transaction={transactionDetail} />
        <RowIconContainer>
          <TransactionPartyIcon
            list={[]}
            loading={loading}
            data={transactionDetail}
            iconSize={IconSize.MediumLarge}
            neverHover
          />
        </RowIconContainer>
        <RowBodyContainer>
          <TransactionBodyStyled data={transactionDetail} list={[]} loading={loading} />
        </RowBodyContainer>
        <RowAmount>
          <TransactionAmount list={[]} loading={loading} data={transactionDetail} />
        </RowAmount>
        <PointingRightChevron />
      </Row>
    </TransactionRow>
  )
}

const TransactionDate: React.FC<{ loading: boolean; transaction?: Transaction }> = ({
  loading,
  transaction,
}) => {
  if (loading || !transaction) {
    return <LoadingBlock width="45px" height="12px" />
  }

  return <SingleTransactionDate transaction={transaction} />
}

const useReadTransactionDetail = (
  legalEntityId: string,
  viewType: ViewType | null | undefined,
  transactionFactId?: string | null
) => {
  const productArea = useProductArea()

  const detailsRequestVariables: ReadTransactionDetailsQueryVariables = {
    transactionFactId: transactionFactId || "",
    filter: {
      viewKey: {
        viewType,
        legalEntityId,
      },
      productArea,
      // If we don't have a product area, ensure we handle selecting the correct display
      // side on the return by specify HandleNone.Ignore.
      handleNone: productArea === ProductArea.None ? HandleNone.Ignore : undefined,
    },
  }

  const { loading, data } = useReadTransactionDetailsQuery({
    variables: detailsRequestVariables,
    context: {
      noBatch: true,
    },
  })

  return { loading, transactionDetail: data?.readTransactionDetails.transaction }
}
