import * as React from "react"
import {
  LayoutComponentType,
  SummaryLineItemConfig,
  useReadSummaryLineItemByTimeCategoryLiveDataQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { defined } from "@digits-shared/helpers/filters"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import {
  sortTimeseriesValues,
  TimeseriesValues,
  toSortedTimeseriesValues,
  toTimeseriesValue,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"

export function useSummaryLineItemByTimeCategoryLiveData(
  config: SummaryLineItemConfig | undefined,
  type: LayoutComponentType
) {
  const viewId = useViewVersion()

  const { data, loading } = useReadSummaryLineItemByTimeCategoryLiveDataQuery({
    variables: {
      viewId,
      config: {
        type,
        summaryLineItem: config,
      },
    },
    skip: !viewId?.viewVersion,
  })

  return React.useMemo(() => {
    const total = data?.total
    const breakdown = data?.breakdown

    // Resolves the component data union types to the portions we care about
    if (total?.__typename === "DimensionSummary" && breakdown?.__typename === "DimensionSummary") {
      const timeseries = toSortedTimeseriesValues(total.time)

      const seriesByCategory = breakdown.time.reduce(
        (series, { by: { category: byCategory } }) => {
          byCategory.forEach(({ categoryObject, summary }) => {
            const catSeries = series[categoryObject.name] || []
            catSeries.push(toTimeseriesValue(summary))
            series[categoryObject.name] = catSeries
          })
          return series
        },
        {} as Record<string, TimeseriesValues>
      )

      const breakdownTimeseries = Object.entries(seriesByCategory)
        .map(([label, seriesCategory]) => {
          const values = seriesCategory.sort(sortTimeseriesValues)

          // if the total value is 0, filter out
          const valuesTotal = moneyFlowHelper.addValues(
            ...values.flatMap(({ moneyFlow }) => moneyFlow)
          )
          if (!valuesTotal.value.amount) return undefined

          return {
            label,
            values,
          }
        })
        .filter(defined)

      return { timeseries, breakdownTimeseries, loading }
    }

    return { timeseries: [], breakdownTimeseries: [], loading }
  }, [data?.breakdown, data?.total, loading])
}
