import * as React from "react"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

const Footer = styled.footer`
  padding: 25px 0 15px;
  text-align: center;
  font-size: 9px;
  font-family: ${fonts.family.avenir};
  color: ${themedValue({ light: colors.translucentSecondary50, dark: colors.translucentWhite50 })};
  height: 68px;
  position: relative;
`

const Sentence = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`

const CopyRightText = styled.div`
  margin-top: 1px;
`

const TermsLink = styled.a`
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: underline;
  transition: opacity 250ms ease;
  color: inherit;
  opacity: 0.8;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`

const termsOfService = "/legal/terms-of-service"
const privacyPolicy = "/legal/privacy-policy"

export const CopyrightFooter: React.FC<{ className?: string }> = ({ className }) => (
  <Footer className={className}>
    <Sentence>
      <CopyRightText>© {new Date().getFullYear()} Digits Financial, Inc.</CopyRightText>
      <TermsLink target="_blank" href={termsOfService}>
        Terms of Service
      </TermsLink>
      <TermsLink target="_blank" href={privacyPolicy}>
        Privacy Policy
      </TermsLink>
    </Sentence>
    <Sentence>
      <CopyRightText>Digits® is a registered trademark of Digits Financial, Inc.</CopyRightText>
    </Sentence>
  </Footer>
)
