import { ApolloClient, NormalizedCacheObject, useApolloClient } from "@apollo/client"
import { scope } from "@digits-shared/scope/scope"
import { ScopeProvider } from "@digits-shared/scope/ScopeContext"

export const apolloClientScope = scope<ApolloClient<NormalizedCacheObject>>(
  "apolloClient",
  undefined as unknown as ApolloClient<NormalizedCacheObject>
)

export const ApolloClientScopeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const client = useApolloClient()

  return (
    <ScopeProvider scope={apolloClientScope} value={client}>
      {children}
    </ScopeProvider>
  )
}
