import styled from "styled-components"

/*
  SEARCH RESULT SUMMARY
*/

export const ChartContainer = styled.div`
  margin-right: 20px;
  width: 100px;
  height: 30px;
  transition: opacity 200ms ease;
  opacity: 0.6;
  a:hover & {
    opacity: 1;
  }
`

export const AmountAndCount = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-left: 10px;
`
