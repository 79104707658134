import * as React from "react"
import { Flow, MonetaryValue, MoneyFlow } from "@digits-graphql/frontend/graphql-bearer"
import {
  useInvertValues,
  useIsInboundAmount,
} from "@digits-shared/components/Contexts/InvertValuesContext"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import {
  EXPANDED_CLASS_NAME,
  HOVERABLE_CLASS_NAME,
  SELECTED_CLASS_NAME,
} from "@digits-shared/components/UI/Table/tableConstants"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import { themedStyles, themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import { useThemedConstant } from "@digits-shared/themes/themedFunctions"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const hoverStyles = themedStyles({
  dark: css`
    .${SELECTED_CLASS_NAME} &,
    ${"." + HOVERABLE_CLASS_NAME}:not(.${EXPANDED_CLASS_NAME}):hover & {
      color: ${colors.white};
    }
  `,
  light: css`
    .${SELECTED_CLASS_NAME} &,
    ${"." + HOVERABLE_CLASS_NAME}:not(.${EXPANDED_CLASS_NAME}):hover & {
      color: ${colors.secondary};
    }
  `,
})

const titleColor = themedValue({
  light: colors.secondary,
  dark: colors.white,
})
export const RowContentTitle = styled.div`
  ${hoverStyles};
  color: ${titleColor};
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const descriptionColor = themedValue({
  light: colors.translucentSecondary80,
  dark: colors.regentGray,
})
export const RowContentDescription = styled.div`
  ${hoverStyles};
  color: ${descriptionColor};
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Values = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const AmountWithColor = styled.div<{ color: string }>`
  color: ${({ color }) => color};
`

const dateColor = themedValue({
  light: colors.secondary,
  dark: colors.subtitleGray,
})
export const Date = styled.div`
  ${hoverStyles};
  color: ${dateColor};
  font-weight: ${fonts.weight.black};
  text-transform: uppercase;
  font-size: 10px;
`

export const Capitalize = styled.span`
  text-transform: uppercase;
`

const Count = styled(RowContentDescription)`
  ${hoverStyles};
  text-transform: uppercase;
  font-size: 10px;
  white-space: nowrap;
`

/*
  INTERFACES
*/

interface SummaryWithCountProps {
  loading?: boolean
  value?: MonetaryValue
  moneyFlow?: MoneyFlow
  style?: CurrencyStyle
  absolute?: boolean
  ignoreHover?: boolean
  className?: string
  children?: React.ReactNode
}

export interface AmountProps {
  flow?: Flow
  normal?: boolean
  inbound?: boolean // Prefer flow + normal.
  absolute?: boolean
  children?: React.ReactNode
  className?: string
  ignoreHover?: boolean
}

/*
  COMPONENTS
*/

function useAmountColor({
  flow,
  absolute,
  inbound,
}: Pick<AmountProps, "flow" | "absolute" | "inbound">) {
  const invertValues = useInvertValues()

  if (flow) {
    return flow == Flow.Inbound ? colors.money.inbound : colors.money.outbound
  }

  switch (true) {
    case absolute:
      return colors.money.inbound

    case invertValues && inbound:
      return colors.money.inverted.inbound

    case invertValues: // outbound
      return colors.money.inverted.outbound

    case inbound:
      return colors.money.inbound

    default:
      return colors.money.outbound
  }
}

export const Amount = styled((props: AmountProps) => {
  const { className, ignoreHover, children, ...valueProps } = props

  const color = useThemedConstant({
    light: colors.translucentSecondary80,
    dark: useAmountColor(valueProps),
  })

  return (
    <AmountWithColor className={className} color={color}>
      {children}
    </AmountWithColor>
  )
})`
  font-weight: ${fonts.weight.medium};
  font-size: 13px;

  ${({ ignoreHover }) =>
    !ignoreHover &&
    css`
      .${SELECTED_CLASS_NAME} &,
      .${HOVERABLE_CLASS_NAME}:not(.${EXPANDED_CLASS_NAME}):hover & {
        color: ${colors.secondary};
      }
    `}
`

export const SummaryWithCount: React.FC<SummaryWithCountProps> = ({
  loading,
  moneyFlow,
  value,
  children,
  ignoreHover,
  style = CurrencyStyle.Detail,
  absolute,
  className,
}) => {
  const invertValues = useInvertValues()
  const isInbound = useIsInboundAmount(value?.amount ?? 0)

  if (loading || (!value && !moneyFlow)) {
    return (
      <>
        <Amount inbound ignoreHover={ignoreHover} className={className}>
          <LoadingBlock width="80px" height="12px" $randomWidthRange={30} />
        </Amount>
        <Count>
          <LoadingBlock width="100px" height="12px" $randomWidthRange={30} />
        </Count>
      </>
    )
  }

  // Temporary while only some summaries use MoneyFlow.
  const amountWithCurrency =
    moneyFlow || !value
      ? moneyFlowHelper.currency(moneyFlow, {
          absolute,
          style,
        })
      : numberHelper.currency(value, {
          absolute,
          invertValues: absolute ? undefined : invertValues,
          style,
        })

  return (
    <>
      <Amount
        flow={moneyFlow?.businessFlow}
        absolute={absolute}
        inbound={isInbound}
        ignoreHover={ignoreHover}
        className={className}
      >
        {amountWithCurrency}
      </Amount>
      <Count>{children}</Count>
    </>
  )
}
