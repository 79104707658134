import React, { PureComponent } from "react"
import OdometerJS from "odometer"
import styled from "styled-components"

/*
  STYLES
*/

const OdometerStyled = styled.div<{ duration: number; animation?: "count" | "slide" }>`
  .odometer {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;

    .odometer-formatting-mark {
      transform: translateY(1px);
      display: inline-block;
    }

    .odometer-inside {
      white-space: nowrap;
    }

    .odometer-digit {
      display: inline-block;
      vertical-align: middle;
      *vertical-align: auto;
      *zoom: 1;
      *display: inline;
      position: relative;

      .odometer-digit-spacer {
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        *zoom: 1;
        *display: inline;
        visibility: hidden;
      }

      .odometer-digit-inner {
        text-align: left;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: ${({ animation }) => (animation === "slide" ? "hidden" : "visible")};
      }

      .odometer-ribbon {
        display: block;
      }

      .odometer-ribbon-inner {
        display: block;
        backface-visibility: hidden;
      }

      .odometer-value {
        display: block;
        transform: translateZ(0);

        &.odometer-last-value {
          position: absolute;
        }
      }
    }

    &.odometer-animating-up {
      .odometer-ribbon-inner {
        transition: transform ${(props) => props.duration}ms;
        transform: translateY(0);
      }

      &.odometer-animating .odometer-ribbon-inner {
        transform: translateY(-100%);
      }
    }

    &.odometer-animating-down {
      .odometer-ribbon-inner {
        transition: transform ${(props) => props.duration}ms;
        transform: translateY(-100%);
      }

      &.odometer-animating .odometer-ribbon-inner {
        transform: translateY(0);
      }
    }
  }
`

/*
  INTERFACE
*/

interface Props {
  value: number
  startValue?: number
  duration?: number
  delay?: number
  format?: string
  animation?: "count" | "slide"
  children?: React.ReactNode
  className?: string
}

/*
  COMPONENT
*/

export default class Odometer extends PureComponent<Props> {
  private node = React.createRef<HTMLDivElement>()
  private odometer: OdometerJS

  componentDidMount() {
    if (!this.node.current) return

    const { value, delay, startValue, children, ...options } = this.props
    this.odometer = new OdometerJS({
      el: this.node.current,
      auto: true,
      value: startValue || 0,
      ...options,
    })
    if (startValue === undefined) {
      this.node.current.style.visibility = "hidden"
    }

    setTimeout(() => {
      if (!this.node.current) return

      this.node.current.style.visibility = "inherit"
      this.odometer.update(value)
    }, delay || 0)
  }

  componentDidUpdate() {
    const { value } = this.props
    this.odometer.update(value)
  }

  render() {
    const { duration, children, className, animation } = this.props

    return (
      <OdometerStyled duration={duration || 0} className={className} animation={animation}>
        <div ref={this.node} />
        {children}
      </OdometerStyled>
    )
  }
}
