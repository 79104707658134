import { Permission } from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { useObjectSharingState } from "src/frontend/hooks/useObjectSharingState"
import FrontendSession from "src/frontend/session"
import { FrontendPermissionModule, objectKindToModule } from "src/frontend/session/permissionModule"

/**
 * Determines whether the current user has been granted one of the specified permissions
 * through current object sharing context. Sharing permissions are a mutually exclusive
 * enum, so providing multiple values to this hook returns true if any of the provided
 * values match.
 *
 * Returns false if the granted permission is not in the provided set, or the user is not
 * currently in an object sharing context.
 */
export function useHasPermissionFromObjectSharing(
  module: FrontendPermissionModule,
  ...permission: Permission[]
) {
  const session = useSession<FrontendSession>()
  const objectSharingState = useObjectSharingState()

  if (!session.isSharingContextActive) return false

  // Ensure the sharing state object kind matches the module we're checking for permissions
  if (
    !objectSharingState.sharedObjectInfo?.kind ||
    objectKindToModule(objectSharingState.sharedObjectInfo.kind) !== module
  ) {
    return false
  }

  // sharedObjectInfo derives the legal entity slug from the current URL
  const slug = objectSharingState.sharedObjectInfo?.legalEntitySlug
  const urlLegalEntityInSession = !!slug && !!session.findLegalEntityBySlug(slug)

  if (!urlLegalEntityInSession) return false

  // The permission that a grant has given to the user via object sharing (if any)
  const grantedSharingPermission = objectSharingState.permission?.permission

  if (!grantedSharingPermission) return false

  // If the session is in a sharing context, you need to have the legal entity represented
  // by the URL slug in your session (via the sharing context), and your current
  // sharing state permissions must allow managing sharing.
  //
  // Note that the ObjectSharingState is always determined relative to a particular
  // ObjectKind (specified by the route), which serves a similar purpose as the
  // FrontendPermissionModule.
  return permission.includes(grantedSharingPermission)
}
