import { Interval, IntervalOrigin } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper, { TimeRange } from "@digits-shared/helpers/dateTimeHelper"

export interface DateRangeState {
  timeRange: TimeRange
}

export type DateRangeAction =
  | { type: "SET_START"; value: number }
  | { type: "SET_END"; value: number }
  | { type: "SET_INTERVAL_ORIGIN"; origin: IntervalOrigin } // convenience function to set interval, start and end times
  | { type: "SET_INTERVAL"; interval: Interval }

/*
  REDUCER
*/
export function processDateRangeReducer(curState: DateRangeState, action: DateRangeAction) {
  const newState: DateRangeState = { ...curState }

  switch (action.type) {
    case "SET_START":
      newState.timeRange.startedAt = action.value
      return newState
    case "SET_END":
      newState.timeRange.endedAt = action.value
      return newState
    case "SET_INTERVAL":
      newState.timeRange.interval = action.interval
      newState.timeRange.endedAt = newState.timeRange.startedAt
      return newState
    case "SET_INTERVAL_ORIGIN": {
      const range = dateTimeHelper.periodFromIntervalOrigin(action.origin)
      newState.timeRange.interval = range.interval
      newState.timeRange.startedAt = range.startedAt
      newState.timeRange.endedAt = range.endedAt
      return newState
    }
  }
}
