import * as React from "react"
import { useHistory } from "react-router-dom"
import { DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import envHelper from "@digits-shared/helpers/envHelper"
import useSession from "@digits-shared/hooks/useSession"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import Permissions from "@digits-shared/session/Permissions"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import {
  hasJwtPermissions,
  PermissionCheck,
  useHasJwtPermissions,
} from "src/frontend/hooks/useHasJwtPermissions"
import FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

/*
  INTERFACE
*/

interface Props {
  children?: React.ReactNode
  source: FrontendPermissionSource
  module: FrontendPermissionModule | FrontendPermissionModule[]
  flag: JWTPermissionFlag
  redirectTo?: string
  digitsEmployeeOnly?: boolean
  allowDev?: boolean
  redirectName?: string
}

type NoFlagProps = Omit<Props, "flag">

type NoSourceProps = Omit<Props, "source">

/*
  COMPONENTS
*/

const Requires: React.FC<Props> = ({
  source,
  module,
  flag,
  digitsEmployeeOnly,
  allowDev,
  redirectTo,
  redirectName,
  children,
}) => {
  const hasPermissions = useHasPermission({ source, module, flag, digitsEmployeeOnly })

  if (allowDev && envHelper.isDevelopment()) return <>{children}</>

  if (!hasPermissions) {
    return <Unauthorized redirectTo={redirectTo} redirectName={redirectName} />
  }

  return <>{children}</>
}

export default Requires

export const RequiresCreate: React.FC<NoFlagProps> = ({
  source,
  module,
  digitsEmployeeOnly,
  redirectTo,
  redirectName,
  allowDev,
  children,
}) => (
  <Requires
    source={source}
    module={module}
    flag={JWTPermissionFlag.Create}
    digitsEmployeeOnly={digitsEmployeeOnly}
    redirectTo={redirectTo}
    redirectName={redirectName}
    allowDev={allowDev}
  >
    {children}
  </Requires>
)

export const RequiresRead: React.FC<NoFlagProps> = ({
  source,
  module,
  digitsEmployeeOnly,
  redirectTo,
  redirectName,
  children,
}) => (
  <Requires
    source={source}
    module={module}
    flag={JWTPermissionFlag.Read}
    digitsEmployeeOnly={digitsEmployeeOnly}
    redirectTo={redirectTo}
    redirectName={redirectName}
  >
    {children}
  </Requires>
)

export const RequiresComment: React.FC<NoFlagProps> = ({
  source,
  module,
  digitsEmployeeOnly,
  redirectTo,
  redirectName,
  children,
}) => (
  <Requires
    source={source}
    module={module}
    flag={JWTPermissionFlag.Comment}
    digitsEmployeeOnly={digitsEmployeeOnly}
    redirectTo={redirectTo}
    redirectName={redirectName}
  >
    {children}
  </Requires>
)

export const RequiresUpdate: React.FC<NoFlagProps> = ({
  source,
  module,
  digitsEmployeeOnly,
  redirectTo,
  redirectName,
  children,
}) => (
  <Requires
    source={source}
    module={module}
    flag={JWTPermissionFlag.Update}
    digitsEmployeeOnly={digitsEmployeeOnly}
    redirectTo={redirectTo}
    redirectName={redirectName}
  >
    {children}
  </Requires>
)

export const RequiresDelete: React.FC<NoFlagProps> = ({
  module,
  source,
  digitsEmployeeOnly,
  redirectTo,
  redirectName,
  children,
}) => (
  <Requires
    source={source}
    module={module}
    flag={JWTPermissionFlag.Delete}
    digitsEmployeeOnly={digitsEmployeeOnly}
    redirectTo={redirectTo}
    redirectName={redirectName}
  >
    {children}
  </Requires>
)

export const RequiresDigitsEmployee: React.FC<{
  allowDev?: boolean
  children?: React.ReactNode
}> = ({ allowDev, children }) => (
  <Requires
    source={FrontendPermissionSource.Organization}
    module={[]}
    flag={JWTPermissionFlag.Read}
    digitsEmployeeOnly
    allowDev={allowDev}
  >
    {children}
  </Requires>
)

type RequireAspect = { aspect: AspectCode }
type RequireAspects = { aspects: AspectCode[] }

type RequiresProps = React.PropsWithChildren<RequireAspect | RequireAspects>

function isSingleAspect(props: RequireAspect | RequireAspects): props is RequireAspect {
  return (props as RequireAspect).aspect !== undefined
}

export const RequiresAspect: React.FC<RequiresProps> = (props) => {
  const { children } = props
  const session = useSession<FrontendSession>()
  const { currentLegalEntity } = session

  const hasAspects = React.useMemo(
    () => {
      const aspects = isSingleAspect(props) ? [props.aspect] : props.aspects
      return aspects.every((a) => session.hasAccessToAspect(a))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, currentLegalEntity?.id]
  )

  if (!hasAspects) return null

  return <>{children}</>
}

const Unauthorized: React.FC<{ redirectTo?: string; redirectName?: string }> = ({
  redirectTo,
  redirectName,
}) => {
  const history = useHistory()
  if (!redirectTo || redirectTo === (history?.location as DigitsLocation)?.fullPathname) {
    return null
  }

  return <LoggedRedirect name={redirectName ?? "Unauthorized"} to={redirectTo} />
}

export function useHasPermission({ source, module, flag, digitsEmployeeOnly }: Props) {
  const requires = Array.isArray(module) ? module : [module]
  const { isDigitsEmployee } = useSession<FrontendSession>()
  const checks: PermissionCheck[] = requires.map((r) => ({ flag, module: r }))
  const hasPermissions = useHasJwtPermissions(source, ...checks)

  if (digitsEmployeeOnly && !isDigitsEmployee) return false

  return hasPermissions
}

// Check permission when you have a permission object you'd like to check directly, rather
// than implicitly derived from the session based on FrontendPermissionSource.
export function useHasPermissionTo({
  permission,
  module,
  flag,
  digitsEmployeeOnly,
}: NoSourceProps & { permission: Permissions<FrontendPermissionModule> | undefined }) {
  const requires = Array.isArray(module) ? module : [module]
  const { isDigitsEmployee } = useSession<FrontendSession>()
  const checks: PermissionCheck[] = requires.map((r) => ({ flag, module: r }))
  const hasPermissions = hasJwtPermissions(permission, ...checks)

  if (digitsEmployeeOnly && !isDigitsEmployee) return false

  return hasPermissions
}
