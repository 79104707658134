import * as React from "react"
import useSession from "@digits-shared/hooks/useSession"
import { useEffectReducer } from "use-effect-reducer"
import { useAskAssistant } from "src/frontend/components/Shared/Assistant/hooks/useAskAssistant"
import { useFetchAssistantMessages } from "src/frontend/components/Shared/Assistant/hooks/useFetchAssistantMessages"
import {
  AssistantAction,
  assistantReducer,
  AssistantState,
  DEFAULT_ASSISTANT_STATE,
} from "src/frontend/components/Shared/Assistant/reducer"
import { useCanComment } from "src/frontend/hooks/useCanComment"
import FrontendSession from "src/frontend/session"
import { FrontendPermissionModule } from "src/frontend/session/permissionModule"

export interface AssistantContextProps {
  dispatch: React.Dispatch<AssistantAction>
  state: AssistantState
}

const AssistantContext = React.createContext<AssistantContextProps>({
  dispatch: () => {},
  state: DEFAULT_ASSISTANT_STATE,
})

export function useAssistantContext() {
  const context = React.useContext(AssistantContext)

  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => context, [context])
}

export const AssistantProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { currentLegalEntity, user, doppelganger } = useSession<FrontendSession>()

  const canComment = useCanComment(FrontendPermissionModule.Portals)

  const enabled = canComment && !!(currentLegalEntity?.hasDashboardAccess(doppelganger) && user)
  const onSubmit = useAskAssistant()
  const onSubmitDone = React.useCallback((state: AssistantState) => {
    if (state.isNewThread) {
      state.refetch?.()
    }
  }, [])

  const [state, dispatch] = useEffectReducer(assistantReducer, DEFAULT_ASSISTANT_STATE, {
    onSubmit,
    onSubmitDone,
  })

  useFetchAssistantMessages(enabled, dispatch, state.isSubmitting)

  React.useEffect(() => {
    dispatch({ type: "LEGAL_ENTITY_RESET", legalEntityId: currentLegalEntity?.id })
  }, [currentLegalEntity?.id, dispatch])

  const context = React.useMemo(
    () => ({
      // Avoid returning stale state between when the LE changes in the session
      // and the LEGAL_ENTITY_RESET action is reflected in state.
      state: currentLegalEntity?.id === state.legalEntityId ? state : DEFAULT_ASSISTANT_STATE,
      dispatch,
    }),
    [currentLegalEntity?.id, state, dispatch]
  )

  if (!enabled) return <>{children}</>

  return <AssistantContext.Provider value={context}>{children}</AssistantContext.Provider>
}
