import * as React from "react"
import { EntityLegalEntity, EntityReportPackage } from "@digits-graphql/frontend/graphql-bearer"
import { SharedReportPackageRow } from "src/frontend/components/OS/Springboard/Applications/SharedWithMe/Entities/EntityRows/SharedReportPackageRow"
import {
  LegalEntitiesContainer,
  LegalEntityRows,
  useFilterByLegalEntity,
} from "src/frontend/components/OS/Springboard/Applications/SharedWithMe/Entities/Shared"
import { LegalEntitySharedEntities } from "src/frontend/components/OS/Springboard/Applications/SharedWithMe/Shared"

/*
  COMPONENTS
*/
export const SharedReportPackages: React.FC<{
  reportEntities: LegalEntitySharedEntities<EntityReportPackage>[]
  selectedLegalEntity?: EntityLegalEntity
}> = ({ reportEntities, selectedLegalEntity }) => {
  const filterReports = useFilterByLegalEntity(reportEntities, selectedLegalEntity)

  return (
    <LegalEntitiesContainer>
      {filterReports.map(({ legalEntity, entities }) => (
        <LegalEntityRows key={legalEntity.id} legalEntity={legalEntity}>
          {entities.map((pkg, idx) => (
            <SharedReportPackageRow
              key={idx}
              legalEntity={legalEntity}
              reportPackage={pkg.entity}
              sharedAt={pkg.grantCreatedAt}
            />
          ))}
        </LegalEntityRows>
      ))}
    </LegalEntitiesContainer>
  )
}
