import styled from "styled-components"

export const ChartContainer = styled.div`
  position: relative;
  height: 200px;
  width: 100%;

  svg {
    margin: 0;
  }
`
