import * as React from "react"
import { IntervalOrigin } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

/*
  STYLES
*/

const StyledTitle = styled.div`
  display: flex;
  gap: 0 4px;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: ${fonts.weight.heavy};
  margin-bottom: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const PeriodName = styled.span`
  color: ${colors.translucentSecondary70};
`

/*
  COMPONENTS
*/

export const TopPeriodTitle: React.FC<
  React.PropsWithChildren<{ intervalOrigin?: IntervalOrigin }>
> = ({ intervalOrigin, children }) => {
  const periodName = React.useMemo(() => {
    if (!intervalOrigin) return ""
    return dateTimeHelper.displayNameForIntervalOriginRange(intervalOrigin, { delimiter: " to " })
  }, [intervalOrigin])
  return (
    <StyledTitle>
      {children}
      <PeriodName>{periodName}</PeriodName>
    </StyledTitle>
  )
}
