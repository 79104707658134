import * as React from "react"
import { IntervalOrigin, LayoutComponentType } from "@digits-graphql/frontend/graphql-bearer"
import useRouter from "@digits-shared/hooks/useRouter"
import { useHighlight } from "src/frontend/components/OS/Details/Shared/hooks/useHighlight"
import {
  SharedBarChartStyles,
  SharedLineChartStyles,
} from "src/frontend/components/OS/Shared/Charts/styles"
import { CashBadge } from "src/frontend/components/Shared/Layout/CashBadge"
import { ParentSizedTimeseriesBreakdownChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesBreakdownChart"
import {
  Timeseries,
  TimeseriesValue,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ComponentExpandLink } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentExpandIcon"
import {
  ComponentDateSummary,
  ComponentSummary,
} from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { TimeseriesBreakdownChartTile } from "src/frontend/components/Shared/Layout/Components/Tiles/TimeseriesBreakdownChartTile"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { ChartContainer } from "src/frontend/components/Shared/Layout/Shared"
import { SizingProps } from "src/frontend/components/Shared/Layout/types"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"

/*
  INTERFACES
*/

interface ChartComponentProps extends SizingProps {
  componentSize: ComponentSize
  skipAnimations: boolean
  origin: IntervalOrigin
  totalCash: TimeseriesValue[]
  breakdownTimeseries: Timeseries[]
}

/*
  COMPONENTS
*/

export const BreakdownChartComponent: React.FC<ChartComponentProps> = ({
  totalCash,
  breakdownTimeseries,
  origin,
  componentSize,
  height,
  skipAnimations,
}) => {
  const { history } = useRouter()
  const generatePath = useFrontendPathGenerator()

  const highlight = useHighlight()
  const [selectedIndex, setSelectedIndex] = React.useState(highlight ?? undefined)

  const onMouseOver = React.useCallback((value: TimeseriesValue, index: number) => {
    setSelectedIndex(index)
  }, [])

  const onMouseOut = React.useCallback(
    (value?: TimeseriesValue) => {
      setSelectedIndex(highlight ?? undefined)
    },
    [highlight]
  )

  const onBarClick = React.useCallback(
    (_: TimeseriesValue, index: number) => {
      setSelectedIndex(index)
      const metricPath = generatePath(routes.layoutComponentDetails, {
        configType: LayoutComponentType.CashSummaryChart,
        ...origin,
        intervalCount: undefined,
        highlight: index,
      })
      history.push(metricPath)
    },
    [origin, generatePath, history]
  )

  const title = React.useMemo(() => {
    const metricPath = generatePath(routes.layoutComponentDetails, {
      configType: LayoutComponentType.CashSummaryChart,
      ...origin,
      intervalCount: undefined,
    })

    return (
      <ComponentExpandLink to={metricPath} linkBadge={<CashBadge />} componentSize={componentSize}>
        Cash Flow
      </ComponentExpandLink>
    )
  }, [componentSize, origin, generatePath])

  const totalTimeseries: Timeseries = { label: "Total Cash", values: totalCash }

  if (componentSize === ComponentSize.Small || componentSize === ComponentSize.PageSmall) {
    return (
      <>
        <ComponentDateSummary
          componentSize={componentSize}
          title={title}
          timeseries={totalCash}
          selectedIndex={selectedIndex}
        />
        <ChartContainer height={height}>
          <TimeseriesBreakdownChartTile
            breakdownTimeseries={breakdownTimeseries}
            totalTimeseries={totalTimeseries}
            height={height}
            barChartStyle={SharedBarChartStyles}
            lineChartStyle={SharedLineChartStyles}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
            onClick={onBarClick}
            selectedIndex={selectedIndex}
          />
        </ChartContainer>
      </>
    )
  }

  return (
    <>
      <ComponentSummary
        componentSize={componentSize}
        title={title}
        timeseries={totalCash}
        selectedIndex={selectedIndex}
      />
      <ChartContainer height={height}>
        <ParentSizedTimeseriesBreakdownChart
          breakdownTimeseries={breakdownTimeseries}
          totalTimeseries={totalTimeseries}
          barChartStyle={SharedBarChartStyles}
          lineChartStyle={SharedLineChartStyles}
          onClick={onBarClick}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          skipAnimations={skipAnimations}
        />
      </ChartContainer>
    </>
  )
}
