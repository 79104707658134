import * as React from "react"
import { LayoutComponentConfig, LayoutComponentType } from "@digits-graphql/frontend/graphql-bearer"
import { DocumentsConfig } from "src/frontend/components/Shared/Layout/Components/Vault/DocumentsConfig"
import { ChartType, matchConfig } from "src/frontend/components/Shared/Layout/types"
import { CashSummaryChartConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Charts/CashSummaryChartConfig"
import { CategoryChartConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Charts/CategoryChartConfig"
import { MetricChartConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Charts/MetricChartConfig"
import { PartyChartConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Charts/PartyChartConfig"
import { RunwayChartConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Charts/RunwayChartConfig"
import { SummaryLineItemChartConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Charts/SummaryLineItemChartConfig"
import { TopEntitiesConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Lists/TopEntitiesConfig"
import { TopTransactionsConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Lists/TopTransactionsConfig"
import { StatementComponentConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Statements/StatementComponentConfig"
import { TextConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Text/TextConfig"
import { KPIConfig } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Drawer/Components/KPI/KPIConfig"

const CONFIG_CHART_HEIGHT = 120
const CONFIG_TEXT_HEIGHT = 75

export const MatchConfig: React.FC<{
  config: LayoutComponentConfig
  skipAnimations: boolean
}> = ({ config, skipAnimations }) => {
  if (matchConfig(config, "summaryLineItem", LayoutComponentType.SummaryLineItemChart)) {
    return (
      <SummaryLineItemChartConfig
        config={config.summaryLineItem}
        componentType={config.type}
        height={CONFIG_CHART_HEIGHT}
        skipAnimations={skipAnimations}
      />
    )
  }

  if (matchConfig(config, "metricChart", LayoutComponentType.MetricSummaryChart)) {
    return (
      <MetricChartConfig
        config={config.metricChart}
        componentType={config.type}
        height={CONFIG_CHART_HEIGHT}
        skipAnimations={skipAnimations}
      />
    )
  }

  if (matchConfig(config, "cashSummaryChart", LayoutComponentType.CashSummaryChart)) {
    return (
      <CashSummaryChartConfig
        config={config.cashSummaryChart}
        height={CONFIG_CHART_HEIGHT}
        skipAnimations={skipAnimations}
      />
    )
  }

  if (matchConfig(config, "runwayChart", LayoutComponentType.RunwayChart)) {
    return (
      <RunwayChartConfig
        config={config.runwayChart}
        height={CONFIG_CHART_HEIGHT}
        skipAnimations={skipAnimations}
      />
    )
  }

  if (matchConfig(config, "categoryChart", LayoutComponentType.CategoryTransactionSummaryChart)) {
    return (
      <CategoryChartConfig
        config={config.categoryChart}
        componentType={config.type}
        chartType={ChartType.TransactionSummary}
        height={CONFIG_CHART_HEIGHT}
        skipAnimations={skipAnimations}
      />
    )
  }

  if (matchConfig(config, "categoryChart", LayoutComponentType.CategoryBalanceSummaryChart)) {
    return (
      <CategoryChartConfig
        config={config.categoryChart}
        componentType={config.type}
        chartType={ChartType.BalanceSummary}
        height={CONFIG_CHART_HEIGHT}
        skipAnimations={skipAnimations}
      />
    )
  }

  if (matchConfig(config, "partyChart", LayoutComponentType.PartyTransactionSummaryChart)) {
    return (
      <PartyChartConfig
        config={config.partyChart}
        componentType={config.type}
        chartType={ChartType.TransactionSummary}
        height={CONFIG_CHART_HEIGHT}
        skipAnimations={skipAnimations}
      />
    )
  }

  if (matchConfig(config, "partyChart", LayoutComponentType.PartyRoleBalanceSummaryChart)) {
    return (
      <PartyChartConfig
        config={config.partyChart}
        componentType={config.type}
        chartType={ChartType.BalanceSummary}
        height={CONFIG_CHART_HEIGHT}
        skipAnimations={skipAnimations}
      />
    )
  }

  if (matchConfig(config, "topEntities", LayoutComponentType.TopEntities)) {
    return (
      <TopEntitiesConfig
        config={config.topEntities}
        componentType={config.type}
        height={CONFIG_TEXT_HEIGHT}
      />
    )
  }

  if (matchConfig(config, "topTransactions", LayoutComponentType.TopTransactions)) {
    return (
      <TopTransactionsConfig
        config={config.topTransactions}
        componentType={config.type}
        height={CONFIG_TEXT_HEIGHT}
      />
    )
  }

  if (matchConfig(config, "statement", LayoutComponentType.Statement)) {
    return <StatementComponentConfig config={config.statement} height={CONFIG_TEXT_HEIGHT} />
  }

  if (matchConfig(config, "text", LayoutComponentType.Text)) {
    return <TextConfig config={config.text} />
  }

  if (matchConfig(config, "staticMetric", LayoutComponentType.StaticMetric)) {
    return <KPIConfig config={config.staticMetric} />
  }

  if (matchConfig(config, "documentCollection", LayoutComponentType.DocumentCollection)) {
    return <DocumentsConfig config={config.documentCollection} />
  }

  return null
}
