import * as React from "react"
import {
  PageScrollContext,
  PageScrollContextProps,
  useBuildPageScrollContext,
} from "@digits-shared/components/UI/Elements/PageScrollContext"
import styled from "styled-components"

const ScrollableContainer = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  flex: 1;
  overflow: auto;
  padding: 0 0;
  // dont change this here, if you really have to, use "styled(FullScreenScrollableContainer)"
  width: calc(100vw - var(--sidebar-content-offset));
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;

  &:before,
  &:after {
    content: "";
    display: block;
    flex: 1;
  }

  & > div {
    width: ${({ fullWidth }) =>
      fullWidth ? "var(--application-pane-full-width)" : "var(--application-pane-width)"};
    height: fit-content;
    padding-bottom: 30px;
  }
`

interface ScrollableContainerProps {
  className?: string
  children?: React.ReactNode
  browserScrollContainer?: React.MutableRefObject<HTMLDivElement | null>
  pageScrollContext?: PageScrollContextProps
  fullWidth?: boolean
}

export const FullScreenScrollableContainer: React.FC<ScrollableContainerProps> = ({
  className,
  children,
  browserScrollContainer,
  pageScrollContext,
  fullWidth,
}) => {
  const { browserScrollContainer: defaultContainer, pageScrollContext: defaultScrollContext } =
    useBuildPageScrollContext()

  const scrollContainer = browserScrollContainer || defaultContainer
  const scrollContext = pageScrollContext || defaultScrollContext
  return (
    <PageScrollContext.Provider value={scrollContext}>
      <ScrollableContainer ref={scrollContainer} className={className} fullWidth={fullWidth}>
        <div>{children}</div>
      </ScrollableContainer>
    </PageScrollContext.Provider>
  )
}
