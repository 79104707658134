import * as React from "react"
import {
  Flow,
  MoneyFlow,
  ReportOptionComparison,
  StatementDeltaValue,
} from "@digits-graphql/frontend/graphql-bearer"
import colorHelper from "@digits-shared/helpers/colorHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import numberHelper from "@digits-shared/helpers/numberHelper"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import {
  ColumnOptionKey,
  isBreakdownColumn,
  isDeltaColumn,
  isDoubleColumn,
  overTimeDeltaValue,
  StatementColumns,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/columnTypes"
import { TotalCell } from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import {
  RowDetails,
  useToOptionTimeSeries,
  useToTimeSeries,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/toDetailsData"
import { useStatementCellEvents } from "src/frontend/components/Shared/Reports/Report/Components/Statements/useStatementCellEvents"
import {
  useReportCurrencyOptions,
  useReportDocumentOptions,
} from "src/frontend/components/Shared/Reports/Report/hooks/useReportDocumentOptions"

/*
  STYLES
*/

const deltaStyle = themedValue({
  print: css`
    opacity: 1;
    color: ${colors.translucentBlack50};
  `,
  light: (props: DeltaProps) => deltaValueColor(props),
  dark: (props: DeltaProps) => deltaValueColor(props),
})

const DeltaStyled = styled(TotalCell)<DeltaProps>`
  ${deltaStyle};
`

/*
  INTERFACES
*/

interface DeltasProps {
  rowId: string
  details?: RowDetails
}

interface DeltaProps {
  deltaValue?: StatementDeltaValue | null
  optionKey: ColumnOptionKey
}

/*
  COMPONENTS
*/

export const RowDeltas: React.FC<DeltasProps> = ({ rowId, details }) => {
  const reportOptions = useReportDocumentOptions()
  return (
    <>
      {details &&
        reportOptions?.columnKeys.map(
          (optionKey: keyof StatementColumns) =>
            isDeltaColumn(optionKey, reportOptions.columns) && (
              <DeltasOverTime
                key={optionKey}
                rowId={rowId}
                optionKey={optionKey}
                comparison={reportOptions.columns[optionKey] as ReportOptionComparison}
                details={details}
              />
            )
        )}
    </>
  )
}

export const DeltasOverTime: React.FC<{
  rowId: string
  optionKey: ColumnOptionKey
  comparison: ReportOptionComparison
  details: RowDetails
}> = ({ rowId, optionKey, details, comparison }) => {
  const values = useToOptionTimeSeries(optionKey, details)?.values.slice().reverse()
  if (!values) return <div />

  const lookbackOffset = optionKey === "yearToDate" ? 0 : 1
  const dualColumn = isDoubleColumn(optionKey, comparison)

  return (
    <>
      {dualColumn && (
        <ValueOverTime
          rowId={rowId}
          lookback={lookbackOffset}
          optionKey={optionKey}
          comparison={ReportOptionComparison.Total}
          values={values}
          details={details}
        />
      )}
      <ValueOverTime
        rowId={rowId}
        lookback={lookbackOffset}
        optionKey={optionKey}
        comparison={comparison}
        values={values}
        details={details}
      />
    </>
  )
}

export const TotalsOverTime: React.FC<DeltasProps> = ({ rowId, details }) => {
  const reportOptions = useReportDocumentOptions()
  const optionKey = "deltaMonthOverMonth"

  const periodDetails = details?.periodDetails
  const values = useToTimeSeries(periodDetails)?.values.slice().reverse()

  if (!details || !isBreakdownColumn(optionKey, reportOptions?.columns)) return null

  const count = Math.max(1, reportOptions.deltaMonthOverMonthPeriods)
  if (!values?.length) {
    return (
      <>
        {Array.from({ length: count }).map((_, idx) => (
          <div key={idx}></div>
        ))}
      </>
    )
  }

  return (
    <>
      {Array.from({ length: count }).map((_, idx) => (
        <ValueOverTime
          key={idx}
          rowId={rowId}
          lookback={count - idx}
          optionKey={optionKey}
          comparison={ReportOptionComparison.Total}
          details={details}
          values={values}
        />
      ))}
    </>
  )
}

const ValueOverTime: React.FC<{
  rowId: string
  lookback: number
  optionKey: ColumnOptionKey
  comparison: ReportOptionComparison
  details: RowDetails
  values: TimeseriesValue[]
}> = ({ rowId, lookback, values, details, optionKey, comparison }) => {
  const currentValue = values[0]?.moneyFlow
  const timeseriesValue = values[lookback]
  const nextValue = timeseriesValue?.moneyFlow

  const { value, deltaValue } = useOvertimeValues(
    optionKey,
    comparison,
    details,
    currentValue,
    nextValue
  )

  if (!currentValue || !nextValue) return null

  return (
    <DeltaColumn
      rowId={rowId}
      timeseriesValue={timeseriesValue}
      deltaValue={deltaValue}
      optionKey={optionKey}
    >
      {value}
    </DeltaColumn>
  )
}

const DeltaColumn: React.FC<
  React.PropsWithChildren<{
    rowId: string
    optionKey: ColumnOptionKey
    timeseriesValue?: TimeseriesValue
    deltaValue?: StatementDeltaValue | null
  }>
> = ({ rowId, timeseriesValue, optionKey, deltaValue, children }) => {
  const { onMouseEnter, onClick } = useStatementCellEvents(rowId, { optionKey, timeseriesValue })

  return (
    <DeltaStyled
      deltaValue={deltaValue}
      optionKey={optionKey}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {children}
    </DeltaStyled>
  )
}

function useOvertimeValues(
  optionKey: ColumnOptionKey,
  comparison: ReportOptionComparison,
  details: RowDetails | undefined,
  currentValue?: MoneyFlow,
  nextValue?: MoneyFlow
) {
  const currencyOptions = useReportCurrencyOptions()
  return React.useMemo(() => {
    const { moneyFlow, deltaValue, deltaAmount, percentValue } = overTimeDeltaValue(
      optionKey,
      currentValue,
      nextValue,
      details?.deltas
    )

    const amount = moneyFlow ? moneyFlowHelper.currency(moneyFlow, currencyOptions) : "-"

    const delta = deltaAmount
      ? moneyFlowHelper.currency(deltaAmount, {
          ...currencyOptions,
          signDisplay: "always",
        })
      : "-"

    const percentFractionDigits = Math.abs(percentValue ?? 0) < 1 ? 1 : 0
    const percentSignDisplay =
      optionKey === "comparedToIncome" || optionKey === "comparedToTotal" ? "never" : "always"
    const percentage = percentValue
      ? numberHelper
          .numberFormatter({
            ...currencyOptions,
            maximumFractionDigits: percentFractionDigits,
            minimumFractionDigits: percentFractionDigits,
            style: "percent",
            signDisplay: percentSignDisplay,
          })
          .format(percentValue / 100)
      : "-"

    switch (comparison) {
      case ReportOptionComparison.Amount:
        return { deltaValue, value: delta }

      case ReportOptionComparison.Percent:
        return { deltaValue, value: percentage }

      case ReportOptionComparison.Total:
      case ReportOptionComparison.InvalidComparison:
        return { deltaValue: undefined, value: amount }
    }
  }, [comparison, currencyOptions, currentValue, details, nextValue, optionKey])
}

// FUNCTIONS

function deltaValueColor({ deltaValue, optionKey }: DeltaProps) {
  if (!deltaValue) {
    return css`
      color: ${colors.secondary};
    `
  }

  if (!deltaValue.moneyFlow?.value.amount) {
    return css`
      color: ${colors.translucentSecondary60};
    `
  }

  const {
    percentageOfAmount,
    moneyFlow: { businessFlow },
  } = deltaValue
  const abs = Math.abs(percentageOfAmount / 100)
  const isPositive = businessFlow === Flow.Inbound

  const alpha = abs < 0.01 ? 0.25 : abs < 0.1 ? 0.4 : abs <= 0.5 ? 0.6 : 1
  const weight = abs < 0.2 ? fonts.weight.medium : fonts.weight.heavy

  switch (optionKey) {
    case "comparedToTotal":
    case "comparedToIncome": {
      const color = colorHelper.hexToRgba(colors.secondary, alpha)
      return css`
        color: ${color};
        font-weight: ${weight};
      `
    }
    case "yearToDate":
    case "deltaYearToDate":
    case "amountPrecision":
    case "deltaMonthOverMonth":
    case "deltaYearOverYear":
    case "deltaMonthOverMonthPeriods":
    case "sparklineLookbackPeriods": {
      const color = isPositive
        ? colorHelper.hexToRgba(colors.primary, alpha)
        : colorHelper.hexToRgba(colors.orange, alpha)
      return css`
        color: ${color};
        font-weight: ${weight};
      `
    }
  }
}
