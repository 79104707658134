import * as React from "react"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { AddClientAction } from "src/frontend/components/OS/Header/AddClient/actions"
import { MODAL_RADIUS } from "src/frontend/components/OS/Header/AddClient/shared"
import { AddClientState } from "src/frontend/components/OS/Header/AddClient/types"

/*
  STYLES
 */
const Wrapper = styled.div`
  padding: 80px 52px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${MODAL_RADIUS}px;
  height: 100%;
  background: linear-gradient(177.24deg, #6b92d5 0.5%, #6cb1cb 100%);

  & img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 8px;
  color: ${colors.white};
`

const Headline = styled.h2`
  font-size: 24px;
  font-weight: ${fonts.weight.heavy};
  color: ${colors.white};
`

const Body = styled.p`
  font-size: 14px;
`

export const LegalEntityName = styled.div`
  color: ${colors.white};
  font-weight: ${fonts.weight.heavy};
  font-size: 14px;
  margin-bottom: 12px;
`

/*
  COMPONENTS
 */

export const Done: React.FC<{
  className?: string
  state: AddClientState
  dispatch: React.Dispatch<AddClientAction>
  onDone: () => void
}> = ({ className, state, dispatch, onDone }) => {
  const { clientDetails } = state

  const onClick = () => {
    dispatch({ type: "reset" })
    onDone()
  }

  return (
    <Wrapper className={className}>
      <img
        alt="line art of a dashboard with charts"
        src={require("static/images/clientportal/client-portal-stylized.png")}
      />

      <TextWrapper>
        <Headline>New Client Invite sent!</Headline>
        {clientDetails?.name && <LegalEntityName>{clientDetails.name}</LegalEntityName>}
        <Body>
          An invite has been sent to <strong>{clientDetails?.emailAddress}</strong>
        </Body>
      </TextWrapper>
      <DigitsButton $fullWidth onClick={onClick}>
        Back to Dashboard
      </DigitsButton>
    </Wrapper>
  )
}
