import { ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import moment from "moment"

export default class QueryBuilder {
  text: string = ""
  occurredAfter?: Date
  occurredBefore?: Date
  kind: ObjectKind

  constructor(urlQueryParams: Record<string, string> = {}) {
    const { q, after, before } = urlQueryParams
    const occurredAfter = after ? new Date(parseInt(after, 10)) : undefined
    const occurredBefore = before ? new Date(parseInt(before, 10)) : undefined

    this.setText(q || "")
      .setOccurredAfter(occurredAfter)
      .setOccurredBefore(occurredBefore)
  }

  setText(text: string) {
    this.text = text
    return this
  }

  setOccurredAfter(occurredAfter?: Date) {
    this.occurredAfter = occurredAfter
    return this
  }

  setOccurredBefore(occurredBefore?: Date) {
    this.occurredBefore = occurredBefore
    return this
  }

  setKind(kind: ObjectKind) {
    this.kind = kind
    return this
  }

  build() {
    return new QueryRequest(this)
  }

  buildUrlParams() {
    return {
      q: this.text,
      after: this.occurredAfter?.getTime(),
      before: this.occurredBefore?.getTime(),
    }
  }

  copy() {
    return new QueryBuilder()
      .setText(this.text)
      .setOccurredBefore(this.occurredBefore)
      .setOccurredAfter(this.occurredAfter)
      .setKind(this.kind)
  }

  reset() {
    this.text = ""
    this.occurredAfter = undefined
    this.occurredBefore = undefined
    return this
  }
}

export interface SerializedQueryRequest {
  text: string
  kind: ObjectKind
  occurredAfter?: number
  occurredBefore?: number
}

export class QueryRequest {
  readonly text: string
  readonly occurredAfterDate?: Date
  readonly occurredBeforeDate?: Date
  readonly kind: ObjectKind

  constructor(builder: QueryBuilder) {
    this.text = builder.text
    this.occurredAfterDate = builder.occurredAfter
    this.occurredBeforeDate = builder.occurredBefore
    this.kind = builder.kind
  }

  get occurredAfter() {
    // Only transactions support searching with time ranges
    if (!this.occurredAfterDate || this.kind !== ObjectKind.Transaction) return undefined
    return moment(this.occurredAfterDate).unix()
  }

  get occurredBefore() {
    // Only transactions support searching with time ranges
    if (!this.occurredBeforeDate || this.kind !== ObjectKind.Transaction) return undefined
    return moment(this.occurredBeforeDate).unix()
  }

  get isValid() {
    return !!this.text?.trim().length
  }

  static serialize(query: QueryRequest): SerializedQueryRequest {
    const { text, occurredAfterDate, occurredBeforeDate, kind } = query

    return {
      text,
      kind,
      occurredAfter: occurredAfterDate?.getTime(),
      occurredBefore: occurredBeforeDate?.getTime(),
    }
  }

  static deserialize(obj: SerializedQueryRequest) {
    const occurredAfter = obj.occurredAfter ? new Date(obj.occurredAfter) : undefined
    const occurredBefore = obj.occurredBefore ? new Date(obj.occurredBefore) : undefined

    return new QueryBuilder()
      .setText(obj.text)
      .setKind(obj.kind)
      .setOccurredAfter(occurredAfter)
      .setOccurredBefore(occurredBefore)
      .build()
  }
}
