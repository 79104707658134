import * as React from "react"
import { usePrevious } from "react-use"
import useRouter from "@digits-shared/hooks/useRouter"
import { UNKNOWN_MODULE_NAME } from "src/frontend/components/Shared/Contexts/ModuleNameContext"
import { useModuleName } from "src/frontend/components/Shared/Contexts/useModuleName"
import routes from "src/frontend/routes"

/**
 * Returns a value that changes when going between different main application areas.
 * It is derived from watching the module name, but ignores the module names of details pages.
 */
export function useAppAreaChange() {
  const { location } = useRouter()
  const moduleName = useModuleName()
  const [moduleChange, setModuleChange] = React.useState(0)

  const route = routes[location.name]
  const isDetailsView = !!route?.config.detailsViewAction

  const effectiveModuleName = isDetailsView ? UNKNOWN_MODULE_NAME : moduleName
  const prevModuleName = usePrevious(effectiveModuleName)

  React.useEffect(() => {
    if (
      effectiveModuleName !== UNKNOWN_MODULE_NAME &&
      !!prevModuleName &&
      prevModuleName !== UNKNOWN_MODULE_NAME &&
      effectiveModuleName !== prevModuleName
    ) {
      setModuleChange((i) => i + 1)
    }
  }, [effectiveModuleName, prevModuleName])

  return moduleChange
}
