import * as React from "react"
import {
  LayoutComponentConfig,
  useReadPartySummaryByTimeComponentDataQuery,
  ViewIdentifier,
} from "@digits-graphql/frontend/graphql-bearer"

/**
 * Queries for layout component data fitting the dimensional reducer shape: by component by time.
 */
export function usePartySummaryByTimeComponentData(
  config: LayoutComponentConfig,
  layoutId: string,
  viewId: ViewIdentifier,
  dataId: string | undefined | null
) {
  const { data, loading } = useReadPartySummaryByTimeComponentDataQuery({
    variables: {
      dataId: dataId ?? "",
      layoutId,
      viewId,
    },
    skip: !dataId,
  })

  return React.useMemo(() => {
    const componentData = data?.readComponentData.data

    // Resolves the component data union types to the portions we care about
    if (
      componentData?.__typename === "Chart" &&
      componentData?.entity.__typename === "EntityParty"
    ) {
      return { party: componentData.entity, dimensionalSummary: componentData.summary, loading }
    }

    return { party: undefined, dimensionalSummary: undefined, loading }
  }, [data?.readComponentData.data, loading])
}
