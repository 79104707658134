import * as React from "react"
import { ViewType } from "@digits-graphql/frontend/graphql-bearer"
import type {
  ViewVersion,
  ViewVersions,
} from "src/frontend/components/Shared/Contexts/ViewVersionContext"

export const ViewVersionContext = React.createContext<ViewVersions>({
  accounting: new Map<string, Map<ViewType, ViewVersion>>(),
  updateKey: "",
  appAreaChange: 0,
  forceUpdate: () => {},
})
