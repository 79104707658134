import * as React from "react"
import { EntityTransaction } from "@digits-graphql/frontend/graphql-bearer"
import { TransactionListHeader } from "src/frontend/components/Shared/Reports/Report/Components/Transactions/TransactionListHeader"
import { TransactionRow } from "src/frontend/components/Shared/Reports/Report/Components/Transactions/TransactionRow"

/*
 INTERFACES
*/

interface TableProps {
  transactions: EntityTransaction[]
  showHeader?: boolean
  className?: string
}

/*
 COMPONENT
*/

export const TransactionsTable: React.FC<TableProps> = ({
  transactions,
  showHeader,
  className,
}) => {
  const transactionIds = React.useMemo(
    () =>
      transactions
        .map(({ factId }) => factId)
        .filter((factId): factId is string => Boolean(factId)),
    [transactions]
  )

  return (
    <div className={className}>
      {showHeader !== false && (
        <TransactionListHeader>
          <div>Date</div>
          <div>Transaction</div>
          <div>Amount</div>
        </TransactionListHeader>
      )}
      {transactions.map((t, idx) => (
        <TransactionRow
          key={`${idx}_${t.factId}`}
          transaction={t}
          transactionIds={transactionIds}
        />
      ))}
    </div>
  )
}
