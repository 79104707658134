import * as React from "react"
import colorHelper from "@digits-shared/helpers/colorHelper"
import Color from "color"
import { StackableBarColorsIds } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Shared"

/*
  INTERFACES
*/

interface GradientProps {
  id: string
  reverse?: boolean
}

interface FillProps {
  activePeriod?: boolean
  hovered?: boolean
  color: string
  bottomColor?: string
  withOpacity?: boolean
}

export interface ColoringProps {
  barColorIds: StackableBarColorsIds
}

/*
 COMPONENTS
*/

export const InterpolateColor = (x: Color, y: Color, ratio: number) => {
  const r = x.red() + ratio * (y.red() - x.red())
  const g = x.green() + ratio * (y.green() - x.green())
  const b = x.blue() + ratio * (y.blue() - x.blue())
  return Color.rgb(r, g, b)
}

const STRONG_WHITENING_MULTIPLIER = 0.2

export const FillTopColor = (props: FillProps) => {
  const { activePeriod, color, hovered } = props

  return hovered
    ? Color(color)
    : activePeriod
      ? Color(colorHelper.hexToRgba(color, 0.8))
      : Color(colorHelper.hexToRgba(color, 0.3))
}

const FillBottomColor = (props: FillProps, topColor: Color) => {
  const { hovered, bottomColor } = props

  if (bottomColor) {
    return hovered
      ? Color(bottomColor).whiten(STRONG_WHITENING_MULTIPLIER).saturate(0.2)
      : Color(bottomColor)
  }

  if (hovered) {
    return topColor.whiten(STRONG_WHITENING_MULTIPLIER).saturate(0.2)
  }
  return topColor.lighten(0.2)
}

export const HorizontalGradient: React.FC<GradientProps & FillProps> = (props) => {
  const { id, withOpacity } = props
  const leftColor = FillTopColor(props)
  const rightColor = FillBottomColor(props, leftColor)

  return (
    <linearGradient id={id}>
      <stop offset="0%" stopColor={withOpacity ? leftColor.string() : leftColor.hex()} />
      <stop offset="100%" stopColor={withOpacity ? rightColor.string() : rightColor.hex()} />
    </linearGradient>
  )
}

export const VerticalGradient: React.FC<GradientProps & FillProps> = (props) => {
  const { id, reverse, withOpacity } = props
  const topColor = FillTopColor(props)
  const bottomColor = FillBottomColor(props, topColor)
  const direction = reverse ? { y1: "0%", y2: "100%" } : { y1: "100%", y2: "0%" }

  return (
    <linearGradient id={id} x1="0%" y1={direction.y1} x2="0%" y2={direction.y2}>
      <stop offset="0%" stopColor={withOpacity ? bottomColor.string() : bottomColor.hex()} />
      <stop offset="100%" stopColor={withOpacity ? topColor.string() : topColor.hex()} />
    </linearGradient>
  )
}

export const VerticalAngledGradient: React.FC<GradientProps & FillProps> = (props) => {
  const { id, withOpacity } = props
  const topColor = FillTopColor(props)
  const bottomColor = FillBottomColor(props, topColor)

  return (
    <linearGradient id={id} x1="0%" y1="0%" x2="100%" y2="100%" gradientTransform="rotate(33)">
      <stop offset="0%" stopColor={withOpacity ? topColor.string() : topColor.hex()} />
      <stop offset="100%" stopColor={withOpacity ? bottomColor.string() : bottomColor.hex()} />
    </linearGradient>
  )
}

export const SolidFill: React.FC<GradientProps & FillProps> = (props) => {
  const { id, reverse, withOpacity } = props
  const topColor = FillTopColor(props)
  const direction = reverse ? { y1: "0%", y2: "100%" } : { y1: "100%", y2: "0%" }

  return (
    <linearGradient id={id} x1="0%" y1={direction.y1} x2="0%" y2={direction.y2}>
      <stop offset="0%" stopColor={withOpacity ? topColor.string() : topColor.hex()} />
    </linearGradient>
  )
}
