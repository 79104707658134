import * as React from "react"
import { Interval, IntervalOrigin } from "@digits-graphql/frontend/graphql-bearer"
import { chevronStyles, PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

/*
  STYLES
*/

export const ActivePeriodContainer = styled.div<{ $editable: boolean }>`
  &::after {
    ${({ $editable }) =>
      $editable &&
      css`
        ${chevronStyles(PointingDirection.Down, "4px", colors.secondary)};
        position: absolute;
        right: 9px;
        top: 50%;
        margin-top: -2px;
        transition: border-color 250ms ease-out;
      `}
  }

  position: relative;
  pointer-events: none;
  background: ${colors.transparent};
  font-weight: ${fonts.weight.book};
  font-size: 18px;
  color: ${colors.translucentSecondary80};
  border: none;
  padding: 7px 25px 7px 0;
  line-height: 1.5;
  transition:
    background-color 250ms ease,
    color 250ms ease;

  b {
    text-transform: capitalize;
    font-weight: ${fonts.weight.heavy};
    color: ${colors.secondary};
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  &:focus {
    outline: none;
  }
`

/*
  COMPONENTS
*/

export const ActivePeriod: React.FC<{
  editable: boolean
  todayView?: boolean
  activeIntervalOrigin?: IntervalOrigin
  className?: string
  children?: React.ReactNode
}> = ({ todayView, editable, activeIntervalOrigin, className, children }) => {
  const intervalOrigin = useIntervalOrigin()
  const displayIntervalOrigin = activeIntervalOrigin || intervalOrigin

  return (
    <ActivePeriodContainer className={className} color={colors.white} $editable={editable}>
      {children}
      <PeriodName todayView={todayView} intervalOrigin={displayIntervalOrigin} />
    </ActivePeriodContainer>
  )
}

const PeriodName: React.FC<{ todayView?: boolean; intervalOrigin: IntervalOrigin }> = ({
  todayView,
  intervalOrigin,
}) => {
  if (!intervalOrigin.intervalCount || intervalOrigin.intervalCount === 1) {
    if (
      todayView &&
      dateTimeHelper.areIntervalOriginsEqual(
        intervalOrigin,
        dateTimeHelper.todayIntervalOrigin(Interval.Month),
        true
      )
    ) {
      return <b>Today</b>
    }

    return <IntervalName intervalOrigin={intervalOrigin} />
  }

  const priorPeriodOrigin = dateTimeHelper.addIntervalToOrigin(
    intervalOrigin,
    -intervalOrigin.intervalCount + 1
  )

  return (
    <>
      <IntervalName intervalOrigin={priorPeriodOrigin} />{" "}
      <span css="text-transform: lowercase;">to</span>{" "}
      <IntervalName intervalOrigin={intervalOrigin} />
    </>
  )
}

const IntervalName: React.FC<{ intervalOrigin: IntervalOrigin }> = ({ intervalOrigin }) => {
  const intervalParts = dateTimeHelper
    .displayNameFromIntervalOrigin({ ...intervalOrigin, intervalCount: 1 })
    .split(" ")
  if (intervalParts.length > 1) {
    return (
      <>
        <b>{intervalParts.slice(0, -1).join(" ")}&nbsp;</b>
        {intervalParts[intervalParts.length - 1]}
      </>
    )
  }
  return <b>{intervalParts.join(" ")}</b>
}
