import * as React from "react"
import { SpringboardModuleLoading } from "@digits-shared/components/Loaders"
import envHelper from "@digits-shared/helpers/envHelper"
import useSession from "@digits-shared/hooks/useSession"
import { useSessionListeners } from "@digits-shared/session/useSessionListeners"
import { ClientRoutes, SharedObjectClientRoutes } from "src/frontend/components/ClientRoutes"
import { DocumentTitle } from "src/frontend/components/DocumentTitle"
import { LegalEntityIdScopeProvider } from "src/frontend/components/LegalEntityIdScope"
import { EventsLoggerProvider } from "src/frontend/components/Shared/Contexts/EventsLoggerContext"
import { ModuleNameProvider } from "src/frontend/components/Shared/Contexts/ModuleNameProvider"
import { useObjectSharingState } from "src/frontend/hooks/useObjectSharingState"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"
import { ObjectSharingProvider } from "src/frontend/session/ObjectSharing"
import ClientVersionTracker from "src/shared/components/Background/ClientVersionTracker"
import { SpringControlsContextProvider } from "src/shared/components/Contexts/SpringControlsContext"
import BearerRefresh from "src/shared/components/Session/BearerRefresh"

/*
  STYLES
*/

/*
  COMPONENTS
*/

/**
 * Helper Client component to encapsulate the app routes and object sharing concerns
 */
export const ClientRoutesSwitcher: React.FC = () => {
  const { hasUserData } = useSession<FrontendSession>()
  const { sharedObjectInfo } = useObjectSharingState()

  // If there is not a user logged in, and this route represents an object that can
  // be shared, we potentially need to display a public shared object page.
  if (!hasUserData && sharedObjectInfo) {
    return <SharedObjectClientRoutes />
  }

  return <ClientRoutes />
}

/**
 * Top-level app component for the frontend
 */
export const FrontendClient: React.FC = () => {
  const session = useSession<FrontendSession>()
  useSessionListeners(routes.login.parameterizedPath)

  const blockAppOnBearerRefresh = session.hasUserData && !session.hasUsableBearer()

  // Has to be done in an effect so that the <BearerRefresh> is rendered before tell the session to do it.
  React.useEffect(() => {
    if (blockAppOnBearerRefresh) {
      session.blockingBearerRefresh()
    }
  }, [blockAppOnBearerRefresh, session])

  if (blockAppOnBearerRefresh && !envHelper.isMocks()) {
    return (
      <>
        <SpringboardModuleLoading />
        <BearerRefresh />
      </>
    )
  }

  return (
    <LegalEntityIdScopeProvider>
      <DocumentTitle />
      <BearerRefresh />
      <ClientVersionTracker />
      <SpringControlsContextProvider enabled={session.animationConfigDevToolsEnabled}>
        <ModuleNameProvider>
          <EventsLoggerProvider>
            <ObjectSharingProvider>
              <ClientRoutesSwitcher />
            </ObjectSharingProvider>
          </EventsLoggerProvider>
        </ModuleNameProvider>
      </SpringControlsContextProvider>
    </LegalEntityIdScopeProvider>
  )
}
