import * as React from "react"
import { OnDataOptions } from "@apollo/client"
import {
  AskAssistantDocument,
  AskAssistantSubscription,
  AskAssistantSubscriptionVariables,
  AssistantEventKind,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import {
  AssistantAction,
  AssistantEffect,
  AssistantState,
} from "src/frontend/components/Shared/Assistant/reducer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import { useLazySubscription } from "src/frontend/hooks/useLazySubscription"
import FrontendSession from "src/frontend/session"

export function useAskAssistant() {
  const viewKey = useViewVersion()
  const { user } = useSession<FrontendSession>()

  const askAssistant = useLazySubscription<
    AskAssistantSubscription,
    AskAssistantSubscriptionVariables
  >(AskAssistantDocument)

  return React.useCallback(
    (state: AssistantState, _: AssistantEffect, dispatch: React.Dispatch<AssistantAction>) => {
      const userMessages = Array.from(state.messages.values()).filter(
        (m) => m.comment.authorId === user.id
      )
      const messages = userMessages
        .slice(Math.max(0, userMessages.length - 10), userMessages.length)
        .map((m) => m.comment.text)
        .concat(state.currentMessageText ? [state.currentMessageText] : [])

      if (!messages.length) {
        console.warn("Cannot submit assistant message without a current message text")
        return
      }

      const unsubscribe = askAssistant({
        variables: {
          viewKey,
          threadId: state.threadId || "",
          messages,
        },
        onData: onAssistantData(state.threadId, state.isSubmitting, state.refetch, dispatch),
        onError: (error) => {
          dispatch({
            type: "RESOLVER_ERROR",
            payload: (error.graphQLErrors?.[0]?.extensions?.originalMessage as string) ?? "",
          })
        },
      })

      dispatch({ type: "SUBSCRIPTION", payload: unsubscribe })
    },
    [askAssistant, user.id, viewKey]
  )
}

function onAssistantData(
  threadId: string | undefined,
  isSubmitting: boolean,
  refetchThread: (() => void) | undefined,
  dispatch: React.Dispatch<AssistantAction>
) {
  return (d: OnDataOptions<AskAssistantSubscription>) => {
    const { data } = d.data
    switch (data?.askAssistant?.kind) {
      case AssistantEventKind.Update:
        if (!threadId && data.askAssistant.threadId) {
          dispatch({ type: "THREAD_START", payload: data.askAssistant.threadId })
        }

        dispatch({ type: "MESSAGE", payload: data.askAssistant })
        return
      case AssistantEventKind.Complete:
        if (isSubmitting) {
          dispatch({ type: "SUBMIT_DONE" })
        }
        return
      case AssistantEventKind.Error:
        if (isSubmitting) {
          dispatch({ type: "SUBMIT_ERROR" })
        }
        return
      case AssistantEventKind.Unknown:
      case undefined:
        console.warn("Unknown assistant event kind", data)
    }
  }
}
