import styled from "styled-components"

/*
  STYLES
*/
export const ChartContainer = styled.div`
  margin-right: 4px;
  width: 80px;
  height: 20px;
  transition: opacity 200ms ease;
  opacity: 0.6;
  a:hover & {
    opacity: 1;
  }
`

export const AmountAndCount = styled.div`
  font-size: 10px !important;
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-left: 10px;
`
