import * as React from "react"
import { Link, LinkProps } from "react-router-dom"
import {
  EntityCategory,
  EntityInvoice,
  EntityParty,
  EntityReport,
  EntityTransaction,
  EntityUser,
  SearchHit,
} from "@digits-graphql/frontend/graphql-bearer"
import { HOVERABLE_CLASS_NAME, SELECTED_CLASS_NAME } from "@digits-shared/components/UI/Table"
import colorHelper from "@digits-shared/helpers/colorHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

/*
  STYLES
*/

const ResultsLink = styled(Link)`
  display: block;
  padding: 15px 25px;

  width: 100%;
  font-weight: ${fonts.weight.normal};

  transition:
    border-color 200ms ease,
    box-shadow 200ms ease;
  border-top: 0.5px solid ${colorHelper.hexToRgba(colors.secondary, 0.2)};
  border-left: 0.5px solid ${colors.transparent};
  border-right: 0.5px solid ${colors.transparent};

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &:hover,
  &.${SELECTED_CLASS_NAME} {
    border-top-color: ${colorHelper.hexToRgba(colors.secondary, 0.5)};
    & + * {
      border-top-color: ${colorHelper.hexToRgba(colors.secondary, 0.5)};
    }
  }
`

export const ResultTitle = styled.div`
  color: ${colors.secondary};
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ResultDescription = styled.div`
  color: ${colors.translucentSecondary80};
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ResultAmount = styled.div`
  color: ${colors.translucentSecondary80}; // using just gray in Search until we decide on colors
  font-weight: ${fonts.weight.medium};
  font-size: 13px;
`

export const ResultRightAmount = styled(ResultAmount)`
  text-align: right;
`

export const ResultDate = styled.div`
  color: ${colors.translucentSecondary80};
  font-weight: ${fonts.weight.black};
  text-transform: uppercase;
  font-size: 10px;
`

export const Month = styled.div`
  margin-top: 1px;
`

export const Year = styled.div`
  font-weight: ${fonts.weight.book};
`

/*
  INTERFACES
*/

type SupportedEntities =
  | EntityTransaction
  | EntityParty
  | EntityReport
  | EntityUser
  | EntityCategory
  | EntityInvoice

export interface RowProps<T extends SupportedEntities> {
  entity: T
  hit: SearchHit
}

interface ResultRowProps {
  className?: string
}

/*
  COMPONENTS
*/

export const ResultRow: React.FC<LinkProps & ResultRowProps> = ({ className, ...rest }) => {
  const linkClassNames = [HOVERABLE_CLASS_NAME]
  if (className) {
    linkClassNames.push(className)
  }

  return <ResultsLink className={linkClassNames.join(" ")} {...rest} />
}

export const GridRow = styled(ResultRow)`
  display: grid;
  grid-template-columns: 55px minmax(0, 1fr) minmax(50px, auto);
  grid-column-gap: 5px;
  align-items: center;
`
