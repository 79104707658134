import { MonetaryValue } from "@digits-graphql/frontend/graphql-bearer"
import numberHelper from "@digits-shared/helpers/numberHelper"

export default {
  addValues(...values: MonetaryValue[]) {
    const total = this.buildMonetaryValue({
      ...(values[0] as MonetaryValue),
      amount: 0,
    })

    values.forEach((val) => {
      if (isInvalidValue(val, total)) {
        console.error("Adding 2 monetary values of different currency or multiplier", total, val)
      }
      total.amount += val.amount
    })
    return total
  },

  subtractValues(minuend: MonetaryValue, subtrahend: MonetaryValue) {
    const total = this.buildMonetaryValue(minuend)
    if (isInvalidValue(subtrahend, total)) {
      console.error(
        "Subtracting 2 monetary values of different currency or multiplier",
        total,
        subtrahend
      )
    }
    total.amount -= subtrahend.amount
    return total
  },

  buildMonetaryValue(val?: MonetaryValue) {
    return numberHelper.buildMonetaryAmount(
      val?.amount ?? 0,
      val?.iso4217CurrencyCode,
      val?.currencyMultiplier
    )
  },

  unmultipliedAmount(val: MonetaryValue): number {
    return val.currencyMultiplier === 0 ? 0 : val.amount / val.currencyMultiplier
  },

  absoluteAmount(val: MonetaryValue) {
    return this.buildMonetaryValue({
      ...val,
      amount: Math.abs(val.amount),
    })
  },

  subtractAbsoluteValues(minuend: MonetaryValue, subtrahend: MonetaryValue) {
    return this.subtractValues(this.absoluteAmount(minuend), this.absoluteAmount(subtrahend))
  },

  buildZeroMonetaryValue(): MonetaryValue {
    return {
      amount: 0,
      currencyMultiplier: 1e6,
      iso4217CurrencyCode: "USD",
    }
  },

  /**
   * Given a MonetaryValue calculate the percentage
   */
  percentage(current: MonetaryValue, next: MonetaryValue) {
    const currentTotal = current.amount / current.currencyMultiplier
    const nextTotal = next.amount / next.currencyMultiplier

    const delta = (currentTotal * 100) / nextTotal
    const truncatedDelta = Math.abs(delta) <= 10 ? parseFloat(delta.toFixed(2)) : Math.trunc(delta)

    if (!isFinite(truncatedDelta) || isNaN(truncatedDelta)) return NaN

    return truncatedDelta - 100
  },
}

function isInvalidValue(val: MonetaryValue, total: MonetaryValue) {
  // Zero monetary values are sometimes returned as "zero value" proto objects, containing no
  // currency multiplier or currency code. However, zero is zero in all currencies and multipliers,
  // so ignore this difference in that case.
  return (
    val.amount !== 0 &&
    (total.currencyMultiplier !== val.currencyMultiplier ||
      total.iso4217CurrencyCode !== val.iso4217CurrencyCode)
  )
}
