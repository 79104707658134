import * as React from "react"
import { ApolloError } from "@apollo/client"
import {
  ObjectIdentifier,
  useListAccountantSuggestedUsersQuery,
  useListLimitedSuggestedUsersQuery,
  useListSuggestedUsersQuery,
  User,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { useCanComment } from "src/frontend/hooks/useCanComment"
import { useJwtPermissions } from "src/frontend/hooks/useJwtPermissions"
import FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
  objectKindToModule,
} from "src/frontend/session/permissionModule"

interface SuggestedUsers {
  loading: boolean
  error?: ApolloError
  users?: User[]
}

function useHasCommentingPermissions() {
  const permissions = useJwtPermissions(FrontendPermissionSource.LegalEntity)
  return React.useMemo(
    () => !!permissions?.hasCommentPermission(FrontendPermissionModule.Reports),
    [permissions]
  )
}

export function useListSuggestedUsers(
  objectIdentifier: ObjectIdentifier,
  organizationId?: string,
  skip = false
): SuggestedUsers {
  const hasCommentingPermissions = useHasCommentingPermissions()
  const { isAffiliatedSession } = useSession<FrontendSession>()

  const operatorResponse = useOperatorEndpoint(objectIdentifier, organizationId, skip)
  const accountantResponse = useAccountantEndpoint(objectIdentifier, organizationId, skip)
  const limitedUserResponse = useLimitedUserEndpoint(objectIdentifier, skip)

  return hasCommentingPermissions
    ? isAffiliatedSession
      ? accountantResponse
      : operatorResponse
    : limitedUserResponse
}

function useOperatorEndpoint(identifier: ObjectIdentifier, organizationId?: string, skip = false) {
  const hasCommentingPermissions = useHasCommentingPermissions()
  const { isAffiliatedSession } = useSession<FrontendSession>()

  const { data, loading, error } = useListSuggestedUsersQuery({
    variables: {
      organizationId: organizationId || "",
      identifier,
    },
    skip: skip || isAffiliatedSession || !hasCommentingPermissions || !organizationId,
  })

  return React.useMemo(
    () => ({
      loading,
      users: data?.listSuggestedUsers,
      error,
    }),
    [loading, data?.listSuggestedUsers, error]
  )
}

function useAccountantEndpoint(
  identifier: ObjectIdentifier,
  organizationId?: string,
  skip = false
) {
  const hasCommentingPermissions = useHasCommentingPermissions()
  const { isAffiliatedSession } = useSession<FrontendSession>()

  const { data, loading, error } = useListAccountantSuggestedUsersQuery({
    variables: {
      organizationId: organizationId || "",
      identifier,
    },
    skip: skip || !isAffiliatedSession || !hasCommentingPermissions || !organizationId,
  })

  return React.useMemo(
    () => ({
      loading,
      users: data?.listAccountantSuggestedUsers,
      error,
    }),
    [loading, data?.listAccountantSuggestedUsers, error]
  )
}

function useLimitedUserEndpoint(identifier: ObjectIdentifier, skip = false) {
  const hasCommentingPermissions = useHasCommentingPermissions()
  const hasCommentingGrant = useCanComment(objectKindToModule(identifier.kind))

  const { data, loading, error } = useListLimitedSuggestedUsersQuery({
    variables: {
      identifier,
    },
    // skip if user is not limited (hasCommentingPermissions) or has no permission from object sharing
    skip: skip || hasCommentingPermissions || !hasCommentingGrant,
  })

  return React.useMemo(
    () => ({
      loading,
      users: data?.listLimitedSuggestedUsers,
      error,
    }),
    [loading, data?.listLimitedSuggestedUsers, error]
  )
}
