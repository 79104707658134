import * as React from "react"
import { Category, Insight, ObjectEntities } from "@digits-graphql/frontend/graphql-bearer"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgCheckCircle } from "@digits-shared/components/SVGIcons/line/CheckCircle.svg"
import { SvgPlusCircle } from "@digits-shared/components/SVGIcons/line/PlusCircle.svg"
import { RowContentDescription } from "@digits-shared/components/UI/Table/Content"
import useSession from "@digits-shared/hooks/useSession"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { themedValue } from "@digits-shared/themes"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { useProductArea } from "src/frontend/components/Shared/Contexts/ProductAreaContext"
import useReportPackageContext, {
  useReportPackageMode,
} from "src/frontend/components/Shared/Reports/Packages/Viewer/ReportPackageContext"
import { useReportComponentIntervalOrigin } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/hooks/useReportComponentIntervalOrigin"
import FrontendSession from "src/frontend/session"
import { InsightItem } from "src/shared/components/Insights/Item"

const assistant = require("static/images/icons/stars.gif")

/*
  STYLES
*/

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 20px 20px;
  padding-top: 15px;
  ${borders.theme.dark.divider.top};
`

const InsightContainer = styled.div`
  flex: 1;
`

const StyledInsightItem = styled(InsightItem)`
  padding: 0;
  align-items: flex-start;
  border: none;

  ${RowContentDescription} {
    font-size: 12px;
    line-height: 17px;
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: -10px;
`

const Icon = styled.img`
  padding: 6px;
  width: 32px;
  height: 32px;
`

const AddContainer = styled.div`
  padding: 0 10px 22px;
  text-align: center;
`

const SvgAddInsight = styled(SvgPlusCircle)`
  height: 18px;
  width: 18px;
  margin-right: 8px;
  ${svgPathStyles("#526d9f", 1.5)};
`

const addInsightColor = themedValue({ dark: colors.neonGreen, light: colors.primary })

const AddInsight = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: ${fonts.weight.medium};
  font-size: 14px;
  color: #526d9f;
  cursor: pointer;

  &:hover {
    color: ${addInsightColor};

    svg {
      ${(p) => svgPathStyles(addInsightColor(p), 1.5)};
    }
  }
`

const SvgAddedInsight = styled(SvgCheckCircle)`
  height: 18px;
  width: 18px;
  margin-right: 8px;
  ${(p) => svgPathStyles(addInsightColor(p), 1.5)};

  path[opacity="0.15"] {
    opacity: 1;
  }
`

const AddedInsight = styled(AddInsight)`
  color: ${addInsightColor};
  pointer-events: none;
`

/*
  INTERFACES
*/

interface CategoryInsightProps {
  category: Category
  insight: Insight | undefined | null
}

/*
  COMPONENTS
*/

export const CategoryInsight: React.FC<CategoryInsightProps> = ({ category, insight }) => {
  const intervalOrigin = useReportComponentIntervalOrigin(1)
  const session = useSession<FrontendSession>()
  const { currentLegalEntityId: legalEntityId } = session
  const productArea = useProductArea()
  const entities: ObjectEntities = { categories: [category] }

  if (!insight) return null

  return (
    <>
      <Container>
        <IconContainer>
          <Icon src={`${assistant}?.rand=${category.id}`} />
        </IconContainer>
        <InsightContainer>
          <StyledInsightItem
            alwaysShowDate
            insight={insight}
            entities={entities}
            intervalOrigin={intervalOrigin}
            session={session}
            legalEntityId={legalEntityId}
            productArea={productArea}
          />
        </InsightContainer>
      </Container>
      <AddInsightToSummary insight={insight} />
    </>
  )
}

const AddInsightToSummary: React.FC<Omit<CategoryInsightProps, "category">> = ({ insight }) => {
  const { value: added, setTrue: setAsAdded } = useStateBoolean(useIsAdded(insight))
  const {
    packageDispatch,
    packageState: { canAppendInsightsToSummary },
  } = useReportPackageContext()
  const mode = useReportPackageMode()

  const onAdd = React.useCallback(() => {
    if (!insight) return
    packageDispatch({ type: "NEW_SUMMARY_INSIGHT", value: insight })
    setAsAdded()
  }, [insight, packageDispatch, setAsAdded])

  if (mode === "VIEW") return null

  if (added) {
    return (
      <AddContainer>
        <AddedInsight>
          <SvgAddedInsight />
          Added to Executive Summary
        </AddedInsight>
      </AddContainer>
    )
  }

  if (!insight || !canAppendInsightsToSummary) return null

  return (
    <AddContainer>
      <AddInsight onClick={onAdd}>
        <SvgAddInsight />
        Add to Executive Summary
      </AddInsight>
    </AddContainer>
  )
}

function useIsAdded(insight: Insight | undefined | null) {
  const { packageState } = useReportPackageContext()
  return !!insight?.sentence && packageState.addedInsights.includes(insight.sentence)
}
