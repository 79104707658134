import * as React from "react"
import { Position, RingLogoLoader } from "@digits-shared/components/Loaders/RingLogo"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

export const LoadingSpinner = styled(RingLogoLoader)`
  ${(props) => svgIconStyles(props.color ?? colors.primary)};
`

export const LogoLoader: React.FC<{ className?: string; position?: Position; color?: string }> = ({
  className,
  position,
  color = colors.primary,
}) => <LoadingSpinner className={className} color={color} position={position} />
