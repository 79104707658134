import * as React from "react"
import { SummaryLineItem } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import { BarChartComponent } from "src/frontend/components/Shared/Assistant/Messages/Layout/Chart/BarChartComponent"
import { LineChartComponent } from "src/frontend/components/Shared/Assistant/Messages/Layout/Chart/LineChartComponent"
import { useLayoutContext } from "src/frontend/components/Shared/Assistant/Messages/Layout/Context"
import { useSummaryLineItemByTimeComponentData } from "src/frontend/components/Shared/Assistant/Messages/Layout/hooks/useSummaryLineItemByTimeComponentData"
import {
  TimeseriesValue,
  toSortedTimeseriesValues,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { MatchedComponent } from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface SummaryLineItemChartComponentProps {
  component: MatchedComponent<"summaryLineItem">
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const SummaryLineItemChartComponent: React.FC<SummaryLineItemChartComponentProps> = ({
  component,
  skipAnimations,
}) => {
  const { layoutId, viewKey } = useLayoutContext()

  const {
    config: { summaryLineItem: config },
    dataId,
  } = component

  const { dimensionalSummary } = useSummaryLineItemByTimeComponentData(
    component.config,
    layoutId,
    viewKey,
    dataId
  )

  const period = React.useMemo(
    () => dateTimeHelper.periodFromIntervalOrigin(config.origin),
    [config.origin]
  )

  const timeseries = React.useMemo(
    () => toSortedTimeseriesValues(dimensionalSummary?.time),
    [dimensionalSummary?.time]
  )

  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined)

  const onMouseOver = React.useCallback((value: TimeseriesValue, index: number) => {
    setSelectedIndex(index)
  }, [])

  const onMouseOut = React.useCallback((value?: TimeseriesValue) => {
    setSelectedIndex(undefined)
  }, [])

  const name = React.useMemo(() => stringHelper.camelCaseToSpaces(config.item), [config.item])

  if (config.item === SummaryLineItem.TotalCash) {
    return (
      <LineChartComponent
        name={name}
        timeseries={timeseries}
        period={period}
        skipAnimations={skipAnimations}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        selectedIndex={selectedIndex}
      />
    )
  }

  return (
    <BarChartComponent
      name={name}
      timeseries={timeseries}
      period={period}
      skipAnimations={skipAnimations}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      selectedIndex={selectedIndex}
    />
  )
}
