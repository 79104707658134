import * as React from "react"
import { EntityCategory, EntityParty, EntityUser } from "@digits-graphql/frontend/graphql-bearer"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import { IconContainer, IconSize } from "@digits-shared/components/UI/Icons/Icon"
import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import { themedSVGIconStyles } from "@digits-shared/themes/svgIconStyles"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { EntityPopOver as EntityPopOverProps } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Components/Text/PopOver/EntityPopOver"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import { HoverableEntity } from "src/shared/components/ObjectEntities/EntitiesParser"
import { EntityPopOver } from "src/shared/components/ObjectEntities/EntityPopOver"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"

/*
 STYLES
*/

const EntityContainer = styled(EntityPopOver)`
  cursor: ${({ disableHover }) => (disableHover ? "auto" : "pointer")};
  display: inline-flex;
  align-items: baseline;
  margin-right: 2px;

  & > ${IconContainer} {
    align-self: center;
  }
`

export const User = styled.span`
  display: inline-flex;
  align-items: baseline;
  white-space: nowrap;
  margin-right: 2px;
  padding: 3px 5px;
  border-radius: 3px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.15) 102.05%
  );
`

const Avatar = styled(ColorIcon)`
  height: 18px;
  width: 18px;
  margin: -1px 0 0;
  flex: 1;
  align-self: center;
  font-size: 8px;
`

const entityNameStyles = themedStyles({
  print: css`
    color: ${colors.black};
  `,
  light: css`
    color: ${colors.secondary};
  `,
  dark: css`
    color: #8ef9ff;
  `,
})

export const EntityName = styled.span`
  ${entityNameStyles};
  margin-left: 4px;
  font-weight: ${fonts.weight.heavy};
`

const iconStyle = themedSVGIconStyles({
  light: colors.black,
  dark: "#8ef9ff",
})

const CategoryIcon = styled(SVGIconComponent)<{ size: IconSize }>`
  ${iconStyle};
  height: ${({ size }) => size.pixels ?? 10}px;
  width: ${({ size }) => size.pixels ?? 10}px;
  margin-right: 1px;
  align-self: center;
`

/*
 INTERFACES
*/

interface TagProps<T extends HoverableEntity> {
  entity: T
  entityPopOver?: EntityPopOverProps
  disableHover?: boolean
}

/*
 COMPONENTS
*/

export const UserTag: React.FC<{ user: EntityUser }> = ({ user }) => (
  <User>
    <Avatar imageUrl={user.avatarUrl} fallbackUser={user} size={18} />
    <EntityName>{user.givenName || user.familyName || user.emailAddress}</EntityName>
  </User>
)

export const PartyTag: React.FC<TagProps<EntityParty>> = ({
  entity: party,
  entityPopOver,
  disableHover,
}) => (
  <EntityContainer entity={party} entityPopOver={entityPopOver} disableHover={disableHover}>
    <PartyIcon party={party} size={IconSize.Tiny} />
    <EntityName>{party.name}</EntityName>
  </EntityContainer>
)

export const CategoryTag: React.FC<TagProps<EntityCategory>> = ({
  entity: category,
  entityPopOver,
  disableHover,
}) => (
  <EntityContainer entity={category} entityPopOver={entityPopOver} disableHover={disableHover}>
    <CategoryIcon subjectDisplayKey={category.displayKey ?? ""} size={IconSize.Tiny} />
    <EntityName>{category.name}</EntityName>
  </EntityContainer>
)
