import * as React from "react"
import { OnDataOptions } from "@apollo/client"
import {
  EventType,
  NotificationCreatedSubscription,
  ObjectKind,
  useNotificationCreatedSubscription,
  useReadAssociatedThreadsQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { useCanCommentReports } from "src/frontend/components/Shared/Reports/Packages/Viewer/hooks/useCanCommentReports"
import { ReportCommentsAction } from "src/frontend/components/Shared/Reports/ReportComments/actions"
import { useThreadDeeplink } from "src/frontend/components/Shared/Reports/ReportComments/hooks"
import { reducer } from "src/frontend/components/Shared/Reports/ReportComments/reducer"
import { ReportCommentsState } from "src/frontend/components/Shared/Reports/ReportComments/types"
import FrontendSession from "src/frontend/session"
import { useDocumentVisible } from "src/shared/hooks/useVisibilityChange"

interface CommentsContext {
  state: ReportCommentsState
  dispatch: React.Dispatch<ReportCommentsAction>
}

type ProviderProps = React.PropsWithChildren<{
  packageId: string
}>

/*
  CONTEXTS
*/

const ReportCommentsContext = React.createContext<CommentsContext>({
  state: {
    registry: {},
    initialized: undefined,
  },
  dispatch: () => {},
})

export const ReportCommentsContextProvider: React.FC<ProviderProps> = ({ packageId, children }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    registry: {},
    initialized: undefined,
  })

  const context: CommentsContext = React.useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  )

  useInitCommentingStore(packageId, context)

  return <ReportCommentsContext.Provider value={context}>{children}</ReportCommentsContext.Provider>
}

export function useReportCommentsContext() {
  return React.useContext(ReportCommentsContext)
}

export function useReportCommentsState() {
  return useReportCommentsContext().state
}

export function useReportCommentsDispatch() {
  return useReportCommentsContext().dispatch
}

function useInitCommentingStore(packageId: string, context: CommentsContext) {
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const deeplink = useThreadDeeplink()
  const documentVisible = useDocumentVisible()
  const canComment = useCanCommentReports()

  const { state, dispatch } = context

  const { data, loading, refetch, startPolling, stopPolling } = useReadAssociatedThreadsQuery({
    variables: {
      legalEntityId,
      id: packageId,
      kind: ObjectKind.ReportPackage,
      allowResolved: true,
    },
    skip: !canComment,
    context: { noBatch: true },
    onError: (error) => {
      stopPolling()
    },
  })

  // Poll for comments while not actively commenting and UI is visible.
  React.useEffect(() => {
    if (documentVisible && canComment) {
      startPolling(20000)
    } else {
      stopPolling()
    }

    return stopPolling
  }, [startPolling, stopPolling, documentVisible, canComment])

  React.useEffect(() => {
    // Create new context using the response from the query
    if (!state.initialized && !loading && data?.response.threads) {
      dispatch({ type: "INIT_REGISTRY", threads: data.response.threads, deeplink })
    }

    // Update existing context
    if (state.initialized && !loading && data?.response.threads) {
      dispatch({ type: "SET_REGISTRY", threads: data.response.threads, deeplink })
    }

    // Create or update entities
    if (data?.response.entities) {
      dispatch({ type: "UPSERT_ENTITIES", entities: data.response.entities })
    }
  }, [
    loading,
    data?.response.threads,
    data?.response.entities,
    deeplink,
    state.initialized,
    dispatch,
  ])

  // If a relevant notification appears and we won't disrupt the user, refetch immediately.
  const refetchIfNotInteracting = React.useCallback(() => {
    if (canComment) {
      refetch()
      if (state.initialized && !loading && data) {
        dispatch({ type: "SET_REGISTRY", threads: data.response.threads, deeplink })
        // Create or update entities
        if (data?.response.entities) {
          dispatch({ type: "UPSERT_ENTITIES", entities: data.response.entities })
        }
      }
    }
  }, [canComment, refetch, state.initialized, loading, data, dispatch, deeplink])

  useOnRelevantNotifications(refetchIfNotInteracting)
}

function useOnRelevantNotifications(refetch: () => void) {
  const onData = React.useCallback(
    (data: OnDataOptions<NotificationCreatedSubscription>) => {
      if (!data.data.data?.notificationCreated.id) return

      const { eventType } = data.data.data.notificationCreated
      switch (eventType) {
        case EventType.ReportComment:
        case EventType.ReportMention:
        case EventType.ReportCommentThreadResolved:
          refetch()
      }
    },
    [refetch]
  )

  useNotificationCreatedSubscription({
    onData,
  })
}
