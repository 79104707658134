import { ArrivalContext, Contact, ObjectIdentifier } from "@digits-graphql/frontend/graphql-bearer"
import { InviteClientsModalState } from "src/frontend/components/Shared/Portals/State/types"

export enum ClientStatus {
  Pending = "Pending",
  Sent = "Sent",
  Failed = "Failed",
  FailedSelfInvite = "FailedSelf",
}

export interface ContactWithStatus extends Contact {
  status: ClientStatus
}

export interface InviteClientState {
  modalState: InviteClientsModalState
  invitees: ContactWithStatus[]
  errorMessage?: React.ReactNode
  displayEmailInviteForm: boolean
  sendingInvitations: boolean
  showAutocomplete: boolean
  showViewUsers: boolean
  autocompleteUsers: Contact[]
  inviteFormValid: boolean
  validClientInForm: Contact | undefined
  arrivalContext: ArrivalContext | undefined
  contextObject: ObjectIdentifier | undefined
  userNoun: string
  userPluralNoun: string
}
