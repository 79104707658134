import * as React from "react"
import {
  DirectionFromOrigin,
  EntityParty,
  Interval,
  IntervalOrigin,
  PartyRole,
  SummarizeTransactionsByTimeQueryVariables,
} from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { RowContent } from "@digits-shared/components/UI/Table/Content"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import styled from "styled-components"
import { PartyAndRoleSearchResultBalanceSummary } from "src/frontend/components/OS/Springboard/Applications/Search/SearchResults/SearchResultBalanceSummary"
import { useProductArea } from "src/frontend/components/Shared/Contexts/ProductAreaContext"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import routes from "src/frontend/routes"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import { SearchResultType } from "src/frontend/types/SearchResultType"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"
import MatchedText from "./MatchedText"
import { ResultDescription, ResultRow, ResultTitle, RowProps } from "./ResultRow"
import SearchResultActivitySummary from "./SearchResultActivitySummary"

/*
  STYLE
*/

const Details = styled.div`
  display: flex;
  align-items: center;
`

const PartyDescription = styled(RowContent)`
  flex: 1;
  margin-right: 5px;
`

/*
  INTERFACE
*/

interface Props extends RowProps<EntityParty> {
  type: SearchResultType
}

interface SummaryProps {
  partyRole: PartyRole
  party: EntityParty
  origin: IntervalOrigin
}

/*
  COMPONENT
*/

const PartyResult: React.FC<Props> = ({ entity, hit, type }) => {
  const role = typeToPartyRole(type)
  const { invertValues } = FrontendPartyRole.findByRole(role)

  const showBalanceSummary = React.useMemo(
    () => FrontendPartyRole.findByRole(role).showsBalanceSummary,
    [role]
  )

  const urlRole = role && FrontendPartyRole.roleURLKey(role)
  if (!role || !urlRole) {
    return null
  }

  const origin = dateTimeHelper.todayIntervalOrigin(Interval.Month, 12)
  const path = routes.partyDetails.generateFromCurrentPath({
    partyId: entity.id,
    partyRole: urlRole,
    ...origin,
  })

  return (
    <ResultRow to={path}>
      <Details>
        <PartyIcon party={entity} css="margin-right: 10px;" disableHoverBorder />
        <PartyDescription>
          <ResultTitle>
            <MatchedText fieldName="name" highlights={hit.highlights}>
              {entity.name}
            </MatchedText>
          </ResultTitle>
          <ResultDescription>
            <MatchedText fieldName="short_description" highlights={hit.highlights}>
              {entity.shortDescription || ""}
            </MatchedText>
          </ResultDescription>
        </PartyDescription>
        <InvertValuesContext.Provider value={invertValues}>
          {showBalanceSummary ? (
            <PartyAndRoleSearchResultBalanceSummary
              partyId={entity.id}
              partyRole={role}
              origin={origin}
            />
          ) : (
            <TransactionSummary party={entity} partyRole={role} origin={origin} />
          )}
        </InvertValuesContext.Provider>
      </Details>
    </ResultRow>
  )
}

export default PartyResult

const TransactionSummary: React.FC<SummaryProps> = ({ partyRole, party, origin }) => {
  const viewKey = useViewVersion()
  const productArea = useProductArea(partyRole)

  const variables: SummarizeTransactionsByTimeQueryVariables = {
    filter: {
      viewKey,
      partyId: party.id,
      productArea,
      partyRole,
    },
    origin: {
      ...origin,
      intervalCount: 12,
    },
    direction: DirectionFromOrigin.Past,
  }
  return <SearchResultActivitySummary variables={variables} />
}

function typeToPartyRole(type: SearchResultType) {
  switch (type) {
    case SearchResultType.Customer:
      return PartyRole.EntityCustomerRole
    case SearchResultType.Supplier:
      return PartyRole.EntitySupplierRole
    case SearchResultType.Vendor:
      return PartyRole.EntityVendorRole
    case SearchResultType.Lender:
      return PartyRole.EntityLenderRole
    case SearchResultType.OwedVendor:
      return PartyRole.EntityOwedVendorRole
    case SearchResultType.UnpaidCustomer:
      return PartyRole.EntityUnpaidCustomerRole
    case SearchResultType.PrepaidVendor:
      return PartyRole.EntityPrepaidVendorRole
    case SearchResultType.Shareholder:
      return PartyRole.EntityShareholderRole

    case SearchResultType.Category:
    case SearchResultType.Comment:
    case SearchResultType.Report:
    case SearchResultType.Transaction:
    case SearchResultType.User:
    case SearchResultType.Invoice:
      return undefined
  }
}
