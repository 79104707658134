import * as React from "react"
import { CategoryType, EntityCategory, Interval } from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { CategoryBalanceSummary } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Components/Search/BalanceSummary"
import { CategoryChartSummary } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Components/Search/ChartSummary"
import { SummaryListData } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/WidgetSummaryList"
import { invertValuesForCategory } from "src/shared/helpers/categoryHelper"

/*
  COMPONENTS
 */

export const CategorySummary: React.FC<SummaryListData<EntityCategory>> = ({ data: category }) => {
  if (!category) return null

  const origin = dateTimeHelper.todayIntervalOrigin(Interval.Month, 12)

  const showBalanceSummary =
    category.type === CategoryType.Assets || category.type === CategoryType.Liabilities

  return (
    <InvertValuesContext.Provider value={invertValuesForCategory(category)}>
      {showBalanceSummary ? (
        <CategoryBalanceSummary category={category} origin={origin} />
      ) : (
        <CategoryChartSummary category={category} origin={origin} />
      )}
    </InvertValuesContext.Provider>
  )
}
