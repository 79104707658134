import * as React from "react"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

/*
 STYLES
*/

const Checkbox = styled.span`
  --checkbox-default: ${colors.accentTurquoise};
  --checkbox-hover: ${colors.primary};
  --checkbox-disabled: ${colors.primary50};
  --checkbox-focus: #00a69c;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const CheckmarkIcon = styled.div`
  position: absolute;
  height: 60%;
  width: 60%;
  pointer-events: none;
  border-radius: 2px;
  background-color: var(--checkbox-default);

  opacity: 0;
  transition: opacity 100ms;
`

const CheckboxInput = styled.input`
  display: inline-block;
  background-color: ${colors.transparent};
  padding: 9px;
  appearance: none;
  overflow: hidden;
  cursor: pointer;
  margin: 0;
  transition:
    opacity 200ms ease,
    border 200ms ease;
  border: 1.5px solid var(--checkbox-default);
  border-radius: 3px;

  &:hover {
    border-color: var(--checkbox-hover);
    & + ${CheckmarkIcon} {
      background-color: var(--checkbox-hover);
    }
  }

  &:checked {
    & + ${CheckmarkIcon} {
      opacity: 1;
    }
  }

  &:disabled {
    border-color: var(--checkbox-disabled);
    & + ${CheckmarkIcon} {
      background-color: var(--checkbox-disabled);
    }
  }
`

type CheckboxProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  className?: string
}

export const DigitsCheckbox: React.FC<CheckboxProps> = ({ className, onClick, ...props }) => (
  <Checkbox className={className} onClick={onClick}>
    <CheckboxInput {...props} type="checkbox" />
    <CheckmarkIcon />
  </Checkbox>
)
