import { useMemo, useState } from "react"
import { useIsomorphicLayoutEffect } from "react-use"
import { UseMeasureRect, UseMeasureResult } from "react-use/lib/useMeasure"

const defaultState: UseMeasureRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
}

// Use this instead of `react-use` useMeasure because this hook will use `getBoundingClientRect`
// which gives a more accurate Rect than the other ones. The rest is the same as `react-use`
export function useMeasure<E extends Element = Element>(): UseMeasureResult<E> {
  const [element, ref] = useState<E | null>(null)
  const [rect, setRect] = useState<UseMeasureRect>(defaultState)

  const observer = useMemo(() => {
    if (!window.ResizeObserver) return undefined
    return new ResizeObserver((entries) => {
      if (entries[0]) {
        const { x, y, width, height, top, left, bottom, right } =
          entries[0].target.getBoundingClientRect()
        setRect({ x, y, width, height, top, left, bottom, right })
      }
    })
  }, [])

  useIsomorphicLayoutEffect(() => {
    if (!element) return
    observer?.observe(element)
    return () => {
      observer?.disconnect()
    }
  }, [element])

  return [ref, rect]
}
