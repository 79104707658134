import * as React from "react"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { BarChartComponent } from "src/frontend/components/Shared/Assistant/Messages/Layout/Chart/BarChartComponent"
import { LineChartComponent } from "src/frontend/components/Shared/Assistant/Messages/Layout/Chart/LineChartComponent"
import { useLayoutContext } from "src/frontend/components/Shared/Assistant/Messages/Layout/Context"
import { useCategorySummaryByTimeComponentData } from "src/frontend/components/Shared/Assistant/Messages/Layout/hooks/useCategorySummaryByTimeComponentData"
import { CategoryIconStyled } from "src/frontend/components/Shared/Assistant/Messages/Layout/Shared/Icons"
import {
  TimeseriesValue,
  toSortedTimeseriesValues,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ChartType, MatchedComponent } from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface CategoryChartComponentProps {
  component: MatchedComponent<"categoryChart">
  chartType: ChartType
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const CategoryChartComponent: React.FC<CategoryChartComponentProps> = ({
  component,
  chartType,
  skipAnimations,
}) => {
  const { layoutId, viewKey } = useLayoutContext()
  const {
    config: { categoryChart: config },
    dataId,
  } = component

  const { category, dimensionalSummary } = useCategorySummaryByTimeComponentData(
    component.config,
    layoutId,
    viewKey,
    dataId
  )

  const period = React.useMemo(
    () => (config?.origin ? dateTimeHelper.periodFromIntervalOrigin(config.origin) : null),
    [config?.origin]
  )

  const timeseries = React.useMemo(
    () => toSortedTimeseriesValues(dimensionalSummary?.time),
    [dimensionalSummary?.time]
  )

  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined)

  const onMouseOver = React.useCallback((value: TimeseriesValue, index: number) => {
    setSelectedIndex(index)
  }, [])

  const onMouseOut = React.useCallback((value?: TimeseriesValue) => {
    setSelectedIndex(undefined)
  }, [])

  const icon = React.useMemo(() => {
    if (!category) return null

    return <CategoryIconStyled subjectDisplayKey={category.displayKey || category.name} />
  }, [category])

  if (!period) {
    return null
  }

  switch (chartType) {
    case ChartType.BalanceSummary:
      return (
        <LineChartComponent
          name={category?.name}
          timeseries={timeseries}
          period={period}
          skipAnimations={skipAnimations}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          selectedIndex={selectedIndex}
          icon={icon}
        />
      )
    case ChartType.TransactionSummary:
      return (
        <BarChartComponent
          name={category?.name}
          timeseries={timeseries}
          period={period}
          skipAnimations={skipAnimations}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          selectedIndex={selectedIndex}
          icon={icon}
        />
      )
  }
}
