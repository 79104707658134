import * as React from "react"
import {
  DirectionFromOrigin,
  EntityParty,
  IntervalOrigin,
  MonetaryValue,
  PartyRole,
  TransactionSummary,
  useSummarizeTransactionsByTimeQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { SummaryWithCount } from "@digits-shared/components/UI/Table/Content"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import SparkBarChart from "src/frontend/components/OS/Shared/Charts/SparkBarChart"
import {
  AmountAndCount,
  ChartContainer,
} from "src/frontend/components/OS/Springboard/Applications/Search/SearchResults/Shared"
import { useProductArea } from "src/frontend/components/Shared/Contexts/ProductAreaContext"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"

/*
  INTERFACES
 */

interface SummaryProps {
  partyRole: PartyRole
  party: EntityParty
  origin: IntervalOrigin
}

/*
  COMPONENTS
 */
export const PartyTransactionSummary: React.FC<SummaryProps> = ({ partyRole, party, origin }) => {
  const viewKey = useViewVersion()
  const productArea = useProductArea(partyRole)

  const { data, loading } = useSummarizeTransactionsByTimeQuery({
    variables: {
      filter: {
        viewKey,
        partyId: party.id,
        productArea,
        partyRole,
      },
      origin,
      direction: DirectionFromOrigin.Past,
    },
  })

  const summaries: TransactionSummary[] | undefined = React.useMemo(
    () => data?.dimensionalTransactionSummary.time.map((t) => t.summary),
    [data?.dimensionalTransactionSummary.time]
  )

  if (loading) {
    return (
      <AmountAndCount>
        <SummaryWithCount loading />
      </AmountAndCount>
    )
  }

  if (!summaries || !summaries.length) {
    return null
  }

  const { total } = summaries.reduce<{ count: number; total: MonetaryValue }>(
    (totals, summary) => {
      totals.count += summary.total.transactionsCount
      totals.total.amount += summary.total.value.amount
      return totals
    },
    {
      count: 0,
      total: { ...summaries[0]?.total.value, amount: 0 } as MonetaryValue,
    }
  )

  // TODO - enable this once we have a better way to display totals
  const showSparkChart = false

  return (
    <>
      {showSparkChart && <SummarySparkChart summaries={summaries} total={total} />}
      <AmountAndCount>
        <SummaryWithCount value={total} style={CurrencyStyle.Aggregation} absolute ignoreHover>
          Last 12 Months
        </SummaryWithCount>
      </AmountAndCount>
    </>
  )
}

const SummarySparkChart: React.FC<{ summaries: TransactionSummary[]; total: MonetaryValue }> = ({
  summaries,
  total,
}) => {
  const series = React.useMemo(
    () =>
      summaries
        .flatMap((item) => {
          const periodMoney = item.total
          return {
            x: item.period,
            y: periodMoney.value,
          }
        })
        .reverse(),
    [summaries]
  )

  // if total is less than $5.00 dont show a graph
  if (Math.abs(total.amount / total.currencyMultiplier) < 5) return null

  return (
    <ChartContainer>
      <SparkBarChart series={series} animateChartInitialDelay={800} />
    </ChartContainer>
  )
}
