import React from "react"
import {
  EntityCategory,
  EntityParty,
  useSearchTermsQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { QueryRequest } from "src/frontend/components/OS/Springboard/Applications/Search/QueryBuilder"
import { ViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"

export default function useTermsQuery(query: QueryRequest, viewVersion: ViewVersion) {
  const { text, isValid } = query

  return useSearchTermsQuery({
    variables: {
      ...viewVersion,
      text: text.trim(),
    },
    skip: !isValid,
  })
}

export function useEntities(query: QueryRequest, viewVersion: ViewVersion) {
  const { data, loading } = useTermsQuery(query, viewVersion)

  return React.useMemo(() => {
    if (loading || !data) {
      return { terms: [], loading }
    }
    const { entities: searchEntities } = data.searchTerms

    const nameById = [searchEntities.categories ?? [], searchEntities.parties ?? []]
      .flat()
      .reduce((map, entity) => {
        map.set(entity.id, entity)
        return map
      }, new Map<string, EntityCategory | EntityParty>())

    // The search results are returned in a best-match order, so it's
    // important to iterate them in order. Iterate the results (hits),
    // using their IDs to look up the entity names to be returned.
    const entities = data.searchTerms.results.reduce(
      (acc, hit) => {
        const entity = nameById.get(hit.objectId.id)
        if (entity) {
          acc.push(entity)
        }
        return acc
      },
      [] as (EntityCategory | EntityParty)[]
    )

    return { entities, loading }
  }, [data, loading])
}

export function useTerms(query: QueryRequest, viewVersion: ViewVersion) {
  const { entities, loading } = useEntities(query, viewVersion)

  return React.useMemo(() => {
    if (loading || !entities) {
      return { terms: [], loading }
    }

    const terms = entities.map((entity) => entity.name)

    return { terms, loading }
  }, [entities, loading])
}
