import * as React from "react"
import { Period } from "@digits-graphql/frontend/graphql-bearer"
import { SharedBarChartStyles } from "src/frontend/components/OS/Shared/Charts/styles"
import { ComponentHeader } from "src/frontend/components/Shared/Assistant/Messages/Layout/Shared/ComponentHeader"
import { ChartContainer } from "src/frontend/components/Shared/Assistant/Messages/Layout/Shared/Styles"
import { ParentSizedTimeseriesBarChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesBarChart"
import { TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"

/*
  INTERFACES
*/

interface BarChartComponentProps {
  name: string | undefined
  timeseries: TimeseriesValue[]
  period: Period | undefined
  skipAnimations: boolean
  onMouseOver: (value: TimeseriesValue, index: number) => void
  onMouseOut: (value?: TimeseriesValue) => void
  selectedIndex?: number
  icon?: React.ReactNode
}

/*
  COMPONENTS
*/

export const BarChartComponent = React.memo<BarChartComponentProps>(
  ({ name, timeseries, period, skipAnimations, onMouseOver, onMouseOut, selectedIndex, icon }) => (
    <>
      <ComponentHeader
        subjectName={name}
        timeseries={timeseries}
        selectedIndex={selectedIndex}
        period={period}
        icon={icon}
        showTotal
      />
      <ChartContainer>
        <ParentSizedTimeseriesBarChart
          timeseries={timeseries}
          skipAnimations={skipAnimations}
          hideGrid
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          chartStyle={SharedBarChartStyles}
        />
      </ChartContainer>
    </>
  )
)
