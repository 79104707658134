import * as React from "react"
import { Flow, MoneyFlow, StatementDeltaValue } from "@digits-graphql/frontend/graphql-bearer"
import { chevronStyles, PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import colorHelper from "@digits-shared/helpers/colorHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"

/*
  STYLES
*/

const DeltaStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
`

const Amount = styled.div`
  color: ${colors.secondary};
  font-weight: ${fonts.weight.heavy};
  font-size: 14px;
  margin-bottom: 2px;
`

const Chevron = styled.div<{ direction: PointingDirection; color: string }>`
  ${({ direction, color }) => chevronStyles(direction, "3px", color)}
  position: absolute;
  top: 6px;
`

const Delta = styled.div`
  position: relative;
`

const DeltaPercent = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 12px;
  margin-left: 8px;
`

/*
  INTERFACES
*/

interface ComponentDeltaProps {
  value: MoneyFlow
  delta?: StatementDeltaValue
}

interface PeriodDeltaProps {
  delta?: StatementDeltaValue
  className?: string
}

/*
  COMPONENTS
*/

export const ComponentDelta: React.FC<ComponentDeltaProps> = ({ value, delta }) => (
  <DeltaStyled>
    <PeriodDelta delta={delta} />
    <Amount>
      {moneyFlowHelper.currency(value, {
        style: CurrencyStyle.Detail,
      })}
    </Amount>
  </DeltaStyled>
)

export const PeriodDelta: React.FC<PeriodDeltaProps> = ({ delta, className }) => {
  const green = colors.translucentPrimary80
  const orange = colorHelper.hexToRgba(colors.orange, 0.8)

  const numberOptions: Intl.NumberFormatOptions = React.useMemo(
    () => ({
      signDisplay: "never",
    }),
    []
  )

  const amountOptions = React.useMemo(
    () => ({
      ...numberOptions,
      style: CurrencyStyle.Detail,
    }),
    [numberOptions]
  )

  const percentOptions = React.useMemo(
    () => ({
      ...numberOptions,
      style: "percent" as const,
    }),
    [numberOptions]
  )

  const displayDelta = React.useMemo(() => {
    if (!delta?.moneyFlow.value) return undefined
    const { percentageOfAmount, moneyFlow } = delta
    const amount = moneyFlowHelper.currency(moneyFlow, amountOptions)
    const percent = numberHelper.numberFormatter(percentOptions).format(percentageOfAmount / 100)

    const isPeriodPositive = moneyFlow.value.amount >= 0
    const color = isPeriodPositive ? green : orange
    const direction =
      (isPeriodPositive && moneyFlow.businessFlow === Flow.Inbound) ||
      (!isPeriodPositive && moneyFlow.businessFlow === Flow.Outbound)
        ? PointingDirection.Up
        : PointingDirection.Down

    return {
      amount,
      percent,
      color,
      direction,
    }
  }, [delta, amountOptions, percentOptions, green, orange])

  if (!displayDelta) return null

  return (
    <Delta className={className}>
      <Chevron direction={displayDelta.direction} color={displayDelta.color} />
      <DeltaPercent color={displayDelta.color}>{displayDelta.percent}</DeltaPercent>
    </Delta>
  )
}
