import * as React from "react"
import { TransactionParty } from "@digits-graphql/frontend/graphql-bearer"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import styled from "styled-components"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"

const PartyIconContainer = styled.div`
  position: relative;
  cursor: pointer;
`

interface Props {
  party?: TransactionParty | null
  size?: IconSize
  className?: string
  children?: React.ReactNode
}

export const PartyItemIcon: React.FC<Props> = ({ party, size, className, children }) => (
  <PartyIconContainer className={className}>
    <PartyIcon party={party} size={size} />
    {children}
  </PartyIconContainer>
)
