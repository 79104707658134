import * as React from "react"
import {
  PageScrollContext,
  useBuildPageScrollContext,
} from "@digits-shared/components/UI/Elements/PageScrollContext"
import useConstant from "@digits-shared/hooks/useConstant"
import useSession from "@digits-shared/hooks/useSession"
import { Theme } from "@digits-shared/themes"
import styled, { ThemeContext } from "styled-components"
import { HomeHeader } from "src/frontend/components/OS/Home/HomeHeader"
import { PortalContent } from "src/frontend/components/OS/Home/PortalContent"
import { ApplicationFullWidthPane } from "src/frontend/components/OS/Springboard/ApplicationPane"
import { Desktop } from "src/frontend/components/Shared/Responsive/Desktop"
import FrontendSession from "src/frontend/session"

/*
  STYLES
*/

const HomeChrome = styled.div`
  display: flex;
  flex-direction: column;
  height: var(--viewport-height);
  width: 100%;
`

const HomeContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const ScrollableContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: calc(100vh - 111px);
  width: 100%;
  padding-bottom: 30px;
  margin-top: calc(var(--header-height) + var(--header-gap));
  border-radius: 16px 16px 0 0;
`

/**
 * Fixes the horizontal offset that happens in the presence of a permanent
 * scroll bar (comes from having a wired mouse).
 *
 * https://stackoverflow.com/a/30293718
 */
export const ScrollBarHorizontalAdjustment = styled.div`
  height: fit-content;
  width: 100%;
  border-radius: 16px;
`

/*
 COMPONENTS
*/

export const HomeContent: React.FC<React.PropsWithChildren> = () => {
  const { isSharingContextActive, hasDashboardLegalEntityOrAffiliation, currentLegalEntity } =
    useSession<FrontendSession>()
  const theme = useConstant({ mode: Theme.Light })

  if (!currentLegalEntity) return null

  return (
    <Desktop>
      <ThemeContext.Provider value={theme}>
        {/* Need to ensure a legal entity to handle loading support for Digits Employees */}
        {hasDashboardLegalEntityOrAffiliation && !isSharingContextActive && (
          <ApplicationFullWidthPane>
            <HomeChrome>
              <HomeContainer>
                <HomeHeader />

                <SwitchedContent />
              </HomeContainer>
            </HomeChrome>
          </ApplicationFullWidthPane>
        )}
      </ThemeContext.Provider>
    </Desktop>
  )
}

const SwitchedContent: React.FC = () => {
  const { browserScrollContainer: scrollContainer, pageScrollContext } = useBuildPageScrollContext()

  return (
    <PageScrollContext.Provider value={pageScrollContext}>
      <ScrollableContainer ref={scrollContainer}>
        <ScrollBarHorizontalAdjustment>
          <PortalContent />
        </ScrollBarHorizontalAdjustment>
      </ScrollableContainer>
    </PageScrollContext.Provider>
  )
}
