import styled from "styled-components"

/*
  STYLES
*/

export const SVGContainer = styled.svg<{ height: number }>`
  height: ${(props) => props.height}px;
  width: 100%;
  overflow: visible;
  transform: translateZ(0);
  margin-bottom: 15px;
`

export const ChartContainer = styled.div<{ width: number; height: number }>`
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`
