import * as React from "react"
import { CategoryType } from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { useLayoutContext } from "src/frontend/components/Shared/Assistant/Messages/Layout/Context"
import { useCategoriesListCountDisplay } from "src/frontend/components/Shared/Assistant/Messages/Layout/hooks/useCategoriesListCountDisplay"
import { useTopCategoriesComponentData } from "src/frontend/components/Shared/Assistant/Messages/Layout/List/useTopCategoriesComponentData"
import { MatchedComponent } from "src/frontend/components/Shared/Layout/types"
import { TopCategoriesList } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopCategoriesList"
import { CategoryTransactionSummary } from "src/frontend/types"
import {
  adjectiveForCategoryType,
  invertValuesForCategoryType,
} from "src/shared/helpers/categoryHelper"
import { TopPeriodTitle } from "./TopPeriodTitle"

/*
  COMPONENTS
*/

export const TopCategories: React.FC<{ component: MatchedComponent<"topEntities"> }> = ({
  component,
}) => {
  const { layoutId, viewKey } = useLayoutContext()

  const {
    config: { topEntities: config },
    dataId,
  } = component

  const categoryTypeName = React.useMemo(
    () => adjectiveForCategoryType(config.categoryType),
    [config.categoryType]
  )

  const { data, loading } = useTopCategoriesComponentData(config, layoutId, viewKey, dataId)

  const invertValues = React.useMemo(
    () => invertValuesForCategoryType(config.categoryType ?? CategoryType.UnknownType),
    [config.categoryType]
  )

  const categorySummaries: CategoryTransactionSummary[] | undefined = React.useMemo(
    () =>
      data?.entities.category.map((c) => ({
        category: c.categoryObject,
        summary: c.summary,
      })),
    [data]
  )

  const countDisplay = useCategoriesListCountDisplay(categoryTypeName, config.sort, invertValues)

  if (!countDisplay) return null

  return (
    <InvertValuesContext.Provider value={invertValues}>
      <TopPeriodTitle intervalOrigin={config?.origin}>Top {countDisplay}</TopPeriodTitle>
      <TopCategoriesList categorySummaries={categorySummaries} loading={loading} />
    </InvertValuesContext.Provider>
  )
}
