import * as React from "react"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import {
  AssistantAuthorDigitsThinking,
  AssistantAuthorLoading,
} from "src/frontend/components/Shared/Assistant/Messages/Header"
import {
  AssistantMessageBubble,
  AssistantMessageGrid,
} from "src/frontend/components/Shared/Assistant/Messages/Shared"

// Needed to avoid unit test failure due to canvas not being available in Vitest
const Lottie = React.lazy(() =>
  import("@digits-shared/components/UI/Lottie/Lottie").then((module) => ({
    default: module.Lottie,
  }))
)

/*
  STYLES
*/

const LoadingMessageBubble = styled(AssistantMessageBubble)`
  position: relative;
  width: 75px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadingDots = styled(Lottie)`
  position: absolute;
  width: 160px;
`

/*
  COMPONENTS
*/

export const AssistantLoadingMessage: React.FC = () => (
  <AssistantMessageGrid>
    <AssistantAuthorLoading />
    <AssistantMessageBubble>
      <LoadingBlock height="15px" width="100px" $shineColor={colors.translucentSecondary30} />
    </AssistantMessageBubble>
  </AssistantMessageGrid>
)

export const AssistantDigitsThinking: React.FC = () => (
  <AssistantMessageGrid>
    <AssistantAuthorDigitsThinking />
    <LoadingMessageBubble>
      <React.Suspense fallback={<LoadingBlock width="50px" height="15px" />}>
        <LoadingDots animationData={require("static/lottie/assistant-loading.json")} />
      </React.Suspense>
    </LoadingMessageBubble>
  </AssistantMessageGrid>
)
