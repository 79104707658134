import * as React from "react"
import {
  DocumentFilter,
  DocumentSort,
  DocumentSortBy,
  FeatureKind,
  ListVaultFilesQueryVariables,
  Pagination,
  SortDirection,
} from "@digits-graphql/frontend/graphql-bearer"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import FrontendSession from "src/frontend/session"

export const VAULT_PAGE_LIMIT = 100

export function useVaultQueryParams(documentCollectionId?: string): ListVaultFilesQueryVariables {
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const { search } = useRouter()

  const filter = React.useMemo((): DocumentFilter => {
    const mimeTypes = search.has("mime") ? search.getAll("mime") : undefined
    const creatorId = search.get("creatorId") ?? undefined

    return {
      featureKinds: [FeatureKind.Title],
      documentCollectionId,
      creatorId,
      mimeTypes,
    }
  }, [documentCollectionId, search])

  const order = React.useMemo((): DocumentSort | undefined => {
    const fieldParam = search.get("sort") as DocumentSortBy | null
    if (!fieldParam) return undefined

    const field = DocumentSortBy[fieldParam]

    const directionParam = search.get("direction") as SortDirection | null
    const direction = directionParam ? SortDirection[directionParam] : undefined

    return {
      field,
      direction,
    }
  }, [search])

  const pagination = React.useMemo((): Pagination => ({ limit: VAULT_PAGE_LIMIT, offset: 0 }), [])

  return {
    legalEntityId,
    filter,
    order,
    pagination,
  }
}
