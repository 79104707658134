import React from "react"
import { UseMeasureRect } from "react-use/lib/useMeasure"
import { usePopoverMutexContext } from "@digits-shared/components/UI/Elements/PopUp/PopOverMutexContext"
import { PopUp, PopUpProps } from "@digits-shared/components/UI/Elements/PopUp/PopUp"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import useReportPackageContext from "src/frontend/components/Shared/Reports/Packages/Viewer/ReportPackageContext"
import { DetailsPopOverButtons } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/DetailsPopOverButtons"
import {
  DetailsPopUpPortal,
  useDetailsPortalState,
} from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/DetailsPopUpPortal"
import useRowSelectionContext from "src/frontend/components/Shared/Reports/Report/Components/Statements/RowSelectionContext"
import {
  isCategoryDetails,
  RowDetails,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/toDetailsData"

export const POPUP_BACKGROUND = colors.white

export const MAIN_CONTENT_BACKGROUND = "#f4f4f4"

const HoverHelperStyled = styled.div`
  position: absolute;
  top: -10px;
  left: -70px;
  bottom: 0;
  right: 0;
`

/*
  STYLES
*/

export const ChartContainer = styled.div`
  height: 230px;
  padding: 80px 15px 15px 12px;
  position: relative;
`

/** Keeps the pop over invisible until we know what its size is, and where it will be positioned */
const StyledDetailsPopUp = styled(PopUp)<{ invisible: boolean }>`
  opacity: ${({ invisible }) => (invisible ? 0 : 1)};

  display: block;
  background: ${POPUP_BACKGROUND};
  margin-left: 10px;
  left: calc(100% + 50px);
  border: none;
  box-shadow: 0 0 34px ${colors.translucentBlack20};
`

interface DetailsPopUpProps extends PopUpProps {
  rowId: string
  details: RowDetails | undefined
  measureRect: UseMeasureRect
  positionRef: React.RefObject<HTMLElement>
}

interface ButtonProps {
  rowId: string
  details?: RowDetails
  measureRect: UseMeasureRect
}

export const DetailsPopUp = React.forwardRef<HTMLDivElement, DetailsPopUpProps>(
  ({ positionRef, measureRect, rowId, details, children, ...props }, ref) => {
    const portalRef = React.useRef<HTMLDivElement>(null)

    // No commenting outside a report package
    // TODO: use props or some other way to add/hide buttons
    const { reportPackage } = useReportPackageContext()
    const hasButtons = !!reportPackage?.id

    const { clearActivePopOver } = usePopoverMutexContext()
    const { setPinnedCell } = useRowSelectionContext()
    const onBackgroundClick = React.useCallback(
      (e: MouseEvent) => {
        let cellParent: HTMLElement | null = e.target as HTMLElement
        while (cellParent) {
          if (cellParent === positionRef.current || cellParent == portalRef.current) break
          cellParent = cellParent.parentElement
        }

        // click happened outside the popup but inside the portal, ie. comment button
        if (cellParent == portalRef.current) {
          setPinnedCell(undefined)
        }

        // click happened outside the row or the popup (incl portal)
        if (!cellParent) {
          setPinnedCell(undefined)
          clearActivePopOver()
        }
      },
      [clearActivePopOver, positionRef, setPinnedCell]
    )

    const { position } = useDetailsPortalState(positionRef, measureRect, hasButtons)
    return (
      <DetailsPopUpPortal position={position} ref={portalRef}>
        <StyledDetailsPopUp
          ref={ref}
          {...props}
          invisible={!measureRect.y}
          onClose={onBackgroundClick}
          noStopPropagation
        >
          {hasButtons && <HoverHelperStyled />}
          {children}
        </StyledDetailsPopUp>
        {hasButtons && <PopOverButtons measureRect={measureRect} rowId={rowId} details={details} />}
      </DetailsPopUpPortal>
    )
  }
)

const PopOverButtons: React.FC<ButtonProps> = ({ measureRect, rowId, details }) => {
  if (!isCategoryDetails(details?.periodDetails) || !measureRect?.height) return null
  const {
    periodDetails: {
      hover: { entity },
    },
  } = details
  return <DetailsPopOverButtons rowId={rowId} linkObject={entity} />
}
