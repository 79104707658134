export enum CDNAsset {
  PartyIcons = "vendor-icons",
  OrganizationIcons = "org-icons",
}

const AssetsHelper = {
  publicUrl(assetPath: CDNAsset, objectName: string) {
    if (!objectName?.length) return undefined

    const assetsUrl = process.env.PUBLIC_ASSETS_URL
    if (!assetsUrl) {
      TrackJS?.console.error("Missing public assets url in the environment")
      return undefined
    }

    return [assetsUrl, assetPath, objectName].join("/")
  },
}

export default AssetsHelper
