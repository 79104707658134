import * as React from "react"
import { EntityCategory, EntityParty } from "@digits-graphql/frontend/graphql-bearer"
import { SvgMessagePlusSquare } from "@digits-shared/components/SVGIcons/line/MessagePlusSquare.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { useCanCommentReports } from "src/frontend/components/Shared/Reports/Packages/Viewer/hooks/useCanCommentReports"
import { useContextPath } from "src/frontend/components/Shared/Reports/Report/Viewer/UseContextPath"
import { useReportCommentsDispatch } from "src/frontend/components/Shared/Reports/ReportComments/ReportCommentsContext"
import zIndexes from "src/shared/config/zIndexes"

/*
  STYLES
*/

const ButtonsContainer = styled.div`
  position: absolute;
  left: 15px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  z-index: ${zIndexes.dndOverlay};
`

const CommentIcon = styled(SvgMessagePlusSquare)`
  stroke: ${colors.secondary};
  overflow: visible;
  padding: 4px 4px 3px 4px;
`

// const LiveIcon = styled(SvgSignal01Solid)`
//   ${svgIconStyles(colors.secondary)};
//   overflow: visible;
//   padding: 6px;
// `

const PopOverButton = styled(DigitsButton)`
  position: relative;

  &:before {
    content: "";
    z-index: -1;
    border-radius: inherit;
    background: white;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`

/*
  INTERFACES
*/

interface ButtonProps {
  rowId: string | undefined
  linkObject?: EntityCategory | EntityParty
}

/*
  COMPONENTS
*/

export const DetailsPopOverButtons: React.FC<ButtonProps> = ({ rowId, linkObject }) => (
  // const { isSharingContextActive } = useSession<FrontendSession>()
  <ButtonsContainer>
    {rowId !== undefined && <AddComment rowId={rowId} linkObject={linkObject} />}
    {/* {!isSharingContextActive && <ViewLive rowId={rowId} linkObject={linkObject} />} */}
  </ButtonsContainer>
)

const AddComment: React.FC<ButtonProps> = ({ rowId }) => {
  const commentsDispatch = useReportCommentsDispatch()
  const fullContextPath = useContextPath(rowId)

  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      commentsDispatch({ type: "ADD_COMMENT", context: fullContextPath })
    },
    [commentsDispatch, fullContextPath]
  )

  const canComment = useCanCommentReports()
  if (!canComment) return null

  return (
    <div>
      <PopOverButton $circle size="medium" $variant="secondary-dark" onClick={onClick}>
        <CommentIcon />
      </PopOverButton>
    </div>
  )
}

// function isCategory(linkObject: EntityCategory | EntityParty): linkObject is EntityCategory {
//   return !!(linkObject as EntityCategory).type
// }
//
// const ViewLive: React.FC<ButtonProps> = ({ linkObject }) => {
//   if (!linkObject) return null
//
//   if (isCategory(linkObject)) {
//     return <ViewCategory category={linkObject} />
//   }
//   return <ViewParty party={linkObject} />
// }
//
// const ViewCategory: React.FC<{ category: EntityCategory }> = ({ category }) => {
//   const path = useCategoryLink(category)
//   if (!path) return null
//   return <ViewLink path={path} />
// }
//
// const ViewParty: React.FC<{ party: EntityParty }> = ({ party }) => {
//   const path = usePartyLink(party)
//   if (!path) return null
//   return <ViewLink path={path} />
// }
//
// const ViewLink: React.FC<{ path: string }> = ({ path }) => {
//   const { history } = useRouter()
//   const onClick = React.useCallback(
//     (e: React.MouseEvent) => {
//       e.stopPropagation()
//       e.metaKey ? window.open(path) : history.push(path)
//     },
//     [history, path]
//   )
//
//   return (
//     <TopBarTooltipContainer>
//       <PopOverButton circle size="medium" newVariant="secondary-dark" onClick={onClick}>
//         <LiveIcon />
//       </PopOverButton>
//       <TopBarTooltip top="110%" left={-23}>
//         View Details
//       </TopBarTooltip>
//     </TopBarTooltipContainer>
//   )
// }
