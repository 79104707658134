import * as React from "react"
import { BalanceSummary, TransactionSummary } from "@digits-graphql/frontend/graphql-bearer"
import styled from "styled-components"
import { SparkChartProps } from "src/frontend/components/OS/Shared/Charts/index"
import SparkBarChart from "src/frontend/components/OS/Shared/Charts/SparkBarChart"
import { SparkLineChart } from "src/frontend/components/OS/Shared/Charts/SparkLineChart"

const SPARK_CHART_HEIGHT = "30px"

/*
  STYLES
*/

const SparkBarChartContainer = styled.div<{ inheritHeight?: boolean }>`
  width: 100%;
  height: ${({ inheritHeight }) => (inheritHeight ? "inherit" : SPARK_CHART_HEIGHT)};
`
/*
  COMPONENTS
*/

type Props = Omit<SparkChartProps, "series"> & {
  summaries: TransactionSummary[] | BalanceSummary[]
  inheritHeight?: boolean
  showBalances?: boolean
}

export const SparkSummaryChart: React.FC<Props> = ({
  summaries,
  animateChart,
  animateChartInitialDelay,
  highlightedIndices,
  inheritHeight,
  showBalances,
}) => {
  const activity = React.useMemo(
    () =>
      summaries.map((d) => ({
        x: d.period,
        y: d.total.value,
      })),
    [summaries]
  )

  return (
    <SparkBarChartContainer inheritHeight={inheritHeight}>
      {showBalances ? (
        <SparkLineChart
          series={activity || []}
          animateChart={animateChart}
          animateChartInitialDelay={animateChartInitialDelay}
          highlightedIndices={highlightedIndices}
          lineWidth={2}
          pointRadius={3}
        />
      ) : (
        <SparkBarChart
          series={activity || []}
          animateChart={animateChart}
          animateChartInitialDelay={animateChartInitialDelay}
          highlightedIndices={highlightedIndices}
        />
      )}
    </SparkBarChartContainer>
  )
}
