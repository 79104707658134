import * as React from "react"
import { ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import FrontendSession from "src/frontend/session"

export function useAssistantObjectIdentifier() {
  const { currentLegalEntityId, user } = useSession<FrontendSession>()

  return React.useMemo(
    () => ({
      legalEntityId: currentLegalEntityId,
      id: user.id,
      kind: ObjectKind.Portal,
    }),
    [currentLegalEntityId, user.id]
  )
}
