import { DropdownWithLabelWrapper } from "@digits-shared/components/UI/Elements/Dropdown/Basic"
import { DayPickerInput } from "@digits-shared/components/UI/Elements/Inputs/DateInput"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

/*
  STYLES
*/

export const OptionRow = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 0 4px;
`

export const OptionLabel = styled.div<{ disabled?: boolean }>`
  flex: 1;
  font-size: 13px;
  line-height: 18px;
  color: ${colors.translucentSecondary80};
  font-weight: ${fonts.weight.medium};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`

export const OptionTitle = styled(OptionLabel)`
  color: ${colors.secondary};
  font-weight: ${fonts.weight.heavy};
`

export const OptionsDropDownWrapper = styled(DropdownWithLabelWrapper)`
  select {
    height: auto;
    padding: 4px 25px 4px 2px;
  }
`

export const OptionsDropDownRightWrapper = styled(OptionsDropDownWrapper)`
  select {
    text-align: right;
  }
`

export const OptionGroupContainer = styled.div``

export const GroupLabel = styled(OptionTitle)`
  padding: 6px 0 8px;
`

export const GroupOptions = styled.div``

export const DropDownOptions = styled.div`
  position: relative;
  padding-left: 10px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: ${colors.secondary30};
    width: 2px;
  }
`

export const DropDownOptionsContainer = styled.div<{ expanded: boolean }>`
  ${({ expanded }) =>
    expanded &&
    css`
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    `};
`

export const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
`

export const OptionInputContainer = styled.div`
  margin-bottom: 10px;
`

const inputStyles = css`
  border-radius: 8px;
  border: 1px solid ${colors.secondary30};
  background: ${colors.translucentWhite50};
  color: ${colors.secondary60};

  width: 100%;
  padding: 7px 8px;
  outline: none;
  font-size: 14px;
  font-family: ${fonts.family.avenir};
  font-weight: ${fonts.weight.medium};

  &:focus-within {
    border-color: ${colors.primary70};
    box-shadow: 0 0 0 1px ${colors.primary70};
    color: ${colors.secondary};
  }
`

export const OptionInputField = styled.input`
  ${inputStyles};
`

export const OptionTextAreaField = styled.textarea`
  ${inputStyles};
  min-height: 33px;
  resize: vertical;
`

export const OptionDateField = styled(DayPickerInput)`
  ${inputStyles};
`
