import * as React from "react"
import { LayoutComponent } from "@digits-graphql/frontend/graphql-bearer"
import colors from "@digits-shared/themes/colors"
import { useDraggable } from "@dnd-kit/core"
import { useInView } from "framer-motion"
import styled, { css, keyframes } from "styled-components"
import { useDragAndDropConnectionContext } from "src/frontend/components/Shared/Assistant/DragAndDropConnectionContext"
import {
  GraphContainer,
  MatchComponent,
} from "src/frontend/components/Shared/Assistant/Messages/Layout/MatchComponent"
import { DropType, TargetKind } from "src/frontend/components/Shared/DragAndDrop/dragAndDropShared"
import { ComponentConfigWrapper } from "src/frontend/components/Shared/Layout/Shared"

const WIGGLE = keyframes`
    0% { transform: rotate(0); }
    33% { transform: rotate(-1deg); }
    66% { transform: rotate(1deg); }
    100% { transform: rotate(-1deg) translateX(-2px); }
  `

const DraggableGraph = styled(GraphContainer)<{ isDraggable: boolean }>`
  padding: 0;
  ${({ isDraggable }) =>
    isDraggable &&
    css`
      &:hover {
        box-shadow: 0 0 8px 1px ${colors.neonGreen};
        animation: ${WIGGLE} 800ms ease-in-out forwards;
      }
    `}
`

const DraggableComponentWrapper = styled(ComponentConfigWrapper)`
  && {
    padding: 16px;
    width: auto;
  }
`

/*
  INTERFACES
*/

interface DraggableComponentProps {
  className?: string
  component: LayoutComponent
}

/*
  COMPONENTS
*/

export const DraggableComponent: React.FC<DraggableComponentProps> = ({ className, component }) => {
  const { isEditLayoutActive, disabled, activateEditLayout } = useDragAndDropConnectionContext()

  const { componentId, config } = component
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: componentId,
    disabled,
    data: {
      id: componentId,
      config,
      targetKind: TargetKind.Config,
      type: DropType.Config,
    },
  })

  const wrapperRef = React.useRef<HTMLDivElement | null>(null)
  const inView = useInView(wrapperRef)

  React.useEffect(() => {
    if (isDragging && !isEditLayoutActive) {
      activateEditLayout()
    }
  }, [activateEditLayout, isDragging, isEditLayoutActive])

  return (
    <DraggableGraph isDraggable={!disabled}>
      {/* css prop may have to be applied to another inner element? */}
      <DraggableComponentWrapper
        className={className}
        ref={(el) => {
          setNodeRef(el)
          wrapperRef.current = el
        }}
        isDisabled={disabled}
        {...attributes}
        {...listeners}
      >
        <MatchComponent component={component} skipAnimations={isDragging || !inView} />
      </DraggableComponentWrapper>
    </DraggableGraph>
  )
}
