import * as React from "react"
import { ApolloCache } from "@apollo/client/cache"
import {
  Comment,
  CommentFieldsFragmentDoc,
  CommentReaction,
  CreateReactionMutation,
  DeleteReactionMutation,
  Thread,
  ThreadFieldsFragmentDoc,
  useCreateReactionMutation,
  useDeleteReactionMutation,
} from "@digits-graphql/frontend/graphql-bearer"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgThumbsDownSolid } from "@digits-shared/components/SVGIcons/solid/ThumbsDownSolid.svg"
import { SvgThumbsUpSolid } from "@digits-shared/components/SVGIcons/solid/ThumbsUpSolid.svg"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { useAssistantContext } from "src/frontend/components/Shared/Assistant/Context"
import { ASSISTANT_GRID_AREAS } from "src/frontend/components/Shared/Assistant/Messages/Shared"
import { AssistantMessage } from "src/frontend/components/Shared/Assistant/reducer"

/*
  STYLES
*/

const Reactions = styled.div`
  grid-area: ${ASSISTANT_GRID_AREAS.reactions};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 10px;
  font-weight: ${fonts.weight.heavy};
  color: ${colors.translucentSecondary50};
  text-transform: uppercase;
`

const Reaction = styled.div<{ isActive: boolean | undefined; isLoading: boolean }>`
  border-radius: 50%;
  background-color: rgb(218, 232, 246);
  padding: 5px;

  &:not(:last-child) {
    margin-right: 3px;
  }

  & > svg {
    ${svgIconStyles("rgb(200, 216, 230)", true)};
  }

  &:hover {
    cursor: pointer;

    & > svg {
      ${svgIconStyles(colors.translucentSecondary60, true)};
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &:hover > svg,
      & > svg {
        ${svgIconStyles(colors.translucentSecondary60, true)};
      }
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;

      & > svg {
        ${svgIconStyles(colors.translucentSecondary60, true)};
      }
    `}
`

const iconStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
`

const ThumbsUpIcon = styled(SvgThumbsUpSolid)`
  ${iconStyles};
`

const ThumbsDownIcon = styled(SvgThumbsDownSolid)`
  ${iconStyles};
`

/*
  COMPONENTS
*/

export const AssistantMessageReactions: React.FC<{
  message: AssistantMessage
}> = ({ message }) => {
  const hasReaction = React.useMemo(
    () =>
      (message.comment.reactions || []).reduce(
        (agg, r) => agg.set(r, true),
        new Map<CommentReaction, boolean>()
      ),
    [message.comment.reactions]
  )

  const { modifyReaction, activeReaction, loading } = useModifyReaction(
    message.comment,
    hasReaction
  )

  return (
    <Reactions>
      <Reaction
        isActive={hasReaction.get(CommentReaction.Like)}
        isLoading={loading && activeReaction === CommentReaction.Like}
        onClick={modifyReaction(CommentReaction.Like)}
      >
        <ThumbsUpIcon />
      </Reaction>
      <Reaction
        isActive={hasReaction.get(CommentReaction.Dislike)}
        isLoading={loading && activeReaction === CommentReaction.Dislike}
        onClick={modifyReaction(CommentReaction.Dislike)}
      >
        <ThumbsDownIcon />
      </Reaction>
    </Reactions>
  )
}

function useModifyReaction(comment: Comment, hasReaction: Map<CommentReaction, boolean>) {
  const { state } = useAssistantContext()

  const [createReaction, { loading: createLoading }] = useCreateReactionMutation()
  const [deleteReaction, { loading: deleteLoading }] = useDeleteReactionMutation()
  const loading = createLoading || deleteLoading
  const activeReactionRef = React.useRef<CommentReaction | undefined>()

  const modifyReaction = React.useCallback(
    (reaction: CommentReaction) => () => {
      if (!state.threadId) {
        return Promise.reject("cannot modify like without thread id")
      }

      activeReactionRef.current = reaction

      const invertReaction =
        reaction === CommentReaction.Like ? CommentReaction.Dislike : CommentReaction.Like

      const variables = { threadId: state.threadId, commentId: comment.id, reaction }

      if (hasReaction.get(reaction)) {
        return deleteReaction({
          variables,
          update: updateCommentCache(state.threadId, comment, "delete", reaction),
        })
      }

      const promises: Promise<unknown>[] = []
      if (hasReaction.get(invertReaction)) {
        promises.push(
          deleteReaction({
            variables: {
              ...variables,
              reaction: invertReaction,
            },
            update: updateCommentCache(state.threadId, comment, "delete", invertReaction),
          })
        )
      }

      promises.push(
        createReaction({
          variables,
          update: updateCommentCache(state.threadId, comment, "create", reaction),
        })
      )

      return Promise.all(promises)
    },
    [state.threadId, comment, hasReaction, createReaction, deleteReaction]
  )

  return React.useMemo(
    () => ({ modifyReaction, activeReaction: activeReactionRef.current, loading }),
    [modifyReaction, loading]
  )
}

function updateThreadCache(
  store: ApolloCache<CreateReactionMutation | DeleteReactionMutation>,
  threadId: string,
  comment: Comment
) {
  const id = `Thread:${threadId}`
  const cacheThread = store.readFragment<Thread>({
    id,
    fragment: ThreadFieldsFragmentDoc,
    fragmentName: "ThreadFields",
  })
  if (!cacheThread) return

  const newComments = [...cacheThread.comments]

  const commentIndex = cacheThread.comments.findIndex((c) => c.id === comment.id)
  if (commentIndex !== -1) {
    newComments[commentIndex] = comment
  } else {
    newComments.push(comment)
  }

  const newThread = {
    ...cacheThread,
    comments: newComments,
  }

  store.writeFragment({
    id,
    fragment: ThreadFieldsFragmentDoc,
    fragmentName: "ThreadFields",
    data: newThread,
  })
}

function updateCommentCache(
  threadId: string | undefined,
  comment: Comment,
  action: "create" | "delete",
  reaction: CommentReaction
) {
  return (store: ApolloCache<CreateReactionMutation | DeleteReactionMutation>) => {
    const cacheComment = store.readFragment<Comment>({
      id: store.identify(comment),
      fragment: CommentFieldsFragmentDoc,
      fragmentName: "CommentFields",
    })

    if (!cacheComment) return

    const newComment = {
      ...cacheComment,
      reactions:
        action === "create"
          ? [...(cacheComment.reactions || []), reaction]
          : cacheComment.reactions?.filter((r) => r !== reaction) || [],
    }

    store.writeFragment({
      id: store.identify(comment),
      fragment: CommentFieldsFragmentDoc,
      fragmentName: "CommentFields",
      data: newComment,
    })

    if (threadId) {
      updateThreadCache(store, threadId, newComment)
    }
  }
}
