import * as React from "react"
import {
  LayoutComponentType,
  PartyChartConfig as Config,
} from "@digits-graphql/frontend/graphql-bearer"
import { toSortedTimeseriesValues } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ChartType, SizingProps } from "src/frontend/components/Shared/Layout/types"
import { useRemoveConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/ComponentConfigContext"
import { BarChartConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Charts/BarChartConfig"
import { LineChartConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Charts/LineChartConfig"
import { usePartySummaryByTimeLiveData } from "src/frontend/components/Shared/Portals/hooks/usePartySummaryByTimeLiveData"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

/*
  INTERFACES
*/

interface PartyChartConfigProps extends SizingProps {
  config: Config
  componentType: LayoutComponentType
  chartType: ChartType
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const PartyChartConfig: React.FC<PartyChartConfigProps> = ({
  config,
  componentType,
  chartType,
  height,
  skipAnimations,
}) => {
  const removeConfig = useRemoveConfig()

  const { party, dimensionalSummary, loading } = usePartySummaryByTimeLiveData(
    config,
    componentType
  )

  const timeseries = React.useMemo(
    () => toSortedTimeseriesValues(dimensionalSummary?.time),
    [dimensionalSummary?.time]
  )

  const name = React.useMemo(() => {
    if (!party) return undefined

    return `${party.name} (${FrontendPartyRole.findByRole(config.partyRole).displayName})`
  }, [config.partyRole, party])

  // remove this component if the results are empty
  React.useEffect(() => {
    if (!loading && !dimensionalSummary?.time?.length) {
      removeConfig()
    }
  }, [dimensionalSummary, loading, removeConfig])

  switch (chartType) {
    case ChartType.BalanceSummary:
      return (
        <LineChartConfig
          name={name}
          timeseries={timeseries}
          skipAnimations={skipAnimations}
          height={height}
        />
      )
    case ChartType.TransactionSummary:
      return (
        <BarChartConfig
          name={name}
          timeseries={timeseries}
          skipAnimations={skipAnimations}
          height={height}
        />
      )
  }
}
