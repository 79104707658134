import * as React from "react"
import { Insight, ListInsightsQuery, Period } from "@digits-graphql/frontend/graphql-bearer"
import {
  MAX_SUGGESTION_COUNT,
  PeriodInsight,
} from "src/frontend/components/OS/Springboard/Applications/Search/SearchSuggestions/Shared"

interface InsightWithPeriod {
  period: Period
  periodIndex: number
  insight: Insight
}

export function useSuggestionInsights(
  isSearchActive: boolean,
  data?: ListInsightsQuery
): PeriodInsight[] {
  return React.useMemo(() => {
    // Make a copy because it throws an exception if we try to sort these values in-place
    const periodInsightsCopy =
      data?.listInsights?.flatMap((periodInsight, periodIndex) =>
        periodInsight.insights.map((insight) => ({
          period: periodInsight.period,
          periodIndex,
          insight,
        }))
      ) ?? []

    // Create a map of subject ids to insights in order to dedupe subjects.
    // Iterate over all of the insights, and assign the insights to their
    // related subject id.
    // If the key already has an insight assigned to it, replace it as long
    // as the global score of the current insight is higher.
    // This will result in only a single insight being used for each subject.
    const dedupedInsightsMap = new Map<string, InsightWithPeriod>()

    periodInsightsCopy.forEach((pi) => {
      const insightForSubjectKey = dedupedInsightsMap.get(pi.insight.subjectId)
      if (
        !insightForSubjectKey ||
        !insightForSubjectKey?.insight.globalScore ||
        (pi.insight.globalScore &&
          pi.insight.globalScore > insightForSubjectKey?.insight.globalScore)
      ) {
        dedupedInsightsMap.set(pi.insight.subjectId, pi)
      }
    })

    const filteredInsights = Array.from(dedupedInsightsMap.values())
      // Sort highest score to lowest
      .sort((pi1, pi2) =>
        pi2.insight.globalScore && pi1.insight.globalScore
          ? pi2.insight.globalScore - pi1.insight.globalScore
          : pi2.insight.score - pi1.insight.score
      )
      // Attach the period to the insight result
      .map((pi) => ({
        ...pi.insight,
        period: pi.period,
      }))
      // Take the highest scored insights
      .slice(0, MAX_SUGGESTION_COUNT)

    return filteredInsights
  }, [data?.listInsights])
}
