import * as React from "react"
import {
  LayoutComponentType,
  SummaryLineItemConfig,
  useReadSummaryLineItemByTimeLiveDataQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"

export function useSummaryLineItemByTimeLiveData(
  config: SummaryLineItemConfig | undefined | null,
  type: LayoutComponentType
) {
  const viewId = useViewVersion()

  const { data, loading } = useReadSummaryLineItemByTimeLiveDataQuery({
    variables: {
      viewId,
      config: {
        type,
        summaryLineItem: config,
      },
    },
    skip: !viewId?.viewVersion,
  })

  return React.useMemo(() => {
    const liveData = data?.liveComponentData

    // Resolves the component data union types to the portions we care about
    if (liveData?.__typename === "DimensionSummary") {
      return { dimensionalSummary: liveData, loading }
    }

    return { dimensionalSummary: undefined, loading }
  }, [data?.liveComponentData, loading])
}
