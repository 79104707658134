import * as React from "react"
import { getSurroundingRectForRects } from "src/frontend/components/OS/Shared/DocumentViewer/helpers"
import {
  DocumentViewerAction,
  DocumentViewerState,
} from "src/frontend/components/OS/Shared/DocumentViewer/reducer"
import { Point, Rect } from "src/frontend/components/OS/Shared/DocumentViewer/types"

export function useViewportControls(
  svgRef: React.RefObject<SVGSVGElement>,
  state: DocumentViewerState,
  dispatch: React.Dispatch<DocumentViewerAction>,
  activePageIndex: number,
  pageCount: number
) {
  const zoomIn = React.useCallback(() => {
    dispatch({ type: "ZoomIn" })
  }, [dispatch])

  const zoomOut = React.useCallback(() => {
    dispatch({ type: "ZoomOut" })
  }, [dispatch])

  const pageUp = React.useCallback(() => {
    dispatch({ type: "PanAndZoomTo", pageIndex: Math.max(0, activePageIndex - 1) })
  }, [dispatch, activePageIndex])

  const pageDown = React.useCallback(() => {
    dispatch({ type: "PanAndZoomTo", pageIndex: Math.min(pageCount - 1, activePageIndex + 1) })
  }, [dispatch, pageCount, activePageIndex])

  const onDoubleClick = React.useCallback(
    (e: React.MouseEvent) => {
      dispatch({ type: "ToggleDebugColors" })
    },
    [dispatch]
  )

  const dragEnd = React.useCallback(() => {
    dispatch({ type: "DragEnd" })
  }, [dispatch])

  // Track the last mouse position during a drag for panning. Needs to be a local ref,
  // otherwise the state is stale and causes bouncing at scroll edges.
  const dragOriginRef = React.useRef<Point>({ x: 0, y: 0 })

  const onMouseDown = React.useCallback(
    (e: React.MouseEvent) => {
      if (e.button !== 0) return

      dragOriginRef.current = { x: e.clientX, y: e.clientY }
      dispatch({ type: "DragStart" })
    },
    [dispatch]
  )

  const onMouseMove = React.useCallback(
    (e: React.MouseEvent) => {
      if (state.viewport.dragging) {
        const dragOrigin = dragOriginRef.current

        const svgRect = svgRef.current?.getBoundingClientRect()
        if (!svgRect) return

        const allImagesRect = getSurroundingRectForRects(
          state.imageElements.map((imgEl) => imgEl.getBoundingClientRect())
        )
        const eventDeltaX = dragOrigin.x - e.clientX
        const eventDeltaY = dragOrigin.y - e.clientY

        const { deltaX, deltaY } = constrainedDeltasForMovement(
          svgRect,
          allImagesRect,
          eventDeltaX,
          eventDeltaY
        )

        dispatch({
          type: "Dragged",
          from: dragOrigin,
          to: {
            x: dragOrigin.x + deltaX,
            y: dragOrigin.y + deltaY,
          },
        })
        dragOriginRef.current = { x: e.clientX, y: e.clientY }
      }
    },
    [state.viewport.dragging, state.imageElements, svgRef, dispatch]
  )

  const onWheel = React.useCallback(
    (e: React.WheelEvent) => {
      const svgRect = svgRef.current?.getBoundingClientRect()
      if (!svgRect) return

      const allImagesRect = getSurroundingRectForRects(
        state.imageElements.map((imgEl) => imgEl.getBoundingClientRect())
      )

      dispatch({
        type: "Pan",
        ...constrainedDeltasForMovement(svgRect, allImagesRect, e.deltaX, e.deltaY),
      })
    },
    [dispatch, state.imageElements, svgRef]
  )

  // Handles the case where the user releases the drag outside the bounds of the SVG
  const mouseUpOutside = React.useCallback(() => {
    if (state.viewport.dragging) {
      dispatch({ type: "DragEnd" })
    }
  }, [dispatch, state.viewport.dragging])

  React.useEffect(() => {
    window.addEventListener("mouseup", mouseUpOutside)

    return () => window.removeEventListener("mouseup", mouseUpOutside)
  }, [mouseUpOutside])

  return React.useMemo(
    () => ({
      zoomIn,
      zoomOut,
      pageUp,
      pageDown,
      listenerProps: {
        onDoubleClick,
        onMouseDown,
        onMouseMove,
        onMouseUp: dragEnd,
        onWheel,
      },
    }),
    [zoomIn, zoomOut, pageUp, pageDown, onDoubleClick, onMouseDown, onMouseMove, onWheel, dragEnd]
  )
}

// Describes rules for panning that keep the images visible within the SVG viewport.
//
// The rules change depending on whether the images are zoomed to be wider or narrower than the container:
// - When the images are narrower/shorter than the container, the edges are kept inside the container.
// - When the images are wider/taller than the container, the edges are kept outside the container.
function constrainedDeltasForMovement(
  svgRect: DOMRect,
  allImagesRect: Rect,
  eventDeltaX: number,
  eventDeltaY: number
): { deltaX: number; deltaY: number } {
  const minX = allImagesRect.x
  const maxX = allImagesRect.x + allImagesRect.width
  const minY = allImagesRect.y
  const maxY = allImagesRect.y + allImagesRect.height

  let deltaX = 0
  // xMinDiff is negative when the left edge of the image is outside the container, positive when inside
  const xMinDiff = minX - eventDeltaX - svgRect.left
  // xMaxDiff is positive when the right edge of the image is outside the container, negative when inside
  const xMaxDiff = maxX - eventDeltaX - svgRect.right

  // Compare images width to the container width
  if (maxX - minX < svgRect.width) {
    // We are zoomed out so that the images are less wide than the container.
    // The left and right edges of the image should be kept _inside_ the container.
    deltaX =
      eventDeltaX > 0
        ? // Scrolling to the left, cancel out any deltaX that would move the left edge of the image outside the container
          xMinDiff < 0
          ? -eventDeltaX - xMinDiff
          : -eventDeltaX
        : // Scrolling to the right, cancel out any deltaX that would move the right edge of the image outside the container
          xMaxDiff > 0
          ? -eventDeltaX - xMaxDiff
          : -eventDeltaX
  } else {
    // We are zoomed in so that the images are wider than the container.
    // The left and right edges of the image should be kept _outside_ the container.
    deltaX =
      eventDeltaX > 0
        ? // Scrolling to the left, cancel out any deltaX that would move the right edge of the image inside the container
          xMaxDiff < 0
          ? -eventDeltaX - xMaxDiff
          : -eventDeltaX
        : // Scrolling to the right, cancel out any deltaX that would move the left edge of the image inside the container
          xMinDiff > 0
          ? -eventDeltaX - xMinDiff
          : -eventDeltaX
  }

  let deltaY = 0
  // yMinDiff is negative when the top edge of the image is outside the container, positive when inside
  const yMinDiff = minY - eventDeltaY - svgRect.top
  // yMaxDiff is positive when the bottom edge of the image is outside the container, negative when inside
  const yMaxDiff = maxY - eventDeltaY - svgRect.bottom

  // Compare images height to the container height
  if (maxY - minY < svgRect.height) {
    // We are zoomed out so that the images are less tall than the container.
    // The top and bottom edges of the image should be kept _inside_ the container.
    deltaY =
      eventDeltaY < 0
        ? // Images moving down, cancel out any deltaY that would move the bottom edge of the image outside the container
          yMaxDiff > 0
          ? -eventDeltaY - yMaxDiff
          : -eventDeltaY
        : // Images moving up, cancel out any deltaY that would move the top edge of the image outside the container
          yMinDiff < 0
          ? -eventDeltaY - yMinDiff
          : -eventDeltaY
  } else {
    // We are zoomed in so that the images are taller than the container.
    // The top and bottom edges of the image should be kept _outside_ the container.
    deltaY =
      eventDeltaY < 0
        ? // Images moving down, cancel out any deltaY that would move the top edge of the image inside the container
          yMinDiff > 0
          ? -eventDeltaY - yMinDiff
          : -eventDeltaY
        : // Images moving up, cancel out any deltaY that would move the bottom edge of the image inside the container
          yMaxDiff < 0
          ? -eventDeltaY - yMaxDiff
          : -eventDeltaY
  }

  // Return the constrained deltas
  return { deltaX, deltaY }
}
