/*
  STYLES
*/

import * as React from "react"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { useAssistantContext } from "src/frontend/components/Shared/Assistant/Context"

const StyledSuggestion = styled.div`
  font-weight: ${fonts.weight.medium};
  padding: 10px 15px;
  margin: 20px 0;
  border-radius: 20px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  transition: box-shadow 100ms ease-in-out;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 0 15px ${colors.translucentSecondary40};
  }
`

/*
  COMPONENTS
*/

export const Suggestion: React.FC<{ message: string }> = ({ message }) => {
  const { dispatch } = useAssistantContext()
  const onClick = React.useCallback(
    () => dispatch({ type: "SUBMIT", payload: message }),
    [dispatch, message]
  )

  return <StyledSuggestion onClick={onClick}>{message}</StyledSuggestion>
}
