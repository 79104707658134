import * as React from "react"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import FrontendSession from "src/frontend/session"

/*
  STYLES
*/

const ErrorContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${colors.secondary};
  padding-top: 100px;

  font-family: ${fonts.family.avenir};
  font-size: 22px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  color: ${colors.white};
  font-weight: ${fonts.weight.heavy};
  font-size: 30px;
  line-height: 40px;
`

const Subtitle = styled.div`
  color: ${colors.translucentWhite50};
  font-weight: ${fonts.weight.book};
  font-size: 16px;
  line-height: 30px;
`

const Ufo = styled.video`
  width: 420px;
  height: 420px;
  flex-shrink: 0;
  margin: 0 auto;
  mix-blend-mode: lighten;
`

/*
  COMPONENTS
*/

export function useErrorStates() {
  const session = useSession<FrontendSession>()
  const { affiliations, doppelganger, currentLegalEntity } = session

  const legalEntityIds = React.useMemo(
    () => affiliations?.map(({ entity }) => entity.id) || [],
    [affiliations]
  )

  if (!legalEntityIds.length || !currentLegalEntity) {
    return null
  }

  if (currentLegalEntity.hasDashboardAccess(doppelganger)) return null

  return <FinancialsPending />
}

/*
  COMPONENTS
*/

const FinancialsPending: React.FC = () => (
  <ErrorContainer>
    <Ufo muted playsInline autoPlay loop src={require("static/videos/ufo.mp4")} />
    <Title>Processing Financials</Title>
    <Subtitle>We’re currently processing the QuickBooks data.</Subtitle>
    <Subtitle>This will take a while.</Subtitle>
  </ErrorContainer>
)
