import React from "react"
import { useLazyQuery } from "@apollo/client"
import {
  ReportDownloadUrlDocument,
  ReportDownloadUrlQuery,
  ReportDownloadUrlQueryVariables,
} from "@digits-graphql/frontend/graphql-bearer"
import { VaultDownloadLink } from "@digits-shared/components/Vault/VaultDownload"
import useCancelablePromise from "src/shared/hooks/useCancelablePromise"

/**
 * Create a callback that returns a promise that will fetch a Report download URL. The callback
 * can be used as a click or event handler.
 *
 * Can only be used in React Functional Components since it uses `React.useEffect`
 */
export default function useDownloadUrl(
  legalEntityId: string,
  reportId: string,
  reportVersionId: string,
  fileName: string,
  reportPackageId: string
) {
  const resolveRef = React.useRef<(value: VaultDownloadLink) => void>()
  const rejectRef = React.useRef<(reason?: unknown) => void>()

  const [fetchUrl, result] = useLazyQuery<ReportDownloadUrlQuery, ReportDownloadUrlQueryVariables>(
    ReportDownloadUrlDocument,
    {
      variables: {
        legalEntityId,
        reportId,
        reportVersionId,
        reportPackageId,
      },
      fetchPolicy: "network-only",
    }
  )

  React.useEffect(() => {
    if (result.called && !result.loading && resolveRef.current && rejectRef.current) {
      const url = result.data?.reportDownloadUrl?.url
      if (url) {
        resolveRef.current({ fileName, url })
      } else {
        rejectRef.current("Download url not found")
      }

      resolveRef.current = undefined
      rejectRef.current = undefined
    }
  }, [result, fileName])

  const cancelablePromise = useCancelablePromise<VaultDownloadLink>()

  return React.useCallback(() => {
    fetchUrl()
    const promise = new Promise<VaultDownloadLink>((resolve, reject) => {
      resolveRef.current = resolve
      rejectRef.current = reject
    })

    return cancelablePromise(promise)
  }, [cancelablePromise, fetchUrl])
}
