import * as React from "react"
import { SharedLineChartStyles } from "src/frontend/components/OS/Shared/Charts/styles"
import { ParentSizedTimeseriesLineChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesLineChart"
import { TimeseriesValues } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ComponentSummary } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { ChartContainer } from "src/frontend/components/Shared/Layout/Shared"
import { SizingProps } from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface LineChartConfigProps extends SizingProps {
  name: React.ReactNode | undefined
  timeseries: TimeseriesValues
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const LineChartConfig = React.memo<LineChartConfigProps>(
  ({ name, timeseries, height, skipAnimations }) => (
    <>
      <ComponentSummary componentSize={ComponentSize.Small} title={name} timeseries={timeseries} />
      <ChartContainer height={height}>
        <ParentSizedTimeseriesLineChart
          timeseries={timeseries}
          skipAnimations={skipAnimations}
          chartStyle={SharedLineChartStyles}
          hideGrid
          noTooltip
          hideAxis
        />
      </ChartContainer>
    </>
  )
)
