import * as React from "react"
import { Link } from "react-router-dom"
import {
  Category,
  EntityTransaction,
  TransactionParty,
} from "@digits-graphql/frontend/graphql-bearer"
import { IconSize, SmallIconContainer } from "@digits-shared/components/UI/Icons/Icon"
import {
  Amount,
  Date,
  RowContent,
  RowContentDescription,
  RowContentTitle,
} from "@digits-shared/components/UI/Table/Content"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import useSession from "@digits-shared/hooks/useSession"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import moment from "moment"
import styled from "styled-components"
import { transactionListRequestVariables } from "src/frontend/components/OS/Springboard/Applications/Shared/Transactions/transactionListRequestVariables"
import { PartyItemIcon } from "src/frontend/components/Shared/Reports/Report/Components/Parties/PartyItemIcon"
import { TRANSACTION_LIST_GRID_STYLES } from "src/frontend/components/Shared/Reports/Report/Components/Transactions/TransactionListHeader"
import { RightNeonAmount } from "src/frontend/components/Shared/Reports/Report/Elements/ReportElements"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"
import { TransactionIcon } from "src/shared/components/Transactions/TransactionRow/TransactionPartyIcon"
import transactionHelper from "src/shared/helpers/transactionHelper"

/*
 STYLE
 */

const TRANSFER_OPACITY = 0.5

const Row = styled(Link)<{ disabled: boolean }>`
  ${TRANSACTION_LIST_GRID_STYLES};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "")};
  text-decoration: none !important;

  border-style: solid;
  border-color: transparent transparent ${colors.translucentPrimary10};
  border-width: 1px 0 1px 0;
  &:last-of-type {
    border-bottom-color: transparent;
  }

  &:hover {
    &:first-of-type {
      border-top-color: ${colors.translucentPrimary10};
    }

    &:last-of-type {
      border-bottom-color: ${colors.translucentPrimary10};
    }
    background: ${colors.translucentSecondary05};
  }
`

const Details = styled.div`
  display: flex;
`

const titleColor = themedValue({
  light: colors.black,
  dark: colors.white,
})

const TransactionDate = styled(Date)`
  color: ${titleColor};
`

const TransactionTitle = styled(RowContentTitle)`
  color: ${titleColor};
`

const descriptionColor = themedValue({
  light: colors.gray,
  dark: colors.translucentWhite70,
})

const TransactionDescription = styled(RowContentDescription)`
  color: ${descriptionColor};
`

const TransactionPartyHover = styled(PartyItemIcon)`
  margin-right: 10px;
`

const SpacingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TransferContainer = styled(RowContentTitle)`
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: ${fonts.weight.medium};
  overflow: visible;
`

const BankIconContainer = styled.div`
  margin-right: 5px;

  ${SmallIconContainer} {
    opacity: ${TRANSFER_OPACITY};
  }
`

const TransferAmount = styled(Amount)`
  font-size: 10px;
`

const BankName = styled(RowContentDescription)`
  font-size: inherit;
  opacity: ${TRANSFER_OPACITY};
`

const Text = styled.div`
  margin: 0 5px;
  opacity: ${TRANSFER_OPACITY};
  flex-shrink: 0;
`

/*
 INTERFACE
 */

export interface TransactionRowProps {
  transaction: EntityTransaction
  transactionIds?: string[]
  className?: string
}

interface TransactionContentProps {
  transaction: TransactionRowProps["transaction"]
}

interface TransferSideProps {
  institution?: TransactionParty
  category?: Category | null
}

/*
 COMPONENTS
*/

export const TransactionRow: React.FC<TransactionRowProps> = ({
  transaction,
  transactionIds,
  className,
}) => {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const { factId } = transaction

  const transactionPath = routes.defaultTransactionDetails.generateFromCurrentPath({
    transactionId: transaction.factId,
  })
  const transactionIdsState = transactionListRequestVariables.idsToLocationState(
    transactionIds || []
  )

  const isTransfer = transactionHelper.isTransfer(transaction)

  return (
    <Row
      data-context-id={factId}
      className={className}
      to={{ pathname: transactionPath, state: transactionIdsState }}
      disabled={isSharingContextActive}
    >
      {isTransfer ? (
        <TransferTransactionContent transaction={transaction} />
      ) : (
        <TransactionContent transaction={transaction} />
      )}
    </Row>
  )
}

const TransactionContent: React.FC<TransactionContentProps> = ({ transaction }) => {
  const { timestamp, party, moneyFlow, displayCategory } = transaction
  const description = party?.name || transaction.description || transaction.name
  const date = moment.unix(timestamp).utc()

  const amountWithCurrency = moneyFlowHelper.currency(moneyFlow, {
    style: CurrencyStyle.Detail,
  })

  return (
    <>
      <TransactionDate>{date.format("MMM DD")}</TransactionDate>
      <Details>
        <TransactionPartyHover party={party} />
        <RowContent css="flex: 1;">
          <TransactionTitle>{description}</TransactionTitle>
          <TransactionDescription>
            {displayCategory?.name || "Uncategorized"}
          </TransactionDescription>
        </RowContent>
      </Details>
      <RightNeonAmount>{amountWithCurrency}</RightNeonAmount>
    </>
  )
}

const TransferTransactionContent: React.FC<TransactionContentProps> = ({ transaction }) => {
  const { displayCategory, moneyFlow } = transaction

  const amount = moneyFlowHelper.currency(moneyFlow, {
    style: CurrencyStyle.Detail,
    absolute: true,
  })

  const fromCategory = transaction.splitCategory

  return (
    <SpacingContainer>
      <TransferContainer>
        <TransferAmount flow={moneyFlow.businessFlow} normal={moneyFlow.isNormal}>
          {amount}
        </TransferAmount>
        <Text>transfer from</Text>
        <TransferSide transaction={transaction} category={fromCategory} />
        <Text>to</Text>
        <TransferSide transaction={transaction} category={displayCategory} />
      </TransferContainer>
    </SpacingContainer>
  )
}

const TransferSide: React.FC<TransactionRowProps & TransferSideProps> = ({
  transaction,
  institution,
  category,
}) => (
  <TransferContainer>
    <BankIconContainer>
      <TransactionIcon
        transaction={transaction}
        party={institution}
        category={category}
        iconSize={IconSize.Small}
        neverHover
      />
    </BankIconContainer>
    <BankName>{category?.name}</BankName>
  </TransferContainer>
)
