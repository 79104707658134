import { EntityUser, Role, User } from "@digits-graphql/frontend/graphql-bearer"
import SessionUser from "@digits-shared/session/User"

export interface UserDisplayInfo {
  givenName?: string | null
  familyName?: string | null
  primaryEmailAddress?: string
  emailAddress?: string | null
  avatarUrl?: string | null
}

export enum UserRole {
  NoAccess = "No Access",
  // NOTE: Intentionally displaying limited user as associate,
  //       which is distinct from AffiliationAssociate.
  Limited = "Associate",
  AccountManager = "Account Manager",
  Admin = "Admin",
  Managed = "Managed",
}

export enum UserRoleFullyQualified {
  NoAccess = "",
  Limited = "com.digits.roles.organization.limited",
  AccountManager = "com.digits.roles.organization.accountmanager",
  Admin = "com.digits.roles.organization.admin",
  AffiliationAdmin = "com.digits.roles.affiliation.admin",
  AffiliationAssociate = "com.digits.roles.affiliation.associate",
  Managed = "com.digits.roles.organization.managed",
}

type DisplayNameOptions = "abbreviateLast"

export default {
  displayName(user: UserDisplayInfo, ...options: DisplayNameOptions[]) {
    const details = []
    if (user.givenName) details.push(user.givenName)
    if (user.familyName) {
      if (user.givenName && options?.includes("abbreviateLast")) {
        details.push(`${user.familyName.charAt(0)}.`)
      } else {
        details.push(user.familyName)
      }
    }

    if (!details.length) {
      details.push(user.emailAddress || user.primaryEmailAddress)
    }

    return details.join(" ")
  },

  largeAvatarUrl(user: { avatarUrl?: string | null }, size = 130) {
    if (!user.avatarUrl) return ""
    return user.avatarUrl.replace(/=s\d+-c/, `=s${size * 2}-c`)
  },

  roleDisplayName(role?: Role | string): UserRole {
    if (!role) return UserRole.NoAccess

    const id = typeof role === "object" ? role.id : role

    switch (id) {
      case UserRoleFullyQualified.Admin:
        return UserRole.Admin
      case UserRoleFullyQualified.AccountManager:
        return UserRole.AccountManager
      case UserRoleFullyQualified.Limited:
        return UserRole.Limited
      case UserRoleFullyQualified.AffiliationAdmin:
        return UserRole.Admin
      case UserRoleFullyQualified.AffiliationAssociate:
        return UserRole.Limited
      case UserRoleFullyQualified.Managed:
        return UserRole.Managed
      case UserRoleFullyQualified.NoAccess:
      default:
        return UserRole.NoAccess
    }
  },

  isDigitsEmployee(user: UserDisplayInfo) {
    return (
      (!!user.primaryEmailAddress && !!user.primaryEmailAddress.match(/@digits.com$/)) ||
      (!!user.emailAddress && !!user.emailAddress.match(/@digits.com$/)) ||
      false
    )
  },

  userToEntity(user: User): EntityUser {
    const { id, givenName, familyName, primaryEmailAddress, avatarUrl } = user
    return {
      id,
      avatarUrl,
      givenName: givenName || "",
      familyName: familyName || "",
      emailAddress: primaryEmailAddress,
    }
  },

  sessionUserToEntity(user: SessionUser): EntityUser {
    const { id, givenName, familyName, emailAddress, avatarUrl } = user
    return {
      id,
      avatarUrl,
      givenName: givenName || "",
      familyName: familyName || "",
      emailAddress,
    }
  },
}
