import * as React from "react"
import { ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import { SizingProps } from "src/frontend/components/Shared/Layout/types"
import { TopEntitiesProps } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Lists/shared"
import { TopCategoriesConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Lists/TopCategoriesConfig"
import { TopPartiesConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Lists/TopPartiesConfig"

export const TopEntitiesConfig: React.FC<SizingProps & TopEntitiesProps> = ({
  config,
  componentType,
}) => {
  if (config.kind === ObjectKind.Category)
    return <TopCategoriesConfig config={config} componentType={componentType} />

  if (config.kind === ObjectKind.Party)
    return <TopPartiesConfig config={config} componentType={componentType} />

  return null
}
