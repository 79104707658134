import { Flow, Maybe, MonetaryValue, MoneyFlow } from "@digits-graphql/frontend/graphql-bearer"
import envHelper from "@digits-shared/helpers/envHelper"
import monetaryValueHelper from "@digits-shared/helpers/monetaryValueHelper"
import numberHelper, { CurrencyFormatOptions } from "@digits-shared/helpers/numberHelper"

export default {
  calculateOpeningMoneyFlow(
    current: MoneyFlow,
    deltaPrevious?: Maybe<number>,
    isBalanceSheetCategory?: boolean
  ): MoneyFlow {
    const {
      value: { amount: currentAmount },
    } = current

    if (!isBalanceSheetCategory) {
      return buildZeroMoneyFlow()
    }

    if (deltaPrevious === undefined || deltaPrevious === null) {
      return current
    }

    // Is previous amount zero calculation to avoid floating point errors
    // 1. difference between current and previous
    // 2. converted to currency units
    // 3. multiplied by 100 to calculate to the penny -- Unclear if the penny calculation will be correct for all currencies
    // 4. truncated to remove any fractional pennies
    const isPreviousAmountZero =
      Math.trunc(((currentAmount - deltaPrevious) / current.value.currencyMultiplier) * 100) === 0

    if (isPreviousAmountZero) {
      return buildZeroMoneyFlow()
    }

    const previousAmount = currentAmount - deltaPrevious
    const previousValue = {
      ...current.value,
      amount: previousAmount,
    }

    return {
      value: previousValue,
      businessFlow:
        Math.sign(currentAmount) === Math.sign(previousAmount)
          ? current.businessFlow // same amount signs, same business flow
          : invertBusinessFlow(current.businessFlow), // different amount signs, invert business flow
      isNormal:
        Math.sign(currentAmount) === Math.sign(previousAmount)
          ? current.isNormal // same amount sign, same isNormal
          : !current.isNormal, // different amount signs, invert isNormal
    }
  },

  amountBusinessFlow(amount: number): Flow {
    if (amount >= 0) return Flow.Inbound
    return Flow.Outbound
  },

  toMonetaryValue(moneyFlow?: MoneyFlow | null): MonetaryValue {
    const flow = moneyFlow ?? buildZeroMoneyFlow()
    return {
      amount: flow.isNormal ? Math.abs(flow.value.amount) : Math.abs(flow.value.amount) * -1,
      currencyMultiplier: flow.value.currencyMultiplier,
      iso4217CurrencyCode: flow.value.iso4217CurrencyCode,
    }
  },

  toMonetaryUnmultipliedValue(moneyFlow?: MoneyFlow | null): number | undefined {
    if (!moneyFlow) return undefined
    const monetaryValue = this.toMonetaryValue(moneyFlow)
    return monetaryValueHelper.unmultipliedAmount(monetaryValue)
  },

  currency(moneyFlow?: MoneyFlow | null, options?: CurrencyFormatOptions) {
    return numberHelper.currency(this.toMonetaryValue(moneyFlow), options)
  },

  addValues(...values: MoneyFlow[]) {
    return values.reduce((agg, val) => this.add(agg, val), this.buildZeroMoneyFlow())
  },

  add(a: MoneyFlow | undefined | null, b: MoneyFlow | undefined | null): MoneyFlow {
    const aValue = this.toMonetaryValue(a)
    const bValue = this.toMonetaryValue(b)
    const total = aValue.amount + bValue.amount

    if (total === 0) {
      return buildZeroMoneyFlow()
    }
    if (!a || aValue.amount === 0) {
      return b ?? buildZeroMoneyFlow()
    }
    if (!b || bValue.amount === 0) {
      return a ?? buildZeroMoneyFlow()
    }

    const flowMismatch =
      (a.businessFlow === b.businessFlow && a.isNormal !== b.isNormal) ||
      (a.businessFlow !== b.businessFlow && a.isNormal === b.isNormal)
    if (flowMismatch && envHelper.isDevelopment()) {
      console.error(
        "Flow mismatch while summing money flows. This means the sum is non-deterministic.",
        a,
        b
      )
    }

    const invert = (aValue.amount < 0 && total > 0) || (aValue.amount > 0 && total < 0)
    return {
      value: {
        amount: total,
        currencyMultiplier: aValue.currencyMultiplier,
        iso4217CurrencyCode: aValue.iso4217CurrencyCode,
      },
      businessFlow: invert ? invertBusinessFlow(a.businessFlow) : a.businessFlow,
      isNormal: invert ? !a.isNormal : a.isNormal,
    }
  },

  divide(a: MoneyFlow | undefined | null, b: number): MoneyFlow {
    const flow = a ?? buildZeroMoneyFlow()
    if (b === 0) return flow
    return {
      ...flow,
      value: {
        ...flow.value,
        amount: flow.value.amount / b,
      },
    }
  },

  invert(flow: MoneyFlow): MoneyFlow {
    if (flow.value.amount === 0) return buildZeroMoneyFlow()

    return {
      value: flow.value,
      businessFlow: invertBusinessFlow(flow.businessFlow),
      isNormal: !flow.isNormal,
    }
  },

  subtract(a: MoneyFlow | undefined, b: MoneyFlow | undefined): MoneyFlow {
    return this.add(a, this.invert(b ?? buildZeroMoneyFlow()))
  },

  equals(a: MoneyFlow, b: MoneyFlow): boolean {
    const aValue = this.toMonetaryValue(a)
    const bValue = this.toMonetaryValue(b)

    return (
      aValue.amount === bValue.amount &&
      a.isNormal === b.isNormal &&
      a.businessFlow === b.businessFlow
    )
  },

  buildZeroMoneyFlow,
}

function invertBusinessFlow(flow: Flow): Flow {
  switch (flow) {
    case Flow.Inbound:
      return Flow.Outbound
    case Flow.Outbound:
      return Flow.Inbound
    default:
      return Flow.UnknownFlow
  }
}

export function buildZeroMoneyFlow(businessFlow = Flow.Inbound): MoneyFlow {
  return {
    value: {
      amount: 0,
      currencyMultiplier: 1e6,
      iso4217CurrencyCode: "USD",
    },
    businessFlow,
    isNormal: true,
  }
}
