import * as React from "react"
import { LayoutSection } from "@digits-graphql/frontend/graphql-bearer"
import styled, { css } from "styled-components"
import {
  Gutter,
  GutterOrientation,
  RowGutter,
} from "src/frontend/components/Shared/Portals/Layout/Gutter"
import { Row } from "src/frontend/components/Shared/Portals/Layout/Row"

/*
 STYLES
*/

const SectionContainer = styled.div<{ rowCount: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ rowCount }) =>
    rowCount < 2 &&
    css`
      ${Gutter}[data-gutter-orientation=${GutterOrientation.Horizontal}]:last-child {
        padding-bottom: 200px;
      }
    `}
`

/*
 COMPONENTS
*/

export const Section: React.FC<{ section: LayoutSection }> = ({ section }) => {
  const { sectionId } = section

  return (
    <SectionContainer rowCount={section.rows ? section.rows.length : 0}>
      <RowGutter sectionId={sectionId} index={0} />
      {section.rows?.map((r, i) => (
        <React.Fragment key={r.rowId}>
          <Row row={r} sectionId={sectionId} />
          <RowGutter sectionId={sectionId} index={i + 1} />
        </React.Fragment>
      ))}
    </SectionContainer>
  )
}
