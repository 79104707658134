import colors from "@digits-shared/themes/colors"
import { css, RuleSet } from "styled-components"

export const COMMENT_CONTAINER_MAX_HEIGHT = 200

export const SPRINGBOARD_DETAILS_NAV_HEIGHT = 72
export const SPRINGBOARD_DETAILS_NAV_FULL_MARGIN_BOTTOM = 35
export const SPRINGBOARD_DETAILS_NAV_FULL_HEIGHT =
  SPRINGBOARD_DETAILS_NAV_HEIGHT + SPRINGBOARD_DETAILS_NAV_FULL_MARGIN_BOTTOM
export const SPRINGBOARD_DETAILS_NAV_CONDENSED_MARGIN_BOTTOM = 25

export function springboardDetailsCondensedNavMediaQuery(styles: RuleSet) {
  return css`
    @media only screen and (max-height: 750px) {
      ${styles};
    }
  `
}

export const SPRINGBOARD_DETAILS_CONTAINER_MARGIN = 25
export const SPRINGBOARD_DETAILS_CONTENT_WIDTH = 750
export const SPRINGBOARD_DETAILS_CONTENT_SPACING_LEFT = 18
export const SPRINGBOARD_DETAILS_CONTENT_SPACING_RIGHT = 18
export const SPRINGBOARD_DETAILS_CONTENT_BORDER_RADIUS = 16
export const SPRINGBOARD_DETAILS_CONTENT_PADDING = 16

export const SPRINGBOARD_DETAILS_CONTENT_ASIDE_WIDTH = 360
export const SPRINGBOARD_DETAILS_CONTENT_ASIDE_PADDING = 16
export const SPRINGBOARD_DETAILS_CONTENT_ASIDE_HEADER_OVERLAP = 27
export const SPRINGBOARD_DETAILS_CONTENT_ASIDE_INTERIOR_MARGIN_TOP =
  -SPRINGBOARD_DETAILS_CONTENT_ASIDE_PADDING - SPRINGBOARD_DETAILS_CONTENT_ASIDE_HEADER_OVERLAP
export const SPRINGBOARD_DETAILS_CONTENT_ASIDE_INTERIOR_BORDER_RADIUS = 8
export const SPRINGBOARD_DETAILS_CONTENT_ASIDE_HEADER_HEIGHT = 72
export const SPRINGBOARD_DETAILS_CONTENT_ASIDE_HEADER_HEIGHT_WITH_OVERLAP =
  SPRINGBOARD_DETAILS_CONTENT_ASIDE_HEADER_HEIGHT + SPRINGBOARD_DETAILS_CONTENT_ASIDE_HEADER_OVERLAP

export const SPRINGBOARD_DETAILS_MAIN_SECTION_WIDTH =
  SPRINGBOARD_DETAILS_CONTENT_WIDTH + SPRINGBOARD_DETAILS_CONTENT_ASIDE_WIDTH
export const SPRINGBOARD_DETAILS_RIGHT_SIDEBAR_MARGIN_LEFT = 20
export const SPRINGBOARD_DETAILS_RIGHT_SIDEBAR_WIDTH = 280

export const SPRINGBOARD_DETAILS_DEEPER_DIVE_NAV_HEIGHT = 50

export const SPRINGBOARD_DETAILS_HIDE_WHEN_SCROLL_Z_INDEX = 1

// Percent of parent container to fill when collapsed.
export const SPRINGBOARD_DETAILS_CONTENT_COLLAPSED_HEIGHT_PERCENT = 80
export const SPRINGBOARD_DETAILS_CONTENT_EXPAND_ANIMATION_DURATION = 250
export const SPRINGBOARD_DETAILS_CONTENT_EXPAND_ANIMATION_CURVE = "ease-out"

export const SPRINGBOARD_DETAILS_BACKGROUND_STYLES = css`
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 20px 0;
  background: linear-gradient(rgba(255, 255, 255, 0.45) 12.73%, rgba(255, 255, 255, 0.33) 90.37%);
  color: ${colors.secondary};
`
