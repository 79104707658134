import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import {
  ComponentSizeProps,
  MatchedComponent,
  SizingProps,
} from "src/frontend/components/Shared/Layout/types"

export const ITEM_HEIGHT = 40

export type TopEntitiesProps = SizingProps & {
  component: MatchedComponent<"topEntities">
  componentSize: ComponentSize
}

export const Title = styled.div<ComponentSizeProps>`
  font-size: ${({ componentSize }) => componentSize.headerSize};
  font-weight: ${fonts.weight.heavy};
  margin-bottom: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
