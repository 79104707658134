import * as React from "react"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { BarChartComponent } from "src/frontend/components/Shared/Assistant/Messages/Layout/Chart/BarChartComponent"
import { LineChartComponent } from "src/frontend/components/Shared/Assistant/Messages/Layout/Chart/LineChartComponent"
import { useLayoutContext } from "src/frontend/components/Shared/Assistant/Messages/Layout/Context"
import { usePartySummaryByTimeComponentData } from "src/frontend/components/Shared/Assistant/Messages/Layout/hooks/usePartySummaryByTimeComponentData"
import { PartyIconStyled } from "src/frontend/components/Shared/Assistant/Messages/Layout/Shared/Icons"
import {
  TimeseriesValue,
  toSortedTimeseriesValues,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ChartType, MatchedComponent } from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface PartyChartComponentProps {
  component: MatchedComponent<"partyChart">
  chartType: ChartType
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const PartyChartComponent: React.FC<PartyChartComponentProps> = ({
  component,
  chartType,
  skipAnimations,
}) => {
  const { layoutId, viewKey } = useLayoutContext()
  const {
    config: { partyChart: config },
    dataId,
  } = component

  const { party, dimensionalSummary } = usePartySummaryByTimeComponentData(
    component.config,
    layoutId,
    viewKey,
    dataId
  )

  const period = React.useMemo(
    () => dateTimeHelper.periodFromIntervalOrigin(config.origin),
    [config.origin]
  )

  const timeseries = React.useMemo(
    () => toSortedTimeseriesValues(dimensionalSummary?.time),
    [dimensionalSummary?.time]
  )

  const name = React.useMemo(() => {
    if (!party) return undefined

    return party.name
  }, [party])

  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined)

  const onMouseOver = React.useCallback((value: TimeseriesValue, index: number) => {
    setSelectedIndex(index)
  }, [])

  const onMouseOut = React.useCallback((value?: TimeseriesValue) => {
    setSelectedIndex(undefined)
  }, [])

  const icon = React.useMemo(() => {
    if (!party) return null

    return <PartyIconStyled size={IconSize.Tiny} party={party} />
  }, [party])

  switch (chartType) {
    case ChartType.BalanceSummary:
      return (
        <LineChartComponent
          name={name}
          timeseries={timeseries}
          period={period}
          skipAnimations={skipAnimations}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          selectedIndex={selectedIndex}
          icon={icon}
        />
      )
    case ChartType.TransactionSummary:
      return (
        <BarChartComponent
          name={name}
          timeseries={timeseries}
          period={period}
          skipAnimations={skipAnimations}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          selectedIndex={selectedIndex}
          icon={icon}
        />
      )
  }
}
