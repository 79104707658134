import * as React from "react"
import { Link, LinkProps, useRouteMatch } from "react-router-dom"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgExpand05 } from "@digits-shared/components/SVGIcons/line/Expand05.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { ComponentWrapper } from "src/frontend/components/Shared/Layout/Shared"
import routes from "src/frontend/routes"

const ExpandLink = styled(Link)<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  display: flex;
  align-items: center;
  gap: 4px;
`

const ExpandContent = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ExpandIcon = styled(SvgExpand05)`
  width: 16px;
  height: 16px;
  min-width: 16px;
  ${svgPathStyles(colors.secondary, 1.5)};

  display: none;
  opacity: 0;
  ${ComponentWrapper}:hover a > & {
    display: unset;
    opacity: 0.65;

    &:hover {
      opacity: 1;
    }
  }
`

interface ComponentExpandLinkProps extends LinkProps {
  componentSize: ComponentSize
  linkBadge?: React.ReactNode
}

const ExpandButton = styled(DigitsButton)`
  padding: 0 10px;
  font-size: 10px;
  height: 24px;
  margin-left: 4px;

  display: none;
  opacity: 0;
  ${ComponentWrapper}:hover & {
    display: unset;
    opacity: 1;
  }
`

export const ComponentExpandLink: React.FC<React.PropsWithChildren<ComponentExpandLinkProps>> = ({
  children,
  linkBadge,
  componentSize,
  ...linkProps
}) => {
  const isDetailsView = !!useRouteMatch(routes.layoutComponentDetails.parameterizedPath)
  const isEditView = !!useRouteMatch(routes.legalEntityHomeEdit.parameterizedPath)
  const isReportEditView = !!useRouteMatch(routes.reportPackageEditVersion.parameterizedPath)
  const showExpandIcon = !isDetailsView && !isEditView && !isReportEditView

  const icon =
    componentSize === ComponentSize.Small || componentSize === ComponentSize.PageSmall ? (
      <ExpandIcon />
    ) : (
      <ExpandButton $variant="secondary-dark">View Details</ExpandButton>
    )

  return (
    <ExpandLink {...linkProps} disabled={!showExpandIcon}>
      <ExpandContent>{children}</ExpandContent>
      {linkBadge}
      {showExpandIcon && icon}
    </ExpandLink>
  )
}
