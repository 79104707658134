import * as React from "react"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { m } from "framer-motion"
import styled, { css } from "styled-components"

const Marketing = styled(m.div)<{ fadeOut: boolean }>`
  text-align: center;
  width: unset;
  margin: 0 70px;
  font-weight: ${fonts.weight.normal};
  pointer-events: none;
  transition: opacity 500ms ease-out;
  overflow: hidden;
  transform: translateY(25vh);

  ${({ fadeOut }) => css`
    height: ${fadeOut ? "0px" : "auto"};
    opacity: ${fadeOut ? 0 : 1};
    padding-top: ${fadeOut ? 0 : 20}px;
  `}
`

const MarketingTitle = styled.div`
  color: ${colors.translucentSecondary80};
  font-size: 22px;

  b {
    color: ${colors.secondary};
    font-weight: ${fonts.weight.heavy};
  }
`

const Text = styled.div`
  color: ${colors.translucentSecondary70};
  font-size: 16px;
  margin: 10px 0 20px;
`

/*
  INTERFACES
*/

interface SearchMarketingProps {
  hide: boolean
}

/*
  COMPONENTS
*/

export const SearchMarketing: React.FC<SearchMarketingProps> = ({ hide }) => (
  <Marketing fadeOut={hide}>
    <MarketingTitle>
      Search your entire financial history. <b>Instantly.</b>
    </MarketingTitle>
    <Text>
      Digits puts all of your vendors, transactions,
      <br /> and more at your fingertips.
    </Text>
  </Marketing>
)
