import * as React from "react"
import { Interval, IntervalOrigin, Period } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper, { TimeRange } from "@digits-shared/helpers/dateTimeHelper"
import {
  DateRangeAction,
  DateRangeState,
  processDateRangeReducer,
} from "src/shared/components/DateRangeSelector/dateRangeReducer"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

type DateRangeContextProps = {
  dateRangeState: DateRangeState
  dateRangeDispatch: React.Dispatch<DateRangeAction>
  onSetClick: () => void
  onCancelClick: () => void
  includeDays: boolean
  includeMonths: boolean
  includeQuarters: boolean
  includeYears: boolean
  showIntervals: boolean
  singleInterval: boolean
}

const DateRangeContext = React.createContext<DateRangeContextProps>({
  dateRangeState: { timeRange: { startedAt: 0, endedAt: 0, interval: Interval.IntervalNone } },
  dateRangeDispatch: () => {},
  onSetClick: () => {},
  onCancelClick: () => {},
  includeDays: false,
  includeMonths: true,
  includeQuarters: true,
  includeYears: true,
  showIntervals: true,
  singleInterval: false,
})

interface ProviderProps {
  defaultValue?: Period | IntervalOrigin
  onTimeRange: (timeRange: TimeRange) => void
  onCancel: () => void
  includeDays?: boolean
  includeMonths?: boolean
  includeQuarters?: boolean
  includeYears?: boolean
  singleInterval?: boolean
}

function isPeriod(v?: Period | IntervalOrigin): v is Period {
  return (v as Period)?.startedAt !== undefined
}

export const DateRangeContextProvider: React.FC<React.PropsWithChildren<ProviderProps>> = ({
  defaultValue,
  onTimeRange,
  children,
  onCancel: onCancelClick,
  includeDays = false,
  includeMonths = true,
  includeQuarters = true,
  includeYears = true,
  singleInterval = false,
}) => {
  const intervalOrigin = useIntervalOrigin()

  const showIntervals = React.useMemo(
    () => [includeDays, includeMonths, includeQuarters, includeYears].filter(Boolean).length > 1,
    [includeDays, includeMonths, includeQuarters, includeYears]
  )

  const [dateRangeState, dateRangeDispatch] = React.useReducer(processDateRangeReducer, {
    timeRange: isPeriod(defaultValue)
      ? { ...defaultValue }
      : dateTimeHelper.periodFromIntervalOrigin(defaultValue || intervalOrigin),
  })

  // Safeguard against bad defaults
  React.useEffect(() => {
    if (includeDays) return
    if (dateRangeState.timeRange.interval === Interval.Day) {
      dateRangeDispatch({ type: "SET_INTERVAL", interval: Interval.Month })
    }
  }, [dateRangeState.timeRange.interval, includeDays])

  const onSetClick = React.useCallback(() => {
    onTimeRange(dateRangeState.timeRange)
  }, [dateRangeState.timeRange, onTimeRange])

  const context = React.useMemo<DateRangeContextProps>(
    () => ({
      dateRangeState,
      dateRangeDispatch,
      onSetClick,
      onCancelClick,
      includeDays,
      includeMonths,
      includeQuarters,
      includeYears,
      showIntervals,
      singleInterval,
    }),
    [
      dateRangeState,
      onCancelClick,
      onSetClick,
      singleInterval,
      includeDays,
      includeMonths,
      includeQuarters,
      showIntervals,
      includeYears,
    ]
  )

  return <DateRangeContext.Provider value={context}>{children}</DateRangeContext.Provider>
}

export function useDateRangeContext() {
  const context = React.useContext(DateRangeContext)
  return React.useMemo(() => context, [context])
}
