import {
  InsightRuleType,
  Interval,
  IntervalOrigin,
  MonetaryValueFieldsFragment,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper, { DateFormat } from "@digits-shared/helpers/dateTimeHelper"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import moment from "moment"
import { v4 as generateUUID } from "uuid"

export enum SentenceTagKeys {
  SUBJECT_NAME = "SUBJECT_NAME",
  ABSOLUTE_AMOUNT_GREATER = "ABSOLUTE_AMOUNT_GREATER",
  ABSOLUTE_AMOUNT_LESS = "ABSOLUTE_AMOUNT_LESS",
  ABSOLUTE_AMOUNT_BOLD = "ABSOLUTE_AMOUNT_BOLD",
  ABSOLUTE_AMOUNT = "ABSOLUTE_AMOUNT",
  PREVIOUS_AMOUNT_GREATER = "PREVIOUS_AMOUNT_GREATER",
  PREVIOUS_AMOUNT_LESS = "PREVIOUS_AMOUNT_LESS",
  PREVIOUS_AMOUNT_BOLD = "PREVIOUS_AMOUNT_BOLD",
  PREVIOUS_AMOUNT = "PREVIOUS_AMOUNT",
  PERCENT_DELTA_GREATER = "PERCENT_DELTA_GREATER",
  PERCENT_DELTA_LESS = "PERCENT_DELTA_LESS",
  PERCENT_DELTA_BOLD = "PERCENT_DELTA_BOLD",
  PERCENT_DELTA = "PERCENT_DELTA",
  ABSOLUTE_DELTA_LESS = "ABSOLUTE_DELTA_LESS",
  ABSOLUTE_DELTA_GREATER = "ABSOLUTE_DELTA_GREATER",
  ABSOLUTE_DELTA_BOLD = "ABSOLUTE_DELTA_BOLD",
  ABSOLUTE_DELTA = "ABSOLUTE_DELTA",
  LOOKBACK_WINDOW = "LOOKBACK_WINDOW",
  PAST_TIME_PLACEHOLDER = "PAST_TIME_PLACEHOLDER",
  CURRENT_TIME_PLACEHOLDER = "CURRENT_TIME_PLACEHOLDER",
  FULL_DATE_PLACEHOLDER = "FULL_DATE_PLACEHOLDER",

  ENTITY_CATEGORY = "Category",
  ENTITY_CATEGORY_LEGACY = "CATEGORY", // to support legacy insights
  ENTITY_PARTY = "Party",
  ENTITY_PARTY_LEGACY = "PARTY", // to support legacy insights
  // PartyRole-specific insights
  ENTITY_PARTY_VENDOR = "Vendor",
  ENTITY_PARTY_PREPAID_VENDOR = "PrepaidVendor",
  ENTITY_PARTY_OWED_VENDOR = "OwedVendor",
  ENTITY_PARTY_CUSTOMER = "Customer",
  ENTITY_PARTY_UNPAID_CUSTOMER = "UnpaidCustomer",
  ENTITY_PARTY_SUPPLIER = "Supplier",
  ENTITY_PARTY_SHAREHOLDER = "Shareholder",
  ENTITY_PARTY_LENDER = "Lender",
  ENTITY_PARTY_FACILITATOR = "Facilitator",
}

export const startTagMarker = "~~~"
const startTagMarkerLength = startTagMarker.length

export const endTagMarker = "~~*"
const endTagMarkerLength = endTagMarker.length

const tagEntityIdMarker = "|"
const tagEntityIdMarkerLength = tagEntityIdMarker.length

const entityIdLength = generateUUID().length

export interface InsightTagInfo {
  parsedValue: string
  tagStartIndex: number
  tagEndIndex: number
  entityId?: string
}

const sentenceParsingHelper = {
  // parses out the value (ex: Meals and Entertainment) stored between
  // opening and closing tags of given type (ex: SUBJECT_NAME)
  parseSentencePart: (sentence: string, tagKey?: SentenceTagKeys): InsightTagInfo | null => {
    if (!tagKey || !tagKey.length) {
      return null
    }

    const tagKeyLength = tagKey.length
    const tagStartIndex = sentence.indexOf(startTagMarker + tagKey)
    if (tagStartIndex === -1) {
      return null
    }

    const entityIdStart =
      tagStartIndex + tagKeyLength + startTagMarkerLength + tagEntityIdMarkerLength
    const hasEntityId =
      sentence.charAt(entityIdStart - tagEntityIdMarkerLength) === tagEntityIdMarker
    const entityId = hasEntityId ? sentence.substr(entityIdStart, entityIdLength) : undefined

    const endTagIndex = sentence.indexOf(endTagMarker + tagKey)

    const tagValueStartIndex =
      tagStartIndex +
      tagKeyLength +
      startTagMarkerLength * 2 +
      (hasEntityId ? tagEntityIdMarkerLength + entityIdLength : 0)

    const tagValueEndIndex = endTagIndex
    const tagValue = sentence.substring(tagValueStartIndex, tagValueEndIndex)

    const tagEndIndex = tagValueEndIndex + tagKeyLength + endTagMarkerLength * 2

    return {
      parsedValue: tagValue,
      tagStartIndex,
      tagEndIndex,
      entityId,
    }
  },

  // determine if there are any unparsed tags left in the sentence
  sentenceContainsUnparsedTag: (sentence: string) =>
    !!Object.values(SentenceTagKeys).find(() => sentence.includes(startTagMarker)),

  // determine if the tag we're looking for exists in the sentence
  sentenceContainsTag: (sentence: string, tagKey: SentenceTagKeys) =>
    new RegExp(
      `${startTagMarker}${tagKey}(\\|[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}){0,1}${startTagMarker}`
    ).test(sentence),

  // this function is used to identify the next tag in the sentence and return
  // its value so that the sentence part may be correctly parsed out
  getFirstStartTag: (sentence: string): SentenceTagKeys | undefined => {
    let tagSentence = sentence
    const indexOfFirstStartMarker = tagSentence.indexOf(startTagMarker)
    tagSentence = tagSentence.substring(indexOfFirstStartMarker + startTagMarker.length)
    const indexOfSecondStartMarker = tagSentence.indexOf(startTagMarker)

    let tagName = tagSentence.substring(0, indexOfSecondStartMarker)
    const entityMarkerIndex = tagName.indexOf(tagEntityIdMarker)
    if (entityMarkerIndex !== -1) {
      tagName = tagName.substring(0, entityMarkerIndex)
    }

    return Object.values(SentenceTagKeys).find((keyName) => tagName === keyName)
  },

  // currency multiplier is 1 because numbers are currency adjusted
  // for sentence on backend
  getParsedAmountWithCurrency: (amount: number, periodValue: MonetaryValueFieldsFragment) => {
    const monetaryValue = {
      amount,
      iso4217CurrencyCode: periodValue.iso4217CurrencyCode,
      currencyMultiplier: 1,
    }

    return numberHelper.currency(monetaryValue, {
      style: CurrencyStyle.Summary,
      invertValues: false,
    })
  },

  // format the date represented in the insight sentence to refer to last month/year/etc
  // when it's the current month and the actual name of the previous period in all other periods
  formatTime: (intervalOrigin: IntervalOrigin, insightType: InsightRuleType) => {
    const isNow = dateTimeHelper.isCurrentInterval(intervalOrigin)

    if (isNow) return `last ${intervalOrigin.interval.toLowerCase()}`

    if (insightType === InsightRuleType.New) {
      const period = dateTimeHelper.periodFromIntervalOrigin(intervalOrigin)
      const displayName = dateTimeHelper.displayNameFromPeriod(period, DateFormat.Short)
      return `in ${displayName}`
    }

    const prevInterval = dateTimeHelper.addIntervalToOrigin(intervalOrigin, -1)
    const prevPeriod = dateTimeHelper.periodFromIntervalOrigin(prevInterval)
    return dateTimeHelper.displayNameFromPeriod(prevPeriod, DateFormat.Short)
  },

  formatPastTime: (intervalOrigin: IntervalOrigin, sentencePartCount: number) => {
    const isNow = dateTimeHelper.isCurrentInterval(intervalOrigin)

    if (isNow) {
      const firstWord = sentencePartCount === 0 ? "Last" : "last"
      return `${firstWord} ${intervalOrigin.interval.toLowerCase()}`
    }

    const prevInterval = dateTimeHelper.addIntervalToOrigin(intervalOrigin, -1)
    const prevPeriod = dateTimeHelper.periodFromIntervalOrigin(prevInterval)
    return dateTimeHelper.displayNameFromPeriod(prevPeriod, DateFormat.Short)
  },

  formatCurrentTime: (intervalOrigin: IntervalOrigin, sentencePartCount: number) => {
    const isNow = dateTimeHelper.isCurrentInterval(intervalOrigin)

    if (isNow) {
      const firstWord = sentencePartCount === 0 ? "So far this" : "so far this"
      return `${firstWord} ${intervalOrigin.interval.toLowerCase()}`
    }

    const period = dateTimeHelper.periodFromIntervalOrigin(intervalOrigin)
    const displayName = dateTimeHelper.displayNameFromPeriod(period, DateFormat.Short)
    const firstWord = sentencePartCount === 0 ? "In" : "in"
    return `${firstWord} ${displayName}`
  },

  formatFullDate: (date: number) => {
    const period = dateTimeHelper.periodFromIntervalOrigin(
      dateTimeHelper.intervalOriginFromMoment(moment.unix(date).utc(), Interval.Day)
    )
    const displayName = dateTimeHelper.displayNameFromPeriod(period, DateFormat.Default)
    return `${displayName}`
  },

  hasPositiveTrend: (sentence: string) =>
    sentenceParsingHelper.sentenceContainsTag(sentence, SentenceTagKeys.ABSOLUTE_AMOUNT_GREATER) ||
    sentenceParsingHelper.sentenceContainsTag(sentence, SentenceTagKeys.PERCENT_DELTA_GREATER) ||
    sentenceParsingHelper.sentenceContainsTag(sentence, SentenceTagKeys.ABSOLUTE_DELTA_GREATER),

  hasNegativeTrend: (sentence: string) =>
    sentenceParsingHelper.sentenceContainsTag(sentence, SentenceTagKeys.ABSOLUTE_AMOUNT_LESS) ||
    sentenceParsingHelper.sentenceContainsTag(sentence, SentenceTagKeys.PERCENT_DELTA_LESS) ||
    sentenceParsingHelper.sentenceContainsTag(sentence, SentenceTagKeys.ABSOLUTE_DELTA_LESS),
}

export default sentenceParsingHelper
