import * as React from "react"
import { themedStyles, themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"

/*
  STYLES
*/

export const IconSVGContainerNoBackgroundColor = styled.div<{ iconShape?: IconShape }>`
  position: relative;
  display: flex;
  height: inherit;
  width: inherit;
  font-size: 11px;
  // Default to circular icon shape
  // TODO: May want to set square radius based on size
  border-radius: ${({ iconShape }) => (iconShape === IconShape.Square ? "5px" : "50%")};
  padding: 8%;
  align-items: center;
  justify-content: center;
`

const iconStyles = themedStyles({
  light: css`
    background: ${colors.theme.light.genericIcon};
  `,
  dark: css`
    background: ${colors.theme.dark.genericIcon};
  `,
})

export const IconSVGContainer = styled(IconSVGContainerNoBackgroundColor)`
  ${iconStyles};
`

export interface IconContainerProps {
  className?: string
  disableHoverBorder?: boolean
  isSelected?: boolean
  children?: React.ReactNode
  iconShape?: IconShape
}

export const BORDER_COLOR = themedValue({
  light: colors.translucentSecondary20,
  dark: colors.translucentWhite20,
})

export const IconContainer = styled.div<IconContainerProps>`
  position: relative;
  font-size: 11px;
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: ${({ iconShape }) => (iconShape === IconShape.Square ? "5px" : "50%")};
`

export const InheritIconContainer = styled(IconContainer)`
  height: inherit;
  width: inherit;
  /* Adjust for border and padding */
  margin-top: -2px;
  margin-left: -2px;

  ${IconSVGContainer} {
    & > svg {
      height: 80%;
      width: 80%;
    }
  }
`

export const LargeIconContainer = styled(IconContainer)`
  height: 80px;
  width: 80px;
`

export const MediumLargerIconContainer = styled(IconContainer)`
  height: 48px;
  width: 48px;
`

export const MediumLargestIconContainer = styled(IconContainer)`
  height: 58px;
  width: 58px;
`

export const DetailsAsideIconContainer = styled(IconContainer)`
  height: 62px;
  width: 62px;
  border-width: 2px;
`

export const MediumLargeIconContainer = styled(IconContainer)`
  height: 35px;
  width: 35px;
`

export const MediumIconContainer = styled(IconContainer)`
  height: 30px;
  width: 30px;
`

export const SmallMediumIconContainer = styled(IconContainer)`
  height: 25px;
  width: 25px;
`

export const SmallIconContainer = styled(IconContainer)`
  height: 20px;
  width: 20px;

  ${IconSVGContainerNoBackgroundColor} {
    padding: 2px;
  }
`

export const TinyIconContainer = styled(IconContainer)`
  height: 15px;
  width: 15px;

  ${IconSVGContainerNoBackgroundColor} {
    padding: 1px;
  }
`

export const ExtraLargeIconContainer = styled(LargeIconContainer)`
  height: 100px;
  width: 100px;
`

export const LargestIconContainer = styled(LargeIconContainer)`
  height: 250px;
  width: 250px;
`

/*
  INTERFACES
*/

export class IconSize {
  static readonly Tiny = new IconSize(15)
  static readonly Small = new IconSize(20)
  static readonly SmallMedium = new IconSize(25)
  static readonly Medium = new IconSize(30)
  static readonly MediumLarge = new IconSize(35)
  static readonly MediumLarger = new IconSize(48)
  static readonly MediumLargest = new IconSize(58)
  static readonly Large = new IconSize(80)
  static readonly ExtraLarge = new IconSize(100)
  static readonly Inherit = new IconSize()
  static readonly Largest = new IconSize(250)
  static readonly DetailsAside = new IconSize(40)

  readonly pixels?: number

  constructor(pixels?: number) {
    this.pixels = pixels
  }
}

export enum IconShape {
  Circle = "circle",
  Square = "square",
}
