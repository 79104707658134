import * as React from "react"
import stringHelper from "@digits-shared/helpers/stringHelper"
import useSession from "@digits-shared/hooks/useSession"
import { Variants } from "framer-motion"
import { InviteClientsModalState } from "src/frontend/components/Shared/Portals/State/types"
import FrontendSession from "src/frontend/session"

export const MODAL_WIDTH = 430
export const MODAL_HEIGHT = 650
export const MODAL_RADIUS = 30

export interface ModalStateProps {
  modalState: InviteClientsModalState
}

export const transitionVariants: Variants = {
  entering: { opacity: 1, transition: { duration: 0.2 } },
  exiting: { opacity: 0, transition: { duration: 0.2 } },
}

export function useDefaultInviteMessage() {
  const { currentLegalEntity, isAffiliatedSession } = useSession<FrontendSession>()
  return React.useMemo(
    () =>
      `Excited to share ${stringHelper.apostrophize(
        currentLegalEntity.name
      )} finances on Digits! Stay up to date on ${
        isAffiliatedSession ? "your" : "our"
      } finances and monthly reports.`,
    [currentLegalEntity.name, isAffiliatedSession]
  )
}
