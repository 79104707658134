import * as React from "react"
import { Link } from "react-router-dom"
import urlHelper from "@digits-shared/helpers/urlHelper"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { m } from "framer-motion"
import { LocationDescriptorObject } from "history"
import styled from "styled-components"
import {
  Chip,
  SuggestionAnimProps,
  suggestionVariants,
} from "src/frontend/components/OS/Springboard/Applications/Search/SearchSuggestions/Shared"
import { SearchQuerySource } from "src/frontend/components/OS/Springboard/Applications/Search/shared"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session/FrontendSession"

/*
  INTERFACES
*/

type SuggestionChipProps = SuggestionAnimProps & {
  // Index of the chip within the suggestion data. Differs from the
  // animIndex from SuggestionAnimProps, which is offset by 1 to account
  // for the animation of the label which precedes the chips.
  dataIndex: number
  text: string
  source: SearchQuerySource
  onMouseEnterChip?: (chip: Chip) => void
  onMouseLeaveChip?: (chip: Chip) => void
}

/*
  STYLES
*/

const StyledChip = styled(m.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  margin-right: 12px;
  margin-bottom: 8px;
  padding: 6px 16px 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background: ${colors.translucentWhite20};
  font-weight: ${fonts.weight.medium};
  cursor: pointer;
  backdrop-filter: blur(10px);
  transition: background 150ms ease;

  &:hover {
    background: ${colors.white};
  }

  &:last-child {
    margin-right: 0;
  }
`

const Text = styled.span`
  max-width: 150px;
  font-size: 11px;
  color: ${colors.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: ${fonts.weight.heavy};
`

/*
  COMPONENTS
*/

export const SuggestionChip = React.forwardRef<HTMLAnchorElement, SuggestionChipProps>(
  ({ text, fadeOut, animIndex, dataIndex, source, onMouseEnterChip, onMouseLeaveChip }, ref) => {
    const { currentLegalEntity } = useSession<FrontendSession>()
    const generatePath = useFrontendPathGenerator()

    const chipRef = React.useMemo<Chip>(
      () => ({
        ref: ref as React.MutableRefObject<HTMLAnchorElement>,
        text,
        index: dataIndex,
      }),
      [ref, text, dataIndex]
    )

    const onMouseEnter = React.useCallback(() => {
      onMouseEnterChip?.(chipRef)
    }, [chipRef, onMouseEnterChip])

    const onMouseLeave = React.useCallback(() => {
      onMouseLeaveChip?.(chipRef)
    }, [chipRef, onMouseLeaveChip])

    const { pathname, search } = urlHelper.fullDashboardURL(
      generatePath(routes.search, { leSlug: currentLegalEntity?.slug, q: text })
    )
    const to: LocationDescriptorObject = {
      pathname,
      search,
      state: { source },
    }

    return (
      <Link to={to}>
        <StyledChip
          variants={suggestionVariants}
          initial="exiting"
          animate="entering"
          exit="exiting"
          custom={animIndex}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Text>{text}</Text>
        </StyledChip>
      </Link>
    )
  }
)
