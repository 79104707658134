import React, { PropsWithChildren } from "react"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

/*
  STYLES
*/

export const ASSISTANT_GRID_AREAS = {
  icon: "icon",
  name: "name",
  reactions: "reactions",
  content: "content",
}

export const ASSISTANT_ICON_SIZE = 24

export const AssistantMessageUser = styled.div`
  grid-area: ${ASSISTANT_GRID_AREAS.name};
  display: flex;
  align-items: center;
`

const BubbleContainer = styled.div`
  grid-area: ${ASSISTANT_GRID_AREAS.content};
  display: flex;
  flex-direction: column;
`

const BubbleBackground = styled.div`
  padding: 16px;
  font-size: 14px;
  font-weight: ${fonts.weight.heavy};
  border-radius: 20px;
  min-height: 55px;
`

const MessageBubble: React.FC<PropsWithChildren & { className?: string }> = ({
  className,
  children,
}) => (
  <BubbleContainer>
    <BubbleBackground className={className}>{children}</BubbleBackground>
  </BubbleContainer>
)

export const AssistantMessageBubble = styled(MessageBubble)``

export const AssistantMessageGrid = styled.div<{ isCurrentUser?: boolean }>`
  display: grid;
  grid-template-columns: ${ASSISTANT_ICON_SIZE}px 1fr;
  grid-template-rows: 1fr;
  grid-gap: 6px 4px;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  ${({ isCurrentUser }) =>
    isCurrentUser
      ? css`
          grid-template-areas: "${ASSISTANT_GRID_AREAS.content} ${ASSISTANT_GRID_AREAS.content} ${ASSISTANT_GRID_AREAS.content}" "${ASSISTANT_GRID_AREAS.icon} ${ASSISTANT_GRID_AREAS.name} ${ASSISTANT_GRID_AREAS.reactions}";
          direction: rtl;

          // Ensure the elements after the grid are LTR
          & > * {
            direction: ltr;
          }

          ${AssistantMessageUser} {
            justify-content: flex-end;
          }

          ${AssistantMessageBubble} {
            align-self: flex-end;
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
              linear-gradient(
                84.38deg,
                rgba(44, 146, 190, 0.8) -4.07%,
                rgba(61, 58, 194, 0.54) 95.1%
              );
            border-bottom-right-radius: 0;
            color: ${colors.white};
          }
        `
      : css`
          grid-template-areas: "${ASSISTANT_GRID_AREAS.icon} ${ASSISTANT_GRID_AREAS.name} ${ASSISTANT_GRID_AREAS.reactions}" "${ASSISTANT_GRID_AREAS.content} ${ASSISTANT_GRID_AREAS.content} ${ASSISTANT_GRID_AREAS.content}";

          ${AssistantMessageBubble} {
            background-color: ${colors.white};
            border-top-left-radius: 0;
          }
        `};
`
