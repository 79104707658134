import * as React from "react"
import {
  Flow,
  Interval,
  MoneyFlow,
  StatementDeltaValue,
} from "@digits-graphql/frontend/graphql-bearer"
import { chevronStyles, PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import colorHelper from "@digits-shared/helpers/colorHelper"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { ComponentSizeProps } from "src/frontend/components/Shared/Layout/types"
import { useIsEditLayoutActive } from "src/frontend/components/Shared/Portals/State/useIsEditLayoutActive"

/*
  STYLES
*/

const Summary = styled.div<{ isEditLayoutActive: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-right: ${({ isEditLayoutActive }) => (isEditLayoutActive ? "10px" : "0")};
`

const Header = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  height: 28px;
  gap: 6px;
  align-items: center;
`

const ComponentTitle = styled.div<ComponentSizeProps>`
  position: relative;
  flex: 1;

  font-size: ${({ componentSize }) => componentSize.headerSize};
  font-weight: ${fonts.weight.heavy};
  margin-bottom: 2px;
  color: ${colors.secondary};

  display: flex;
  gap: 6px;
  align-items: center;
  align-self: center;
`

const TitleWrap = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Date = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const SubHeader = styled.div<ComponentSizeProps>`
  ${themedStyles({
    light: css`
      color: ${colors.secondary50};
    `,
    dark: css`
      color: ${colorHelper.hexToRgba("#0c404b", 0.7)};
    `,
  })}

  font-size: ${({ componentSize }) => componentSize.subHeaderSize};
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  gap: 4px;
`

const HeaderValue = styled.div<ComponentSizeProps>`
  ${themedStyles({
    light: css`
      color: ${colors.secondary};
    `,
    dark: css`
      color: #0c404b;
    `,
  })}
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-left: auto;
  gap: 4px;
  font-weight: ${fonts.weight.heavy};
  font-size: ${({ componentSize }) => componentSize.headerSize};
  margin-bottom: 2px;
`

/*
  INTERFACES
*/

interface ComponentSummaryProps {
  className?: string
  componentSize: ComponentSize
  title: React.ReactNode | undefined
  icon?: React.ReactNode
  timeseries: TimeseriesValue[] | undefined
  defaultValueNode?: React.ReactNode
  defaultTimeseriesIndex?: number
  selectedIndex?: number
  subHeaderCustomNode?: React.ReactNode
  isEstimate?: boolean
}

interface ComponentAmountProps {
  moneyFlow: MoneyFlow
  delta?: StatementDeltaValue
  isEstimate?: boolean
}

interface ComponentDateProps {
  timeseriesToDisplay: TimeseriesValue
  interval: Interval
  isEditLayoutActive: boolean
}

interface ComponentTitleProps {
  className?: string
  componentSize: ComponentSize
  title: React.ReactNode | undefined
  icon?: React.ReactNode
  periodName: string | undefined
}

interface PeriodDeltaProps {
  delta?: StatementDeltaValue
}

/*
  COMPONENTS
*/

export const ComponentSummary: React.FC<ComponentSummaryProps> = ({
  className,
  componentSize,
  title,
  icon,
  timeseries,
  defaultValueNode,
  defaultTimeseriesIndex,
  selectedIndex,
  isEstimate,
  subHeaderCustomNode,
}) => {
  const isEditLayoutActive = useIsEditLayoutActive()

  const timeseriesToDisplay =
    timeseries && timeseries[selectedIndex ?? defaultTimeseriesIndex ?? timeseries.length - 1]
  const interval = timeseriesToDisplay?.period.interval || Interval.Month

  const showCustomDefault = selectedIndex === undefined && defaultValueNode !== undefined

  return (
    <Summary className={className} isEditLayoutActive={isEditLayoutActive}>
      <Header>
        <ComponentTitle componentSize={componentSize}>
          {icon}
          <TitleWrap>{title}</TitleWrap>
        </ComponentTitle>
        <HeaderValue componentSize={componentSize}>
          {showCustomDefault
            ? defaultValueNode
            : timeseriesToDisplay && (
                <ComponentAmount
                  moneyFlow={timeseriesToDisplay.moneyFlow}
                  isEstimate={isEstimate}
                />
              )}
        </HeaderValue>
      </Header>
      {timeseriesToDisplay && (
        <SubHeader componentSize={componentSize}>
          <ComponentDate
            timeseriesToDisplay={timeseriesToDisplay}
            interval={interval}
            isEditLayoutActive={isEditLayoutActive}
          />
          {showCustomDefault && subHeaderCustomNode}
          {!showCustomDefault && (
            <PeriodDelta delta={timeseriesToDisplay.deltaPrevious} componentSize={componentSize} />
          )}
        </SubHeader>
      )}
    </Summary>
  )
}

export const ComponentDateSummary: React.FC<ComponentSummaryProps> = ({
  className,
  componentSize,
  title,
  icon,
  timeseries,
  defaultTimeseriesIndex,
  selectedIndex,
  subHeaderCustomNode,
}) => {
  const isEditLayoutActive = useIsEditLayoutActive()

  const timeseriesToDisplay =
    timeseries && timeseries[selectedIndex ?? defaultTimeseriesIndex ?? timeseries.length - 1]
  const interval = timeseriesToDisplay?.period.interval || Interval.Month

  return (
    <Summary className={className} isEditLayoutActive={isEditLayoutActive}>
      <Header>
        <ComponentTitle componentSize={componentSize}>
          {icon}
          <TitleWrap>{title}</TitleWrap>
        </ComponentTitle>
      </Header>
      {timeseriesToDisplay && (
        <SubHeader componentSize={componentSize}>
          <ComponentDate
            timeseriesToDisplay={timeseriesToDisplay}
            interval={interval}
            isEditLayoutActive={isEditLayoutActive}
          />
          {subHeaderCustomNode}
        </SubHeader>
      )}
    </Summary>
  )
}

export const ComponentHeaderTitle: React.FC<ComponentTitleProps> = ({
  className,
  componentSize,
  title,
  periodName,
  icon,
}) => (
  <Summary className={className} isEditLayoutActive={useIsEditLayoutActive()}>
    <Header>
      <ComponentTitle componentSize={componentSize}>
        {icon}
        <TitleWrap>{title}</TitleWrap>
      </ComponentTitle>
    </Header>
    <SubHeader componentSize={componentSize}>
      <Date>{periodName}</Date>
    </SubHeader>
  </Summary>
)

const ComponentDate: React.FC<ComponentDateProps> = ({
  timeseriesToDisplay,
  interval,
  isEditLayoutActive,
}) => {
  const { period } = timeseriesToDisplay
  if (
    !isEditLayoutActive &&
    dateTimeHelper.arePeriodsEqual(period, dateTimeHelper.todayPeriodForInterval(interval))
  ) {
    return <Date>as of Today</Date>
  }

  return <Date>{dateTimeHelper.displayNameFromPeriod(period)}</Date>
}

const ComponentAmount: React.FC<ComponentAmountProps> = ({ moneyFlow, isEstimate }) => (
  <>
    {isEstimate ? "~" : ""}
    {moneyFlowHelper.currency(moneyFlow, { style: CurrencyStyle.Detail })}
  </>
)

const PeriodDelta: React.FC<PeriodDeltaProps & ComponentSizeProps> = ({ delta, componentSize }) => {
  const green = colors.accentTurquoise
  const orange = colors.errorDark

  const numberOptions: Intl.NumberFormatOptions = React.useMemo(
    () => ({
      signDisplay: "never",
    }),
    []
  )

  const amountOptions = React.useMemo(
    () => ({
      ...numberOptions,
      style: CurrencyStyle.Detail,
    }),
    [numberOptions]
  )

  const percentOptions = React.useMemo(
    () => ({
      ...numberOptions,
      style: "percent" as const,
    }),
    [numberOptions]
  )

  const displayDelta = React.useMemo(() => {
    if (!delta?.moneyFlow.value) return undefined
    const { percentageOfAmount, moneyFlow } = delta
    const amount = numberHelper.currency(moneyFlow.value, amountOptions)
    const percent = numberHelper.numberFormatter(percentOptions).format(percentageOfAmount / 100)

    const isPeriodPositive = moneyFlow.value.amount >= 0
    const color = isPeriodPositive ? green : orange
    const direction =
      (isPeriodPositive && moneyFlow.businessFlow === Flow.Inbound) ||
      (!isPeriodPositive && moneyFlow.businessFlow === Flow.Outbound)
        ? PointingDirection.Up
        : PointingDirection.Down

    return {
      amount,
      percent,
      color,
      direction,
    }
  }, [delta, amountOptions, percentOptions, green, orange])

  if (!displayDelta) return null

  return (
    <Delta>
      <Chevron
        direction={displayDelta.direction}
        color={displayDelta.color}
        componentSize={componentSize}
      />
      <DeltaPercent color={displayDelta.color} componentSize={componentSize}>
        {displayDelta.percent}
      </DeltaPercent>
    </Delta>
  )
}

const Chevron = styled.div<{ direction: PointingDirection; color: string } & ComponentSizeProps>`
  ${({ color, direction, componentSize }) =>
    themedStyles({
      light: chevronStyles(direction, componentSize.chevronSize, color),
      dark: chevronStyles(
        direction,
        componentSize.chevronSize,
        colorHelper.hexToRgba("#0c404b", 0.7)
      ),
    })}
  position: absolute;
  top: ${({ componentSize }) => componentSize.chevronTop};
  left: ${({ componentSize }) => componentSize.chevronLeft};
`

const Delta = styled.div`
  position: relative;
`

const DeltaPercent = styled.div<{ color: string } & ComponentSizeProps>`
  ${({ color }) =>
    themedStyles({
      light: css`
        color: ${color};
      `,
      dark: css`
        color: ${colorHelper.hexToRgba("#0c404b", 0.7)};
      `,
    })}
  margin-left: 8px;
`
