import * as React from "react"
import promiseHelper, { CancelablePromise } from "@digits-shared/helpers/promises/promiseHelper"

export type CancelablePromiseCallback<T> = (promise: Promise<T>) => Promise<T>

/**
 * Create a promise that will auto-reject when a component unmounts.
 *
 * Can only be used in React Functional Components since it uses `React.useEffect`
 * hook to tap into the components lifecycle.
 */
export default function useCancelablePromise<T>(): CancelablePromiseCallback<T> {
  const promises = React.useRef<CancelablePromise<T>[]>([])

  React.useEffect(() => {
    promises.current = promises.current || []
    return function cancel() {
      promises.current.forEach((p) => p.cancel())
      promises.current = []
    }
  }, [])

  return React.useCallback((promise: Promise<T>) => {
    const cPromise = promiseHelper.makeCancelable(promise)
    promises.current.push(cPromise)
    return cPromise.promise
  }, [])
}
