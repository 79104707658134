import * as React from "react"
import { Report, StatementRowFieldsFragment } from "@digits-graphql/frontend/graphql-bearer"

/*
  INTERFACES
*/

export interface ReportContextProps {
  report?: Report | null
}

export interface SectionProps {
  rows: StatementRowFieldsFragment[]
}

export interface ComponentContextProps {
  isNested: boolean
}

/*
  CONTEXTS
*/

export const ReportContext = React.createContext<ReportContextProps>({})

export default function useReportContext(): ReportContextProps {
  return React.useContext(ReportContext)
}

// Components

export const ComponentContext = React.createContext<ComponentContextProps>({ isNested: false })

export function useComponentContext(): ComponentContextProps {
  return React.useContext(ComponentContext)
}
