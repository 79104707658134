import * as React from "react"
import { UserRoleFullyQualified } from "@digits-shared/helpers/userHelper"
import useSession from "@digits-shared/hooks/useSession"
import FrontendSession from "src/frontend/session"
import { FrontendPermissionModule } from "src/frontend/session/permissionModule"

export interface UserRoleOption {
  id: UserRoleFullyQualified
  description: string
}

export function useUserRole({
  organizationId,
  legalEntityId,
}: {
  organizationId?: string
  legalEntityId?: string
}): UserRoleFullyQualified {
  const { organizations } = useSession<FrontendSession>()

  const organization = React.useMemo(
    () =>
      organizationId || legalEntityId
        ? organizations.find((o) =>
            legalEntityId
              ? o.legalEntities.find((le) => le.id === legalEntityId)
              : o.id === organizationId
          )
        : undefined,
    [organizationId, legalEntityId, organizations]
  )

  const affiliation = React.useMemo(
    () =>
      legalEntityId
        ? organizations?.find((o) => o.affiliations?.find((le) => le.id === legalEntityId))
        : undefined,
    [organizations, legalEntityId]
  )

  return React.useMemo(() => {
    if (affiliation) {
      const { permissions } = affiliation

      switch (true) {
        case permissions.hasUpdatePermission(FrontendPermissionModule.LegalEntities):
          return UserRoleFullyQualified.AffiliationAdmin
        case permissions.hasReadPermission(FrontendPermissionModule.Transactions) &&
          !affiliation.permissions.hasGrantPermission(FrontendPermissionModule.Transactions):
          return UserRoleFullyQualified.AffiliationAssociate
        default:
          return UserRoleFullyQualified.Limited
      }
    }

    if (!organization) return UserRoleFullyQualified.NoAccess

    const { permissions } = organization

    // TODO: Using permission module access as proxies for role. Could we do better?
    switch (true) {
      case permissions.hasUpdatePermission(FrontendPermissionModule.LegalEntities):
        return UserRoleFullyQualified.Admin
      // if the user is not an admin but can update employees, that's enough for now
      // to know they are an Account Manager
      case permissions.hasUpdatePermission(FrontendPermissionModule.Employees):
        return UserRoleFullyQualified.AccountManager
      default:
        return UserRoleFullyQualified.Limited
    }
  }, [organization, affiliation])
}
