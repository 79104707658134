import * as React from "react"
import {
  LayoutComponentType,
  PartyChartConfig,
  useReadPartySummaryByTimeLiveDataQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"

/**
 * Queries for layout live party summary data fitting the dimensional reducer shape: by time.
 */
export function usePartySummaryByTimeLiveData(
  config: PartyChartConfig | undefined | null,
  type: LayoutComponentType
) {
  const viewId = useViewVersion()

  const { data, loading } = useReadPartySummaryByTimeLiveDataQuery({
    variables: {
      viewId,
      config: {
        type,
        partyChart: config,
      },
    },
    skip: !viewId?.viewVersion,
  })

  return React.useMemo(() => {
    const liveData = data?.liveComponentData

    // Resolves the component data union types to the portions we care about
    if (liveData?.__typename === "Chart" && liveData?.entity.__typename === "EntityParty") {
      return { party: liveData.entity, dimensionalSummary: liveData.summary, loading }
    }

    return { party: undefined, dimensionalSummary: undefined, loading }
  }, [data?.liveComponentData, loading])
}
