export const userPreferenceKeys = {
  animationConfigDevTools: "acdt",
  reportFileSourceDevTools: "rfsdt",
  gridDevTools: "gdt",
  limitedUser: "limited-user",
  hideInviteAccountantToast: "hide-invite-accountant-toast",
  viewedAssistant: "viewed-assistant",
  digitsLedgerWorkItems: "dlwi",
  newViewVersionAlert: "nvva",
  reportDocViewer: "rdv",
  mockInvoicingData: "minv",
  mockInvoicingDataStore: "mids",
}
