import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgArrowDown } from "@digits-shared/components/SVGIcons/line/ArrowDown.svg"
import { SvgArrowUp } from "@digits-shared/components/SVGIcons/line/ArrowUp.svg"
import { SvgExpand05 } from "@digits-shared/components/SVGIcons/line/Expand05.svg"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

/*
 * INTERFACES
 */

interface ControlsProps {
  className?: string
  zoomIn: () => void
  zoomOut: () => void
  pageUp: () => void
  pageDown: () => void
  reset: () => void
  showResetButton: boolean
  activePageIndex: number
  pageCount: number
  animating: boolean
}

/*
 * STYLES
 */

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 8px;
  margin-top: 46px;

  .landscape-viewer & {
    margin-top: 0;
    flex-direction: row;
  }
`

const Separator = styled.div`
  height: 1px;
  width: 50%;
  background: rgba(0, 0, 0, 0.1);
`

const ControlButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.secondary};
  font-size: 24px;
  width: 42px;
  height: 42px;
  padding: 1px 6px;
  border-radius: 0;
  user-select: none;
  background: ${colors.transparent};

  &:hover:not(:disabled) {
    cursor: pointer;
    background: ${colors.secondary10};
  }

  &:disabled {
    opacity: 0.3;
  }

  &:active {
    box-shadow: none;
  }

  &:focus {
    outline: none;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.translucentWhite90};
  backdrop-filter: blur(17px);
  border-radius: 20px;

  flex-direction: column;
  ${ControlButton}:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  ${ControlButton}:last-of-type {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .landscape-viewer & {
    flex-direction: row;
    ${ControlButton}:first-of-type {
      border-radius: 60px;
    }

    ${ControlButton}:last-of-type {
      border-radius: 60px;
    }
  }
`

const PageNumber = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.secondary};
  font-size: 12px;
  height: 42px;
`

const ResetIcon = styled(SvgExpand05)`
  width: 20px;
  height: 20px;
  ${svgPathStyles(colors.secondary, 1.5)};
`

const ArrowUp = styled(SvgArrowUp)`
  width: 24px;
  height: 24px;
  ${svgPathStyles(colors.secondary, 1.5)};
`

const ArrowDown = styled(SvgArrowDown)`
  width: 24px;
  height: 24px;
  ${svgPathStyles(colors.secondary, 1.5)};
`

/*
 * COMPONENTS
 */

export const Controls: React.FC<React.PropsWithChildren<ControlsProps>> = ({
  className,
  zoomIn,
  zoomOut,
  pageUp,
  pageDown,
  reset,
  showResetButton,
  activePageIndex,
  pageCount,
  animating,
}) => (
  <ControlsContainer className={className}>
    <ButtonContainer>
      <ControlButton onClick={zoomIn} disabled={animating} css="padding-bottom: 3px;">
        +
      </ControlButton>
      <Separator />
      <ControlButton
        onClick={reset}
        disabled={animating || (!showResetButton && activePageIndex === 0)}
      >
        <ResetIcon />
      </ControlButton>
      <Separator />
      <ControlButton onClick={zoomOut} disabled={animating} css="padding-bottom: 4px">
        &minus;
      </ControlButton>
    </ButtonContainer>

    {pageCount > 1 && activePageIndex >= 0 && (
      <ButtonContainer>
        <ControlButton onClick={pageUp} disabled={animating || activePageIndex < 1}>
          <ArrowUp />
        </ControlButton>
        <PageNumber>
          {activePageIndex + 1} / {pageCount}
        </PageNumber>
        <ControlButton onClick={pageDown} disabled={animating || activePageIndex >= pageCount - 1}>
          <ArrowDown />
        </ControlButton>
      </ButtonContainer>
    )}

    {showResetButton && activePageIndex >= 0 && <ButtonContainer></ButtonContainer>}
  </ControlsContainer>
)
