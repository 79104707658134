import * as React from "react"
import { EntityParty, Interval } from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { hasFirstElement } from "@digits-shared/helpers/arrayHelper"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { PartyAndRoleBalanceSummary } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Components/Search/BalanceSummary"
import { PartyTransactionSummary } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Components/Search/PartyTransactionSummary"
import { SummaryListData } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/WidgetSummaryList"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

export const PartySummary: React.FC<SummaryListData<EntityParty>> = ({ data: party }) => {
  const showBalanceSummary = React.useMemo(
    () =>
      party?.roles?.length
        ? FrontendPartyRole.findByRole(party.roles[0]).showsBalanceSummary
        : undefined,
    [party]
  )

  if (!party || !hasFirstElement(party.roles)) return null

  const origin = dateTimeHelper.todayIntervalOrigin(Interval.Month, 12)
  const role = party.roles[0]

  const { invertValues } = FrontendPartyRole.findByRole(role)

  return (
    <InvertValuesContext.Provider value={invertValues}>
      {showBalanceSummary ? (
        <PartyAndRoleBalanceSummary partyId={party.id} partyRole={role} origin={origin} />
      ) : (
        <PartyTransactionSummary party={party} partyRole={role} origin={origin} />
      )}
    </InvertValuesContext.Provider>
  )
}
