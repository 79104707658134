import { LayoutComponent, LayoutRow, LayoutSection } from "@digits-graphql/frontend/graphql-bearer"
import { produce } from "immer"
import { v4 as UUID } from "uuid"
import QueryBuilder from "src/frontend/components/OS/Springboard/Applications/Search/QueryBuilder"
import { ViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import {
  ComponentMap,
  DeleteComponentStep,
  SortableLayoutComponent,
  TabNames,
} from "src/frontend/components/Shared/Layout/types"
import { PortalAction } from "src/frontend/components/Shared/Portals/State/actions"
import {
  InviteClientsModalState,
  PortalMode,
  PortalState,
} from "src/frontend/components/Shared/Portals/State/types"

export function initialBuilderState(viewVersion: ViewVersion): PortalState {
  return {
    portalMode: PortalMode.Pending,
    portal: undefined,
    confirmDeleteComponent: undefined,
    deleteComponentAnimation: undefined,
    dropAllowed: true,
    componentMap: {},
    textInFocus: false,
    layout: {
      viewKey: viewVersion,
      layoutId: "",
      sections: undefined,
    },
    sidebar: {
      lists: {
        configs: [],
      },
      metrics: {
        configs: [],
      },
      statements: {
        configs: [],
      },
      custom: {
        configs: [],
      },
    },
    layoutLoading: false,
    activeConfig: null,
    activeComponentId: null,
    dragOverlayContent: null,
    dirty: false,
    saveErrorCount: 0,
    employeesWithAccess: [],
    invitedEmployees: [],
    employeesLoading: true,
    showInviteClientsModal: false,
    initialInviteClientsModalState: InviteClientsModalState.InviteClients,
    currentExperience: undefined,
    isPreview: false,
    query: new QueryBuilder().build(),
    emptySearch: false,
  }
}

// This reducer uses a mutation-like style because we're using Immer (produce()) to manage the mutation-free changes.
//
// The alternative of manually updating of this nested layout structure in a mutation-free way is unpleasant.
//
/* eslint-disable max-nested-callbacks */
export const reducer = produce((curState: PortalState, action: PortalAction) => {
  switch (action.type) {
    case "reset": {
      return initialBuilderState(action.viewVersion)
    }
    case "setShowInviteClientsModal": {
      curState.showInviteClientsModal = action.show
      curState.initialInviteClientsModalState =
        action.show && action.initialModalState
          ? action.initialModalState
          : InviteClientsModalState.InviteClients
      break
    }
    case "setEmployees": {
      curState.employeesWithAccess = action.employees
      curState.invitedEmployees = action.invitedEmployees
      curState.employeesLoading = false
      break
    }
    case "setCurrentExperience": {
      curState.currentExperience = action.currentExperience
      break
    }
    case "setPreview": {
      curState.isPreview = action.isPreview
      break
    }
    case "setPortalMode": {
      curState.portalMode = action.mode
      break
    }
    case "setPortal": {
      curState.portal = action.portal
      break
    }
    case "layoutSaved": {
      curState.dirty = false
      curState.saveErrorCount = 0
      break
    }
    case "layoutSaveError": {
      curState.saveErrorCount += 1
      break
    }
    case "layoutLoading": {
      curState.layoutLoading = action.loading
      break
    }
    case "setLayout": {
      curState.layout = action.layout

      // Set the component map to make all components resolvable by ID
      const newMap: ComponentMap = {}

      action.layout.sections?.forEach((s) => {
        s.rows?.forEach((r) => {
          r.components?.forEach((c) => {
            newMap[c.componentId] = c
          })
        })
      })
      curState.componentMap = newMap
      break
    }
    case "addSection": {
      const curSections = curState.layout.sections ?? []
      curState.layout.sections = [...curSections, createSection()]
      break
    }
    case "setDropAllowed": {
      curState.dropAllowed = action.allowed
      break
    }
    case "setActiveComponent": {
      setActiveComponent(curState, action.component, action.removeCurrentActiveFromMap)
      break
    }
    case "dragStart": {
      curState.activeConfig = action.activeConfig

      setActiveComponent(curState, action.activeComponent)
      break
    }
    case "dragCancel": {
      curState.activeConfig = null
      curState.activeComponentId = null
      curState.dragOverlayContent = null
      break
    }
    case "dragEnd": {
      curState.activeConfig = null
      curState.activeComponentId = null
      curState.dragOverlayContent = null
      break
    }
    case "setDragOverlayContent": {
      curState.dragOverlayContent = action.content
      break
    }
    case "dropInRowGutter": {
      curState.dirty = true

      const { layout, componentMap, activeComponentId } = curState
      if (!activeComponentId) return

      const component = componentMap[activeComponentId]
      if (!component) return

      const section = layout.sections?.find((s) => s.sectionId === action.sectionId)
      if (!section) return

      // Remove the component from any row it was previously in
      layout.sections?.forEach((s) => {
        s.rows?.forEach((r) => {
          const rowComponents = r.components ?? []

          if (rowComponents.some((c) => c.componentId === activeComponentId)) {
            if (r.components?.length === 1) {
              // Delete the row if it was the last component in it
              s.rows = s.rows?.filter((row) => row.rowId !== r.rowId)
            } else {
              r.components = rowComponents.filter((c) => c.componentId !== activeComponentId)
            }
          }
        })
      })

      const newRow = createRow([component])
      const rows = section.rows ?? []

      section.rows = [...rows.slice(0, action.index), newRow, ...rows.slice(action.index)]

      curState.dirty = true
      break
    }
    case "dropInComponentGutter": {
      curState.dirty = true

      const { layout, componentMap, activeComponentId } = curState
      if (!activeComponentId) return

      const component = componentMap[activeComponentId]
      if (!component) return

      const section = layout.sections?.find((s) => s.sectionId === action.sectionId)
      if (!section) return

      const row = section.rows?.find((r) => r.rowId === action.rowId)
      if (!row) return

      const index = (row.components ?? []).findIndex((c) => c.componentId === activeComponentId)
      if (index === action.index) return

      // Remove the component from any row it was previously in
      layout.sections?.forEach((s) => {
        s.rows?.forEach((r) => {
          const rowComponents = r.components ?? []

          if (rowComponents.some((c) => c.componentId === activeComponentId)) {
            if (r.components?.length === 1 && r.rowId !== action.rowId) {
              // Delete the row if it was the last component in it
              s.rows = s.rows?.filter((sRow) => sRow.rowId !== r.rowId)
            } else {
              r.components = rowComponents.filter((c) => c.componentId !== activeComponentId)
            }
          }
        })
      })

      // Compensate for the fact that the indexes will have shifted if we removed the component
      // from a spot before where we're trying to insert it
      const insertIndex = index >= 0 && index < action.index ? action.index - 1 : action.index

      const components = row.components ?? []

      row.components = [
        ...components.slice(0, insertIndex),
        component,
        ...components.slice(insertIndex),
      ]

      curState.dirty = true
      break
    }
    case "confirmDeleteComponent": {
      curState.confirmDeleteComponent = {
        componentId: action.componentId,
      }
      break
    }
    case "cancelDeleteComponent": {
      curState.confirmDeleteComponent = undefined
      break
    }
    case "animateDeleteComponent": {
      curState.confirmDeleteComponent = undefined
      curState.deleteComponentAnimation = {
        componentId: action.componentId,
        step: DeleteComponentStep.Shrink,
      }
      break
    }
    case "componentSlideEnded": {
      if (curState.deleteComponentAnimation?.step === DeleteComponentStep.Slide) {
        curState.deleteComponentAnimation.step = DeleteComponentStep.Shrink
      }
      break
    }
    case "deleteComponentShrinkEnded": {
      const animation = curState.deleteComponentAnimation

      if (!animation) return

      animation.step = DeleteComponentStep.Poof
      break
    }
    case "deleteComponentAnimationEnded": {
      curState.deleteComponentAnimation = undefined
      break
    }
    case "deleteComponent": {
      delete curState.componentMap[action.componentId]

      curState.layout.sections?.forEach((s) => {
        s.rows?.forEach((r) => {
          if (r.components?.some((c) => c.componentId === action.componentId)) {
            if (r.components?.length === 1) {
              s.rows = s.rows?.filter((row) => row.rowId !== r.rowId)
            } else {
              r.components = r.components.filter((c) => c.componentId !== action.componentId)
            }
          }
        })
      })
      curState.dirty = true
      curState.textInFocus = false
      break
    }
    case "setConfigs": {
      curState.sidebar[action.tab].configs = action.configs
      break
    }
    case "removeSidebarConfig": {
      // remove config ID from all tabs because we don't know which tab the component is configured for
      TabNames.forEach((tabName) => {
        curState.sidebar[tabName].configs = curState.sidebar[tabName].configs.filter(
          (it) => it.id !== action.id
        )
      })
      break
    }
    case "setQuery": {
      curState.query = action.query
      break
    }
    case "setEmptySearch": {
      curState.emptySearch = action.emptySearch
      break
    }
    case "setConfig": {
      // Update the component config where it resides in the map
      const mapComponent = curState.componentMap[action.componentId]
      if (mapComponent) {
        mapComponent.config = action.config
      }

      // Update the component config where it resides in the layout
      curState.layout.sections?.forEach((s) => {
        s.rows?.forEach((r) => {
          r.components?.forEach((c) => {
            if (c.componentId === action.componentId) {
              c.config = action.config
            }
          })
        })
      })
      if (action.config) curState.dirty = true
      break
    }
    case "textInFocus": {
      curState.textInFocus = action.value
      break
    }
    default:
      TrackJS?.track(`Unhandled portal action: ${action}`)
      break
  }

  return curState
})

function createSection(): LayoutSection {
  return {
    sectionId: UUID(),
    rows: [],
  }
}

function createRow(components: LayoutComponent[] = []): LayoutRow {
  return {
    rowId: UUID(),
    components,
  }
}

function setActiveComponent(
  curState: PortalState,
  activeComponent: SortableLayoutComponent | null,
  removeCurrentActiveFromMap?: boolean
) {
  // Update the component map
  const newMap = {
    ...curState.componentMap,
  }
  if (removeCurrentActiveFromMap && curState.activeComponentId) {
    delete newMap[curState.activeComponentId]
  }
  if (activeComponent) {
    newMap[activeComponent.componentId] = activeComponent
  }
  curState.componentMap = newMap

  curState.activeComponentId = activeComponent?.componentId ?? null
}
