import * as React from "react"
import {
  TopEntitiesConfig,
  useReadTopPartiesComponentDataQuery,
  ViewIdentifier,
} from "@digits-graphql/frontend/graphql-bearer"

/**
 * Queries for layout component data fitting the dimensional reducer shape: time by party.
 */
export function useTopPartiesComponentData(
  config: TopEntitiesConfig,
  layoutId: string,
  viewId: ViewIdentifier,
  dataId: string | undefined | null
) {
  const { data, loading } = useReadTopPartiesComponentDataQuery({
    variables: {
      dataId: dataId ?? "",
      layoutId,
      viewId,
      limit: config.limit,
      sort: config.sort,
    },
    skip: !dataId,
  })

  return React.useMemo(() => {
    const componentData = data?.readComponentData.data

    // Data read from the archive takes precedence.
    // Resolves the component data union type to the portion we care about
    if (componentData?.__typename === "TopEntities") {
      return { data: componentData, loading }
    }

    return { data: undefined, loading }
  }, [data?.readComponentData.data, loading])
}
