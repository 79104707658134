/* eslint-disable max-nested-callbacks */
import * as React from "react"
import {
  MoneyFlow,
  ReportOptionComparison,
  Statement,
  StatementConfig,
  StatementRow,
  StatementRowSummary,
  TransactionSummary,
  ViewIdentifierInput,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper, { DateFormat } from "@digits-shared/helpers/dateTimeHelper"
import { defined } from "@digits-shared/helpers/filters"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import numberHelper from "@digits-shared/helpers/numberHelper"
import useSession from "@digits-shared/hooks/useSession"
import moment from "moment"
import type { CellObject } from "xlsx"
import { useStatementComponentData } from "src/frontend/components/Shared/Layout/Components/Statements/useStatementComponentData"
import { MatchedConfig } from "src/frontend/components/Shared/Layout/types"
import {
  ColumnOptionKey,
  headerFormattedDate,
  headerTitle,
  isBreakdownColumn,
  isDeltaColumn,
  isDoubleColumn,
  overTimeDeltaValue,
  StatementColumns,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/columnTypes"
import { extractAccountDisplayNumber } from "src/frontend/components/Shared/Reports/Report/Components/Statements/toDetailsData"
import { ReportColumns } from "src/frontend/components/Shared/Reports/Report/hooks/useReportDocumentOptions"
import FrontendSession from "src/frontend/session"

const NOOP = () => {}

interface ExportExcelProps {
  title: string
  config: MatchedConfig<"statement">
  layoutId: string
  layoutVersionId: string | undefined | null
  viewId: ViewIdentifierInput
  dataId: string | undefined | null
  options: ReportColumns | undefined
}

export function useExportToExcel({
  title,
  config,
  layoutId,
  layoutVersionId,
  viewId,
  dataId,
  options = {
    columnKeys: [],
    columns: {},
    deltaMonthOverMonthPeriods: 0,
    hideAccountNumbers: false,
  },
}: ExportExcelProps) {
  const {
    currentLegalEntity: { name },
  } = useSession<FrontendSession>()
  const { statement } = useStatementComponentData(config, layoutId, layoutVersionId, viewId, dataId)

  const exportExcel = React.useCallback(() => {
    if (!statement) return

    const { hideAccountNumbers } = options
    const currencyFormatter = numberHelper.numberFormatter({ style: "currency", currency: "USD" })
    const periodName = dateTimeHelper.displayNameForIntervalOriginRange(config.statement.origin)

    import("xlsx").then((XLSX) => {
      const showDisplayNumber =
        !hideAccountNumbers && statement.rows.some((r) => extractAccountDisplayNumber(r.details))

      const today = moment().format("MMMM DD, YYYY [at] hh:mm A")
      const rows = mapRows(statement, config.statement, options)

      /* generate worksheet and workbook */
      const worksheet = XLSX.utils.json_to_sheet(rows, { origin: "A6" })
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, title)

      /* fix headers */
      XLSX.utils.sheet_add_aoa(worksheet, [[name]], { origin: "A1" })
      XLSX.utils.sheet_add_aoa(worksheet, [[title]], { origin: "A2" })
      XLSX.utils.sheet_add_aoa(worksheet, [[periodName]], { origin: "A3" })
      XLSX.utils.sheet_add_aoa(worksheet, [[`Exported from digits.com on ${today}`]], {
        origin: "A4",
      })

      // Hide titles for category name and display number.
      XLSX.utils.sheet_add_aoa(worksheet, [[""]], { origin: "A6" })
      if (showDisplayNumber) {
        XLSX.utils.sheet_add_aoa(worksheet, [[""]], { origin: "B6" })
      }

      /* calculate column width */
      const columnsWidths = rows.reduce((widths, row) => {
        Object.entries(row).forEach(([key, value], col) => {
          widths[col] = Math.max(
            widths[col] ?? 0,
            key.length + 2,
            typeof value.v === "number"
              ? currencyFormatter.format(value.v).length
              : (value.v?.toString().length ?? 0)
          )
        })
        return widths
      }, [] as number[])

      worksheet["!cols"] = columnsWidths.map((width) => ({ wch: width }))

      /* create an XLSX file and try to save to xlsx */
      XLSX.writeFile(workbook, `${title} - ${periodName} (${name}).xlsx`, {
        compression: true,
        cellStyles: true,
      })
    })
  }, [config.statement, name, options, statement, title])

  if (!statement) return NOOP

  return exportExcel
}

function mapRows(statement: Statement, config: StatementConfig, options: ReportColumns) {
  return statement.rows
    .map((row) => {
      const {
        depth,
        leafCategorySummary,
        parentCategorySummary,
        parentCategoryTitle,
        sectionSummary,
        sectionTitle,
        details,
      } = row

      const displayNumber = options.hideAccountNumbers
        ? undefined
        : displayNumberCell(extractAccountDisplayNumber(details))

      /**
       * SECTION TITLE
       * parentCategorySummary
       *   leafCategorySummary
       * ---------------------
       * parentCategorySummary
       * ---------------------
       * sectionSummary
       */

      // Revenue, Accounts Receivable (A/R), etc
      if (leafCategorySummary) {
        return mapColumns(
          labelsRow(formatLabel(depth, leafCategorySummary.label), displayNumber),
          config,
          options,
          row,
          leafCategorySummary
        )
      }

      // Total Business Development, Total Payroll Expenses, etc
      if (parentCategorySummary)
        return mapColumns(
          labelsRow(formatLabel(depth, parentCategorySummary.label), displayNumber),
          config,
          options,
          row,
          parentCategorySummary
        )

      // Total Liabilities and Equity, Total Equity, etc
      if (sectionSummary)
        return mapColumns(
          labelsRow(formatLabel(depth, sectionSummary.label), displayNumber),
          config,
          options,
          row,
          sectionSummary
        )

      // Current Assets, Business Development, Payroll Expenses, etc
      if (parentCategoryTitle)
        return labelsRow(formatLabel(depth, parentCategoryTitle.title), displayNumber)

      // INCOME, OPERATING EXPENSES, etc
      if (sectionTitle) return labelsRow(formatLabel(depth, sectionTitle.title), displayNumber)

      return undefined
    })
    .filter(defined)
}

type Row = Record<string, CellObject>

function mapColumns(
  row: Row,
  config: StatementConfig,
  options: ReportColumns,
  statementRow: StatementRow,
  rowSummary: StatementRowSummary
) {
  const { origin } = config
  const { columnKeys, deltaMonthOverMonthPeriods, columns } = options
  const hasBreakdownColumns = isBreakdownColumn("deltaMonthOverMonth", columns)

  const summaries = (extractPeriodDetails(statementRow)?.hover.history.time?.slice() ?? []).sort(
    (s1, s2) => s1.summary.period.startedAt - s2.summary.period.startedAt
  )

  if (hasBreakdownColumns) {
    const count = hasBreakdownColumns ? Math.max(1, deltaMonthOverMonthPeriods) : 0
    summaries.slice(-(count + 1)).forEach(({ summary }) => mapSummaryToCell(row, summary))
  } else {
    const date = headerFormattedDate(origin)
    row[date] = formatMoneyFlow(rowSummary.moneyFlow)
  }

  columnKeys.forEach((optionKey: keyof StatementColumns) => {
    if (!isDeltaColumn(optionKey, columns)) return

    const deltaSummaries = extractPeriodDetails(statementRow, optionKey)?.hover.history.time
    if (!deltaSummaries?.length) return

    const comparison = columns[optionKey] as ReportOptionComparison

    const currentValue = deltaSummaries[0]?.summary?.total.moneyFlow

    const lookbackOffset = optionKey === "yearToDate" ? 0 : 1
    const summary = deltaSummaries[lookbackOffset]?.summary
    const nextValue = summary?.total.moneyFlow

    if (!currentValue || !nextValue) return

    const { moneyFlow, deltaAmount, percentValue } = overTimeDeltaValue(
      optionKey,
      currentValue,
      nextValue,
      statementRow.deltas
    )

    if (isDoubleColumn(optionKey, comparison)) {
      const deltaHeader = headerTitle(optionKey, ReportOptionComparison.Total, origin)
      row[deltaHeader] = formatDeltaValue(
        ReportOptionComparison.Total,
        moneyFlow,
        deltaAmount,
        percentValue
      )
    }

    const valueHeader = headerTitle(optionKey, comparison, origin)
    row[valueHeader] = formatDeltaValue(comparison, moneyFlow, deltaAmount, percentValue)
  })

  return row
}
function labelsRow(label: CellObject, displayNumber?: CellObject): Row {
  const newRow: Row = {}
  // use numeric indices to guarantee that these columns will always be in the right order
  if (displayNumber) {
    newRow[0] = displayNumber
  }
  newRow[1] = label
  return newRow
}

function mapSummaryToCell(row: Row, summary: TransactionSummary): Row {
  const { total, period } = summary
  const date = dateTimeHelper.displayNameFromPeriod(period, DateFormat.Default)
  row[date] = formatMoneyFlow(total.moneyFlow)
  return row
}

function formatDeltaValue(
  comparison: ReportOptionComparison,
  moneyFlow: MoneyFlow | undefined,
  deltaAmount: MoneyFlow | undefined,
  percentValue: number | undefined
): CellObject {
  switch (comparison) {
    case ReportOptionComparison.Total:
    case ReportOptionComparison.InvalidComparison:
      return formatMoneyFlow(moneyFlow)

    case ReportOptionComparison.Percent:
      return formatPercentValue(percentValue)

    case ReportOptionComparison.Amount:
      return formatMoneyFlow(deltaAmount)
  }
}

function displayNumberCell(displayNumber?: string | null): CellObject | undefined {
  if (!displayNumber) return undefined
  return {
    v: displayNumber ?? "",
    t: "s",
  }
}

function formatLabel(depth: number, label: string): CellObject {
  const text = depth ? label : label.toLocaleUpperCase()
  const spaces = new Array(depth * 4).join(" ")
  return {
    v: `${spaces}${text}`,
    t: "s",
  }
}

function formatMoneyFlow(moneyFlow?: MoneyFlow | null): CellObject {
  const { amount, currencyMultiplier } = moneyFlowHelper.toMonetaryValue(moneyFlow)

  // First, apply the currency multiplier to obtain the real value,
  // ensuring that a "zero-value" Monetary value doesn't display as $NaN
  const val = amount && currencyMultiplier ? amount / currencyMultiplier : 0

  return {
    v: val,
    t: "n",
    z: '"$"#,##0.00_)',
  }
}

function formatPercentValue(percentValue?: number | null): CellObject {
  const val = percentValue ? percentValue : 0
  return {
    v: val,
    t: "n",
    z: '0"%"',
  }
}

function extractPeriodDetails(statementRow: StatementRow, optionKey?: ColumnOptionKey) {
  switch (optionKey) {
    case "yearToDate":
    case "deltaYearToDate":
      return statementRow?.ytdDetails
    default:
      return statementRow.details
  }
}
