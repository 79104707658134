import * as React from "react"
import { FetchResult } from "@apollo/client"
import {
  Maybe,
  ReportDocumentOptions,
  UpdateReportOptionsMutation,
} from "@digits-graphql/frontend/graphql-bearer"
import { useReportOptionsContext } from "src/frontend/components/Shared/Layout/Components/Statements/ReportOptionsContext"
import { useReportPackageMode } from "src/frontend/components/Shared/Reports/Packages/Viewer/ReportPackageContext"
import {
  useIsActivelyCommenting,
  useReportThreadsReposition,
} from "src/frontend/components/Shared/Reports/ReportComments/hooks"

export function useCollapsingSections(
  options?: Maybe<ReportDocumentOptions>,
  mutation?: (
    o: ReportDocumentOptions
  ) => Promise<FetchResult<UpdateReportOptionsMutation> | void> | undefined
) {
  const isCommenting = useIsActivelyCommenting()
  const threadsReposition = useReportThreadsReposition()
  const mode = useReportPackageMode()
  // Represents the collapsed sections stored in the database. Used when the report package
  // is in EDIT mode.
  const storedCollapsedSections = React.useMemo(
    () => ({ sections: options?.collapsedSections?.sections ?? [] }),
    [options?.collapsedSections?.sections]
  )

  // Holds a local copy of the collapsed sections that is used when not in EDIT mode
  const [localCollapsedSections, setLocalCollapsedSections] = React.useState(
    options?.collapsedSections?.sections ?? []
  )

  // Update the local copy of collapsed sections when the stored value changes
  React.useEffect(() => {
    if (mode === "VIEW") return

    setLocalCollapsedSections(storedCollapsedSections.sections)
  }, [mode, storedCollapsedSections])

  return React.useMemo(() => {
    // Collapse/expand toggle function used when the package is not in EDIT mode
    const localToggle = (...rowIds: string[]) => {
      threadsReposition()
      setLocalCollapsedSections((prevState) => {
        let newState = prevState.slice()
        rowIds.forEach((rowId) => {
          const rowIndex = newState.indexOf(rowId)
          if (rowIndex === -1) {
            newState = newState.concat(rowId)
          } else {
            newState.splice(rowIndex, 1)
          }
        })
        return newState
      })
    }

    // Collapse/expand toggle function used when the package is in EDIT mode
    const toggleMutation = (...rowIds: string[]) => {
      if (!mutation) return

      threadsReposition()

      let newList = storedCollapsedSections.sections.slice()
      rowIds.forEach((rowId) => {
        const rowIndex = newList.indexOf(rowId)
        if (rowIndex === -1) {
          newList = newList.concat(rowId)
        } else {
          newList.splice(rowIndex, 1)
        }
      })

      return mutation({
        collapsedSections: {
          sections: newList,
        },
      })
    }

    const setLocalCollapsed = (...rowIds: string[]) => {
      threadsReposition()
      setLocalCollapsedSections(rowIds)
    }

    const setCollapsedSections = (...rowIds: string[]) => {
      if (!mutation) return
      threadsReposition()

      return mutation({
        collapsedSections: {
          sections: rowIds,
        },
      })
    }

    if (isCommenting) {
      return {
        toggleCollapsedSection: localToggle,
        setCollapsedSections: setLocalCollapsed,
        collapsedSections: { sections: [] },
      }
    }

    // If we're in VIEW mode, row collapsing is still possible, and we still need to
    // track that state. We track modifications locally so that changes aren't written
    // back to the DB.
    if (mode === "VIEW" || !mutation) {
      return {
        toggleCollapsedSection: localToggle,
        setCollapsedSections: setLocalCollapsed,
        collapsedSections: { sections: localCollapsedSections },
      }
    }

    return {
      toggleCollapsedSection: toggleMutation,
      setCollapsedSections: setCollapsedSections,
      collapsedSections: storedCollapsedSections,
    }
  }, [
    isCommenting,
    localCollapsedSections,
    mode,
    mutation,
    storedCollapsedSections,
    threadsReposition,
  ])
}

/**
 * Given a statement row ID, returns whether it is part of the set of collapsed rows.
 * Internally translates summary row IDs into their corresponding title row IDs so that
 * since summary rows are what are displayed when a group of rows is collapsed.
 */
export function useIsSectionCollapsed(rowId: string): boolean {
  const isCommenting = useIsActivelyCommenting()
  const { collapsedSections } = useReportOptionsContext()
  const collapsingId = useCorrespondingTitleRowId(rowId)

  return React.useMemo(
    () => !isCommenting && collapsedSections.sections.includes(collapsingId),
    [collapsedSections.sections, collapsingId, isCommenting]
  )
}

/**
 * Returns true if the current row can be toggled expanded or collapsed.
 * Internally translates summary row IDs into their corresponding title row IDs so that
 * since summary rows are what are displayed when a group of rows is collapsed.
 */
export function useCanToggleCollapse(rowId: string) {
  const isCollapsed = useIsSectionCollapsed(rowId)
  const isActivelyCommenting = useIsActivelyCommenting()

  return React.useMemo(() => {
    if (isActivelyCommenting) return false

    if (isCollapsed) {
      return rowId.endsWith("-summary") || rowId.endsWith("Summary")
    }

    return rowId.endsWith("-title") || rowId.endsWith("Title")
  }, [isActivelyCommenting, isCollapsed, rowId])
}

/**
 * Translates summary row IDs into their corresponding title row ID.
 */
export function useCorrespondingTitleRowId(rowId: string) {
  return rowId.replace(/-summary$/, "-title").replace(/Summary$/, "Title")
}
