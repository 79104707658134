import React from "react"
import { useReadBillingOrganizationPlanQuery } from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import FrontendSession from "src/frontend/session/FrontendSession"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

export function useBillingOrganizationPlan(organizationId?: string) {
  const session = useSession<FrontendSession>()
  const hasReadBillingPermission = useHasPermission({
    source: FrontendPermissionSource.Organization,
    module: FrontendPermissionModule.Billing,
    flag: JWTPermissionFlag.Read,
  })

  const organization = React.useMemo(
    () => session.findOrganizationById(organizationId ?? ""),
    [session, organizationId]
  )

  const result = useReadBillingOrganizationPlanQuery({
    variables: {
      organizationId: organizationId ?? "",
    },
    fetchPolicy: "network-only",
    skip: !organizationId || !hasReadBillingPermission || !organization?.hasAffiliations,
    context: { noBatch: true },
  })

  return React.useMemo(() => {
    const plan = result?.data?.readBillingOrganizationPlan
    return { plan, loading: result.loading }
  }, [result])
}
