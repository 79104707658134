import * as React from "react"
import {
  CategoryChartConfig as Config,
  LayoutComponentType,
} from "@digits-graphql/frontend/graphql-bearer"
import { toSortedTimeseriesValues } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ChartType, SizingProps } from "src/frontend/components/Shared/Layout/types"
import { useRemoveConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/ComponentConfigContext"
import { BarChartConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Charts/BarChartConfig"
import { LineChartConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Charts/LineChartConfig"
import { useCategorySummaryByTimeLiveData } from "src/frontend/components/Shared/Portals/hooks/useCategorySummaryByTimeLiveData"

/*
  INTERFACES
*/

interface CategoryChartConfigProps extends SizingProps {
  config: Config
  componentType: LayoutComponentType
  chartType: ChartType
  skipAnimations: boolean
}

/*
  COMPONENTS
*/
export const CategoryChartConfig: React.FC<CategoryChartConfigProps> = ({
  config,
  componentType,
  chartType,
  height,
  skipAnimations,
}) => {
  const removeConfig = useRemoveConfig()

  const { category, dimensionalSummary, loading } = useCategorySummaryByTimeLiveData(
    config,
    componentType
  )

  const timeseries = React.useMemo(
    () => toSortedTimeseriesValues(dimensionalSummary?.time),
    [dimensionalSummary?.time]
  )

  // remove this component if the results are empty
  React.useEffect(() => {
    if (!loading && !dimensionalSummary?.time?.length) {
      removeConfig()
    }
  }, [dimensionalSummary, loading, removeConfig])

  switch (chartType) {
    case ChartType.BalanceSummary:
      return (
        <LineChartConfig
          name={category?.name}
          timeseries={timeseries}
          skipAnimations={skipAnimations}
          height={height}
        />
      )
    case ChartType.TransactionSummary:
      return (
        <BarChartConfig
          name={category?.name}
          timeseries={timeseries}
          skipAnimations={skipAnimations}
          height={height}
        />
      )
  }
}
