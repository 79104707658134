import * as React from "react"
import { WebNotification } from "@digits-graphql/frontend/graphql-bearer"
import { produce } from "immer"
import { SystemWebNotification } from "src/frontend/types/SystemWebNotification"

export const ALERT_ITEM_VERTICAL_SPACING = 10

export interface AlertsState {
  alerts: Array<WebNotification | SystemWebNotification>
}

const INITIAL_STATE: AlertsState = {
  alerts: [],
}

export type AlertsAction =
  | { type: "Reset" }
  | { type: "AddAlert"; alert: SystemWebNotification | WebNotification }
  | { type: "RemoveAlert"; id: string }
  | { type: "SetHeight"; id: string; height: number }

export interface ChromeAlertContextVal {
  reset: () => void
  addAlert: (alert: SystemWebNotification | WebNotification | undefined) => void
  removeAlert: (id: string) => void
  alerts: Array<WebNotification | SystemWebNotification>
}

export const ChromeAlertContext = React.createContext<ChromeAlertContextVal>({
  reset: () => {},
  addAlert: () => {},
  removeAlert: () => {},
  alerts: [],
})

const MAX_DISPLAYED_ALERTS = 5

export const ChromeAlertContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = React.useReducer(alertsReducer, INITIAL_STATE)

  const reset = React.useCallback(() => {
    dispatch({ type: "Reset" })
  }, [])

  const addAlert = React.useCallback((alert: SystemWebNotification) => {
    dispatch({ type: "AddAlert", alert })
  }, [])

  const removeAlert = React.useCallback((id: string) => {
    dispatch({ type: "RemoveAlert", id })
  }, [])

  const alerts = React.useMemo(() => state.alerts.slice(0, MAX_DISPLAYED_ALERTS), [state.alerts])

  const value = React.useMemo(
    (): ChromeAlertContextVal => ({
      reset,
      addAlert,
      removeAlert,
      alerts,
    }),
    [reset, addAlert, removeAlert, alerts]
  )

  return <ChromeAlertContext.Provider value={value}>{children}</ChromeAlertContext.Provider>
}

export function useChromeAlertContext() {
  return React.useContext(ChromeAlertContext)
}

const alertsReducer = produce((state: AlertsState, action: AlertsAction) => {
  switch (action.type) {
    case "Reset": {
      return INITIAL_STATE
    }
    case "AddAlert": {
      if (!action.alert) break

      // ignore duplicated alerts
      if (state.alerts.find((alert) => alert.id === action.alert.id)) break

      state.alerts = [...state.alerts, action.alert]
      break
    }
    case "RemoveAlert": {
      state.alerts = state.alerts.filter((alert) => alert.id !== action.id)
      break
    }
  }
  // Return the mutated (or untouched) immer proxy object
  return state
})
