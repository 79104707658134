import * as React from "react"
import { usePrevious } from "react-use"
import { DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import { DetailsViewAction } from "@digits-shared/components/Router/DigitsRoute"
import useRouter from "@digits-shared/hooks/useRouter"
import { useModuleName } from "src/frontend/components/Shared/Contexts/useModuleName"
import routes from "src/frontend/routes"

export interface ChromeStructure {
  hasActiveDetailsView: boolean
  // Tracks a stack of details view that are in separate modules that been visited.
  // Is cleared when no details view are active.
  detailsViewStack: DigitsLocation[]
}

export const ChromeStructureContext = React.createContext<ChromeStructure>({
  hasActiveDetailsView: false,
  detailsViewStack: [],
})

export function useChromeStructure() {
  return React.useContext(ChromeStructureContext)
}

export function useBuildChromeStructure(): ChromeStructure {
  const { location, history } = useRouter()
  const structure = React.useRef<ChromeStructure>({
    hasActiveDetailsView: routes.pathIsDetailsView(location.name),
    detailsViewStack: [],
  })

  const moduleName = useModuleName()
  const previousModuleName = usePrevious(moduleName)

  return React.useMemo(() => {
    const previousStructure = { ...structure.current }
    // At this point we are resetting the Ref so all returns have the new value
    structure.current = {
      hasActiveDetailsView: routes.pathIsDetailsView(location.name),
      detailsViewStack: structure.current.detailsViewStack,
    }
    // If we are not in an active details view, we can short circuit.
    if (!structure.current.hasActiveDetailsView) {
      structure.current.detailsViewStack = []
      return structure.current
    }

    // Determine we are coming back to the last location in our stored stack. If we are, pop it off.
    let justPopped = false
    if (
      structure.current.detailsViewStack[structure.current.detailsViewStack.length - 1]
        ?.fullPathname === location.fullPathname
    ) {
      structure.current.detailsViewStack.pop()
      justPopped = true
    }

    // Determine if we should add a new location in our stack of details views visited. This will
    // allow us to know if we should go back to a previous details view someone was viewing or if
    // we should go back to the content view. We'll add to our stack if we match the following
    // conditions:
    //
    //   - If there was not a details view just pooped off. Otherwise the other conditions
    //     will pass and we add back on the stack what we just popped off.
    //   - There is a previous location to compare against.
    //   - It is an active details view and we were previously on a details view.
    //   - The route is changing the module we are viewing.
    //
    // The module name check should prevent us from adding a details view stack that is in the same
    // module: e.g. transaction details -> a different transaction details
    if (
      !justPopped &&
      history.previousLocation &&
      previousStructure.hasActiveDetailsView &&
      structure.current.hasActiveDetailsView &&
      previousModuleName !== moduleName &&
      routes[history.previousLocation.name]?.config.detailsViewAction === DetailsViewAction.Push &&
      history.action === "PUSH"
    ) {
      structure.current.detailsViewStack.push(history.previousLocation)
    }

    return structure.current
  }, [location, history.previousLocation, history.action, previousModuleName, moduleName])
}
