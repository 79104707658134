import { Permission } from "@digits-graphql/frontend/graphql-bearer"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import { useHasPermissionFromObjectSharing } from "src/frontend/hooks/useHasPermissionFromObjectSharing"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

/**
 * Determines whether the current user has permissions to add a comment
 * (Grants) based on either:
 *   - permissions granted by object comment itself, or
 *   - permissions on the current legal entity (through organization or affiliation)
 */
export function useCanComment(module: FrontendPermissionModule) {
  // "Comment" permission from the session's JWT for the module in question
  const hasPermissionFromLegalEntity = useHasPermission({
    module,
    source: FrontendPermissionSource.LegalEntity,
    flag: JWTPermissionFlag.Comment,
  })

  // Check if the permission granted by object sharing allows for managing
  // sharing settings for the current object sharing context.
  const hasPermissionFromObjectSharing = useHasPermissionFromObjectSharing(
    module,
    Permission.CommentRead,
    Permission.CommentShare,
    Permission.FullAccess,
    Permission.SensitiveFullAccess
  )

  return hasPermissionFromObjectSharing || hasPermissionFromLegalEntity
}
