import * as React from "react"
import { DimensionSortMode } from "@digits-graphql/frontend/graphql-bearer"

export function useCategoriesListCountDisplay(
  categoryTypeName: string,
  sortMode: DimensionSortMode,
  invertValues: boolean
) {
  return React.useMemo(() => {
    let sortModeName
    switch (sortMode) {
      case DimensionSortMode.Total:
        sortModeName = ""
        break
      case DimensionSortMode.Growing:
        sortModeName = invertValues ? "Shrinking" : "Growing"
        break
      case DimensionSortMode.Shrinking:
        sortModeName = invertValues ? "Growing" : "Shrinking"
        break
    }
    return [sortModeName, categoryTypeName].filter((n) => n).join(" ")
  }, [sortMode, categoryTypeName, invertValues])
}
