import { Link } from "react-router-dom"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgDownload01 } from "@digits-shared/components/SVGIcons/line/Download01.svg"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { ReportPackageDocumentDownloader } from "src/frontend/components/Shared/Reports/Report/Downloader/Downloader"

export const REPORT_PACKAGE_ITEM_BORDER_RADIUS = 20

/*
  STYLES
*/

export const ReportPackageLink = styled(Link)<{ $disabled?: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}
`

const PACKAGE_DOCUMENT_STYLES = css`
  position: relative;
  border: 0.5px solid ${colors.transparent};
  border-radius: 50vh;
  font-weight: ${fonts.weight.medium};
  font-size: 12px;
  line-height: 11px;
  display: flex;
  align-items: center;
  color: ${colors.translucentSecondary80};
  padding: 3px 0;
  word-break: break-word;
  cursor: pointer;

  &:hover {
    color: ${colors.secondary};
  }
`

export const PackageCentering = styled.div<{ isClickable?: boolean }>`
  display: flex;
  width: 100%;

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `}
`

export const PackageDetails = styled.div`
  position: relative;
  display: flex;
  width: 60%;
  align-items: flex-start;
  margin-top: 8px;
`

export const PackageDetailsBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  z-index: -1;
  border-radius: ${REPORT_PACKAGE_ITEM_BORDER_RADIUS}px;

  &::before {
    position: absolute;
    padding: 75% 0;
    width: 115%;
    border-radius: 50%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "";
  }
`

export const PackageDocument = styled.div`
  ${PACKAGE_DOCUMENT_STYLES}
`

export const PackageDocumentLink = styled(Link)`
  ${PACKAGE_DOCUMENT_STYLES}
`

export const PackageDocumentName = styled.div`
  flex: 1;
  font-size: 10px;
  line-height: 11px;
`

const PACKAGE_DOCUMENT_ICON_STYLES = css`
  ${svgPathStyles(colors.secondary, 1.5)};
  opacity: 0;
  cursor: pointer;

  ${PackageDocument}:hover &, ${PackageDocumentLink}:hover & {
    opacity: 1;
  }
`

export const PackageDocumentDownloadIcon = styled(SvgDownload01)`
  width: 11px;
  height: 11px;
  ${PACKAGE_DOCUMENT_ICON_STYLES};
`

export const Downloader = styled(ReportPackageDocumentDownloader)`
  display: flex;
  width: 100%;
`

export const PackageDocumentDownloadContainer = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      ${PackageDocument} {
        pointer-events: none;
      }

      ${PackageDocumentDownloadIcon} {
        ${svgIconStyles(colors.regentGray)};
      }
    `}
`
export const PackageTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 21px 0 25px;
  padding-right: 15px;
  flex: 1;
`

export const PackageTitlePeriod = styled(ReportPackageLink)`
  font-weight: ${fonts.weight.heavy};
  font-size: 16px;
  line-height: 22px;
  word-break: break-word;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const PackageStatus = styled.div`
  font-weight: ${fonts.weight.medium};
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
`

export const PackageSubtitle = styled.div`
  font-weight: ${fonts.weight.heavy};
  font-size: 12px;
  line-height: 18px;
  color: ${colors.translucentSecondary80};
  word-break: break-word;
`

export const PackagePreparer = styled.div`
  font-size: 10px;
  line-height: 16px;
  color: ${colors.translucentSecondary70};
  word-break: break-word;
`

export const PackageLabels = styled.div`
  display: flex;
  margin-top: 5px;
  gap: 5px;
`

export const PackageContents = styled.div<{ isHidden?: boolean }>`
  position: relative;
  flex: 1;
  padding: 15px 15px 15px 25px;

  background: linear-gradient(270.19deg, rgba(0, 0, 0, 0.0096) 0.99%, rgba(0, 0, 0, 0) 84.86%);
  box-shadow: 5px 0 9px -4px rgba(0, 0, 0, 0.15) inset;
  border-radius: 0 ${REPORT_PACKAGE_ITEM_BORDER_RADIUS}px ${REPORT_PACKAGE_ITEM_BORDER_RADIUS}px 0px;

  display: flex;
  align-items: center;
`

export const PackageContentsTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${fonts.weight.heavy};
  font-size: 10px;
  line-height: 11px;
  text-transform: uppercase;
  color: ${colors.translucentSecondary60};
  margin-bottom: 5px;
  min-height: 18px;
`

export const PackageContentNote = styled.div`
  font-size: 10px;
`

export const ReportPackageItemContainer = styled.div<{ isSelected?: boolean }>`
  background: linear-gradient(rgba(255, 255, 255, 0.74) 12.73%, rgba(255, 255, 255, 0.43) 90.37%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: ${REPORT_PACKAGE_ITEM_BORDER_RADIUS}px;

  position: relative;
  background-clip: padding-box;

  border: 0.5px solid
    ${({ isSelected }) =>
      isSelected ? colors.translucentNeonGreen70 : "rgba(255, 255, 255, 0.05)"};

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`
