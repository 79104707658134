import * as React from "react"
import { Theme } from "@digits-shared/themes/theme"
import { useThemeMode } from "@digits-shared/themes/ThemeContext"

type FnParams = readonly unknown[]

export type ThemedConstants<R> = {
  [Theme.Light]: R
  [Theme.Dark]: R
  [Theme.Print]?: R
}

export type ThemedFunctions<P extends FnParams, R> = {
  [Theme.Light]: (...args: P) => R
  [Theme.Dark]: (...args: P) => R
  [Theme.Print]?: (...args: P) => R
}

/**
 * Accepts an object whose keys match each theme defined in the Theme enum. Each value should be
 * constant
 */
export function useThemedConstant<R = object | undefined>(constants: ThemedConstants<R>): R {
  const mode = useThemeMode()
  return React.useMemo(() => {
    if (mode === Theme.Print) {
      return constants[mode] ?? constants.light
    }
    return constants[mode]
  }, [constants, mode])
}

/**
 * Accepts an object whose keys match each theme defined in the Theme enum. Each value should be
 * a function that is evaluated and returns a themed values
 */
export function useThemedFunction<P extends FnParams, R = object>(
  functions: ThemedFunctions<P, R>,
  ...args: P
): R {
  const mode = useThemeMode()
  return React.useMemo(() => {
    if (mode === Theme.Print) {
      return (functions[mode] ?? functions.light)(...args)
    }
    return functions[mode](...args)
  }, [functions, mode, args])
}
