import * as React from "react"
import { LayoutComponent, LayoutComponentType } from "@digits-graphql/frontend/graphql-bearer"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { ComponentOptionsContextProvider } from "src/frontend/components/Shared/Reports/Report/Options/ReportOptionsContext"
import { ContextPath } from "src/frontend/components/Shared/Reports/Report/Viewer/UseContextPath"

interface ComponentContextVal {
  component: LayoutComponent
  componentSize?: ComponentSize
}

const ComponentContext = React.createContext<ComponentContextVal>({
  component: { componentId: "", config: { type: LayoutComponentType.Text } },
})

export const ComponentContextProvider: React.FC<
  React.PropsWithChildren<Required<ComponentContextVal>>
> = ({ children, component, componentSize }) => {
  const context = React.useMemo(
    () => ({
      component,
      componentSize,
    }),
    [component, componentSize]
  )

  return (
    <ComponentContext.Provider value={context}>
      <ComponentOptionsContextProvider>
        <ContextPath contextId={component.componentId}>{children}</ContextPath>
      </ComponentOptionsContextProvider>
    </ComponentContext.Provider>
  )
}

export function useComponentContext() {
  return React.useContext(ComponentContext)
}
