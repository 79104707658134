import * as React from "react"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { MAIN_CONTENT_BACKGROUND } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/DetailsPopUp"

/*
  STYLES
*/

const PopOverTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: -70px;
    bottom: -15px;
    left: -10px;
    right: -10px;
    z-index: -1;
  }
`

const TabContents = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const TabsTitles = styled.div`
  display: flex;
  overflow-x: scroll;
  background: ${MAIN_CONTENT_BACKGROUND};
`

const TabTitle = styled.div<{ active: boolean }>`
  font-size: 12px;
  font-weight: ${fonts.weight.heavy};
  color: ${colors.translucentSecondary40};
  text-transform: uppercase;
  padding: 7px 10px 8px;
  min-width: 65px;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;

  &:last-child {
    border-right: none;
  }

  ${({ active }) =>
    active
      ? css`
          color: ${colors.secondary};
          position: relative;

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 3px;
            background: ${colors.neonGreen};
          }
        `
      : css`
          &:hover {
            color: ${colors.translucentSecondary80};
          }
        `}
`

/*
  INTERFACES
*/

interface PopOverTabsProps {
  children: React.ReactElement<TabProps>[]
  className?: string
}

interface PopOverTabsTitlesProps {
  activeIndex: number
  onClick: React.SetState<number>
  children: React.ReactElement<TabProps>[]
}

interface TabProps {
  title: string
  active?: boolean
  children?: React.ReactNode
}

/*
  COMPONENTS
*/

export const PopOverTabs: React.FC<PopOverTabsProps> = ({ className, children }) => {
  const [activeIndex, setActiveIndex] = React.useState(0)

  React.useEffect(() => {
    const childIndex = Math.max(
      children.findIndex((c) => !!c.props.active),
      0
    )
    setActiveIndex(childIndex)
  }, [children])

  const activeChild = children[activeIndex]

  return (
    <PopOverTabsContainer className={className}>
      <PopOverTabsTitles activeIndex={activeIndex} onClick={setActiveIndex}>
        {children}
      </PopOverTabsTitles>
      {activeChild}
    </PopOverTabsContainer>
  )
}

export const PopOverTab: React.FC<TabProps> = ({ title, children }) => (
  <TabContents>{children}</TabContents>
)

const PopOverTabsTitles: React.FC<PopOverTabsTitlesProps> = ({
  activeIndex,
  onClick,
  children,
}) => {
  // hide the title if there is only a single tab being displayed
  if (children.length === 1) {
    return null
  }

  return (
    <TabsTitles>
      {children.map((child, index) => (
        <TabTitle
          key={`tab_${index}`}
          active={index === activeIndex}
          onClick={onClick.bind(undefined, index)}
        >
          {child.props.title}
        </TabTitle>
      ))}
    </TabsTitles>
  )
}
