import * as React from "react"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { useLayoutContext } from "src/frontend/components/Shared/Assistant/Messages/Layout/Context"
import { useTopPartiesComponentData } from "src/frontend/components/Shared/Assistant/Messages/Layout/hooks/useTopPartiesComponentData"
import { TopPeriodTitle } from "src/frontend/components/Shared/Assistant/Messages/Layout/List/TopPeriodTitle"
import { MatchedComponent } from "src/frontend/components/Shared/Layout/types"
import { TopPartiesList } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopPartiesList"
import { PartyTransactionSummary } from "src/frontend/types"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

/*
  COMPONENTS
*/

export const TopParties: React.FC<{ component: MatchedComponent<"topEntities"> }> = ({
  component,
}) => {
  const { layoutId, viewKey } = useLayoutContext()

  const {
    config: { topEntities: config },
    dataId,
  } = component

  const role = React.useMemo(
    () => (config.partyRole ? FrontendPartyRole.findByRole(config.partyRole) : undefined),
    [config.partyRole]
  )

  const invertValues = React.useMemo(() => role?.invertValues ?? false, [role?.invertValues])

  const countDisplay = React.useMemo(() => {
    if (!role) return undefined

    return config.limit < 2 ? role.displayName : role.pluralDisplayName
  }, [config.limit, role])

  const { data, loading } = useTopPartiesComponentData(config, layoutId, viewKey, dataId)

  const partySummaries: PartyTransactionSummary[] | undefined = React.useMemo(
    () =>
      data?.entities.party.map((p) => ({
        party: p.partyObject,
        summary: p.summary,
        categoryNames: p.by.category.map((c) => c.categoryObject.name),
      })),
    [data]
  )

  if (!countDisplay) return null

  return (
    <InvertValuesContext.Provider value={invertValues}>
      <TopPeriodTitle intervalOrigin={config?.origin}>Top {countDisplay}</TopPeriodTitle>
      <TopPartiesList padding={10} partySummaries={partySummaries} loading={loading} />
    </InvertValuesContext.Provider>
  )
}
