import * as React from "react"
import {
  GRAPHQL_TRACE_KEY,
  useGraphQLTracer,
} from "@digits-shared/components/Contexts/GraphQLTracerContext"
import { PageHeader, PageHeaderTitle } from "@digits-shared/components/UI/Elements/Container"
import { Switch } from "@digits-shared/components/UI/Elements/Switch"
import envHelper from "@digits-shared/helpers/envHelper"
import useForceUpdate from "@digits-shared/hooks/useForceUpdate"
import useSession from "@digits-shared/hooks/useSession"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { DIGITS_EMPLOYEE_OVERRIDE } from "@digits-shared/session/Session"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import FrontendSession from "src/frontend/session"
import { userPreferenceKeys } from "src/shared/config/userPreferenceKeys"

/*
  STYLES
*/

const Preferences = styled.div`
  width: var(--application-pane-full-width);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  justify-content: space-between;
  margin-bottom: 48px;
`

const PreferenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
`

const PreferenceName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-weight: ${fonts.weight.heavy};
  margin-bottom: 5px;
`

const PreferenceDescription = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  font-weight: ${fonts.weight.book};
`

const PreferenceStatus = styled.div<{ isEnabled: boolean }>`
  color: ${({ isEnabled }) => (isEnabled ? colors.secondary : colors.translucentSecondary50)};
  font-weight: ${fonts.weight.normal};
  margin-left: 10px;
`

/*
  COMPONENTS
*/

export const DigitsEmployeePreferences: React.FC = () => (
  <>
    <PageHeader>
      <PageHeaderTitle>Digits Employee-Only</PageHeaderTitle>
    </PageHeader>
    <Preferences>
      <AnimationConfig />
      <GraphQLTrace />
      <DigitsEmployeeOverride />
      <LimitedUser />
      <GridOverlay />
      <DigitsLedgerWorkItems />
      <NewViewVersionAlert />
      <ReportDocumentViewer />
      <MockInvoicingData />
      <MockInvoicingDataStore />
    </Preferences>
  </>
)

const AnimationConfig: React.FC = () => {
  const session = useSession<FrontendSession>()
  const [_, setUpdated] = React.useState<Date>(new Date())

  const toggle = React.useCallback(
    (enabled: boolean) => {
      session.animationConfigDevToolsEnabled = enabled
      // Have to change something locally to force a rerender of the switch UI
      setUpdated(new Date())
    },
    [session]
  )

  return (
    <Preference
      name="Animation Controls Dev Tools"
      description="Enable Animation Controls dev tool. Displays animation controls window."
      isEnabled={session.animationConfigDevToolsEnabled}
      isLoading={false}
      toggle={toggle}
    />
  )
}

const GraphQLTrace: React.FC = () => {
  const session = useSession<FrontendSession>()
  const graphQLTracer = useGraphQLTracer()

  const toggle = React.useCallback(
    (enable: boolean) => {
      session.setUserPreference(GRAPHQL_TRACE_KEY, enable)
      graphQLTracer.toggleDebugger()
    },
    [session, graphQLTracer]
  )

  return (
    <Preference
      name="GraphQL Trace Dev Tools"
      description="Enable GraphQL trace dev tools to display debug info about network requests."
      isEnabled={session.getBooleanUserPreference(GRAPHQL_TRACE_KEY, false)}
      isLoading={false}
      toggle={toggle}
    />
  )
}

const DigitsEmployeeOverride: React.FC = () => {
  const session = useSession<FrontendSession>()

  const toggle = React.useCallback(
    (enable?: boolean) => {
      session.setUserPreference(DIGITS_EMPLOYEE_OVERRIDE, enable)
      window.location.reload()
    },
    [session]
  )

  // DO NOT ALLOW THIS IN PRODUCTION. Use Doppelgangers instead
  if (envHelper.isProduction()) return null

  return (
    <Preference
      name="Digits Employee"
      description="Current user will be treated as Digits employee. Client-side only, this wont affect your JWT or API requests."
      isEnabled={session.isDigitsEmployee}
      isLoading={false}
      toggle={toggle}
    >
      <PreferenceDescription
        css="margin-top: 6px; font-weight: bold; cursor: pointer"
        onClick={toggle.bind(undefined, undefined)}
      >
        Clear override from storage
      </PreferenceDescription>
    </Preference>
  )
}

const LimitedUser: React.FC = () => {
  const session = useSession<FrontendSession>()
  const { value: isLoading, toggle: toggleLoading } = useStateBoolean(false)

  const toggle = React.useCallback(
    (enable: boolean) => {
      toggleLoading()
      session.setUserPreference(userPreferenceKeys.limitedUser, enable)
      window.location.reload()
    },
    [session, toggleLoading]
  )

  return (
    <Preference
      name="Limited User"
      description="Removes all the Permissions from your user session. Client-side only, this wont affect your JWT or API requests."
      isEnabled={session.getBooleanUserPreference(userPreferenceKeys.limitedUser, false)}
      isLoading={isLoading}
      toggle={toggle}
    />
  )
}

const GridOverlay: React.FC = () => {
  const session = useSession<FrontendSession>()
  const { value: isLoading, toggle: toggleLoading } = useStateBoolean(false)

  const toggle = React.useCallback(
    (enable: boolean) => {
      toggleLoading()
      session.setUserPreference(userPreferenceKeys.gridDevTools, enable)
      window.location.reload()
    },
    [session, toggleLoading]
  )

  return (
    <Preference
      name="Grid Overlay"
      description="Displays an overlay of the design system grid."
      isEnabled={session.getBooleanUserPreference(userPreferenceKeys.gridDevTools, false)}
      isLoading={isLoading}
      toggle={toggle}
    />
  )
}

const ReportDocumentViewer: React.FC = () => {
  const session = useSession<FrontendSession>()
  const [_, refresh] = useForceUpdate()

  const toggle = React.useCallback(
    (enable: boolean) => {
      refresh()
      session.setUserPreference(userPreferenceKeys.reportDocViewer, enable)
    },
    [session, refresh]
  )

  return (
    <Preference
      name="Report Document Viewer"
      description="Prototype to embed documents in reports."
      isEnabled={session.getBooleanUserPreference(userPreferenceKeys.reportDocViewer, false)}
      isLoading={false}
      toggle={toggle}
    />
  )
}

const DigitsLedgerWorkItems: React.FC = () => {
  const session = useSession<FrontendSession>()
  const { value: isLoading, toggle: toggleLoading } = useStateBoolean(false)

  const toggle = React.useCallback(
    (value: boolean) => {
      toggleLoading()
      session.setUserPreference(userPreferenceKeys.digitsLedgerWorkItems, value)
      window.location.reload()
    },
    [session, toggleLoading]
  )

  return (
    <Preference
      name="Digits Ledger Work Items"
      description="Displays Digits ledger work items instead of QBO work items in Transaction Review / Action Items / Boost Badge Counts (Not Fully Implemented Yet!)."
      isEnabled={session.getBooleanUserPreference(userPreferenceKeys.digitsLedgerWorkItems, false)}
      isLoading={isLoading}
      toggle={toggle}
    />
  )
}

const NewViewVersionAlert: React.FC = () => {
  const session = useSession<FrontendSession>()
  const { value: isLoading, toggle: toggleLoading } = useStateBoolean(false)

  const toggle = React.useCallback(
    (value: boolean) => {
      toggleLoading()
      session.setUserPreference(userPreferenceKeys.newViewVersionAlert, value)
      window.location.reload()
    },
    [session, toggleLoading]
  )

  return (
    <Preference
      name="New View Version Alert"
      description="Displays an alert when a new view version is available for the current legal entity."
      isEnabled={session.getBooleanUserPreference(userPreferenceKeys.newViewVersionAlert, false)}
      isLoading={isLoading}
      toggle={toggle}
    />
  )
}

const MockInvoicingData: React.FC = () => {
  const session = useSession<FrontendSession>()
  const { value: isLoading, toggle: toggleLoading } = useStateBoolean(false)

  const toggle = React.useCallback(
    (value: boolean) => {
      toggleLoading()
      session.setUserPreference(userPreferenceKeys.mockInvoicingData, value)
      window.location.reload()
    },
    [session, toggleLoading]
  )

  return (
    <Preference
      name="Mock Invoicing Data"
      description="Mock Invoicing APIs via service worker"
      isEnabled={session.getBooleanUserPreference(userPreferenceKeys.mockInvoicingData, false)}
      isLoading={isLoading}
      toggle={toggle}
    />
  )
}

const MockInvoicingDataStore: React.FC = () => {
  const session = useSession<FrontendSession>()
  const { value: isLoading, toggle: toggleLoading } = useStateBoolean(false)

  const toggle = React.useCallback(
    (value: boolean) => {
      toggleLoading()
      session.setUserPreference(userPreferenceKeys.mockInvoicingDataStore, value)
      window.location.reload()
    },
    [session, toggleLoading]
  )

  return (
    <Preference
      name="Mock Invoicing Data Store"
      description="Mock Invoicing data with in-memory store"
      isEnabled={session.getBooleanUserPreference(userPreferenceKeys.mockInvoicingDataStore, false)}
      isLoading={isLoading}
      toggle={toggle}
    />
  )
}

const Preference: React.FC<{
  name: string
  description: string
  isEnabled: boolean
  isLoading: boolean
  toggle: (on: boolean) => void
  children?: React.ReactNode
}> = ({ name, description, isEnabled, isLoading, toggle, children }) => (
  <PreferenceContainer>
    <PreferenceName>
      {name}
      <PreferenceStatus isEnabled={isEnabled && !isLoading}>
        {isLoading ? "Updating…" : isEnabled ? "Enabled" : "Disabled"}
      </PreferenceStatus>
    </PreferenceName>
    <PreferenceDescription>
      {description}
      <div css="margin-left: 10px;">
        <Switch on={isEnabled} onChange={toggle} />
      </div>
    </PreferenceDescription>
    {children}
  </PreferenceContainer>
)
