import { DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import { StaticRoutes } from "@digits-shared/components/Router/DigitsRoute"
import {
  DigitsBrowserRouter,
  DigitsRouterProps,
} from "@digits-shared/components/Router/DigitsRouter"
import { withSessionContext } from "@digits-shared/session/withSessionContext"
import { UnregisterCallback } from "history"
import { FrontendStaticRoutes } from "src/frontend/routes"
import FrontendSession from "src/frontend/session"

interface FrontendSessionProps {
  session: FrontendSession
}

class FrontendDigitsRouter<SRC extends StaticRoutes> extends DigitsBrowserRouter<
  SRC,
  FrontendSessionProps
> {
  unregisterContextListener: UnregisterCallback

  constructor(props: DigitsRouterProps<SRC> & FrontendSessionProps) {
    super(props)
    this.unregisterContextListener = this.history.listen(this.setCurrentContext)
    this.setCurrentContext(this.history.location as DigitsLocation)
  }

  componentDidMount() {
    super.componentDidMount?.()
    this.unregisterContextListener = this.history.listen(this.setCurrentContext)
    this.setCurrentContext(this.history.location as DigitsLocation)
  }

  componentWillUnmount() {
    super.componentWillUnmount?.()
    this.unregisterContextListener()
  }

  setCurrentContext = (location: DigitsLocation) => {
    this.setCurrentOrganization(location)
    this.setCurrentLegalEntity(location)
  }

  // If we have not set the current organization by the end of this method,  its because we can't
  // find the org slug in the url (meaning its not a org route) or the user doesn't have access
  // to the org. Not setting it will ensure
  setCurrentOrganization = (location: DigitsLocation) => {
    const {
      routes,
      session,
      session: { currentOrganization },
    } = this.props

    const route = routes[location.name]
    const params = route ? route.getParametersFromPath(location.pathname) : undefined

    const paramsOrgSlug = params?.orgSlug
    if (!paramsOrgSlug) {
      if (currentOrganization) session.currentOrganization = undefined
      return
    }

    // If we don't have a current organization or the current org does not match the url
    if (!currentOrganization || currentOrganization.slug !== paramsOrgSlug) {
      // Check if org slug is valid org
      const organization = session.findOrganizationBySlug(paramsOrgSlug)

      // If there is no matching LE, clear it from the session
      if (!organization) {
        session.currentOrganization = undefined
      }

      // If it is valid, check if it's the current org and set it if not
      if (organization && (!currentOrganization || currentOrganization.slug !== paramsOrgSlug)) {
        session.currentOrganization = organization
      }
    }
  }

  // If we have not set the current legal entity by the end of this method, its because we can't
  // find the LE slug in the url (meaning its not a `@` route) or the user doesn't have access
  // to the legal entity. Not setting it will ensure
  setCurrentLegalEntity = (location: DigitsLocation) => {
    const {
      routes,
      session,
      session: { currentLegalEntity },
    } = this.props

    const route = routes[location.name]
    const params = route ? route.getParametersFromPath(location.pathname) : undefined

    const paramsLESlug = params?.leSlug
    if (!paramsLESlug) {
      // If we aren't on a legal entity page, clear it
      if (currentLegalEntity) session.clearCurrentLegalEntity()
      return
    }

    // If we don't have a current legal entity or the current LE does not match the url
    if (!currentLegalEntity || currentLegalEntity.slug !== paramsLESlug) {
      // Check if LE slug is valid LE
      const legalEntity = this.getMatchingLegalEntity(paramsLESlug)

      // If there is no matching LE, clear it from the session entirely
      if (!legalEntity) {
        session.clearCurrentLegalEntity()
      }

      // If it is valid, check if it's the current LE and set it if not
      if (legalEntity && (!currentLegalEntity || currentLegalEntity.slug !== paramsLESlug)) {
        session.currentLegalEntity = legalEntity
      }
    }
  }

  getMatchingLegalEntity = (paramsLESlug: string) => {
    const {
      session,
      session: { doppelganger },
    } = this.props

    if (doppelganger?.hasDashboardAccess) return session.findLegalEntityBySlug(paramsLESlug)

    const legalEntity = session.findDashboardLegalEntityBySlug(paramsLESlug)
    if (legalEntity) return legalEntity

    // We allow some basic functionality of viewing a new or pending LE for affiliates
    return session.findOnboardingAffiliateLegalEntityBySlug(paramsLESlug)
  }
}

export default withSessionContext<DigitsRouterProps<FrontendStaticRoutes>>(FrontendDigitsRouter)
