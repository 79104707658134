import * as React from "react"
import { LoadingBlock, LoadingCircle } from "@digits-shared/components/Loaders"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import {
  EXPANDED_CLASS_NAME,
  HOVERABLE_CLASS_NAME,
  SELECTED_CLASS_NAME,
} from "@digits-shared/components/UI/Table/tableConstants"
import userHelper, { UserDisplayInfo } from "@digits-shared/helpers/userHelper"
import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

/*
  STYLES
*/

export const UserSummaryStyled = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  color: ${colors.white};
  ${themedStyles({
    light: css`
      color: ${colors.secondary};
    `,
    dark: css`
      color: ${colors.translucentWhite80};
    `,
  })}

  .${SELECTED_CLASS_NAME} &,
  ${"." + HOVERABLE_CLASS_NAME}:not(.${EXPANDED_CLASS_NAME}):hover & {
    ${themedStyles({
      light: css`
        color: ${colors.secondary};
      `,
      dark: css`
        color: ${colors.white};
      `,
    })}
  }
`

export const ContactInfo = styled.div`
  max-width: 100%;
  flex: 1;
  overflow: hidden;
  margin-left: 10px;
`

export const Name = styled.div`
  font-weight: ${fonts.weight.medium};
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
`

export const Email = styled.div`
  font-weight: ${fonts.weight.normal};
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
`

/*
  INTERFACES
*/

export interface UserSummaryProps {
  user: UserDisplayInfo | undefined | null
  borderColor?: string
  margin?: string
  fontSize?: number
  size?: number | string
  className?: string
  children?: React.ReactNode
}

/*
  COMPONENTS
*/

export const LoadingUserSummary: React.FC<{ margin?: string }> = (props) => {
  const margin = props.margin || "0 10px 0 0"
  return (
    <UserSummaryStyled>
      <LoadingCircle width="35px" height="35px" margin={margin} />
      <ContactInfo>
        <Name>
          <LoadingBlock width="80px" height="12px" />
        </Name>
        <Email>
          <LoadingBlock width="60px" height="12px" />
        </Email>
      </ContactInfo>
    </UserSummaryStyled>
  )
}

export const UserSummary: React.FC<UserSummaryProps> = ({
  user,
  borderColor,
  margin,
  fontSize,
  size,
  className,
  children,
}) => {
  if (!user) return null

  return (
    <UserSummaryStyled className={className}>
      <ColorIcon
        size={size}
        imageUrl={user.avatarUrl}
        fallbackUser={user}
        borderColor={borderColor}
        margin={margin}
        fontSize={fontSize}
      />
      <ContactInfo>
        <Name>{userHelper.displayName(user)}</Name>
        <Email>{user.primaryEmailAddress || user.emailAddress}</Email>
      </ContactInfo>
      {children}
    </UserSummaryStyled>
  )
}
