import * as React from "react"
import { Flow } from "@digits-graphql/frontend/graphql-bearer"
import { interpolateRgbBasis } from "d3-interpolate"
import { TimeseriesLineChartStyle } from "src/frontend/components/OS/Shared/Charts/styles"
import { TimeseriesValues } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"

interface GradientProps {
  uuid: string
  hideAxis?: boolean
  timeseries: TimeseriesValues
  chartStyle: TimeseriesLineChartStyle
}

export const TimeseriesGradient: React.FC<GradientProps> = ({ uuid, timeseries, chartStyle }) => {
  const { stops } = useGradients(timeseries, chartStyle)

  return (
    <>
      <linearGradient
        id={`line-stroke-gradient-${uuid}`}
        x1="0%"
        y1="0%"
        x2="100%"
        y2="0%"
        // Allow gradients to span different paths.
        gradientUnits="userSpaceOnUse"
      >
        {stops.map((stop, index) => (
          <stop
            key={`stop${index}`}
            offset={`${(index * 100) / (stops.length - 1)}%`}
            stopColor={stop}
          />
        ))}
      </linearGradient>
      <linearGradient
        id={`line-cover-gradient-${uuid}`}
        x1="0%"
        y1="0%"
        x2="100%"
        y2="0%"
        gradientUnits="userSpaceOnUse"
      >
        {stops.map((stop, index) => (
          <stop
            key={`stop${index}`}
            offset={`${(index * 100) / (stops.length - 1)}%`}
            stopColor={stop}
            stopOpacity={0.4}
          />
        ))}
      </linearGradient>
    </>
  )
}

export function useGradients(timeseries: TimeseriesValues, chartStyle: TimeseriesLineChartStyle) {
  const { length } = timeseries
  const first = timeseries[0]
  const last = timeseries[length - 1]
  return React.useMemo(() => {
    const moneyOut =
      (first?.moneyFlow.value.amount !== 0.0 && first?.moneyFlow.businessFlow === Flow.Outbound) ||
      (last?.moneyFlow.value.amount !== 0.0 && last?.moneyFlow.businessFlow === Flow.Outbound)

    const trendingUp =
      length < 2 ||
      Math.abs(first?.moneyFlow.value.amount || 0) <= Math.abs(last?.moneyFlow.value.amount || 0)

    const showOrange =
      ((moneyOut && trendingUp) || (!moneyOut && !trendingUp)) && !chartStyle.lineGradientStops

    const gradientStart = showOrange
      ? chartStyle.lineNegativeGradientStart
      : chartStyle.lineGradientStart
    const gradientEnd = showOrange ? chartStyle.lineNegativeGradientEnd : chartStyle.lineGradientEnd

    const stops = chartStyle.lineGradientStops ?? [gradientStart, gradientEnd]

    const interpolate = interpolateRgbBasis(stops)
    const interpolateIndex = (index: number) => interpolate(index / (length - 1))
    return { gradientStart, gradientEnd, stops, interpolateIndex }
  }, [chartStyle, first, last, length])
}
