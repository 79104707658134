import * as React from "react"
import { Route } from "react-router-dom"
import { ApolloClient, ApolloProvider } from "@apollo/client"
import { SessionContext } from "@digits-shared/components/Contexts/SessionContext"
import { StylesContextProvider } from "@digits-shared/components/Contexts/StylesContextProvider"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import useRouter from "@digits-shared/hooks/useRouter"
import { Theme } from "@digits-shared/themes"
import { domMax, LazyMotion } from "framer-motion"
import { GlobalStyles } from "src/shared/initializers/global"
import { ApolloClientScopeProvider } from "src/frontend/ApolloClientScope"
import { FrontendClient } from "src/frontend/components/Client"
import FrontendDigitsRouter from "src/frontend/components/Router"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"
import "digits-shared/static/css/fonts.css"

export const session = new FrontendSession()

/**
 * Trailing slashes break our DigitsLocation/DigitsRoute matching, so this ensures
 * that paths we arrive at are normalized to not end with trailing slashes.
 */
const RemoveTrailingSlashRedirect: React.FC = () => {
  const {
    location: { pathname, search },
  } = useRouter()

  const endsWithTrailingSlash = React.useMemo(() => /\/$/.test(pathname), [pathname])

  if (pathname !== "/" && endsWithTrailingSlash) {
    return (
      <LoggedRedirect
        name="RemoveTrailingSlashRedirect"
        to={pathname.replace(/\/+$/, "") + search}
      />
    )
  }

  return null
}

// Memoize to prevent re-renders when graphQLTracer updates. The client prop should never
// change so this effectively will never update.
export const Main: React.FC<{
  apolloClient: ApolloClient<{}>
}> = React.memo(({ apolloClient }) => (
  <ApolloProvider client={apolloClient}>
    <ApolloClientScopeProvider>
      <SessionContext.Provider value={session}>
        <StylesContextProvider mode={Theme.Light}>
          <GlobalStyles />
          <FrontendDigitsRouter routes={routes}>
            <Route component={RemoveTrailingSlashRedirect} />
            <LazyMotion features={domMax} strict>
              <FrontendClient />
            </LazyMotion>
          </FrontendDigitsRouter>
        </StylesContextProvider>
      </SessionContext.Provider>
    </ApolloClientScopeProvider>
  </ApolloProvider>
))
