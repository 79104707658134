import * as React from "react"
import { DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import useRouter from "@digits-shared/hooks/useRouter"
import routes from "src/frontend/routes"

export function useSharedObjectInfo() {
  const { location, params } = useRouter()

  return React.useMemo(() => {
    const route: DigitsRoute | undefined = routes[location.name]
    return route?.sharedObjectInfo()
    // including params for dep list to recompute share object info if we change to
    // a new route for the same object type (e.g. category 1 -> category 2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.name, params])
}
