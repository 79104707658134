import * as React from "react"
import {
  ListEmployeesDocument,
  useSendManagedOperatorInvitationMutation,
} from "@digits-graphql/frontend/graphql-bearer"
import { hasExactlyOne } from "@digits-shared/helpers/arrayHelper"
import useLazyTimeout from "@digits-shared/hooks/useLazyTimeout"
import useSession from "@digits-shared/hooks/useSession"
import { InviteClientAction } from "src/frontend/components/OS/Springboard/Applications/ClientPortal/InviteFlow/State/actions"
import {
  ClientStatus,
  ContactWithStatus,
  InviteClientState,
} from "src/frontend/components/OS/Springboard/Applications/ClientPortal/InviteFlow/State/types"
import { InviteClientsModalState } from "src/frontend/components/Shared/Portals/State/types"
import FrontendSession from "src/frontend/session"

export function useSendInvites(
  state: InviteClientState,
  dispatch: React.Dispatch<InviteClientAction>
) {
  const { contextObject } = state
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const [clientMessage, setClientMessage] = React.useState<string>("")

  const [sendManagedOperatorInvitationMutation] = useSendManagedOperatorInvitationMutation()

  // Sends an individual invitation
  const sendInvite = React.useCallback(
    (client: ContactWithStatus) => {
      sendManagedOperatorInvitationMutation({
        variables: {
          recipient: client,
          legalEntityId,
          message: clientMessage,
          contextObject,
        },
        refetchQueries: [ListEmployeesDocument],
      })
        .then(() => {
          dispatch({ type: "updateStatus", client, status: ClientStatus.Sent })
        })
        .catch((e) => {
          const status = e.message.includes("You are already a member")
            ? ClientStatus.FailedSelfInvite
            : ClientStatus.Failed
          dispatch({ type: "updateStatus", client, status })
          TrackJS?.track(`Invitation email for client portal failed: ${e.message}.
              Legal Entity ID: ${legalEntityId}
              Email: ${client.emailAddress}`)
        })
    },
    [sendManagedOperatorInvitationMutation, legalEntityId, clientMessage, contextObject, dispatch]
  )

  // Transitions out of the sending invitations state
  const completeSending = React.useCallback(() => {
    const hadFailure = state.invitees.some((invitee) =>
      [ClientStatus.Failed, ClientStatus.FailedSelfInvite].includes(invitee.status)
    )
    const failedSelfInvite =
      hasExactlyOne(state.invitees) && state.invitees[0].status === ClientStatus.FailedSelfInvite

    dispatch({ type: "setSendingInvitations", sendingInvitations: false })

    if (hadFailure) {
      dispatch({ type: "resetInvitees" })
      dispatch({
        type: "setErrorMessage",
        message: failedSelfInvite ? (
          <>
            The email address entered is already associated with your account. Please invite a user
            with a new email address.
          </>
        ) : (
          <>
            <b>We're sorry — Invites failed to send.</b> We have notified our support team and they
            are looking into the issue.
          </>
        ),
      })
    } else {
      dispatch({ type: "setModalState", modalState: InviteClientsModalState.Done })
    }
  }, [dispatch, state.invitees])

  // Allows us to delay a minimum of 1s between sends
  const [delayedSendInvite] = useLazyTimeout(sendInvite, 1000)

  // Allows us to delay a minimum of 1s after the last send, before we exit the sending state
  const [delayedCompleteSending] = useLazyTimeout(completeSending, 1000)

  // Monitors the sending state and the list of invitees needing to be sent.
  React.useEffect(() => {
    if (!state.sendingInvitations) return

    const nextInviteeToSend = state.invitees.filter(
      (invitee) => invitee.status === ClientStatus.Pending
    )[0]

    if (nextInviteeToSend) {
      delayedSendInvite(nextInviteeToSend)
    } else {
      delayedCompleteSending()
    }
  }, [delayedCompleteSending, delayedSendInvite, state.invitees, state.sendingInvitations])

  // The callback used to begin the invitation sending process
  const sendInvites = React.useCallback(() => {
    // Clear errors in the case of a retry.
    dispatch({ type: "setErrorMessage", message: undefined })

    if (state.validClientInForm) {
      dispatch({ type: "addInvitee", client: state.validClientInForm })
    }
    dispatch({ type: "setSendingInvitations", sendingInvitations: true })
  }, [state.validClientInForm, dispatch])

  return React.useMemo(
    () => ({
      clientMessage,
      setClientMessage,
      sendInvites,
    }),
    [clientMessage, setClientMessage, sendInvites]
  )
}
