import * as React from "react"
import { useReadLegalEntityMetadataQuery } from "@digits-graphql/frontend/graphql-bearer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"

export function useFirstTransactionTimestamp() {
  const viewKey = useViewVersion()
  const { data } = useReadLegalEntityMetadataQuery({
    variables: {
      viewKey,
    },
    skip: !viewKey,
  })

  return React.useMemo(
    () => data?.readLegalEntityMetadata?.firstTransactionTimestamp || 0,
    [data?.readLegalEntityMetadata?.firstTransactionTimestamp]
  )
}
