import * as React from "react"
import {
  LayoutComponentType,
  ProviderType,
  StatementConfig,
} from "@digits-graphql/frontend/graphql-bearer"
import { ComponentHeaderTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { SizingProps } from "src/frontend/components/Shared/Layout/types"
import { usePortalStatementLiveData } from "src/frontend/components/Shared/Portals/hooks/usePortalStatementLiveData"
import { reportKindDisplayName } from "src/frontend/components/Shared/Reports/Packages/shared"
import { StatementSize } from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import { Statement } from "src/frontend/components/Shared/Reports/Report/Components/Statements/Statement"
import { useDataSourcePreference } from "src/frontend/hooks/useDataSourcePreference"

/*
  STYLES
*/

/*
  INTERFACES
 */

interface StatementConfigProps extends SizingProps {
  config: StatementConfig
}

/*
  COMPONENTS
*/

export const StatementComponentConfig: React.FC<StatementConfigProps> = ({ config }) => {
  const { statement, loading } = usePortalStatementLiveData(config, LayoutComponentType.Statement)
  const dataSourcePreference = useDataSourcePreference(ProviderType.QuickBooks)

  return (
    <>
      <ComponentHeaderTitle
        title={reportKindDisplayName(config.kind, dataSourcePreference?.taxForm)}
        componentSize={ComponentSize.Small}
        periodName={undefined}
      />
      <Statement statement={statement} size={StatementSize.Condensed} loading={loading} />
    </>
  )
}
