import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgMessageChatSquare } from "@digits-shared/components/SVGIcons/line/MessageChatSquare.svg"
import { Textarea, TextareaStyled } from "@digits-shared/components/UI/Elements/Form/TextArea"
import objectHelper from "@digits-shared/helpers/objectHelper"
import { UseFormTrackingVal } from "@digits-shared/hooks/useFormTracking"
import colors from "@digits-shared/themes/colors"
import fonts, { H2Text } from "@digits-shared/themes/typography"
import styled from "styled-components"
import { AddClientAction } from "src/frontend/components/OS/Header/AddClient/actions"
import { AddClientState } from "src/frontend/components/OS/Header/AddClient/types"

export enum SendInviteField {
  message = "message",
}

/*
  STYLES
*/

const StyledForm = styled.form<{ disabled: boolean }>`
  background: ${colors.white};
  border-radius: 8px;
  border: 0.5px solid ${colors.white};
  display: flex;
  flex-direction: column;

  transition: background-color 200ms ease-in-out;
  padding: 10px 18px 0;
  margin-bottom: 12px;
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`

const TitleRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 2px;
  margin-bottom: 16px;
`

const TextFieldWrapper = styled.div`
  width: 100%;
  ${TextareaStyled} {
    height: 100%;
  }
`

export const ValueRow = styled.div`
  position: relative;
`

const MessageIcon = styled(SvgMessageChatSquare)`
  height: 16px;
  width: 16px;
  ${svgPathStyles(colors.white, 2)};
`

const ModuleIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  align-self: center;
  background: ${colors.accentBlue};
`

export const TitleText = styled(H2Text)`
  font-weight: ${fonts.weight.normal};
`

export const SendInviteForm: React.FC<{
  state: AddClientState
  dispatch: React.Dispatch<AddClientAction>
  formTracking: UseFormTrackingVal<SendInviteField>
}> = ({ state, dispatch, formTracking }) => {
  const { onFieldChanged, onFieldBlurred, isFieldInvalid, fieldHasValidValue, getFieldValue } =
    formTracking

  const invalid = objectHelper
    .keysOf(SendInviteField)
    .some((f) => !fieldHasValidValue(SendInviteField[f]))

  React.useEffect(() => {
    dispatch({ type: "setAddClientDetailsFormValid", valid: !invalid })
  }, [dispatch, invalid])

  const formRef = React.useRef<HTMLFormElement>(null)

  return (
    <StyledForm ref={formRef} disabled={false}>
      <TitleRow>
        <ModuleIconContainer>
          <MessageIcon />
        </ModuleIconContainer>
        <TitleText>Message</TitleText>
      </TitleRow>
      <Row>
        <TextFieldWrapper>
          <Textarea
            placeholder="Type your message here..."
            name={SendInviteField.message}
            defaultValue={getFieldValue(SendInviteField.message) ?? undefined}
            rows={16}
            autoFocus
            autoComplete="off"
            onChange={onFieldChanged}
            onBlur={onFieldBlurred}
            isInvalid={isFieldInvalid(SendInviteField.message)}
            $variant="bordered"
            required
          />
        </TextFieldWrapper>
      </Row>
    </StyledForm>
  )
}
