import { AddClientAction } from "src/frontend/components/OS/Header/AddClient/actions"
import {
  AddClientModalState,
  AddClientState,
} from "src/frontend/components/OS/Header/AddClient/types"

export const DEFAULT_STATE: AddClientState = {
  modalState: AddClientModalState.ClientDetails,
  addClientDetailsFormValid: false,
  sending: false,
}

export function reducer(curState: AddClientState, action: AddClientAction) {
  const nextState: AddClientState = { ...curState }

  switch (action.type) {
    case "setModalState": {
      nextState.modalState = action.modalState
      return nextState
    }

    case "setErrorMessage": {
      nextState.errorMessage = action.message
      return nextState
    }

    case "setAddClientDetailsFormValid": {
      nextState.addClientDetailsFormValid = action.valid
      return nextState
    }

    case "setClientIcon": {
      nextState.icon = action.icon
      nextState.iconBase64 = action.icon.slice(action.icon.indexOf(",") + 1)
      return nextState
    }

    case "removeClientIcon": {
      nextState.icon = undefined
      nextState.iconBase64 = undefined
      return nextState
    }

    case "setClientDetails": {
      nextState.clientDetails = action.details
      return nextState
    }

    case "setSending": {
      nextState.sending = action.value
      return nextState
    }

    case "reset": {
      nextState.clientDetails = undefined
      nextState.message = undefined
      return nextState
    }

    default: {
      return curState
    }
  }
}
