import * as React from "react"
import {
  DimensionSortMode,
  Statement,
  StatementConfig,
  useReadReportStatementComponentDataQuery,
  ViewIdentifier,
  ViewIdentifierInput,
} from "@digits-graphql/frontend/graphql-bearer"
import useConstant from "@digits-shared/hooks/useConstant"
import { useStatementLiveData } from "src/frontend/components/Shared/Layout/Components/Statements/useStatementLiveData"
import { MatchedConfig } from "src/frontend/components/Shared/Layout/types"

// EXPERIMENT:
// All archived components have a different ID; even if their kind and origin is the same.
// Which means that 2 P&Ls for the same period will fetch the same data for each component.
// When we add 2-3 P&Ls with slightly different options (columns in the UI) we are fetching the same
// data, and options only affect how the client renders the data.
// What if we hash the configuration (kind & origin) and map it to the first dataId?
// That should allow Apollo to issue 1 single request for all the different P&Ls in the report
const CONFIG_DATAID_MAP = new Map<string, string>()

function hashComponentConfig(
  config: StatementConfig,
  layoutId: string,
  layoutVersionId: string | undefined | null,
  viewId: ViewIdentifier
) {
  const { options: _, ...rest } = config
  const key = { layoutId, layoutVersionId, viewId, ...rest }
  return JSON.stringify(key)
}

function dataIdFromHashedConfig(
  config: MatchedConfig<"statement">,
  layoutId: string,
  layoutVersionId: string | undefined | null,
  viewId: ViewIdentifier,
  dataId: string | undefined | null
) {
  const hashedConfig = hashComponentConfig(config.statement, layoutId, layoutVersionId, viewId)
  const fakeDataId = CONFIG_DATAID_MAP.get(hashedConfig)
  return { hashedConfig, dataId: fakeDataId ?? dataId }
}

/**
 * Must be used within a LayoutContext.
 */
export function useStatementComponentData(
  config: MatchedConfig<"statement">,
  layoutId: string,
  layoutVersionId: string | undefined | null,
  viewId: ViewIdentifierInput,
  dataId: string | undefined | null
) {
  const { dataId: dataIdFromConfig, hashedConfig } = dataIdFromHashedConfig(
    config,
    layoutId,
    layoutVersionId,
    viewId,
    dataId
  )

  const { data, loading } = useReadReportStatementComponentDataQuery({
    variables: {
      dataId: dataIdFromConfig ?? "",
      layoutId,
      viewId,
      limit: 5,
      sort: DimensionSortMode.Total,
    },
    skip: !dataId,
    // LEs with large COA can take 1+ seconds to load a single statement.
    // Stop batching this request to unblock all the other components
    context: { noBatch: true },
  })

  const initialDataId = useConstant(dataId)
  // Skip loading live data if this component received a dataId at creation time.
  // This means the component was loaded from archive and should only ever show
  // archived data.
  const skipLiveData = !!dataId && dataId === initialDataId

  if (skipLiveData && hashedConfig && !CONFIG_DATAID_MAP.has(hashedConfig)) {
    CONFIG_DATAID_MAP.set(hashedConfig, dataId)
  }

  const { statement, loading: liveLoading } = useStatementLiveData(
    config.statement,
    config.type,
    viewId,
    skipLiveData
  )

  return React.useMemo(() => {
    const componentData = data?.readComponentData.data

    // Data read from the archive takes precedence.
    // Resolves the component data union types to the portions we care about.
    if (componentData?.__typename === "Statement") {
      return { statement: componentData as Statement, loading }
    }

    // Fall back to live data if we have fetched it.
    // This is necessary to keep data from disappearing mid-drag while we archive the real component data.
    if (statement && !liveLoading) {
      return { statement, loading: false }
    }

    return { statement: undefined, loading }
  }, [data?.readComponentData.data, statement, liveLoading, loading])
}
