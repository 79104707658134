import * as React from "react"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { VaultFile } from "src/frontend/components/OS/Springboard/Applications/Vault/types"
import { DeleteFileButton } from "src/frontend/components/OS/Springboard/Applications/Vault/VaultBrowser/DeleteFileButton"
import { VaultFileItem } from "src/frontend/components/OS/Springboard/Applications/Vault/VaultBrowser/VaultFileItem"

const StyleFileItem = styled(VaultFileItem)`
  background: #f6f7fb;
  border-color: ${colors.secondary05};
  width: 225px;
`

const DeleteButton = styled(DeleteFileButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
`

const GridItemContainer = styled.div`
  position: relative;

  &:hover ${DeleteButton} {
    display: block;
  }
`

export const DocumentGridItem: React.FC<{ file: VaultFile }> = ({ file }) => (
  <GridItemContainer>
    <StyleFileItem file={file} selection={undefined} />
    <DeleteButton file={file} />
  </GridItemContainer>
)
