import * as React from "react"
import { EntityTransaction, SearchHit } from "@digits-graphql/frontend/graphql-bearer"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgTransfers } from "@digits-shared/components/SVGIcons/custom/Transfers.svg"
import { getIconColor } from "@digits-shared/components/UI/Elements/ColorIcon"
import { IconContainer } from "@digits-shared/components/UI/Icons/Icon"
import { RowContent } from "@digits-shared/components/UI/Table/Content"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import colors from "@digits-shared/themes/colors"
import moment from "moment"
import styled from "styled-components"
import useSearchContext from "src/frontend/components/OS/Springboard/Applications/Search/SearchContext"
import { transactionListRequestVariables } from "src/frontend/components/OS/Springboard/Applications/Shared/Transactions/transactionListRequestVariables"
import routes from "src/frontend/routes"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"
import transactionHelper from "src/shared/helpers/transactionHelper"
import MatchedText from "./MatchedText"
import {
  GridRow,
  Month,
  ResultDate,
  ResultDescription,
  ResultRightAmount,
  ResultTitle,
  RowProps,
  Year,
} from "./ResultRow"

const OPACITY = 0.5

/*
  STYLE
*/

const Details = styled.div`
  display: flex;
`

const SpacingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TransferContainer = styled(ResultTitle)`
  display: flex;
  align-items: center;
`

const TransferText = styled.div`
  margin: 0 5px;
  opacity: ${OPACITY};
  flex-shrink: 0;
`

const TransferIconContainer = styled(IconContainer)`
  background-color: ${getIconColor().background};
  width: 30px;
  height: 30px;
  margin-right: 10px;
`

const TransferIcon = styled(SvgTransfers)`
  padding: 1px;
  width: 30px;
  height: 24px;
  ${svgIconStyles(colors.white)};
`

/*
  INTERFACES
*/

interface TransactionDetailsProps {
  entity: EntityTransaction
  hit: SearchHit
}

/*
  COMPONENT
*/

const TransactionResult: React.FC<RowProps<EntityTransaction>> = ({ entity, hit }) => {
  const { timestamp, moneyFlow } = entity
  const { query } = useSearchContext()

  const date = moment.unix(timestamp).utc()

  const amountWithCurrency = moneyFlowHelper.currency(moneyFlow, {
    absolute: true,
    style: CurrencyStyle.Detail,
  })

  const transactionPath = routes.defaultTransactionDetails.generateFromCurrentPath({
    transactionId: entity.factId,
  })

  return (
    <GridRow
      to={{
        pathname: transactionPath,
        state: transactionListRequestVariables.toLocationState({
          filter: {
            filterTerm: query.text,
          },
        }),
      }}
    >
      <ResultDate>
        <Month>{date.format("MMM DD")}</Month>
        <Year>{date.format("YYYY")}</Year>
      </ResultDate>
      <TransactionDetails entity={entity} hit={hit} />
      <ResultRightAmount>{amountWithCurrency}</ResultRightAmount>
    </GridRow>
  )
}

export default TransactionResult

const TransactionDetails: React.FC<TransactionDetailsProps> = ({ entity, hit }) => {
  if (transactionHelper.isTransfer(entity)) {
    return <TransferDetails entity={entity} hit={hit} />
  }

  const { party } = entity
  const description = party?.name || entity.description || entity.name || ""
  const descriptionField = party?.name ? "party.name" : entity.description ? "description" : "name"

  return (
    <Details>
      <PartyIcon party={party} css="margin-right: 10px;" disableHoverBorder />
      <RowContent>
        <ResultTitle>
          <MatchedText fieldName={descriptionField} highlights={hit.highlights}>
            {description}
          </MatchedText>
        </ResultTitle>
        <ResultDescription>
          <MatchedText
            fieldName={["debit_category.name", "credit_category.name"]}
            highlights={hit.highlights}
          >
            {entity.displayCategory.name || "Uncategorized"}
          </MatchedText>
        </ResultDescription>
      </RowContent>
    </Details>
  )
}

const TransferDetails: React.FC<TransactionDetailsProps> = ({ entity, hit }) => (
  <Details>
    <SpacingContainer>
      <TransferContainer>
        <TransferIconContainer>
          <TransferIcon />
        </TransferIconContainer>
        <TransferText css="margin-left:0px;">Transfer from</TransferText>
        <MatchedText
          fieldName={["debit_category.name", "credit_category.name"]}
          highlights={hit.highlights}
        >
          {entity.displayCategory?.name || "Uncategorized"}
        </MatchedText>
        <TransferText>to</TransferText>{" "}
        <MatchedText
          fieldName={["debit_category.name", "credit_category.name"]}
          highlights={hit.highlights}
        >
          {entity.splitCategory?.name || "Uncategorized"}
        </MatchedText>
      </TransferContainer>
    </SpacingContainer>
  </Details>
)
