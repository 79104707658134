import * as React from "react"
import { IntervalOrigin } from "@digits-graphql/frontend/graphql-bearer"
import { BackButton } from "@digits-shared/DesignSystem/BackButton"
import useConstant from "@digits-shared/hooks/useConstant"
import useLazyInterval from "@digits-shared/hooks/useLazyInterval"
import { usePopOverState } from "@digits-shared/hooks/usePopOverState"
import useRouter from "@digits-shared/hooks/useRouter"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import styled, { createGlobalStyle, css } from "styled-components"
import { ApplicationWrapper } from "src/frontend/components/OS/Home/Home"
import { SearchMarketing } from "src/frontend/components/OS/Springboard/Applications/Search/SearchMarketing"
import { InsightPopUp } from "src/frontend/components/OS/Springboard/Applications/Search/SearchSuggestions/PopUp/InsightPopUp"
import { SearchSuggestions } from "src/frontend/components/OS/Springboard/Applications/Search/SearchSuggestions/SearchSuggestions"
import { InsightChip } from "src/frontend/components/OS/Springboard/Applications/Search/SearchSuggestions/Shared"
import { SearchQuerySource } from "src/frontend/components/OS/Springboard/Applications/Search/shared"
import { FullScreenScrollableContainer } from "src/frontend/components/OS/Springboard/Applications/Shared/FullScreenScrollableContainer"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import QueryBuilder from "./QueryBuilder"
import SearchBox from "./SearchBox"
import SearchQueries from "./SearchQueries"

/*
  STYLES
*/

// Search needs to be wider than the other applications
const SearchApplicationWidth = createGlobalStyle`
  ${ApplicationWrapper} {
    width: 100%;
    display: flex;
  }
`

const SearchContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const SearchStyled = styled.div`
  position: relative;
  width: 900px;
  height: 100%;
  font-size: 13px;
  margin: 0 auto;
  padding-top: calc(var(--header-height) + var(--header-gap));
`

const CenterContainer = styled.div<{ isAnimating: boolean }>`
  margin-bottom: 5px;

  ${(props) =>
    props.isAnimating &&
    css`
      input {
        caret-color: transparent;
      }
    `}
`

const SearchBoxContainer = styled.div`
  display: flex;
  margin: 0 170px 10px 170px;
`

const CustomBackButton = styled(BackButton)``

const SearchScrollContainer = styled(FullScreenScrollableContainer)`
  height: calc(100vh - var(--header-height) - var(--header-gap) - 145px);
  padding-top: 20px;
  width: 900px;
`

/*
  COMPONENTS
*/

export const SearchApplication: React.FC<React.PropsWithChildren<{}>> = () => {
  const { location, history } = useRouter()
  const origin = useConstant<IntervalOrigin>(() => location.state?.origin)

  const generatePath = useFrontendPathGenerator()
  const boxRef = React.useRef<HTMLDivElement | null>(null)

  const [hoveredInsightRef, setHoveredInsightRef] = React.useState<InsightChip>()
  const { isPopOverOpen: shouldKeepPopUpOpen, onMouseEnter, onMouseLeave } = usePopOverState()

  const { value: isSearchRequestInFlight, setValue: setIsSearchRequestInFlight } = useStateBoolean()

  const hoveredInsightChanged = React.useCallback(
    (insightChip: InsightChip | undefined) => {
      // Prevent clearing the hovered insight ref while the user is moused-over the pop-up.
      //
      // The search suggestions will fire a delayed event on chip mouse leave, setting the
      // chip to undefined. The delay allows for time for the user to mouse over the pop-up
      // itself.
      if (!insightChip && shouldKeepPopUpOpen) return

      setHoveredInsightRef(insightChip)
    },
    [shouldKeepPopUpOpen]
  )

  const builder = React.useMemo(() => new QueryBuilder(location.queryParams), [location])
  const query = builder.build()

  const onSubmit = React.useCallback(
    (text: string) => {
      const urlParams = builder.setText(text).buildUrlParams()
      const path = generatePath(routes.search, urlParams)
      const source: SearchQuerySource = "searchbox"
      history.replace(path, { source })
    },
    [history, builder, generatePath]
  )
  const { animatedText, isAnimating } = useAnimatedLinkParam(boxRef, onSubmit)

  const onClear = React.useCallback(() => {
    const path = generatePath(routes.search)
    history.push(path)
  }, [history, generatePath])

  const onClose = React.useCallback(() => {
    const path = generatePath(routes.legalEntityHome, origin)
    history.push(path)
  }, [generatePath, origin, history])

  const isSearchActive = query.isValid && !isAnimating

  return (
    <SearchContainer>
      <SearchApplicationWidth />
      <SearchStyled ref={boxRef}>
        <CenterContainer isAnimating={isAnimating}>
          <SearchBoxContainer>
            <CustomBackButton onClick={onClose} />
            <SearchBox
              onSubmit={onSubmit}
              onClear={onClear}
              initialValue={animatedText || query.text}
              isSearchActive={isSearchActive}
              isSearchRequestInFlight={isSearchRequestInFlight}
              isDisabled={isAnimating}
            />
          </SearchBoxContainer>

          <SearchSuggestions
            isSearchActive={isSearchActive}
            builder={builder}
            onHoveredInsightChanged={hoveredInsightChanged}
          />

          <MarketingOrInsightPopUp
            isSearchActive={isSearchActive}
            hoveredInsight={hoveredInsightRef}
            onMouseEnterPopUp={onMouseEnter}
            onMouseLeavePopUp={onMouseLeave}
          />
        </CenterContainer>
        <SearchScrollContainer>
          <SearchQueries
            builder={builder}
            setIsSearchRequestInFlight={setIsSearchRequestInFlight}
          />
        </SearchScrollContainer>
      </SearchStyled>
    </SearchContainer>
  )
}

interface MarketingOrInsightPopUpProps {
  isSearchActive: boolean
  hoveredInsight: InsightChip | undefined
  onMouseEnterPopUp: (e: React.MouseEvent) => void
  onMouseLeavePopUp: (e: React.MouseEvent) => void
}

const MarketingOrInsightPopUp: React.FC<MarketingOrInsightPopUpProps> = ({
  isSearchActive,
  hoveredInsight,
  onMouseEnterPopUp,
  onMouseLeavePopUp,
}) => (
  <>
    {!isSearchActive && hoveredInsight && (
      <InsightPopUp
        insightChip={hoveredInsight}
        onMouseEnterPopUp={onMouseEnterPopUp}
        onMouseLeavePopUp={onMouseLeavePopUp}
      >
        Insight
      </InsightPopUp>
    )}
    <SearchMarketing hide={isSearchActive} />
  </>
)

function useAnimatedLinkParam(
  ref: React.RefObject<HTMLDivElement | null>,
  onAnimationEnd: (fullString: string) => void
) {
  const { location } = useRouter()
  const { linkParam } = location.queryParams
  const [isAnimating, setIsAnimating] = React.useState(!!linkParam?.length)
  const [animatedText, setAnimatedText] = React.useState("")
  const animationInterval = linkParam?.length ? 100 : 0

  const [startAnimation, interval] = useLazyInterval(() => {
    if (!linkParam?.length || !isAnimating) {
      return interval.current ? clearInterval(interval.current) : null
    }
    setAnimatedText(linkParam.slice(0, animatedText.length + 1))

    if (animatedText.length >= linkParam.length) {
      setIsAnimating(false)
      onAnimationEnd(linkParam)
    }
  }, animationInterval)

  React.useEffect(() => {
    if (ref.current && linkParam) {
      startAnimation()
    }
  }, [linkParam, ref, startAnimation])

  return { animatedText, isAnimating }
}
