import * as React from "react"
import {
  DirectionFromOrigin,
  EntityCategory,
  IntervalOrigin,
  MonetaryValue,
  TransactionSummary,
  useSummarizeTransactionsByTimeQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { SummaryWithCount } from "@digits-shared/components/UI/Table/Content"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import SparkBarChart from "src/frontend/components/OS/Shared/Charts/SparkBarChart"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import {
  AmountAndCount,
  ChartContainer,
} from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Components/Search/Shared"

/*
  INTERFACES
*/
interface SummaryProps {
  origin: IntervalOrigin
  category?: EntityCategory
}

/*
  COMPONENTS
*/
export const CategoryChartSummary: React.FC<SummaryProps> = ({ category, origin }) => {
  const viewKey = useViewVersion()

  const { data, loading } = useSummarizeTransactionsByTimeQuery({
    variables: {
      filter: {
        viewKey,
        categoryId: category?.id,
      },
      origin,
      direction: DirectionFromOrigin.Past,
    },
    skip: !category,
  })

  const summaries: TransactionSummary[] | undefined = React.useMemo(
    () => data?.dimensionalTransactionSummary.time.map((t) => t.summary),
    [data?.dimensionalTransactionSummary.time]
  )

  if (loading) {
    return (
      <AmountAndCount>
        <SummaryWithCount loading={loading} />
      </AmountAndCount>
    )
  }

  if (!summaries || !summaries.length) {
    return null
  }

  const { total, count } = summaries.reduce<{ count: number; total: MonetaryValue }>(
    (totals, summary) => {
      totals.count += summary.total.transactionsCount
      totals.total.amount += summary.total.value.amount
      return totals
    },
    {
      count: 0,
      total: { ...summaries[0]?.total.value, amount: 0 } as MonetaryValue,
    }
  )

  // TODO - enable this always once we have a better way to display totals
  const noSummaries = count === 0

  return (
    <>
      <SummarySparkChart summaries={summaries} total={total} />
      {noSummaries && (
        <AmountAndCount>
          <SummaryWithCount value={total} style={CurrencyStyle.Aggregation} absolute ignoreHover>
            Last 12 Months
          </SummaryWithCount>
        </AmountAndCount>
      )}
    </>
  )
}

const SummarySparkChart: React.FC<{ summaries: TransactionSummary[]; total: MonetaryValue }> = ({
  summaries,
  total,
}) => {
  const series = React.useMemo(
    () =>
      summaries
        .flatMap((item) => {
          const periodMoney = item.total
          return {
            x: item.period,
            y: periodMoney.value,
          }
        })
        .reverse(),
    [summaries]
  )

  // if total is less than $5.00 dont show a graph
  if (Math.abs(total.amount / total.currencyMultiplier) < 5) return null

  return (
    <ChartContainer>
      <SparkBarChart series={series} animateChartInitialDelay={800} />
    </ChartContainer>
  )
}
