import * as React from "react"
import QueryBuilder, {
  QueryRequest,
} from "src/frontend/components/OS/Springboard/Applications/Search/QueryBuilder"

export interface SearchContextProps {
  builder: QueryBuilder
  query: QueryRequest
}

export const SearchContext = React.createContext<SearchContextProps>({
  builder: new QueryBuilder(),
  query: new QueryBuilder().build(),
})

export default function useSearchContext() {
  const context = React.useContext(SearchContext)
  return React.useMemo(() => context, [context])
}
