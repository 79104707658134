import { InsightType, PartyRole, ProductArea } from "@digits-graphql/frontend/graphql-bearer"
import { SvgAccountsReceivable } from "@digits-shared/components/SVGIcons/category/AccountsReceivable.svg"
import { SvgRevenue } from "@digits-shared/components/SVGIcons/category/Revenue.svg"
import { SvgStorage } from "@digits-shared/components/SVGIcons/category/Storage.svg"
import { SvgWorkers } from "@digits-shared/components/SVGIcons/category/Workers.svg"
import { SearchResultType } from "src/frontend/types/SearchResultType"
import { SvgSearchCustomer } from "src/shared/components/SVG/SearchCustomer.svg"
import { SvgSearchLender } from "src/shared/components/SVG/SearchLender.svg"
import { SvgSearchOwedVendor } from "src/shared/components/SVG/SearchOwedVendor.svg"
import { SvgSearchPrepaidVendor } from "src/shared/components/SVG/SearchPrepaidVendor.svg"
import { SvgSearchShareholder } from "src/shared/components/SVG/SearchShareholder.svg"
import { SvgSearchSupplier } from "src/shared/components/SVG/SearchSupplier.svg"
import { SvgSearchUnpaidCustomer } from "src/shared/components/SVG/SearchUnpaidCustomer.svg"
import { SvgSearchVendor } from "src/shared/components/SVG/SearchVendor.svg"

const QUALIFIED_PREFIX = "com.digits.party.role." as const
type FullyQualifiedPartyRole = `${typeof QUALIFIED_PREFIX}${keyof typeof PartyRole}`

// Canonical source of mapping a PartyRole to its different associated types and strings.
export class FrontendPartyRole {
  static readonly Vendor = new FrontendPartyRole({
    partyRole: PartyRole.EntityVendorRole,
    productArea: ProductArea.Expenses,
    urlKey: "vendor",
    pluralUrlKey: "vendors",
    invertValues: true,
    displayName: "Vendor",
    pluralDisplayName: "Vendors",
    SvgIcon: SvgRevenue,
    insightType: InsightType.Expenses,
    searchResultType: SearchResultType.Vendor,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly PrepaidVendor = new FrontendPartyRole({
    partyRole: PartyRole.EntityPrepaidVendorRole,
    productArea: ProductArea.All,
    urlKey: "prepaid-vendor",
    pluralUrlKey: "prepaid-vendors",
    invertValues: true,
    displayName: "Prepaid Vendor",
    pluralDisplayName: "Prepaid Vendors",
    SvgIcon: SvgRevenue,
    insightType: InsightType.AssetsPrepaid,
    searchResultType: SearchResultType.PrepaidVendor,
    SearchSvgIcon: SvgSearchPrepaidVendor,
    showsBalanceSummary: true,
  })

  static readonly OwedVendor = new FrontendPartyRole({
    partyRole: PartyRole.EntityOwedVendorRole,
    productArea: ProductArea.All,
    urlKey: "payable",
    pluralUrlKey: "payables",
    invertValues: true,
    displayName: "Payable",
    pluralDisplayName: "Payables",
    SvgIcon: SvgRevenue,
    insightType: InsightType.LiabilitiesPayable,
    searchResultType: SearchResultType.OwedVendor,
    SearchSvgIcon: SvgSearchOwedVendor,
    showsBalanceSummary: true,
  })

  static readonly Customer = new FrontendPartyRole({
    partyRole: PartyRole.EntityCustomerRole,
    productArea: ProductArea.Revenue,
    urlKey: "customer",
    pluralUrlKey: "customers",
    invertValues: false,
    displayName: "Customer",
    pluralDisplayName: "Customers",
    SvgIcon: SvgWorkers,
    insightType: InsightType.Income,
    searchResultType: SearchResultType.Customer,
    SearchSvgIcon: SvgSearchCustomer,
    showsBalanceSummary: false,
  })

  static readonly UnpaidCustomer = new FrontendPartyRole({
    partyRole: PartyRole.EntityUnpaidCustomerRole,
    productArea: ProductArea.All,
    urlKey: "receivable",
    pluralUrlKey: "receivables",
    invertValues: false,
    displayName: "Receivable",
    pluralDisplayName: "Receivables",
    SvgIcon: SvgAccountsReceivable,
    insightType: InsightType.AssetsReceivable,
    searchResultType: SearchResultType.UnpaidCustomer,
    SearchSvgIcon: SvgSearchUnpaidCustomer,
    showsBalanceSummary: true,
  })

  static readonly Supplier = new FrontendPartyRole({
    partyRole: PartyRole.EntitySupplierRole,
    productArea: ProductArea.Revenue,
    urlKey: "supplier",
    pluralUrlKey: "suppliers",
    invertValues: true,
    displayName: "Supplier",
    pluralDisplayName: "Suppliers",
    SvgIcon: SvgStorage,
    insightType: InsightType.CostOfGoodsSold,
    searchResultType: SearchResultType.Supplier,
    SearchSvgIcon: SvgSearchSupplier,
    showsBalanceSummary: false,
  })

  static readonly Shareholder = new FrontendPartyRole({
    partyRole: PartyRole.EntityShareholderRole,
    productArea: ProductArea.All,
    urlKey: "shareholder",
    pluralUrlKey: "shareholders",
    invertValues: false,
    displayName: "Shareholder",
    pluralDisplayName: "Shareholders",
    SvgIcon: SvgRevenue,
    insightType: InsightType.Equity,
    searchResultType: SearchResultType.Shareholder,
    SearchSvgIcon: SvgSearchShareholder,
    showsBalanceSummary: true,
  })

  static readonly Lender = new FrontendPartyRole({
    partyRole: PartyRole.EntityLenderRole,
    productArea: ProductArea.All,
    urlKey: "lender",
    pluralUrlKey: "lenders",
    invertValues: false,
    displayName: "Lender",
    pluralDisplayName: "Lenders",
    SvgIcon: SvgRevenue,
    insightType: InsightType.LiabilitiesLongTerm,
    searchResultType: SearchResultType.Lender,
    SearchSvgIcon: SvgSearchLender,
    showsBalanceSummary: true,
  })

  static readonly Asset = new FrontendPartyRole({
    partyRole: PartyRole.EntityAssetRole,
    productArea: ProductArea.All,
    urlKey: "asset",
    pluralUrlKey: "assets",
    invertValues: false,
    displayName: "Asset",
    SvgIcon: SvgRevenue,
    pluralDisplayName: "Assets",
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly Liabilities = new FrontendPartyRole({
    partyRole: PartyRole.EntityLiabilitiesRole,
    productArea: ProductArea.All,
    urlKey: "liability",
    pluralUrlKey: "liabilities",
    invertValues: true,
    displayName: "Liability",
    pluralDisplayName: "Liabilities",
    SvgIcon: SvgRevenue,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly CreditInstitution = new FrontendPartyRole({
    partyRole: PartyRole.InstitutionCreditRole,
    productArea: ProductArea.All,
    urlKey: "credit-institution",
    pluralUrlKey: "credit-institutions",
    invertValues: false,
    displayName: "Credit Institution",
    pluralDisplayName: "Credit Institutions",
    SvgIcon: SvgRevenue,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly DebitInstitution = new FrontendPartyRole({
    partyRole: PartyRole.InstitutionDebitRole,
    productArea: ProductArea.All,
    urlKey: "debit-institution",
    pluralUrlKey: "debit-institutions",
    invertValues: false,
    displayName: "Debit Institution",
    pluralDisplayName: "Debit Institutions",
    SvgIcon: SvgRevenue,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly Facilitator = new FrontendPartyRole({
    partyRole: PartyRole.FacilitatorRole,
    productArea: ProductArea.All,
    urlKey: "facilitator",
    pluralUrlKey: "facilitators",
    invertValues: false,
    displayName: "Facilitator",
    pluralDisplayName: "Facilitator",
    SvgIcon: SvgRevenue,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly PaymentProcessor = new FrontendPartyRole({
    partyRole: PartyRole.PaymentProcessorRole,
    productArea: ProductArea.All,
    urlKey: "payment-processor",
    pluralUrlKey: "payment-processors",
    invertValues: false,
    displayName: "Payment Processor",
    pluralDisplayName: "Payment Processors",
    SvgIcon: SvgRevenue,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly Unknown = new FrontendPartyRole({
    partyRole: PartyRole.UnknownRole,
    productArea: ProductArea.All,
    urlKey: "unknown",
    pluralUrlKey: "unknown",
    invertValues: false,
    displayName: "Unknown",
    pluralDisplayName: "Unknown",
    SvgIcon: SvgRevenue,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static all(): FrontendPartyRole[] {
    return Object.values(FrontendPartyRole)
  }

  static findByURLKey(urlKey?: string): FrontendPartyRole {
    return this.all().find((role) => role.urlKey === urlKey) || FrontendPartyRole.Unknown
  }

  static findByRole(partyRole?: PartyRole | null): FrontendPartyRole {
    return this.all().find((role) => role.partyRole === partyRole) || FrontendPartyRole.Unknown
  }

  static findByFullyQualifiedPartyRole(fullyQualifiedPartyRole?: string | null) {
    const role =
      (fullyQualifiedPartyRole?.substring(QUALIFIED_PREFIX.length) as PartyRole) ||
      PartyRole.UnknownRole

    return FrontendPartyRole.findByRole(
      fullyQualifiedPartyRole?.indexOf(QUALIFIED_PREFIX) === 0
        ? PartyRole[role]
        : PartyRole.UnknownRole
    )
  }

  static findByInsightType(insightType?: InsightType): FrontendPartyRole {
    return this.all().find((role) => role.insightType === insightType) || FrontendPartyRole.Unknown
  }

  static findBySearchResultType(searchResultType?: SearchResultType): FrontendPartyRole {
    return (
      this.all().find((role) => role.searchResultType === searchResultType) ||
      FrontendPartyRole.Unknown
    )
  }

  static displayStringForRole(role?: PartyRole, plural = false) {
    const feRole = FrontendPartyRole.findByRole(role)
    return plural ? feRole.pluralDisplayName : feRole.displayName
  }

  static roleURLKey(role?: PartyRole | null): string {
    return FrontendPartyRole.findByRole(role).urlKey
  }

  static rolesForProductArea(productArea: ProductArea) {
    switch (productArea) {
      case ProductArea.All:
      case ProductArea.None:
      case ProductArea.Expenses:
        return [PartyRole.EntityVendorRole]
      case ProductArea.Cash:
        return [PartyRole.EntityCustomerRole, PartyRole.EntityVendorRole]
      case ProductArea.Revenue:
        return [PartyRole.EntityCustomerRole, PartyRole.EntitySupplierRole]
    }
  }

  static roleDisplayStringForProductArea(productArea: ProductArea, plural: boolean = false) {
    return FrontendPartyRole.displayStringForRole(
      FrontendPartyRole.rolesForProductArea(productArea)[0],
      plural
    )
  }

  static fullyQualifiedPartyRole(pr?: PartyRole): FullyQualifiedPartyRole | undefined {
    if (pr) {
      return `${QUALIFIED_PREFIX}${pr}`
    }
    return undefined
  }

  static extractPartyDetailsParams(s?: string | null) {
    const { partyRole } = FrontendPartyRole.findByFullyQualifiedPartyRole(s)
    return {
      partyRole,
    }
  }

  readonly partyRole: PartyRole
  readonly productArea: ProductArea
  readonly urlKey: string
  readonly pluralUrlKey: string
  readonly invertValues: boolean
  readonly displayName: string
  readonly pluralDisplayName: string
  readonly SvgIcon: React.FC
  readonly insightType?: InsightType
  readonly searchResultType?: SearchResultType
  readonly SearchSvgIcon: React.FC
  readonly showsBalanceSummary: boolean

  constructor({
    partyRole,
    productArea,
    urlKey,
    pluralUrlKey,
    invertValues,
    displayName,
    pluralDisplayName,
    SvgIcon,
    insightType,
    searchResultType,
    SearchSvgIcon,
    showsBalanceSummary,
  }: {
    partyRole: PartyRole
    productArea: ProductArea
    urlKey: string
    pluralUrlKey: string
    invertValues: boolean
    displayName: string
    pluralDisplayName: string
    SvgIcon: React.FC
    insightType?: InsightType
    searchResultType?: SearchResultType
    SearchSvgIcon: React.FC
    showsBalanceSummary: boolean
  }) {
    this.partyRole = partyRole
    this.productArea = productArea
    this.urlKey = urlKey
    this.pluralUrlKey = pluralUrlKey
    this.invertValues = invertValues
    this.displayName = displayName
    this.pluralDisplayName = pluralDisplayName
    this.SvgIcon = SvgIcon
    this.insightType = insightType
    this.searchResultType = searchResultType
    this.SearchSvgIcon = SearchSvgIcon
    this.showsBalanceSummary = showsBalanceSummary
  }
}

export const SUPPORTED_FRONTEND_PARTY_ROLES: FrontendPartyRole[] = [
  FrontendPartyRole.Vendor,
  FrontendPartyRole.PrepaidVendor,
  FrontendPartyRole.OwedVendor,
  FrontendPartyRole.Customer,
  FrontendPartyRole.UnpaidCustomer,
  FrontendPartyRole.Supplier,
  FrontendPartyRole.Shareholder,
  FrontendPartyRole.Lender,
  FrontendPartyRole.Facilitator,
  // Intentionally not including Liabilities and Asset roles
  // as they will always be the other side of Vendor and Customer
  // which would be confusing to display.
]
export const SUPPORTED_PARTY_ROLES: PartyRole[] = SUPPORTED_FRONTEND_PARTY_ROLES.map(
  (fRole) => fRole.partyRole
)
export const SUPPORTED_PARTY_ROLES_URL_KEYS: string[] = SUPPORTED_FRONTEND_PARTY_ROLES.map(
  (fRole) => fRole.urlKey
)
