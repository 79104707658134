import * as React from "react"
import envHelper from "@digits-shared/helpers/envHelper"
import errorHelper from "@digits-shared/helpers/errorHelper"
import Session from "@digits-shared/session/Session"

interface ErrorBoundaryProps {
  session?: Session
  readonly children: React.ReactNode
}

interface ErrorBoundaryState {
  readonly error?: Error
  readonly errorInfo?: React.ErrorInfo
}

// Place error boundary around all route to prevent entire tree from breaking when child throw
// https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  readonly state: ErrorBoundaryState = { error: undefined, errorInfo: undefined }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    TrackJS?.track(error)
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    const { session, children } = this.props
    const { error, errorInfo } = this.state

    // Print full error stack (debug info) in development mode
    if (errorInfo && (session?.isDigitsEmployee || envHelper.isDevelopment())) {
      return (
        <div style={{ position: "fixed", padding: "20px" }}>
          <details className="preserve-space">
            <summary>{error && error.toString()}</summary>
            <pre>{errorInfo && errorInfo.componentStack}</pre>
          </details>
        </div>
      )
    }

    // Print simplified error message in production
    if (errorInfo) {
      return <div>{errorHelper.DEFAULT_ERROR_MESSAGE}</div>
    }

    return children
  }
}

export default ErrorBoundary
