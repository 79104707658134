import { RawDocument } from "@digits-graphql/frontend/graphql-bearer"
import numberHelper from "@digits-shared/helpers/numberHelper"
import { produce } from "immer"
import {
  compose,
  fromOneMovingPoint,
  identity,
  Matrix,
  scale,
  translate,
} from "transformation-matrix"
import { yOffsetForPage } from "src/frontend/components/OS/Shared/DocumentViewer/helpers"
import { Point, Rect } from "src/frontend/components/OS/Shared/DocumentViewer/types"

// This represents the "original pixels" (i.e. 1:1) size for the image
const MAX_SCALE_FACTOR = 1.0

const PAN_AND_ZOOM_PADDING = 350

const EMPTY_RECT: Rect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
}

export interface ViewportState {
  width: number
  height: number
  dragging: boolean
  matrix: Matrix
  animating: boolean
  zoomedToFit: boolean
}

export interface DocumentPage {
  collectionId: string
  fileId: string
  imageUrl: string
}

export interface DisplayedPage extends DocumentPage {
  imageEl: SVGImageElement | undefined
  strokeWidth: number
}

export interface DocumentViewerState {
  showDebugColors: boolean
  viewport: ViewportState
  invoice?: RawDocument
  pages: DocumentPage[]
  imageElements: SVGImageElement[]
}

export type DocumentViewerAction =
  | { type: "SetPages"; pages: DocumentPage[] }
  | { type: "ImageLoaded"; pageIndex: number; imageEl: SVGImageElement }
  | { type: "DragStart" | "DragEnd" | "PanAndZoomEnd" | "ToggleDebugColors" }
  | { type: "ResetViewport"; animate?: boolean; pageIndex?: number }
  | { type: "ZoomIn" | "ZoomOut"; center?: Point }
  | { type: "PanAndZoomTo"; rect: Rect }
  | { type: "PanAndZoomTo"; pageIndex: number }
  | { type: "Pan"; deltaX: number; deltaY: number }
  | { type: "Resize"; width: number; height: number }
  | { type: "Dragged"; from: Point; to: Point }

export function initialState(width: number, height: number): DocumentViewerState {
  return {
    showDebugColors: false,
    viewport: {
      width,
      height,
      dragging: false,
      matrix: identity(),
      animating: false,
      zoomedToFit: true,
    },
    pages: [],
    imageElements: [],
  }
}

export const reducer = produce((curState: DocumentViewerState, action: DocumentViewerAction) => {
  switch (action.type) {
    case "ToggleDebugColors": {
      curState.showDebugColors = !curState.showDebugColors
      break
    }
    case "SetPages": {
      curState.pages = action.pages
      break
    }
    case "ImageLoaded": {
      // On first load, center page in viewport
      if (action.pageIndex === 0 && !curState.imageElements[action.pageIndex]) {
        centerViewportOn(
          curState.viewport,
          action.imageEl.getBBox().width,
          action.imageEl.getBBox().height,
          yOffsetForPage(action.pageIndex, curState.imageElements)
        )
        curState.viewport.zoomedToFit = true
      }

      curState.imageElements[action.pageIndex] = action.imageEl
      break
    }
    case "ResetViewport": {
      const pageIndex = action.pageIndex ?? 0
      if (action.animate) {
        curState.viewport.animating = true
      }
      if (curState.imageElements[pageIndex]) {
        const boundingBox = curState.imageElements[pageIndex]?.getBBox()
        if (boundingBox?.width && boundingBox.height) {
          centerViewportOn(
            curState.viewport,
            boundingBox.width,
            boundingBox.height,
            yOffsetForPage(pageIndex, curState.imageElements)
          )
          curState.viewport.zoomedToFit = true
        }
      }
      break
    }
    case "DragStart": {
      curState.viewport.animating = false
      curState.viewport.dragging = true
      curState.viewport.zoomedToFit = false
      break
    }
    case "Dragged": {
      curState.viewport.animating = false
      curState.viewport.matrix = compose(
        fromOneMovingPoint(action.from, action.to),
        curState.viewport.matrix
      )
      break
    }
    case "DragEnd": {
      curState.viewport.dragging = false
      break
    }
    case "Resize": {
      curState.viewport.width = action.width
      curState.viewport.height = action.height
      curState.viewport.zoomedToFit = false
      break
    }
    case "ZoomIn": {
      applyZoomTo(curState.viewport, 1 / 0.875, action.center)
      curState.viewport.zoomedToFit = false
      break
    }
    case "ZoomOut": {
      applyZoomTo(curState.viewport, 1 * 0.875, action.center)
      curState.viewport.zoomedToFit = false
      break
    }
    case "Pan": {
      curState.viewport.matrix = compose(
        translate(action.deltaX, action.deltaY),
        curState.viewport.matrix
      )
      break
    }
    case "PanAndZoomTo": {
      const rect = getSafeRect(action, curState)
      // Calculate the zoom factor for the rectangle we're trying to surround
      const targetWidth = rect.width + PAN_AND_ZOOM_PADDING
      const targetHeight = rect.height + PAN_AND_ZOOM_PADDING
      const targetWidthRatio = curState.viewport.width / targetWidth
      const targetHeightRatio = curState.viewport.height / targetHeight
      const targetScaleFactor = Math.min(targetWidthRatio, targetHeightRatio)

      // Calculate the scale factor used for the initial image zoom-to-fit
      const imageWidth = curState.imageElements[0]?.getBBox().width ?? curState.viewport.width
      const imageHeight = curState.imageElements[0]?.getBBox().height ?? curState.viewport.height
      const ztfWidthRatio = curState.viewport.width / imageWidth
      const ztfHeightRatio = curState.viewport.height / imageHeight
      const ztfScaleFactor = Math.min(ztfWidthRatio, ztfHeightRatio)

      // Force the scale factor to be at least the zoom-to-fit, and no more than the maximum
      const scaleFactor = numberHelper.clamp(targetScaleFactor, ztfScaleFactor, MAX_SCALE_FACTOR)

      curState.viewport.matrix = compose(
        scale(scaleFactor, scaleFactor, curState.viewport.width / 2, curState.viewport.height / 2),
        translate(
          -(rect.x - curState.viewport.width / 2 + targetWidth / 2 - PAN_AND_ZOOM_PADDING / 2),
          -(rect.y - curState.viewport.height / 2 + targetHeight / 2 - PAN_AND_ZOOM_PADDING / 2)
        ),
        identity()
      )
      curState.viewport.animating = true
      curState.viewport.zoomedToFit = "pageIndex" in action && action.pageIndex === 0
      break
    }
    case "PanAndZoomEnd": {
      curState.viewport.animating = false
    }
  }
  return curState
})

/** If the action specifies the rect, use it. If it specifies a page number, derive the rect from the image bounds. */
function getSafeRect(
  action: { type: "PanAndZoomTo"; rect: Rect } | { type: "PanAndZoomTo"; pageIndex: number },
  curState: DocumentViewerState
) {
  if ("rect" in action) {
    return action.rect
  }
  return curState.imageElements[action.pageIndex]?.getBBox() ?? EMPTY_RECT
}

function centerViewportOn(viewport: ViewportState, width: number, height: number, yOffset: number) {
  const widthRatio = viewport.width / width
  const heightRatio = viewport.height / height
  const scaleFactor = Math.min(widthRatio, heightRatio)

  viewport.matrix = compose(
    translate(viewport.width / 2 - (width * scaleFactor) / 2, -yOffset * scaleFactor),
    scale(scaleFactor),
    identity()
  )
}

function applyZoomTo(viewport: ViewportState, scaleFactor: number, center: Point | undefined) {
  const c = center ?? { x: viewport.width / 2, y: viewport.height / 2 }
  viewport.matrix = compose(
    translate(c.x, c.y),
    scale(scaleFactor),
    translate(-c.x, -c.y),
    viewport.matrix
  )
}
