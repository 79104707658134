import {
  Category,
  CategoryType,
  DisplayEntry,
  EntityCategory,
  EntryType,
  ProductArea,
} from "@digits-graphql/frontend/graphql-bearer"
import stringHelper from "@digits-shared/helpers/stringHelper"

export const OTHER_CATEGORIES_NAME = "Other Categories"

const BALANCE_SHEET_CATEGORIES = [
  CategoryType.Assets,
  CategoryType.Liabilities,
  CategoryType.Equity,
]

const PROFIT_AND_LOSS_CATEGORIES = [
  CategoryType.Income,
  CategoryType.OtherIncome,
  CategoryType.Expenses,
  CategoryType.OtherExpenses,
  CategoryType.CostOfGoodsSold,
]

export function adjectiveForCategoryType(
  type: CategoryType | undefined | null,
  lowercase = false
): string {
  if (!type) return ""

  let adjective = stringHelper.camelCaseToSpaces(type)

  switch (type) {
    case CategoryType.Assets:
      adjective = "Asset"
      break
    case CategoryType.Liabilities:
      adjective = "Liability"
      break
    case CategoryType.Expenses:
      adjective = "Expense"
      break
    case CategoryType.OtherExpenses:
      adjective = "Other Expense"
      break
  }

  if (lowercase) {
    adjective = adjective.toLowerCase()
  }

  return adjective
}

export function invertValuesForCategory(
  category: Category | EntityCategory | undefined | null
): boolean {
  if (!category) return false

  switch (category.productArea) {
    case ProductArea.Expenses:
      return true
    case ProductArea.Revenue:
      return category.type === CategoryType.CostOfGoodsSold
    case ProductArea.Cash:
    case ProductArea.None:
    default:
      return invertValuesForCategoryType(category.type)
  }
}

export function invertValuesForCategoryType(type: CategoryType): boolean {
  switch (type) {
    case CategoryType.Assets:
    case CategoryType.OtherIncome:
    case CategoryType.Income:
    case CategoryType.Equity:
    case CategoryType.UnknownType:
      return false

    case CategoryType.Liabilities:
    case CategoryType.Expenses:
    case CategoryType.OtherExpenses:
    case CategoryType.CostOfGoodsSold:
      return true
  }
}

export function showBalancesForCategory(category: Category | undefined | null) {
  if (!category) return false

  return (
    category.type === CategoryType.Assets ||
    category.type === CategoryType.Liabilities ||
    category.type === CategoryType.Equity
  )
}

// Attempting to determine a starting entry type (debit or credit) to show where the money flowed.
// This may be imperfect, so may require updating as we see different scenarios.
export function startingCategoryEntryType(
  displayEntry?: DisplayEntry,
  debitCategory?: Category | null,
  creditCategory?: Category | null
): EntryType {
  if (!debitCategory || !creditCategory) return EntryType.Debit

  if (displayEntry === DisplayEntry.TransferDebit) return EntryType.Credit
  if (displayEntry === DisplayEntry.TransferCredit) return EntryType.Debit

  switch (debitCategory.type) {
    case CategoryType.Assets:
    case CategoryType.OtherIncome:
    case CategoryType.Income: {
      switch (creditCategory.type) {
        case CategoryType.Assets:
        case CategoryType.OtherIncome:
        case CategoryType.Income:
          return EntryType.Credit

        case CategoryType.Liabilities:
        case CategoryType.Expenses:
        case CategoryType.OtherExpenses:
        case CategoryType.CostOfGoodsSold:
          return EntryType.Credit

        case CategoryType.Equity:
          return EntryType.Debit

        case CategoryType.UnknownType:
          return EntryType.Debit
      }
      return EntryType.Debit
    }
    case CategoryType.Liabilities:
    case CategoryType.Expenses:
    case CategoryType.OtherExpenses:
    case CategoryType.CostOfGoodsSold: {
      switch (creditCategory.type) {
        case CategoryType.Assets:
        case CategoryType.OtherIncome:
        case CategoryType.Income:
          return EntryType.Credit

        case CategoryType.Liabilities:
          return EntryType.Credit
        case CategoryType.Expenses:
        case CategoryType.OtherExpenses:
        case CategoryType.CostOfGoodsSold:
          return EntryType.Debit

        case CategoryType.Equity:
          return EntryType.Credit

        case CategoryType.UnknownType:
          return EntryType.Debit
      }
      return EntryType.Debit
    }
    case CategoryType.Equity: {
      switch (creditCategory.type) {
        case CategoryType.Assets:
        case CategoryType.OtherIncome:
        case CategoryType.Income:
          return EntryType.Credit

        case CategoryType.Liabilities:
        case CategoryType.Expenses:
        case CategoryType.OtherExpenses:
        case CategoryType.CostOfGoodsSold:
          return EntryType.Debit

        case CategoryType.Equity:
          return EntryType.Credit

        case CategoryType.UnknownType:
          return EntryType.Debit
      }
      return EntryType.Debit
    }
    case CategoryType.UnknownType:
      return EntryType.Debit
  }
}

export function isBalanceSheetCategory(type: CategoryType): boolean {
  return BALANCE_SHEET_CATEGORIES.indexOf(type) > -1
}

export function isProfitAndLossCategory(type: CategoryType): boolean {
  return PROFIT_AND_LOSS_CATEGORIES.indexOf(type) > -1
}
