import React from "react"
import {
  PartyDesignation,
  Transaction,
  TransactionGroup,
  TransactionRecordType,
} from "@digits-graphql/frontend/graphql-bearer"
import { ColumnComponentProps } from "@digits-shared/components/UI/Table"
import { ExpandedGroup } from "src/shared/components/Transactions/transactionsConstants"
import transactionHelper from "src/shared/helpers/transactionHelper"

export type TransactionRow = Transaction | TransactionGroup

interface TransactionTableProps {
  className?: string
}

export type TransactionRowProps = ColumnComponentProps<TransactionRow> & TransactionTableProps

export type TransactionProps = TransactionTableProps & { transaction: Transaction }

export type TransactionGroupProps = TransactionTableProps & { group: TransactionGroup }

// Returns a party for display in the transaction row, if possible.
export function getTransactionRowParty(transaction?: Transaction) {
  if (!transaction) return undefined

  const recordTypeIsTransaction = transactionHelper.fullDataRecordType(transaction.recordType)
  const recordTypeIsJournal = transaction.recordType === TransactionRecordType.Journal

  const party = transaction.party?.id ? transaction.party : undefined

  // Exclude non-specific parties.
  if (party && party.name === "-- Vendor name pending --") {
    return undefined
  }

  // For Journals only display the party when it's a global party
  // or a person, in order to exclude low-quality synthesized party names.
  const isHighQualityJournalParty =
    transaction.party?.iconUrl ||
    transaction.party?.designation === PartyDesignation.PeopleDesignation
  if (recordTypeIsJournal && isHighQualityJournalParty) {
    return party
  }

  if (recordTypeIsTransaction) {
    return party
  }

  return undefined
}

// Returns an institution for display in the transaction row, if possible.
export function getTransactionRowInstitution(transaction?: Transaction) {
  if (!transaction) return undefined
  const { displayInstitution, splitInstitution, description } = transaction

  // Prefer the display institution.
  if (displayInstitution) return displayInstitution
  if (description) return undefined
  // If the description is blank fall back to the non-display institution.
  return splitInstitution
}

// Returns a facilitator for display in the transaction row, if possible.
export function getTransactionRowFacilitator(transaction?: Transaction) {
  if (!transaction) return undefined
  const { facilitatorParty } = transaction

  return facilitatorParty
}

export function isTransactionGroup(row?: TransactionRow): row is TransactionGroup {
  if (!row) return false
  const group = row as TransactionGroup
  return Array.isArray(group.transactions)
}

export function isGroupExpanded(expandedGroups: ExpandedGroup, transactionGroup: TransactionGroup) {
  return !!expandedGroups[transactionGroup.groupId]
}

export type GroupingActionHandler = (
  transactionGroup: TransactionGroup,
  event: React.MouseEvent<HTMLTableRowElement>
) => void

export interface GroupingActions {
  handleGroupExpand: GroupingActionHandler
  handleGroupCollapse: GroupingActionHandler
}
