import * as React from "react"
import { MutableRefObject, useEffect } from "react"
import { useIntersection, useSet } from "react-use"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgChevronDown } from "@digits-shared/components/SVGIcons/line/ChevronDown.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import useLazyTimeout from "@digits-shared/hooks/useLazyTimeout"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { NavCarouselItemContext } from "src/frontend/components/OS/Details/Shared/Styles/NavCarouselItemContext"
import {
  SPRINGBOARD_DETAILS_CONTENT_BORDER_RADIUS,
  SPRINGBOARD_DETAILS_CONTENT_SPACING_LEFT,
  SPRINGBOARD_DETAILS_CONTENT_SPACING_RIGHT,
  SPRINGBOARD_DETAILS_DEEPER_DIVE_NAV_HEIGHT,
  SPRINGBOARD_DETAILS_HIDE_WHEN_SCROLL_Z_INDEX,
} from "src/frontend/components/OS/Details/Shared/Styles/shared"
import { OS_BUTTON_HOVER_BACKGROUND_COLOR } from "src/frontend/components/OS/Shared/OSStyles"

/**
 STYLES
 */

export const NAV_WITH_ICON = css<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    padding: 2px;
    height: 24px;
    width: 24px;
    opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0.6)};
    transition: opacity 100ms ease-out;

    ${svgIconStyles(colors.secondary)};
    path[stroke]:not([stroke="none"]) {
      fill: none;
    }
  }
`

export const DeeperDiveNavItem = styled.div<{ $isSelected: boolean }>`
  color: ${colors.secondary};
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${colors.white};
    `};
  border-radius: 1em;
  padding: 2px 12px;

  transition:
    color 100ms ease-out,
    background-color 100ms ease-out;

  cursor: ${({ $isSelected }) => ($isSelected ? "auto" : "pointer")};

  ${NAV_WITH_ICON};

  ${({ $isSelected }) =>
    !$isSelected &&
    css`
      &:hover {
        background-color: ${OS_BUTTON_HOVER_BACKGROUND_COLOR};

        svg {
          opacity: 1;
        }
      }
    `}
`

export const DeeperDiveOptionsNavItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-left: auto;
`

export const DeeperDiveNavBar = styled.div<{ top: number }>`
  position: sticky;
  top: ${({ top }) => top}px;
  z-index: ${SPRINGBOARD_DETAILS_HIDE_WHEN_SCROLL_Z_INDEX + 1};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 4px;
  padding: 8px ${SPRINGBOARD_DETAILS_CONTENT_SPACING_RIGHT}px 0
    ${SPRINGBOARD_DETAILS_CONTENT_SPACING_LEFT}px;
  height: ${SPRINGBOARD_DETAILS_DEEPER_DIVE_NAV_HEIGHT}px;
  border-radius: ${SPRINGBOARD_DETAILS_CONTENT_BORDER_RADIUS}px
    ${SPRINGBOARD_DETAILS_CONTENT_BORDER_RADIUS}px 0 0;
  background: inherit;

  ${DeeperDiveNavItem}, ${DeeperDiveOptionsNavItem} {
    font-size: 12px;
    font-weight: ${fonts.weight.heavy};
    flex-shrink: 0;
  }
`

const NavCarouselContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;

  margin-right: 10px;
`

const NavCarouselItems = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  gap: 6px;

  /* Space for the paginator. */
  margin-right: 60px;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    /* Optional: just make scrollbar invisible */
    background: transparent;
  }
`

const PaginatorContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;

  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 100ms;
`

const PaginatorDivider = styled.div`
  height: 80%;
  width: 1px;
  margin: 0 4px;
  opacity: 0.2;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(255, 255, 255, 0) 100%
  );
`

const LeftArrow = styled(SvgChevronDown)`
  width: 18px;
  height: 18px;
  ${svgPathStyles(colors.secondary, 1.5)}

  transform: rotate(90deg);
  margin-right: 2px;
`

const RightArrow = styled(LeftArrow)`
  transform: rotate(-90deg);
  margin-left: 2px;
`

/*
 COMPONENTS
*/

interface CarouselNavProps {
  index: number
  activeIndex: number
  setTargetIndex: (index: number) => void
  onBecameVisible: (index: number) => void
  onBecameHidden: (index: number) => void
  children?: React.ReactNode
}

interface CarouselPaginatorProps {
  visible: boolean
  hasNext: boolean
  hasPrev: boolean
  onClickNext: () => void
  onClickPrev: () => void
}

export const DeeperDiveNavCarousel: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [hasPrevious, setHasPrevious] = React.useState(false)
  const [hasNext, setHasNext] = React.useState(false)

  const childrenArray = React.useMemo(() => React.Children.toArray(children), [children])

  const [targetIndex, setTargetIndex] = React.useState<number | undefined>()

  const [visibleIndices, { add: markVisible, remove: markHidden, has: isVisible }] =
    useSet<number>()

  const onItemHidden = React.useCallback(
    (index: number) => {
      if (index === 0) setHasPrevious(true)
      if (index === childrenArray.length - 1) setHasNext(true)
      markHidden(index)
    },
    [childrenArray.length, markHidden]
  )
  const onItemVisible = React.useCallback(
    (index: number) => {
      if (index === 0) setHasPrevious(false)
      if (index === childrenArray.length - 1) setHasNext(false)
      markVisible(index)
    },
    [childrenArray.length, markVisible]
  )

  const leftmostIndex = React.useMemo(
    () => (visibleIndices.size === 0 ? 0 : Math.min(...visibleIndices.values())),
    [visibleIndices]
  )
  const rightmostIndex = React.useMemo(
    () => (visibleIndices.size === 0 ? 0 : Math.max(...visibleIndices.values())),
    [visibleIndices]
  )

  React.useEffect(() => {
    if (targetIndex && isVisible(targetIndex)) setTargetIndex(undefined)
  }, [isVisible, leftmostIndex, rightmostIndex, targetIndex, visibleIndices])

  const activeIndex = React.useMemo(
    () => targetIndex ?? leftmostIndex ?? 0,
    [leftmostIndex, targetIndex]
  )

  const items = React.useMemo(
    () =>
      childrenArray.map((c, i) => (
        <NavCarouselItemWithContext
          key={i}
          index={i}
          activeIndex={activeIndex}
          setTargetIndex={setTargetIndex}
          onBecameHidden={onItemHidden}
          onBecameVisible={onItemVisible}
        >
          {c}
        </NavCarouselItemWithContext>
      )),
    [activeIndex, childrenArray, onItemHidden, onItemVisible]
  )

  const goLeft = React.useCallback(() => {
    setTargetIndex(leftmostIndex - 1)
  }, [leftmostIndex])
  const goRight = React.useCallback(() => {
    setTargetIndex(rightmostIndex + 1)
  }, [rightmostIndex])

  const [showPaginator, setShowPaginator] = React.useState(false)
  const itemsRef = React.useRef<HTMLDivElement | null>(null)
  React.useEffect(() => {
    if (itemsRef.current && itemsRef.current.clientWidth < itemsRef.current.scrollWidth) {
      setShowPaginator(true)
    }
  }, [childrenArray])

  return (
    <NavCarouselContainer>
      <NavCarouselItems ref={itemsRef}>{items}</NavCarouselItems>
      <CarouselPaginator
        visible={showPaginator}
        hasNext={hasNext}
        hasPrev={hasPrevious}
        onClickNext={goRight}
        onClickPrev={goLeft}
      />
    </NavCarouselContainer>
  )
}

const NavCarouselItemWithContext: React.FC<CarouselNavProps> = ({
  children,
  index,
  activeIndex,
  onBecameHidden,
  onBecameVisible,
  setTargetIndex,
}) => {
  const context = React.useMemo(
    () => ({
      makeItemVisible: () => {
        setTargetIndex(index)
      },
    }),
    [index, setTargetIndex]
  )

  return (
    <NavCarouselItemContext.Provider key={index} value={context}>
      <NavCarouselItem
        index={index}
        activeIndex={activeIndex}
        setTargetIndex={setTargetIndex}
        onBecameHidden={onBecameHidden}
        onBecameVisible={onBecameVisible}
      >
        {children}
      </NavCarouselItem>
    </NavCarouselItemContext.Provider>
  )
}

const CarouselPaginator: React.FC<CarouselPaginatorProps> = ({
  visible,
  hasPrev,
  hasNext,
  onClickPrev,
  onClickNext,
}) => (
  <PaginatorContainer visible={visible}>
    <DigitsButton
      $circle
      $variant="secondary-dark"
      size="small"
      disabled={!hasPrev}
      onClick={onClickPrev}
    >
      <LeftArrow />
    </DigitsButton>
    <PaginatorDivider />
    <DigitsButton
      $circle
      $variant="secondary-dark"
      size="small"
      disabled={!hasNext}
      onClick={onClickNext}
    >
      <RightArrow />
    </DigitsButton>
  </PaginatorContainer>
)

// 0.99 because 1 doesn't seem to trigger for the rightmost item.
const CONTAINER_INTERSECTION_THRESHOLD = [0.99, 0]

const NavCarouselItem: React.FC<CarouselNavProps> = ({
  children,
  activeIndex,
  index,
  onBecameHidden,
  onBecameVisible,
}) => {
  const ref = useScrollToNavItem(activeIndex === index, "smooth")

  // Create an intersection observer that fires as the item comes into or leaves view
  const observerEntry = useIntersection(ref, {
    // It's critical that this value be a constant, otherwise a new value is created
    // on each render, disrupting the hook's ability manage its effect.
    threshold: CONTAINER_INTERSECTION_THRESHOLD,
  })
  const intersectionRatio = observerEntry?.intersectionRatio ?? 0

  React.useEffect(() => {
    if (intersectionRatio >= 0.99) {
      onBecameVisible(index)
    } else {
      onBecameHidden(index)
    }
  }, [index, intersectionRatio, onBecameHidden, onBecameVisible])

  return <div ref={ref}>{children}</div>
}

function useScrollToNavItem(
  isSelected: boolean,
  behavior: ScrollBehavior
): MutableRefObject<HTMLDivElement | null> {
  const ref = React.useRef<HTMLDivElement>(null)
  const scrollToView = React.useCallback(() => {
    ref.current?.scrollIntoView({ behavior, block: "nearest", inline: "nearest" })
  }, [behavior])

  const [delayedScrollTo] = useLazyTimeout(scrollToView, 1)
  useEffect(() => {
    if (isSelected) {
      delayedScrollTo()
    }
  }, [isSelected, delayedScrollTo])

  return ref
}
