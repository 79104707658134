import { ViewType } from "@digits-graphql/frontend/graphql-bearer"
import envHelper from "@digits-shared/helpers/envHelper"
import { devtools, redux } from "zustand/middleware"
import { createWithEqualityFn } from "zustand/traditional"
import { PortalAction } from "src/frontend/components/Shared/Portals/State/actions"
import { initialBuilderState, reducer } from "src/frontend/components/Shared/Portals/State/reducer"
import { PortalState } from "src/frontend/components/Shared/Portals/State/types"

export const usePortalStore = envHelper.isProduction()
  ? createWithEqualityFn(
      redux<PortalState, PortalAction>(
        reducer,
        initialBuilderState({
          legalEntityId: "",
          viewType: ViewType.Ledger,
          viewVersion: "",
        })
      )
    )
  : createWithEqualityFn(
      devtools(
        redux<PortalState, PortalAction, [["zustand/devtools", never]]>(
          reducer,
          initialBuilderState({
            legalEntityId: "",
            viewType: ViewType.Ledger,
            viewVersion: "",
          })
        ),
        {
          name: "Portal store",
        }
      )
    )

export function usePortalDispatch() {
  return usePortalStore((state) => state.dispatch)
}
