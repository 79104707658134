import * as React from "react"
import { useEvent } from "react-use"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgCalendar } from "@digits-shared/components/SVGIcons/line/Calendar.svg"
import { POP_UP_LIGHT_BACKGROUND_STYLES } from "@digits-shared/components/UI/Elements/PopUp/PopUp"
import ElementArrayHelper from "@digits-shared/helpers/ElementArrayHelper"
import { useOnBodyClick } from "@digits-shared/hooks/useOnBodyClick"
import useRouter from "@digits-shared/hooks/useRouter"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import moment from "moment"
import styled, { css } from "styled-components"
import useSearchContext from "src/frontend/components/OS/Springboard/Applications/Search/SearchContext"
import { DateTitle } from "src/frontend/components/OS/Springboard/Applications/Search/SearchElements"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import zIndexes from "src/shared/config/zIndexes"

enum DateRange {
  AllTime = "All time",
  Last30Days = "Last 30 days",
  ThisMonth = "This month",
  LastMonth = "Last month",
  ThisQuarter = "This quarter",
  LastQuarter = "Last quarter",
  ThisYear = "This year",
  LastYear = "Last year",
}

/*
  STYLES
*/

const PickerContainer = styled.div`
  margin-left: 15px;
  position: relative;
  font-size: 11px;
`

const CurrentRange = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: ${fonts.weight.book};
  color: ${colors.translucentSecondary70};
  white-space: nowrap;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }

  &::before {
    position: absolute;
    top: -12px;
    right: -8px;
    bottom: -12px;
    left: -8px;
    content: "";
  }
`

const CalendarIcon = styled(SvgCalendar)`
  ${svgPathStyles(colors.translucentSecondary70, 1.5)};
  width: 14px;
  height: 14px;
  margin-left: 5px;
`

const DateRangesList = styled.div<{ opened: boolean }>`
  ${POP_UP_LIGHT_BACKGROUND_STYLES};
  background: ${colors.offWhite};
  position: absolute;
  min-width: 150px;
  top: 100%;
  right: -8px;
  margin-top: 5px;
  padding: 10px 0;
  overflow: hidden;
  z-index: ${zIndexes.modalOverlay};
  display: ${({ opened }) => (opened ? "block" : "none")};
  cursor: pointer;
`

const DateRangeItem = styled.div<ItemProps>`
  color: ${colors.secondary};
  border-left: 4px solid ${colors.transparent};
  padding: 10px 25px;

  ${({ current }) =>
    current
      ? css`
          margin-left: 10px;
          padding-left: 15px;
          border-left: 4px solid ${colors.primary};
        `
      : css`
          &:hover {
            color: ${colors.secondary};
            background: ${colors.white};
          }
        `};

  white-space: nowrap;
  font-weight: ${fonts.weight.medium};

  ${({ current }) =>
    current &&
    css`
      font-weight: ${fonts.weight.heavy};
      cursor: default;
    `}
`

/*
  INTERFACES
*/

interface Props {
  className?: string
}

interface DateRangesProps {
  opened: boolean
  currentRange: DateRange
  onRangeSelected: (range: DateRange, occurredAfter?: Date, occurredBefore?: Date) => void
}

interface ItemProps {
  current: boolean
}

interface CurrentDateProps {
  onClick: () => void
  occurredAfter?: Date
  occurredBefore?: Date
}

/*
  COMPONENTS
*/

const DatePicker: React.FC<Props> = ({ className }) => {
  const generatePath = useFrontendPathGenerator()
  const { builder, query } = useSearchContext()
  const { history } = useRouter()
  const [range, setRange] = React.useState<DateRange>(DateRange.AllTime)
  const { value: opened, setTrue: open, setFalse: close } = useStateBoolean()

  const occurredAfter = query.occurredAfterDate
  const occurredBefore = query.occurredBeforeDate

  const onDatePicked = React.useCallback(
    (newRange: DateRange, newOccurredAfter?: Date, newOccurredBefore?: Date) => {
      setRange(newRange)
      open()

      const urlParams = builder
        .setOccurredAfter(newOccurredAfter)
        .setOccurredBefore(newOccurredBefore)
        .buildUrlParams()

      const path = generatePath(routes.search, urlParams)
      history.push(path)
    },
    [open, builder, history, generatePath]
  )

  const ref = React.useRef<HTMLDivElement | null>(null)
  const maybeClose = React.useCallback(
    (event: MouseEvent) => {
      if (!opened) return
      close()
    },
    [opened, close]
  )

  useEvent("keydown", close, document.body, { capture: true })
  useOnBodyClick(ref, maybeClose)

  React.useEffect(() => {
    const unregister = history.listen(close)
    return () => unregister()
  }, [close, history])

  return (
    <PickerContainer ref={ref} className={className}>
      <CurrentDate onClick={open} occurredAfter={occurredAfter} occurredBefore={occurredBefore} />
      <DateRanges currentRange={range} onRangeSelected={onDatePicked} opened={opened} />
    </PickerContainer>
  )
}

export default DatePicker

const CurrentDate: React.FC<CurrentDateProps> = ({ onClick, occurredBefore, occurredAfter }) => (
  <CurrentRange onClick={onClick}>
    <div css="line-height: 11px;">
      <DateTitle occurredAfter={occurredAfter} occurredBefore={occurredBefore} />
    </div>
    <CalendarIcon />
  </CurrentRange>
)

const DateRanges: React.FC<DateRangesProps> = ({ currentRange, onRangeSelected, opened }) => {
  const onRangeClick = React.useCallback(
    (range: DateRange) => {
      const utc = moment().utc()

      let occurredAfter: Date | undefined
      let occurredBefore: Date | undefined
      switch (range) {
        case DateRange.AllTime:
          occurredAfter = undefined
          occurredBefore = undefined
          break

        case DateRange.Last30Days:
          occurredAfter = utc.subtract(30, "days").toDate()
          occurredBefore = undefined
          break

        case DateRange.ThisMonth:
          occurredAfter = utc.startOf("month").toDate()
          occurredBefore = undefined
          break
        case DateRange.LastMonth:
          occurredAfter = utc.subtract(1, "months").startOf("month").toDate()
          occurredBefore = utc.endOf("month").toDate()
          break

        case DateRange.ThisQuarter:
          occurredAfter = utc.startOf("quarter").toDate()
          occurredBefore = undefined
          break
        case DateRange.LastQuarter:
          occurredAfter = utc.subtract(1, "quarter").startOf("quarter").toDate()
          occurredBefore = utc.endOf("quarter").toDate()
          break

        case DateRange.ThisYear:
          occurredAfter = utc.startOf("year").toDate()
          occurredBefore = undefined
          break
        case DateRange.LastYear:
          occurredAfter = utc.subtract(1, "year").startOf("year").toDate()
          occurredBefore = utc.endOf("year").toDate()
          break
      }

      onRangeSelected(range, occurredAfter, occurredBefore)
    },
    [onRangeSelected]
  )

  const ranges = [
    [DateRange.AllTime],
    [DateRange.Last30Days],
    [DateRange.ThisMonth, DateRange.LastMonth],
    [DateRange.ThisQuarter, DateRange.LastQuarter],
    [DateRange.ThisYear, DateRange.LastYear],
  ]

  const items = ranges.map((section) =>
    section.map((range, index) => (
      <DateRangeItem
        key={range}
        current={range === currentRange}
        onClick={onRangeClick.bind(undefined, range)}
      >
        {range}
      </DateRangeItem>
    ))
  )

  return <DateRangesList opened={opened}>{new ElementArrayHelper(items)}</DateRangesList>
}
