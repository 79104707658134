import * as React from "react"
import { POP_UP_LIGHT_BACKGROUND_STYLES } from "@digits-shared/components/UI/Elements/PopUp/PopUp"
import styled from "styled-components"
import { Layout } from "src/frontend/components/OS/Home/Operator/Layout"
import { LayoutHeader } from "src/frontend/components/OS/Home/Operator/LayoutHeader"
import { InviteClientModal } from "src/frontend/components/OS/Springboard/Applications/ClientPortal/InviteFlow/InviteClientModal"
import { useEmployeesWithAccess } from "src/frontend/components/Shared/Portals/State/useEmployeesWithAccess"
import { useFetchPortalAndLayout } from "src/frontend/components/Shared/Portals/State/useFetchPortalAndLayout"
import { useInviteClientsModalControls } from "src/frontend/components/Shared/Portals/State/useInviteClientsModalControls"
import { useInviteClientsModalDeepLinking } from "src/frontend/components/Shared/Portals/State/useInviteClientsModalDeepLinking"
import { useResetPortalPerLE } from "src/frontend/components/Shared/Portals/State/useResetPortalPerLE"

const ModuleWrapper = styled.div`
  ${POP_UP_LIGHT_BACKGROUND_STYLES};
  padding: 15px 20px 0;
  display: flex;
  flex-direction: column;
`

export const PortalContent: React.FC = () => {
  useResetPortalPerLE()
  useFetchPortalAndLayout()

  const {
    shouldShow: showInviteClientModal,
    initialModalState,
    hideModal,
  } = useInviteClientsModalControls()
  useInviteClientsModalDeepLinking()
  useEmployeesWithAccess()

  return (
    <ModuleWrapper>
      {showInviteClientModal && (
        <InviteClientModal
          showViewUsers
          onClose={hideModal}
          onDone={hideModal}
          initialModalState={initialModalState}
        />
      )}
      <LayoutHeader />
      <Layout />
    </ModuleWrapper>
  )
}
