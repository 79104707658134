import * as React from "react"
import { usePrevious } from "react-use"
import { useMeasure } from "@digits-shared/hooks/useMeasure"
import styled from "styled-components"
import { toSVG } from "transformation-matrix"
import { Controls } from "src/frontend/components/OS/Shared/DocumentViewer/Controls"
import { DocumentViewerPage } from "src/frontend/components/OS/Shared/DocumentViewer/DocumentViewerPage"
import {
  getRectOverlapArea,
  getSurroundingRectForBoxes,
  strokeWidth,
  yOffsetForPage,
} from "src/frontend/components/OS/Shared/DocumentViewer/helpers"
import {
  DisplayedPage,
  DocumentPage,
} from "src/frontend/components/OS/Shared/DocumentViewer/reducer"
import {
  BoundingBox,
  ResolveBoundingBoxes,
} from "src/frontend/components/OS/Shared/DocumentViewer/types"
import { useDocumentViewerModel } from "src/frontend/components/OS/Shared/DocumentViewer/useDocumentViewerModel"
import { useViewportControls } from "src/frontend/components/OS/Shared/DocumentViewer/useViewportControls"

/*
  INTERFACES
*/

export interface DocumentViewerProps<T> {
  className?: string
  documentId: string | undefined
  pages: DocumentPage[]
  resolveBoundingBoxes: ResolveBoundingBoxes<T>
  onBoundingBoxClick?: (box: BoundingBox<T>) => void
  onBoundingBoxMouseEnter?: (box: BoundingBox<T>) => void
  onBoundingBoxMouseLeave?: (box: BoundingBox<T>) => void
}

/*
  STYLES
*/

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 42px 1fr;
  grid-gap: 24px;
  position: relative;
  height: 100%;
  width: 100%;

  &.landscape-viewer {
    grid-template-columns: unset;
    grid-template-rows: 42px 1fr;
    grid-gap: 8px;
  }
`

const ViewerContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const Viewer = styled.div`
  position: relative;
  width: 100%;
  background: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 26px rgba(139, 139, 139, 0.25);
  backdrop-filter: blur(20px);
  border-radius: 16px;
  aspect-ratio: 8.5 / 11;
  .landscape-viewer & {
    aspect-ratio: 11 / 8.5;
  }
`

const StyledSvg = styled.svg<{ dragging: boolean }>`
  display: block;
  cursor: ${({ dragging }) => (dragging ? "grabbing" : "grab")};
  border-radius: 16px;
  width: 100%;
  height: 100%;
`

const PanAndZoomGroup = styled.g<{ animating: boolean }>`
  transition: transform ${({ animating }) => (animating ? 500 : 0)}ms ease;
`

/*
 * COMPONENTS
 */

export const DocumentViewer: React.FC<DocumentViewerProps<unknown>> = ({
  className,
  documentId,
  pages,
  resolveBoundingBoxes,
  onBoundingBoxClick,
  onBoundingBoxMouseEnter,
  onBoundingBoxMouseLeave,
}) => {
  const svgRef = React.useRef<SVGSVGElement>(null)

  const [measureRef, { width, height }] = useMeasure<HTMLDivElement>()

  const { state, dispatch } = useDocumentViewerModel(width, height, pages)

  React.useEffect(() => {
    dispatch({ type: "ResetViewport" })
  }, [width, height, dispatch, documentId])

  const activePageIndex = React.useMemo(
    () => {
      const svgRect = svgRef.current?.getBoundingClientRect()
      if (!svgRect) return -1

      const overlaps = state.imageElements.map((imgEl, i) => {
        const imgRect = imgEl.getBoundingClientRect()
        return getRectOverlapArea(svgRect, imgRect)
      })

      return overlaps.reduce(
        (largest, overlapArea, index) => {
          if (overlapArea > largest.overlapArea) {
            return {
              index,
              overlapArea,
            }
          }

          return largest
        },
        { index: -1, overlapArea: 0 }
      ).index
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.viewport.matrix, state.viewport.animating]
  )

  const { listenerProps, zoomIn, zoomOut, pageUp, pageDown } = useViewportControls(
    svgRef,
    state,
    dispatch,
    activePageIndex,
    state.pages.length
  )

  const displayedPages: DisplayedPage[] = React.useMemo(
    () =>
      state.pages.map((p, i) => {
        const imageEl = state.imageElements[i]

        return {
          ...p,
          strokeWidth: strokeWidth(imageEl?.getBBox().width ?? 0),
          imageEl,
        }
      }),
    [state.imageElements, state.pages]
  )

  const emphasizedBoundingBoxes = React.useMemo(
    () =>
      displayedPages
        // Collect emphasized features per page (array of arrays)
        .map((page, index) => {
          const yOffset = yOffsetForPage(
            index,
            displayedPages.flatMap((p) => (p.imageEl ? [p.imageEl] : []))
          )
          return resolveBoundingBoxes(
            index,
            yOffset,
            page.imageEl?.getBBox().width ?? 0,
            page.imageEl?.getBBox().height ?? 0
          ).filter((bb) => !!bb.emphasized)
        })
        // Take the set of emphasized features from the first page to have any
        .find((boxes) => !!boxes.length) ?? [],
    [displayedPages, resolveBoundingBoxes]
  )

  const prevEmphasizedBoundingBoxes = usePrevious(emphasizedBoundingBoxes)

  React.useEffect(() => {
    setTimeout(() => {
      if (emphasizedBoundingBoxes?.length) {
        dispatch({
          type: "PanAndZoomTo",
          rect: getSurroundingRectForBoxes(emphasizedBoundingBoxes),
        })
      } else if (prevEmphasizedBoundingBoxes?.length && !emphasizedBoundingBoxes?.length) {
        dispatch({ type: "ResetViewport", animate: true })
      }
    }, 0)
  }, [emphasizedBoundingBoxes, prevEmphasizedBoundingBoxes, dispatch])

  const onTransitionEnd = React.useCallback(
    (e: React.TransitionEvent) => {
      if (e.propertyName === "transform") {
        dispatch({ type: "PanAndZoomEnd" })
      }
    },
    [dispatch]
  )

  const reset = React.useCallback(() => {
    dispatch({
      type: "ResetViewport",
      animate: true,
      // Page index is -1 when no page is currently visible, but reset should
      // always default to going to the first page
      pageIndex: Math.max(0, activePageIndex),
    })
  }, [dispatch, activePageIndex])

  return (
    <Wrapper className={className}>
      <Controls
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        pageUp={pageUp}
        pageDown={pageDown}
        reset={reset}
        activePageIndex={activePageIndex}
        showResetButton={!state.viewport.zoomedToFit}
        pageCount={displayedPages.length}
        animating={state.viewport.animating}
      />
      <ViewerContainer tabIndex={0} data-document-viewer={true}>
        <Viewer ref={measureRef}>
          <StyledSvg
            ref={svgRef}
            dragging={state.viewport.dragging}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...listenerProps}
          >
            <defs>
              <clipPath id="imageCorners">
                <rect id="rect" x="0" y="0" width="100%" height="100%" rx="16" ry="16" />
              </clipPath>
            </defs>
            <PanAndZoomGroup
              transform={toSVG(state.viewport.matrix)}
              animating={state.viewport.animating}
              onTransitionEnd={onTransitionEnd}
            >
              {displayedPages.map((page, index) => (
                <DocumentViewerPage
                  svgRef={svgRef}
                  key={page.fileId}
                  dispatch={dispatch}
                  index={index}
                  pages={displayedPages}
                  showDebugColors={state.showDebugColors}
                  resolveBoundingBoxes={resolveBoundingBoxes}
                  onBoundingBoxClick={onBoundingBoxClick}
                  onBoundingBoxMouseEnter={onBoundingBoxMouseEnter}
                  onBoundingBoxMouseLeave={onBoundingBoxMouseLeave}
                />
              ))}
            </PanAndZoomGroup>
          </StyledSvg>
        </Viewer>
      </ViewerContainer>
    </Wrapper>
  )
}
