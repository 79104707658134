import * as React from "react"
import { Period } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { ComponentDelta } from "src/frontend/components/Shared/Assistant/Messages/Layout/Shared/ComponentDelta"
import { TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"

/*
  STYLES
*/

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`

const Titles = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: space-between;
`

const Subject = styled.div`
  display: flex;
  gap: 6px;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const SubjectName = styled.div`
  font-size: 16px;
  font-weight: ${fonts.weight.heavy};
  margin-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const TimeseriesInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const Date = styled.div<{ left?: boolean }>`
  font-size: 10px;
  font-weight: ${fonts.weight.heavy};
  text-align: ${({ left }) => (left ? "left" : "right")};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${colors.translucentSecondary70};
`

/*
  INTERFACES
*/

interface ComponentSummaryProps {
  className?: string
  subjectName: string | undefined
  timeseries: TimeseriesValue[]
  selectedIndex?: number
  period: Period | undefined
  icon?: React.ReactNode
  showTotal?: boolean
}

/*
  COMPONENTS
*/

export const ComponentHeader: React.FC<ComponentSummaryProps> = ({
  className,
  subjectName,
  timeseries,
  selectedIndex,
  period,
  icon,
  showTotal,
}) => {
  const timeseriesToDisplay = timeseries[selectedIndex ?? -1]
  const total = React.useMemo(
    () =>
      timeseries.reduce(
        (agg, t) => moneyFlowHelper.add(agg, t.moneyFlow),
        moneyFlowHelper.buildZeroMoneyFlow()
      ),
    [timeseries]
  )

  const displayTime = timeseriesToDisplay
    ? dateTimeHelper.displayNameFromPeriod(timeseriesToDisplay.period)
    : period?.name

  return (
    <Header className={className}>
      <Titles>
        <Subject>
          {icon}
          <SubjectName>{subjectName}</SubjectName>
        </Subject>
        <TimeseriesInfo>
          {timeseriesToDisplay ? (
            <ComponentDelta
              value={timeseriesToDisplay.moneyFlow}
              delta={timeseriesToDisplay.deltaPrevious}
            />
          ) : (
            showTotal && <ComponentDelta value={total} />
          )}
        </TimeseriesInfo>
      </Titles>
      <Date left={!showTotal}>{displayTime}</Date>
    </Header>
  )
}
