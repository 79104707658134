import * as React from "react"
import {
  DimensionSortMode,
  useReadTextComponentDataQuery,
  ViewIdentifier,
} from "@digits-graphql/frontend/graphql-bearer"

/**
 * Fetches layout component data fitting the GQL schema shape: Hovers.
 */
export function useTextComponentData(
  layoutId: string,
  viewId: ViewIdentifier,
  dataId: string | undefined | null
) {
  const { data, loading } = useReadTextComponentDataQuery({
    variables: {
      dataId: dataId ?? "",
      layoutId,
      viewId,
      // Limit and sort affect the top entities/transactions lists in the hovers
      limit: 5,
      sort: DimensionSortMode.Total,
    },
    skip: !dataId,
  })

  return React.useMemo(() => {
    const componentData = data?.readComponentData.data

    // Resolves the component data union type to the portion we care about
    if (componentData?.__typename === "EntityHovers") {
      return { data: componentData, loading }
    }

    return { data: undefined, loading }
  }, [data?.readComponentData.data, loading])
}
