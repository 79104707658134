import { ViewType } from "@digits-graphql/frontend/graphql-bearer"
import envHelper from "@digits-shared/helpers/envHelper"
import { devtools, redux } from "zustand/middleware"
import { createWithEqualityFn } from "zustand/traditional"
import { LayoutBuilderAction } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/actions"
import {
  initialBuilderState,
  reducer,
} from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/reducer"
import { LayoutBuilderState } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/types"

export const useLayoutStore = envHelper.isProduction()
  ? createWithEqualityFn(
      redux<LayoutBuilderState, LayoutBuilderAction>(
        reducer,
        initialBuilderState({
          legalEntityId: "",
          viewType: ViewType.Ledger,
          viewVersion: "",
        })
      )
    )
  : createWithEqualityFn(
      devtools(
        redux<LayoutBuilderState, LayoutBuilderAction, [["zustand/devtools", never]]>(
          reducer,
          initialBuilderState({
            legalEntityId: "",
            viewType: ViewType.Ledger,
            viewVersion: "",
          })
        ),
        { name: "Layout store" }
      )
    )

export function useLayoutDispatch() {
  return useLayoutStore((state) => state.dispatch)
}
