import * as React from "react"
import ReactDOM from "react-dom"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgAlertTriangle } from "@digits-shared/components/SVGIcons/line/AlertTriangle.svg"
import { Modal, ModalCenterPositioning } from "@digits-shared/components/UI/Elements/Modals/Modal"
import { ButtonVariant, DigitsButton } from "@digits-shared/DesignSystem/Button"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import colors from "@digits-shared/themes/colors"
import fonts, { BodyText, H2Text } from "@digits-shared/themes/typography"
import { css, styled } from "styled-components"

const ModalContent = styled.div`
  position: relative;
  width: 480px;
  background: ${colors.white};
  border-radius: 16px;
`

const ICON_STYLES = css`
  height: 22px;
  width: 22px;
  ${svgPathStyles(colors.secondary, 1.5)};
`

const Header = styled(H2Text)`
  font-weight: ${fonts.weight.roman};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 24px;
`

const Content = styled(BodyText)`
  padding: 0 24px 24px;
`

const Footer = styled.div`
  border-top: 1px solid ${colors.secondary10};
  height: 72px;
  padding: 16px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const TertiaryButton = styled(DigitsButton)`
  margin-right: auto;
`

/*
 * INTERFACES
 */
interface AlertModalProps {
  headerLabel: string
  HeaderIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
  disabled?: boolean
  disableAll?: boolean

  primaryButtonLabel: string
  primaryButtonVariant?: ButtonVariant
  primaryButtonClick: (e?: React.MouseEvent) => void

  secondaryButtonLabel?: string
  secondaryButtonClick?: (e?: React.MouseEvent) => void

  tertiaryButtonLabel?: string
  tertiaryButtonClick?: (e?: React.MouseEvent) => void
}

/*
 * COMPONENTS
 */

export const AlertModal: React.FC<React.PropsWithChildren<AlertModalProps>> = ({
  HeaderIcon = SvgAlertTriangle,
  headerLabel,
  children,
  disabled,
  disableAll,
  primaryButtonLabel,
  secondaryButtonLabel,
  tertiaryButtonLabel,
  primaryButtonClick,
  secondaryButtonClick,
  tertiaryButtonClick,
  primaryButtonVariant,
}) => {
  const modalRoot = useModalRoot()

  const hasSecondaryButton = React.useMemo(
    () => !!secondaryButtonLabel && !!secondaryButtonClick,
    [secondaryButtonLabel, secondaryButtonClick]
  )

  const hasTertiaryButton = React.useMemo(
    () => !!tertiaryButtonLabel && !!tertiaryButtonClick,
    [tertiaryButtonLabel, tertiaryButtonClick]
  )

  return ReactDOM.createPortal(
    <Modal useCustomModalContainer>
      <ModalCenterPositioning>
        <ModalContent>
          <Header>
            <HeaderIconStyled Icon={HeaderIcon} css={ICON_STYLES} />
            {headerLabel}
          </Header>
          <Content>{children}</Content>
          <Footer>
            {hasTertiaryButton && (
              <TertiaryButton
                onClick={tertiaryButtonClick}
                $variant="ghost-dark"
                disabled={disableAll}
              >
                {tertiaryButtonLabel}
              </TertiaryButton>
            )}

            {hasSecondaryButton && (
              <DigitsButton
                onClick={secondaryButtonClick}
                $variant="secondary-dark"
                disabled={disableAll}
              >
                {secondaryButtonLabel}
              </DigitsButton>
            )}

            <DigitsButton
              onClick={primaryButtonClick}
              $variant={primaryButtonVariant}
              disabled={disabled || disableAll}
            >
              {primaryButtonLabel}
            </DigitsButton>
          </Footer>
        </ModalContent>
      </ModalCenterPositioning>
    </Modal>,
    modalRoot
  )
}

const HeaderIconStyled: React.FC<{
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  className?: string
}> = ({ Icon, className }) => <Icon className={className} />
