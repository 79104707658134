import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgMessageCheckSquare } from "@digits-shared/components/SVGIcons/line/MessageCheckSquare.svg"
import { ButtonProps, DigitsButton } from "@digits-shared/DesignSystem/Button"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { GroupView } from "src/frontend/components/Shared/NavSidebar/ActionItemsV2/useGroupView"

/*
  STYLES
*/

const HeaderContainer = styled.div`
  padding: 0 16px;
`

const ActionItemsIcon = styled(SvgMessageCheckSquare)`
  width: 20px;
  height: 20px;
  ${svgPathStyles(colors.secondary, 2.5)};
`

const HeaderText = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  margin-top: 21px;
  flex-shrink: 0;
  font-weight: ${fonts.weight.heavy};
  font-size: 16px;
  user-select: none;
  gap: 8px;
`

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
`

const ButtonGroup = styled.div`
  display: flex;
`

const GroupViewButton = styled(DigitsButton)<ButtonProps & { active: boolean }>`
  color: ${({ active }) => (active ? colors.secondary : colors.lightDark)};
  transition: color 100ms ease-in-out;
  padding: 0px 8px;
  &:hover:not([disabled]) {
    background: transparent;
    color: ${colors.secondary};
  }
  &:focus:not([disabled]) {
    background: transparent;
  }
`

/*
  COMPONENTS
*/

interface GroupViewProps {
  groupView: GroupView
  setViewOpen: () => void
  setViewResolved: () => void
}

export const ActionItemsHeader: React.FC<GroupViewProps> = ({
  groupView,
  setViewResolved,
  setViewOpen,
}) => (
  <HeaderContainer>
    <HeaderText>
      <ActionItemsIcon />
      Action Items
    </HeaderText>
    <HeaderContent>
      <ButtonGroup>
        <GroupViewButton
          autoFocus
          $variant="ghost-dark"
          size="medium"
          active={groupView === "Open"}
          onClick={setViewOpen}
        >
          Open
        </GroupViewButton>
        <GroupViewButton
          $variant="ghost-dark"
          size="medium"
          active={groupView === "Resolved"}
          onClick={setViewResolved}
        >
          Resolved
        </GroupViewButton>
      </ButtonGroup>
    </HeaderContent>
  </HeaderContainer>
)
