import * as React from "react"
import {
  BalanceSummary,
  BalanceSummary as GqlBalanceSummary,
  Category,
  DirectionFromOrigin,
  IntervalOrigin,
  PartyRole,
  ProductArea,
  useSummarizeTransactionsByTimeQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { useInvertValues } from "@digits-shared/components/Contexts/InvertValuesContext"
import dateTimeHelper, { DateFormat } from "@digits-shared/helpers/dateTimeHelper"
import deltaHelper from "@digits-shared/helpers/deltaHelper"
import numberHelper from "@digits-shared/helpers/numberHelper"
import { useHighlight } from "src/frontend/components/OS/Details/Shared/hooks/useHighlight"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

export function useSummarizeBalances(periodBalances: BalanceSummary[], loading: boolean) {
  const highlight = useHighlight()
  const invertValues = useInvertValues()
  const intervalOrigin = useIntervalOrigin()

  return React.useMemo(() => {
    const periodStartSummary = periodBalances.length ? periodBalances[0] : undefined
    const periodStartValue = periodStartSummary
      ? periodStartSummary.total.value
      : numberHelper.buildMonetaryAmount(0)

    const lastIndex = highlight ?? periodBalances.length - 1
    const endingBalance = periodBalances[lastIndex]
    const endingValue = endingBalance
      ? endingBalance.total.value
      : numberHelper.buildMonetaryAmount(0)

    const balanceDeltaPercent = deltaHelper.deltaPercentageForAmount(
      endingValue.amount,
      endingValue.amount - periodStartValue.amount,
      invertValues
    )

    const balanceDeltaDirection = deltaHelper.deltaDirectionForValue({
      delta: balanceDeltaPercent,
      invertValues,
      amount: endingValue.amount,
    })

    const balanceDeltaColor = deltaHelper.deltaValueColor({
      delta: balanceDeltaPercent,
      invertValues,
      amount: endingValue.amount,
    })

    const balanceDeltaText = balanceDeltaPercent
      ? numberHelper.formatPercentage(Math.abs(balanceDeltaPercent))
      : null

    const balanceDeltaSince = periodStartSummary
      ? dateTimeHelper.displayNameFromUnixTimestamp(
          periodStartSummary.period.endedAt,
          intervalOrigin.interval,
          DateFormat.Default
        )
      : "Period Start"

    return {
      balanceDeltaText,
      balanceDeltaSince,
      balanceDeltaDirection,
      balanceDeltaColor,
      endingValue,
      periodBalances,
      loading,
    }
  }, [periodBalances, highlight, intervalOrigin.interval, invertValues, loading])
}

export function useSummarizePartyAndRoleBalances(
  partyId: string,
  partyRole: PartyRole,
  origin?: IntervalOrigin
) {
  const intervalOrigin = useIntervalOrigin()
  const viewKey = useViewVersion()

  const { loading, data } = useSummarizeTransactionsByTimeQuery({
    variables: {
      origin: origin ?? intervalOrigin,
      filter: {
        viewKey,
        partyId,
        partyRole,
      },
      direction: DirectionFromOrigin.Past,
      asPermanent: true,
    },
    context: { avsBatch: true },
  })

  const periodBalances = React.useMemo(
    () =>
      (data?.dimensionalTransactionSummary?.time || [])
        .map(
          // TODO this is the right shape for this to work out, but this is a temporary approach
          (timeSlice) => timeSlice.summary as GqlBalanceSummary
        )
        .sort((a, b) => a.period.endedAt - b.period.endedAt),
    [data?.dimensionalTransactionSummary?.time]
  )

  return useSummarizeBalances(periodBalances, loading)
}

export function useSummarizeCategoryBalances(category: Category, origin?: IntervalOrigin) {
  const intervalOrigin = useIntervalOrigin()
  const viewKey = useViewVersion()

  // TODO: does not support ugly other (from category details context)
  const { loading, data } = useSummarizeTransactionsByTimeQuery({
    variables: {
      origin: origin ?? intervalOrigin,
      filter: {
        viewKey,
        categoryId: category.id,
        productArea: ProductArea.None,
      },
      direction: DirectionFromOrigin.Past,
      asPermanent: true,
    },
    context: { avsBatch: true },
  })

  const periodBalances = React.useMemo(
    () =>
      (data?.dimensionalTransactionSummary?.time || [])
        .map(
          // TODO this is the right shape for this to work out, but this is a temporary approach
          (timeSlice) => timeSlice.summary as GqlBalanceSummary
        )
        .sort((a, b) => a.period.endedAt - b.period.endedAt),
    [data?.dimensionalTransactionSummary?.time]
  )

  return useSummarizeBalances(periodBalances, loading)
}
