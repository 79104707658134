import { Link } from "react-router-dom"
import { ButtonProps, DigitsButtonStyle } from "@digits-shared/DesignSystem/Button"
import styled from "styled-components"

/**
 * Digits Link Button
 *
 * Styles a <Link /> to look like the Digits Button.
 * Defaults to a primary link button with default size and rounded shape.
 * LinkButtons can have a custom width, but height should not be adjusted.
 */
export const DigitsLinkButton = styled(Link)<ButtonProps>`
  ${DigitsButtonStyle};
`
/*
 * Styles an <a/> TAG to look like the Digits Button.
 * Use it only for external links, otherwise use DigitsLinkButton
 */
export const AnchorLinkButton = styled.a<ButtonProps>`
  ${DigitsButtonStyle};
`
