import * as React from "react"
import { IntervalOrigin } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper, { TimeRange } from "@digits-shared/helpers/dateTimeHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import styled, { CSSProp } from "styled-components"
import { DropDownContainer } from "src/frontend/components/Shared/Reports/Report/Options/OptionsShared"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import { DateRangeSelector } from "src/shared/components/DateRangeSelector/DateRangeSelector"
import { ActivePeriod } from "src/shared/components/PeriodSelector/ActivePeriod"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

const PeriodRangeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PeriodSelector: React.FC<{
  className?: string
  singleInterval?: boolean
  includeDays?: boolean
  includeMonths?: boolean
  includeQuarters?: boolean
  includeYears?: boolean
  dateRangeCSS?: CSSProp
  todayView?: boolean
  showPresets?: boolean
}> = ({
  className,
  includeDays = false,
  includeMonths = true,
  includeQuarters = true,
  includeYears = true,
  singleInterval = true,
  dateRangeCSS,
  todayView,
  showPresets = true,
}) => {
  const contextOrigin = useIntervalOrigin()
  const generatePath = useFrontendPathGenerator()
  const { location, history, search } = useRouter()

  const intervalCount = parseInt(search.get("intervalCount") ?? "", 10) || undefined
  const origin = React.useMemo(
    () => ({ ...contextOrigin, intervalCount: intervalCount }),
    [contextOrigin, intervalCount]
  )
  const [activeOrigin, setActiveOrigin] = React.useState<IntervalOrigin>(origin)
  React.useEffect(() => {
    setActiveOrigin(origin)
  }, [origin])

  const containerRef = React.useRef<HTMLDivElement>(null)
  const {
    value: isRangeSelectorShown,
    setFalse: hideRangeSelector,
    setTrue: showRangeSelector,
  } = useStateBoolean()

  const onTimeChange = React.useCallback((timeRange: TimeRange) => {
    setActiveOrigin(dateTimeHelper.intervalOriginFromRange(timeRange))
  }, [])

  const onTimeRange = React.useCallback(
    (timeRange: TimeRange) => {
      const newIntervalOrigin = dateTimeHelper.intervalOriginFromRange(timeRange)
      if (newIntervalOrigin.intervalCount === 1) newIntervalOrigin.intervalCount = undefined

      const urlParams = {
        ...newIntervalOrigin,
      }

      const path = generatePath(routes[location.name], urlParams, ["other"])
      history.replace(path)
      hideRangeSelector()
    },
    [generatePath, hideRangeSelector, history, location.name]
  )

  const onCancel = React.useCallback(() => {
    setActiveOrigin(origin)
    hideRangeSelector()
  }, [hideRangeSelector, origin])

  return (
    <PeriodRangeContainer ref={containerRef} className={className}>
      <DropDownContainer
        css="position: relative;gap: 6px; cursor: pointer;"
        onClick={showRangeSelector}
      >
        <ActivePeriod todayView={todayView} editable activeIntervalOrigin={activeOrigin} />
      </DropDownContainer>

      {isRangeSelectorShown && (
        <DateRangeSelector
          css={dateRangeCSS}
          positionRef={containerRef}
          defaultValue={origin}
          onChange={onTimeChange}
          onTimeRange={onTimeRange}
          onCancel={onCancel}
          includeDays={includeDays}
          includeMonths={includeMonths}
          includeQuarters={includeQuarters}
          includeYears={includeYears}
          singleInterval={singleInterval}
          showPresets={showPresets}
        />
      )}
    </PeriodRangeContainer>
  )
}
