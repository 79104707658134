import * as React from "react"
import { CategoryType } from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import stringHelper from "@digits-shared/helpers/stringHelper"
import { useLayoutContext } from "src/frontend/components/Shared/Assistant/Messages/Layout/Context"
import { TopPeriodTitle } from "src/frontend/components/Shared/Assistant/Messages/Layout/List/TopPeriodTitle"
import { useTopTransactionsComponentData } from "src/frontend/components/Shared/Assistant/Messages/Layout/List/useTopTransactionComponentData"
import { MatchedComponent } from "src/frontend/components/Shared/Layout/types"
import { TopTransactionsList } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopTransactionsList"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import { invertValuesForCategoryType } from "src/shared/helpers/categoryHelper"

/*
  COMPONENTS
*/

export const TopTransactions: React.FC<{
  component: MatchedComponent<"topTransactions">
}> = ({ component }) => {
  const { layoutId, viewKey } = useLayoutContext()

  const {
    config: { topTransactions: config },
    dataId,
  } = component

  const role = React.useMemo(
    () => (config.partyRole ? FrontendPartyRole.findByRole(config.partyRole) : undefined),
    [config.partyRole]
  )

  const invertValues = React.useMemo(() => {
    if (role) {
      return role?.invertValues ?? false
    }

    return invertValuesForCategoryType(config.categoryType ?? CategoryType.UnknownType)
  }, [config.categoryType, role])

  const countDisplay = React.useMemo(() => {
    if (role) {
      return config.limit < 2
        ? `${role.displayName} Transaction`
        : `${role.pluralDisplayName} Transactions`
    }

    if (config.categoryType) {
      const typeName = stringHelper.camelCaseToSpaces(config.categoryType, true)

      return config.limit < 2 ? `${typeName} Transaction` : `${typeName} Transactions`
    }

    return undefined
  }, [config.categoryType, config.limit, role])

  const { data, loading } = useTopTransactionsComponentData(config, layoutId, viewKey, dataId)

  if (!countDisplay) return null

  return (
    <InvertValuesContext.Provider value={invertValues}>
      <TopPeriodTitle intervalOrigin={config?.origin}>Top {countDisplay}</TopPeriodTitle>
      <TopTransactionsList padding={10} transactions={data?.transactions} loading={loading} />
    </InvertValuesContext.Provider>
  )
}
