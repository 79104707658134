import * as React from "react"
import useConstant from "@digits-shared/hooks/useConstant"
import { usePopOverState } from "@digits-shared/hooks/usePopOverState"
import { PARTY_HOVER_WIDTH } from "src/shared/components/PartyHover/Styles"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

export interface PartyHoverState {
  isHoverVisible: boolean
  shouldHoverDown: boolean
  shouldHoverLeft: boolean
}

interface PartyIconHoverState {
  state: PartyHoverState
  elementRef: React.MutableRefObject<HTMLDivElement | null>
  onMouseEnter: (e: React.MouseEvent) => void
  onMouseLeave: (e: React.MouseEvent) => void
  preventClickBubbling: (event: React.MouseEvent<Element, MouseEvent>) => void
  clickPropagationOptions: object
  shouldShowHover: boolean
}

const DEFAULT_STATE: PartyHoverState = {
  isHoverVisible: false,
  shouldHoverDown: false,
  shouldHoverLeft: false,
}

type PartyHoverAction = { type: "MouseEnter" | "MouseLeave" }

function overflowParent(element: HTMLElement | null) {
  let e = element
  while (e) {
    const style = getComputedStyle(e)
    if (style.overflow === "auto" || style.overflowY === "auto") {
      return e
    }
    e = e.parentElement
  }
  return null
}

export function usePartyIconHoverState(
  partyId: string,
  isPreview: boolean,
  neverHover: boolean
): PartyIconHoverState {
  const elementRef = React.useRef<HTMLDivElement | null>(null)
  const partyIconReducer = React.useCallback(
    (state: PartyHoverState, action: PartyHoverAction): PartyHoverState => {
      switch (action.type) {
        case "MouseEnter": {
          const element = elementRef.current
          const scrollContainer = overflowParent(element)
          const parentRect = scrollContainer?.getBoundingClientRect()
          if (!element || !parentRect) {
            return { ...state }
          }
          const elementRect = element.getBoundingClientRect()
          const elementTop = elementRect.top
          const elementLeft = elementRect.left

          // Don't allow a hover down if it is too close to the bottom of the viewport
          const distanceFromBottomOfViewport =
            elementTop && parentRect.height ? parentRect.height - elementTop : 0

          // Hover to the right if it is too close to the right edge of the parent rect
          const shouldHoverLeft =
            elementLeft + PARTY_HOVER_WIDTH > parentRect.left + parentRect.width

          return {
            ...state,
            isHoverVisible: true,
            shouldHoverDown: !distanceFromBottomOfViewport || distanceFromBottomOfViewport > 100,
            shouldHoverLeft,
          }
        }
        case "MouseLeave":
          return { ...state, ...DEFAULT_STATE }
      }
    },
    []
  )
  const [state, dispatch] = React.useReducer<React.Reducer<PartyHoverState, PartyHoverAction>>(
    partyIconReducer,
    {
      ...DEFAULT_STATE,
    }
  )

  const onStateChange = React.useCallback(
    (visible: boolean) => {
      if (visible) {
        // TODO: We sometimes get back transactions with no party due to our classification rules
        // In this event, we have no party to request a hover up for, so prevent an error
        // by not showing the hover.
        if (!partyId || isPreview || !elementRef.current) return

        return dispatch({ type: "MouseEnter" })
      }
      return dispatch({ type: "MouseLeave" })
    },
    [partyId, isPreview]
  )

  const { onMouseLeave, onMouseEnter } = usePopOverState({ onStateChange })

  const clickPropagationOptions = useConstant({ preventDefault: true })

  const preventClickBubbling = useStopClickPropagation(clickPropagationOptions)

  const shouldShowHover = React.useMemo(
    () => (state.isHoverVisible || isPreview) && !neverHover,
    [state.isHoverVisible, isPreview, neverHover]
  )

  return {
    state,
    elementRef,
    onMouseLeave,
    onMouseEnter,
    clickPropagationOptions,
    preventClickBubbling,
    shouldShowHover,
  }
}
