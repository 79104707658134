import * as React from "react"
import {
  CategoryType,
  DirectionFromOrigin,
  EntityCategory,
  Interval,
  IntervalOrigin,
  ProductArea,
  SummarizeTransactionsByTimeQueryVariables,
} from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import MatchedText from "src/frontend/components/OS/Springboard/Applications/Search/SearchResults/MatchedText"
import {
  ResultRow,
  ResultTitle,
  RowProps,
} from "src/frontend/components/OS/Springboard/Applications/Search/SearchResults/ResultRow"
import SearchResultActivitySummary from "src/frontend/components/OS/Springboard/Applications/Search/SearchResults/SearchResultActivitySummary"
import { CategorySearchResultBalanceSummary } from "src/frontend/components/OS/Springboard/Applications/Search/SearchResults/SearchResultBalanceSummary"
import { ProductAreaContext } from "src/frontend/components/Shared/Contexts/ProductAreaContext"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import routes from "src/frontend/routes"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import { invertValuesForCategory } from "src/shared/helpers/categoryHelper"

/*
  STYLE
*/

const Details = styled.div`
  display: flex;
  align-items: center;
`

const CategoryTile = styled(ResultTitle)`
  flex: 1;
`

const CategoryIconContainer = styled.div<{ name: string }>`
  padding: 3px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
`

const CategorySVGIcon = styled(SVGIconComponent)`
  width: 24px;
  height: 24px;
  padding: 1px;
  background: transparent;
  ${svgIconStyles(colors.translucentSecondary70)};
`

/*
  INTERFACE
*/

interface SummaryProps {
  category: EntityCategory
  origin: IntervalOrigin
}

/*
  COMPONENT
*/

const CategoryResult: React.FC<RowProps<EntityCategory>> = ({ entity, hit }) => {
  const origin = dateTimeHelper.todayIntervalOrigin(Interval.Month, 12)
  const path = routes.categoryDetails.generateFromCurrentPath({ categoryId: entity.id, ...origin })

  return (
    <ResultRow to={path}>
      <Details>
        <CategoryIcon category={entity} css="margin-right: 10px;" />
        <CategoryTile>
          <MatchedText fieldName="name" highlights={hit.highlights}>
            {entity.name}
          </MatchedText>
        </CategoryTile>
        <CategorySummary category={entity} origin={origin} />
      </Details>
    </ResultRow>
  )
}

export default CategoryResult

const CategoryIcon: React.FC<{ category: EntityCategory }> = ({ category }) => (
  <CategoryIconContainer name={category.displayKey || category.name || ""}>
    <CategorySVGIcon subjectDisplayKey={category.displayKey || category.name || ""} />
  </CategoryIconContainer>
)

const CategorySummary: React.FC<SummaryProps> = ({ category, origin }) => {
  const viewKey = useViewVersion()
  const productArea = ProductArea.All

  const showBalanceSummary =
    category.type === CategoryType.Assets || category.type === CategoryType.Liabilities

  const variables: SummarizeTransactionsByTimeQueryVariables = {
    filter: {
      viewKey,
      categoryId: category.id,
      productArea,
    },
    origin: {
      ...origin,
      intervalCount: 12,
    },
    direction: DirectionFromOrigin.Past,
  }

  return (
    <ProductAreaContext.Provider value={productArea}>
      <InvertValuesContext.Provider value={invertValuesForCategory(category)}>
        {showBalanceSummary ? (
          <CategorySearchResultBalanceSummary category={category} origin={origin} />
        ) : (
          <SearchResultActivitySummary variables={variables} />
        )}
      </InvertValuesContext.Provider>
    </ProductAreaContext.Provider>
  )
}
