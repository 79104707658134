import * as React from "react"
import { StatementDeltas } from "@digits-graphql/frontend/graphql-bearer"
import stringHelper from "@digits-shared/helpers/stringHelper"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import {
  DetailsPopUp,
  MAIN_CONTENT_BACKGROUND,
} from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/DetailsPopUp"
import { OverTimeChart } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/OverTimeChart"
import { useBalanceDetails } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/useBalanceDetails"
import { PopOverPositioning } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/usePopOverPositioning"
import {
  SummaryLineRowDetails,
  useToTimeSeries,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/toDetailsData"

/*
  STYLES
*/

const SectionName = styled.div`
  border-radius: ${borders.radius.highlight}px ${borders.radius.highlight}px 0 0;
  padding: 15px 20px 11px;
  font-size: 16px;
  font-weight: ${fonts.weight.heavy};
  white-space: nowrap;
  background: ${MAIN_CONTENT_BACKGROUND};
  color: ${colors.secondary};
`

/*
  INTERFACES
*/

interface PopOverProps {
  rowId: string
  details: SummaryLineRowDetails
  deltas?: StatementDeltas
}

/*
  COMPONENTS
*/

export const SummaryLineItemPopOver: React.FC<PopOverProps & PopOverPositioning> = ({
  rowId,
  details,
  deltas,
  positionRef,
  measureRef,
  measureRect,
  position,
}) => {
  const sectionName = stringHelper.camelCaseToSpaces(details.periodDetails.hover.lineType)

  return (
    <DetailsPopUp
      ref={measureRef}
      rowId={rowId}
      details={details}
      measureRect={measureRect}
      positionRef={positionRef}
      width={420}
      maxHeight="auto"
    >
      <div>
        <SectionName>{sectionName}</SectionName>
        <SectionDetailsChart rowId={rowId} details={details} deltas={deltas} />
      </div>
    </DetailsPopUp>
  )
}

const SectionDetailsChart: React.FC<PopOverProps> = ({ details, deltas }) => {
  const amountOverTime = useToTimeSeries(details.periodDetails)

  return (
    <OverTimeChart
      amountOverTime={amountOverTime}
      deltas={deltas}
      css={`
        border-radius: 0 0 ${borders.radius.highlight}px ${borders.radius.highlight}px;
      `}
      isBalance={useBalanceDetails(details.periodDetails)}
    />
  )
}
