import * as React from "react"
import { Scope, ScopeTuple } from "@digits-shared/scope/scope"
import { useProvideScopes } from "@digits-shared/scope/useScopes"

export const ScopeContext = React.createContext<ScopeTuple<unknown>[]>([])

export type ScopeProviderProps<T> = {
  scope: Scope<T>
  value: T
}

export function ScopeProvider<T>({
  value,
  scope,
  children,
}: React.PropsWithChildren<ScopeProviderProps<T>>): ReturnType<React.FC> {
  const providedTuple: ScopeTuple<T> = React.useMemo(() => [scope, value], [scope, value])

  const providedScopes = useProvideScopes(providedTuple)

  return <ScopeContext.Provider value={providedScopes}>{children}</ScopeContext.Provider>
}
