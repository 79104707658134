import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"

export const SIDEBAR_COLLAPSED_WIDTH = 75
export const SIDEBAR_WIDTH = 246
export const SIDEBAR_SIDE_MARGIN = 24

export const ACTIVE_COLOR = colors.accentBlue
export const ACTIVE_CLASSNAME = "sidebar-active-row"

export const SidebarContentOffsetVar = css`
  --sidebar-content-offset: ${SIDEBAR_COLLAPSED_WIDTH + SIDEBAR_SIDE_MARGIN}px;

  @media only screen and (min-width: 1550px) {
    --sidebar-content-offset: ${SIDEBAR_WIDTH + SIDEBAR_SIDE_MARGIN}px;
  }
`

export const SidebarDivider = styled.div`
  margin: 15px 22px;
  width: -webkit-fill-available;
  border: 0.5px solid ${colors.secondary20};
`

export const SIDEBAR_SVG_STYLES = css`
  height: 24px;
  width: 24px;
  display: block;
  margin: 0 auto;
  ${svgIconStyles(colors.secondary)};
`

export const SIDEBAR_PATH_SVG_STYLES = css`
  height: 24px;
  width: 24px;
  display: block;
  margin: 0 auto;
  ${svgPathStyles(colors.secondary, 1.5)};
`
