import * as React from "react"
import { ActionItemKind, EntityUser } from "@digits-graphql/frontend/graphql-bearer"
import userHelper from "@digits-shared/helpers/userHelper"
import { ActionItemRow } from "src/frontend/components/Shared/NavSidebar/ActionItemsV2/Row/ActionItemRow"
import { NameDateDetail } from "src/frontend/components/Shared/NavSidebar/ActionItemsV2/Row/NameAndDate"
import { DIGITS_CANONICAL_USER_ID } from "src/shared/config/identifiers"

export const ReportRow: React.FC<{ title: string; author?: EntityUser | null; date: string }> = ({
  title,
  author,
  date,
}) => (
  <ActionItemRow
    actionItemKind={ActionItemKind.Report}
    title={title || "New Report"}
    party={{
      name: author?.givenName,
      iconUrl: author?.avatarUrl,
    }}
  >
    {author && (
      <NameDateDetail
        date={date}
        secondary={
          author.id === DIGITS_CANONICAL_USER_ID
            ? "Digits AI"
            : userHelper.displayName(author, "abbreviateLast")
        }
      />
    )}
  </ActionItemRow>
)
