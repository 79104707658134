import { PopUp } from "@digits-shared/components/UI/Elements/PopUp/PopUp"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"

export const POPUP_BACKGROUND = themedValue({
  dark: "linear-gradient(180deg, #10254F 0%, rgba(16, 37, 79, 0.75) 40.1%, #10254F 100%);",
  light: colors.white,
})

/*
  STYLES
*/

export const DetailsPopUp = styled(PopUp)`
  display: block;
  font-size: 12px;
  background: ${POPUP_BACKGROUND};
  margin: 0 0 50px 10px;
  left: -20px;
  top: 100%;
  border: none;
  box-shadow: 0 0 34px rgba(0, 0, 0, 0.5);
`
