import * as React from "react"
import ReactDOM from "react-dom"
import { ArrivalContext, ObjectIdentifier } from "@digits-graphql/frontend/graphql-bearer"
import { Modal, ModalAnchor } from "@digits-shared/components/UI/Elements/Modals/Modal"
import { BackButton } from "@digits-shared/DesignSystem/BackButton"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { Content } from "src/frontend/components/OS/Springboard/Applications/ClientPortal/InviteFlow/Content/Content"
import { FailureBanner } from "src/frontend/components/OS/Springboard/Applications/ClientPortal/InviteFlow/FailureBanner"
import {
  MODAL_HEIGHT,
  MODAL_RADIUS,
  MODAL_WIDTH,
} from "src/frontend/components/OS/Springboard/Applications/ClientPortal/InviteFlow/shared"
import {
  DEFAULT_STATE,
  reducer,
} from "src/frontend/components/OS/Springboard/Applications/ClientPortal/InviteFlow/State/reducer"
import { ExplosionVideo } from "src/frontend/components/Shared/ExplosionVideo"
import { InviteClientsModalState } from "src/frontend/components/Shared/Portals/State/types"
import FrontendSession from "src/frontend/session"

/*
  STYLES
*/

const OverlayStyles = css`
  backdrop-filter: unset;
`

const StyledModal = styled(Modal)`
  box-shadow: 0 0 30px ${colors.translucentBlack20};
  background: none;
  border: none;
  width: unset;
  height: unset;

  border-radius: ${MODAL_RADIUS}px;
  padding: 0;

  ${ModalAnchor} {
    height: 100%;
  }

  &::before {
    border: none;
  }
`

const ModalContent = styled.div`
  position: relative;
  width: ${MODAL_WIDTH}px;
  height: ${MODAL_HEIGHT}px;
`

const AboveModal = styled.div`
  position: absolute;
  bottom: 100%;
`

const StyledBackButton = styled(BackButton)`
  margin-bottom: 20px;
`

/*
  COMPONENTS
*/

export const InviteClientModal: React.FC<{
  onClose: () => void
  onDone: () => void
  initialModalState?: InviteClientsModalState
  arrivalContext?: ArrivalContext
  contextObject?: ObjectIdentifier
  backMessage?: string
  showViewUsers: boolean
}> = ({
  onClose,
  onDone,
  initialModalState,
  arrivalContext,
  contextObject,
  backMessage,
  showViewUsers,
}) => {
  const { isAffiliatedSession } = useSession<FrontendSession>()
  const modalRoot = useModalRoot()
  const [state, dispatch] = React.useReducer(reducer, {
    ...DEFAULT_STATE,
    modalState: initialModalState ?? InviteClientsModalState.InviteClients,
    arrivalContext,
    contextObject,
    showViewUsers,
  })

  const backButtonMsg = backMessage || "Back to Dashboard"

  React.useEffect(() => {
    dispatch({ type: "setIsAffiliatedSession", isAffiliatedSession })
  }, [isAffiliatedSession])

  return ReactDOM.createPortal(
    <>
      {state.modalState === InviteClientsModalState.Done && <ExplosionVideo blurPx={30} />}
      <StyledModal
        overlayStyles={
          state.modalState === InviteClientsModalState.Done ? OverlayStyles : undefined
        }
      >
        <ModalContent>
          <AboveModal>
            {state.modalState !== InviteClientsModalState.Done && (
              <StyledBackButton onClick={onClose}>{backButtonMsg}</StyledBackButton>
            )}
            <FailureBanner message={state.errorMessage} />
          </AboveModal>
          <Content dispatch={dispatch} state={state} onDone={onDone} onClose={onClose} />
        </ModalContent>
      </StyledModal>
    </>,
    modalRoot
  )
}
