import * as React from "react"
import {
  ObjectEntities,
  ObjectKind,
  ObjectKindFacet,
  PartyRole,
  SearchHit,
  SearchResult,
  SearchResultFieldsFragment,
} from "@digits-graphql/frontend/graphql-bearer"
import useSearchContext from "src/frontend/components/OS/Springboard/Applications/Search/SearchContext"
import { SearchResultType } from "src/frontend/types/SearchResultType"

type ResultsMap = Map<SearchResultType, SearchHit[]>

export function useMapResultsByType(search: SearchResultFieldsFragment | undefined): ResultsMap {
  const {
    query: { occurredAfter, occurredBefore },
  } = useSearchContext()

  return React.useMemo(() => {
    const sections = new Map<SearchResultType, SearchHit[]>()
    if (!search) return sections

    const { results, entities } = search

    results.reduce((map, hit) => {
      const types = searchHitToResultTypes(hit, entities)
      if (!types || !types.length) return map

      types.forEach((type) => {
        let hits = map.get(type)
        if (!hits) {
          hits = []
          map.set(type, hits)
        }
        hits.push(hit)
      })

      return map
    }, sections)

    // if there are any filters (time) and no results, add an empty section to allow to reset them.
    if (!sections.size && (occurredAfter || occurredBefore)) {
      sections.set(SearchResultType.Transaction, [])
    }

    return sections
  }, [search, occurredAfter, occurredBefore])
}

function searchHitToResultTypes(hit: SearchHit, entities: ObjectEntities) {
  const { kind } = hit.objectId

  switch (kind) {
    case ObjectKind.Transaction:
    case ObjectKind.Report:
    case ObjectKind.Comment:
    case ObjectKind.Category:
    case ObjectKind.User:
    case ObjectKind.Invoice:
      return [SearchResultType[kind]]

    case ObjectKind.Party: {
      const pEntity = entities.parties?.find((t) => t.id === hit.objectId.id)
      if (!pEntity || !pEntity.roles) return undefined

      return pEntity.roles
        .map((r) => partyRoleToSearchResultType(r))
        .filter((type?: SearchResultType): type is SearchResultType => !!type) as SearchResultType[]
    }

    case ObjectKind.Thread:
    case ObjectKind.DataSource:
    case ObjectKind.LegalEntity:
    case ObjectKind.ReportPackage:
    case ObjectKind.WorkItem:
    case ObjectKind.Portal:
    case ObjectKind.Bill:
    case ObjectKind.LedgerTransaction:
      TrackJS?.console.error("Invalid Entity Kind in search results:", kind)
      return undefined
  }
}

function partyRoleToSearchResultType(role: PartyRole) {
  switch (role) {
    case PartyRole.EntityVendorRole:
      return SearchResultType.Vendor

    case PartyRole.EntitySupplierRole:
      return SearchResultType.Supplier

    case PartyRole.EntityCustomerRole:
      return SearchResultType.Customer

    case PartyRole.EntityLenderRole:
      return SearchResultType.Lender

    case PartyRole.EntityOwedVendorRole:
      return SearchResultType.OwedVendor

    case PartyRole.EntityUnpaidCustomerRole:
      return SearchResultType.UnpaidCustomer

    case PartyRole.EntityPrepaidVendorRole:
      return SearchResultType.PrepaidVendor

    case PartyRole.EntityShareholderRole:
      return SearchResultType.Shareholder

    case PartyRole.EntityUnspecifiedRole:
    case PartyRole.FacilitatorRole:
    case PartyRole.InstitutionCreditRole:
    case PartyRole.InstitutionDebitRole:
    case PartyRole.PaymentProcessorRole:
    case PartyRole.EntityLiabilitiesRole:
    case PartyRole.EntityAssetRole:
    case PartyRole.OwnerRole:
    case PartyRole.UnknownRole:
      return undefined
  }
}

/*
ShareholderRole
    PrepaidVendorRole
    UnpaidCustomerRole
    OwedVendorRole
    LenderRole
    EntityLiabilitiesRole
    EntityAssetRole
 */
export function useHitsCount(
  type: SearchResultType,
  hits: SearchHit[],
  search: SearchResult
): ObjectKindFacet | undefined {
  const { kindCounts } = search
  const {
    query: { kind },
  } = useSearchContext()

  switch (type) {
    case SearchResultType.Customer:
    case SearchResultType.Supplier:
    case SearchResultType.Lender:
    case SearchResultType.OwedVendor:
    case SearchResultType.UnpaidCustomer:
    case SearchResultType.PrepaidVendor:
    case SearchResultType.Shareholder:
    case SearchResultType.Vendor:
      return { kind: ObjectKind.Party, count: hits.length }

    case SearchResultType.Category:
    case SearchResultType.Comment:
    case SearchResultType.Report:
    case SearchResultType.Transaction:
    case SearchResultType.User:
    case SearchResultType.Invoice:
      return kindCounts.find((kc) => kc.kind === kind)
  }
}
