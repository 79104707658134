import { QueryResult } from "@apollo/client"
import { useActionItemsV2BadgeCountsQuery } from "src/frontend/components/OS/Springboard/hooks/useActionItemsV2BadgeCountsQuery"
import { useBoostBadgeCountsQuery } from "src/frontend/components/OS/Springboard/hooks/useBoostBadgeCountsQuery"
import { useConnectionsBadgeCountsQuery } from "src/frontend/components/OS/Springboard/hooks/useConnectionsBadgeCountsQuery"
import { useDataSourcesBadgeCountsQuery } from "src/frontend/components/OS/Springboard/hooks/useDataSourcesBadgeCountsQuery"
import { useReportsBadgeCountsQuery } from "src/frontend/components/OS/Springboard/hooks/useReportsBadgeCountsQuery"
import { useTransactionReviewBadgeCountsQuery } from "src/frontend/components/OS/Springboard/hooks/useTransactionReviewBadgeCountsQuery"

export type BadgeCounts = {
  count: number
  legalEntityId: string
}

type BadgeQueryResult = {
  counts?: BadgeCounts[]
  loading: boolean
  refetch?: QueryResult["refetch"]
}

interface SpringboardNotificationsProps {
  name: string
  description?: string
  iconURL?: string
  notificationIconURL?: string
  generateBadgeCopy?: () => string
  badgeQuery?: (legalEntities: string[], skip?: boolean) => BadgeQueryResult
}

export class SpringboardApplication implements SpringboardNotificationsProps {
  static DigitsSearch = new SpringboardApplication({
    name: "Search",
    description: "Your business. At your fingertips.",
    iconURL: require("static/images/springboard/search.png"),
  })

  static Fuel = new SpringboardApplication({
    name: "Fuel",
    description: "Your cash. Monitored.",
    iconURL: require("static/images/springboard/fuel.png"),
  })

  static Revenue = new SpringboardApplication({
    name: "Revenue",
    description: "Your revenue. Visualized.",
    iconURL: require("static/images/springboard/cash.png"),
  })

  static SubscriptionRadar = new SpringboardApplication({
    name: "Subscription Radar",
    description: "Your repeat vendors. Tracked.",
    iconURL: require("static/images/springboard/subscriptions.png"),
  })

  static BurnControl = new SpringboardApplication({
    name: "Expenses",
    description: "Your spend. Controlled.",
    iconURL: require("static/images/springboard/burn.png"),
  })

  static DigitsReports = new SpringboardApplication({
    name: "Reports",
    description: "Financial reporting. Reimagined.",
    iconURL: require("static/images/springboard/reports.png"),
    notificationIconURL: require("static/images/springboard/reports_notification.png"),
    generateBadgeCopy: () => "UNPUBLISHED",
    badgeQuery: useReportsBadgeCountsQuery,
  })

  static QualityCheck = new SpringboardApplication({
    name: "Quality Check",
    description: "Save time. Automate your work.",
    iconURL: require("static/images/springboard/boost.png"),
    generateBadgeCopy: () => "NEW BOOSTS",
    badgeQuery: useBoostBadgeCountsQuery,
  })

  static TransactionReview = new SpringboardApplication({
    name: "Transaction Review",
    description: "Fix uncategorized transactions. Faster.",
    iconURL: require("static/images/springboard/transaction_review.png"),
    notificationIconURL: undefined, // TODO: Add this once we have a real icon.
    generateBadgeCopy: () => "ITEMS",
    badgeQuery: useTransactionReviewBadgeCountsQuery,
  })

  // Technically not an application, but needed to properly display the badge count
  static Connections = new SpringboardApplication({
    name: "Connections",
    badgeQuery: useConnectionsBadgeCountsQuery,
  })

  static ActionItemsV2 = new SpringboardApplication({
    name: "ActionItemsV2",
    badgeQuery: useActionItemsV2BadgeCountsQuery,
  })

  // Technically not an application, but needed to properly display the badge count on Settings
  static DataSources = new SpringboardApplication({
    name: "DataSources",
    badgeQuery: useDataSourcesBadgeCountsQuery,
  })

  static all: SpringboardApplication[] = Object.values(SpringboardApplication).filter(
    (application) => application instanceof SpringboardApplication
  )

  name: string
  description?: string
  iconURL?: string
  notificationIconURL?: string
  generateBadgeCopy?: () => string
  badgeQuery?: (legalEntities: string[], skip?: boolean) => BadgeQueryResult

  private constructor({
    name,
    description,
    iconURL,
    notificationIconURL,
    generateBadgeCopy,
    badgeQuery,
  }: SpringboardNotificationsProps) {
    this.name = name
    this.description = description
    this.iconURL = iconURL
    this.notificationIconURL = notificationIconURL
    this.generateBadgeCopy = generateBadgeCopy
    this.badgeQuery = badgeQuery
  }
}
