import {
  AssistantEvent,
  Comment,
  ObjectEntities,
  Thread,
} from "@digits-graphql/frontend/graphql-bearer"
import moment from "moment/moment"
import { EffectReducerExec } from "use-effect-reducer"
import { v4 as generateUUID } from "uuid"
import { DIGITS_CANONICAL_USER_ID } from "src/shared/config/identifiers"

export type AssistantMessage = Omit<AssistantEvent, "kind" | "threadId">

export interface AssistantState {
  legalEntityId?: string
  initialThreadFetch: boolean
  isNewThread: boolean
  threadId?: string
  threadEntities?: ObjectEntities | null
  isSubmitting: boolean
  refetch?: () => void
  unsubscribe?: () => void
  currentMessageText?: string
  activeAssistantMessageId?: string
  lastAssistantMessageId?: string
  messages: Map<string, AssistantMessage>
  resolverError?: string
  subscriptionError: boolean
}

export const DEFAULT_ASSISTANT_STATE: AssistantState = {
  legalEntityId: undefined,
  initialThreadFetch: false,
  isNewThread: false,
  isSubmitting: false,
  activeAssistantMessageId: undefined,
  lastAssistantMessageId: undefined,
  messages: new Map(),
  subscriptionError: false,
}

export type AssistantAction =
  | {
      type: "THREAD_START"
      payload: string
    }
  | {
      type: "THREAD_FETCH"
      payload:
        | {
            thread: Thread
            entities: ObjectEntities | undefined | null
          }
        | undefined
    }
  | {
      type: "THREAD_QUERY"
      payload: () => void
    }
  | {
      type: "SUBMIT"
      payload?: string
    }
  | {
      type: "SUBSCRIPTION"
      payload: () => void
    }
  | {
      type: "RESOLVER_ERROR"
      payload: string
    }
  | {
      type: "MESSAGE"
      payload: AssistantMessage
    }
  | {
      type: "LEGAL_ENTITY_RESET"
      legalEntityId?: string
    }
  | {
      type: "SUBMIT_DONE" | "SUBMIT_CANCEL" | "SUBMIT_ERROR" | "CLEAR"
    }

export type AssistantEffect = { type: "onSubmit" } | { type: "onSubmitDone" }

export type AssistantExec = EffectReducerExec<AssistantState, AssistantAction, AssistantEffect>

export const assistantReducer = (
  state: AssistantState,
  action: AssistantAction,
  exec: AssistantExec
): AssistantState => {
  switch (action.type) {
    case "THREAD_START":
      return {
        ...state,
        threadId: action.payload,
      }
    case "THREAD_FETCH":
      if (!action.payload) {
        return {
          ...state,
          initialThreadFetch: true,
          isNewThread: true,
        }
      }

      return {
        ...state,
        initialThreadFetch: true,
        isNewThread: false,
        threadId: action.payload.thread.id,
        threadEntities: action.payload.entities,
        messages: mergeInComments(state.messages, action.payload.thread.comments),
      }
    case "THREAD_QUERY":
      return {
        ...state,
        refetch: action.payload,
      }
    case "SUBMIT":
      exec({ type: "onSubmit" })

      return {
        ...state,
        isSubmitting: true,
        unsubscribe: undefined,
        activeAssistantMessageId: undefined,
        currentMessageText: action.payload,
        resolverError: undefined,
        subscriptionError: false,
      }
    case "SUBSCRIPTION":
      return {
        ...state,
        unsubscribe: action.payload,
      }
    case "SUBMIT_DONE":
      exec({ type: "onSubmitDone" })
      state.unsubscribe?.()

      return {
        ...state,
        isSubmitting: false,
        unsubscribe: undefined,
        currentMessageText: undefined,
        activeAssistantMessageId: undefined,
        resolverError: undefined,
      }

    case "RESOLVER_ERROR": {
      state.unsubscribe?.()

      const messages = new Map(state.messages)
      messages.set(generateUUID(), {
        comment: {
          authorId: DIGITS_CANONICAL_USER_ID,
          id: generateUUID(),
          text: "Sorry, that didn’t work. Do you mind asking that again?",
          timestamp: moment.utc().unix(),
        },
      })

      console.warn(action.payload)

      return {
        ...state,
        isSubmitting: false,
        unsubscribe: undefined,
        activeAssistantMessageId: undefined,
        resolverError: action.payload,
        messages,
      }
    }
    case "SUBMIT_CANCEL":
      state.unsubscribe?.()

      return {
        ...state,
        isSubmitting: false,
        unsubscribe: undefined,
        activeAssistantMessageId: undefined,
        resolverError: undefined,
        subscriptionError: false,
      }
    case "SUBMIT_ERROR":
      return {
        ...state,
        subscriptionError: true,
      }
    case "MESSAGE": {
      const messages = new Map(state.messages)
      messages.set(action.payload.comment.id, action.payload)

      return {
        ...state,
        currentMessageText:
          action.payload.comment.text === state.currentMessageText
            ? undefined
            : state.currentMessageText,
        activeAssistantMessageId:
          action.payload.comment.authorId === DIGITS_CANONICAL_USER_ID
            ? action.payload.comment.id
            : state.activeAssistantMessageId,
        lastAssistantMessageId:
          action.payload.comment.authorId === DIGITS_CANONICAL_USER_ID
            ? action.payload.comment.id
            : state.lastAssistantMessageId,
        messages,
      }
    }
    case "CLEAR":
      return {
        ...DEFAULT_ASSISTANT_STATE,
        legalEntityId: state.legalEntityId,
        initialThreadFetch: state.initialThreadFetch,
        isNewThread: true,
        refetch: state.refetch,
      }

    case "LEGAL_ENTITY_RESET":
      return {
        ...DEFAULT_ASSISTANT_STATE,
        legalEntityId: action.legalEntityId,
        refetch: state.refetch,
      }
  }
}

function mergeInComments(
  currentMessages: Map<string, AssistantMessage>,
  comments: Comment[]
): Map<string, AssistantMessage> {
  const messages = new Map(currentMessages)
  comments.forEach((comment) => {
    const existing: AssistantMessage = messages.get(comment.id) || { comment }
    existing.comment = comment

    messages.set(comment.id, existing)
  })

  return Array.from(messages.values())
    .sort((a, b) =>
      moment.unix(a.comment.timestamp).isAfter(moment.unix(b.comment.timestamp)) ? 1 : -1
    )
    .reduce((acc, m) => {
      acc.set(m.comment.id, m)
      return acc
    }, new Map<string, AssistantMessage>())
}
