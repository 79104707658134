import * as React from "react"
import { StaticMetricConfig } from "@digits-graphql/frontend/graphql-bearer"
import styled from "styled-components"
import { ComponentHeaderTitle } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"

/*
  STYLES
*/

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px 0;
`

/*
  COMPONENTS
*/

export const KPIConfig: React.FC<{ config: StaticMetricConfig }> = () => (
  <>
    <ComponentHeaderTitle title="KPI" componentSize={ComponentSize.Small} periodName={undefined} />
    <Container>
      <img alt="" src={require("static/images/reports/KPI.png")} height={70} />
    </Container>
  </>
)
