export class ComponentSize {
  static readonly Small = new ComponentSize({
    name: "Small",
    headerSize: "18px",
    subHeaderSize: "12px",
    chevronSize: "4px",
    chevronLeft: "-2px",
    chevronTop: "6px",
  })

  static readonly Medium = new ComponentSize({
    name: "Medium",
    headerSize: "18px",
    subHeaderSize: "12px",
    chevronSize: "4px",
    chevronLeft: "-2px",
    chevronTop: "6px",
  })

  static readonly Large = new ComponentSize({
    name: "Large",
    headerSize: "18px",
    subHeaderSize: "12px",
    chevronSize: "5px",
    chevronLeft: "-4px",
    chevronTop: "7px",
  })

  // LEGACY from copy/paste of Layouts; used by reports
  static readonly PageSmall = new ComponentSize({
    name: "Small",
    headerSize: "14px",
    subHeaderSize: "12px",
    chevronSize: "4px",
    chevronLeft: "-2px",
    chevronTop: "6px",
  })

  // LEGACY from copy/paste of Layouts; used by reports
  static readonly PageMedium = new ComponentSize({
    name: "Medium",
    headerSize: "16px",
    subHeaderSize: "12px",
    chevronSize: "4px",
    chevronLeft: "-2px",
    chevronTop: "6px",
  })

  readonly name: string
  readonly headerSize: string
  readonly subHeaderSize: string
  readonly chevronSize: string
  readonly chevronLeft: string
  readonly chevronTop: string

  constructor({
    name,
    headerSize,
    subHeaderSize,
    chevronSize,
    chevronLeft,
    chevronTop,
  }: {
    name: string
    headerSize: string
    subHeaderSize: string
    chevronSize: string
    chevronLeft: string
    chevronTop: string
  }) {
    this.name = name
    this.headerSize = headerSize
    this.subHeaderSize = subHeaderSize
    this.chevronSize = chevronSize
    this.chevronLeft = chevronLeft
    this.chevronTop = chevronTop
  }

  static componentSizeFor(componentCount: number): ComponentSize {
    switch (componentCount) {
      case 1:
        return ComponentSize.Large
      case 2:
      case 3:
        return ComponentSize.Medium
      default:
        return ComponentSize.Small
    }
  }

  // LEGACY from copy/paste of Layouts; used by reports
  static componentSizeForPage(componentCount: number): ComponentSize {
    switch (componentCount) {
      case 1:
        return ComponentSize.Large
      case 2:
        return ComponentSize.PageMedium
      default:
        return ComponentSize.PageSmall
    }
  }
}
