import * as React from "react"
import { SvgChevronLeft } from "@digits-shared/components/SVGIcons/line/ChevronLeft.svg"
import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import { themedSVGPathStyles } from "@digits-shared/themes/svgIconStyles"
import fonts from "@digits-shared/themes/typography"
import styled, { css, keyframes } from "styled-components"

export const BackArrow = styled(SvgChevronLeft)`
  ${themedSVGPathStyles(
    {
      light: colors.secondary,
      dark: colors.white,
    },
    1.5
  )};
  width: 16px;
  cursor: pointer;
  opacity: 0.7;
  margin-right: 5px;
`

const BOUNCE_ARROW = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0.7;
  }
  50% {
    transform: translateX(-10px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

const BackButtonStyled = styled.button`
  display: flex;
  align-items: center;
  ${themedStyles({
    light: css`
      color: ${colors.secondary};
    `,
    dark: css`
      color: ${colors.white};
    `,
  })};
  font-size: 14px;
  font-weight: ${fonts.weight.normal};
  transition: color 250ms ease;
  text-align: left;
  white-space: nowrap;
  background: ${colors.transparent};
  border: none;
  outline: none;
  cursor: pointer;

  //&:focus {
  //  outline: -webkit-focus-ring-color auto 1px;
  //}

  &:hover ${BackArrow} {
    animation: ${BOUNCE_ARROW} 1500ms ease-in-out forwards infinite;
  }
`

interface BackButtonProps {
  className?: string
  noCaret?: boolean
  size?: "small" | "large"
}

const BackButtonComponent: React.FC<
  BackButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ className, noCaret, children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <BackButtonStyled className={className} {...rest}>
    {!noCaret && <BackArrow />}
    {children}
  </BackButtonStyled>
)
export const BackButton = styled(BackButtonComponent)``
