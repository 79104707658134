import * as React from "react"
import {
  BalanceSummary,
  EntityCategory,
  Interval,
  IntervalOrigin,
  MonetaryValue,
  PartyRole,
} from "@digits-graphql/frontend/graphql-bearer"
import { SummaryWithCount } from "@digits-shared/components/UI/Table/Content"
import { hasFirstElement } from "@digits-shared/helpers/arrayHelper"
import { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import moment from "moment/moment"
import {
  useSummarizeCategoryBalances,
  useSummarizePartyAndRoleBalances,
} from "src/frontend/components/OS/Details/Shared/hooks/useSummarizeBalances"
import { SparkLineChart } from "src/frontend/components/OS/Shared/Charts/SparkLineChart"
import {
  AmountAndCount,
  ChartContainer,
} from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Components/Search/Shared"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

/*
  INTERFACES
*/

interface SummaryProps {
  endingValue: MonetaryValue
  summaries: BalanceSummary[]
  loading: boolean
}

/*
  COMPONENTS
*/
export const CategoryBalanceSummary: React.FC<{
  category: EntityCategory
  origin: IntervalOrigin
}> = ({ category, origin }) => {
  const { endingValue, loading, periodBalances } = useSummarizeCategoryBalances(category, origin)

  return (
    <SearchResultBalanceSummary
      summaries={periodBalances}
      endingValue={endingValue}
      loading={loading}
    />
  )
}

export const PartyAndRoleBalanceSummary: React.FC<{
  partyId: string
  partyRole: PartyRole
  origin: IntervalOrigin
}> = ({ partyId, partyRole, origin }) => {
  const { endingValue, loading, periodBalances } = useSummarizePartyAndRoleBalances(
    partyId,
    partyRole,
    { ...origin, intervalCount: 12 }
  )

  return (
    <SearchResultBalanceSummary
      summaries={periodBalances}
      endingValue={endingValue}
      loading={loading}
    />
  )
}

const SearchResultBalanceSummary: React.FC<SummaryProps> = ({
  summaries,
  endingValue,
  loading,
}) => {
  if (loading) {
    return (
      <AmountAndCount>
        <SummaryWithCount loading />
      </AmountAndCount>
    )
  }

  if (!summaries || !summaries.length) {
    return null
  }

  // TODO - enable this once we have a better way to display totals
  const showTotals = false

  return (
    <>
      <SummarySparkChart summaries={summaries} />
      {showTotals && (
        <AmountAndCount>
          <SummaryWithCount value={endingValue} style={CurrencyStyle.Aggregation} ignoreHover>
            Ending Balance
          </SummaryWithCount>
        </AmountAndCount>
      )}
    </>
  )
}

const SummarySparkChart: React.FC<{ summaries: BalanceSummary[] }> = ({ summaries }) => {
  const intervalOrigin = useIntervalOrigin()

  const series = React.useMemo(() => {
    const now = moment.utc()

    return summaries
      .slice()
      .sort((s1, s2) => s1.period.endedAt - s2.period.endedAt)
      .map((point, index) => {
        const endedAtMoment = moment.unix(point.period.endedAt)

        const incomplete = endedAtMoment.isAfter(now)

        const isLastDataPoint = index === summaries.length - 1

        // Balance summaries provide the balance value as of _ending date_ of the period.
        // Because the ending time for the most recent period could be later than today's date
        // (e.g. end of today's month), clamp the timestamps to be no earlier than the 16th of
        // the current month (to allow for adequate space away from the previous data point),
        // and no later than today's date.
        const timestamp =
          intervalOrigin.interval !== Interval.Day && isLastDataPoint && incomplete
            ? Math.max(now.unix(), Math.min(point.period.endedAt, now.date(16).unix()))
            : point.period.endedAt

        const x = {
          ...point.period,
          endedAt: timestamp,
        }
        const y = point.total.value

        return {
          x,
          y,
          incomplete,
        }
      })
  }, [intervalOrigin.interval, summaries])

  // Display nothing if there are no data points, or every data point has the same value
  if (!hasFirstElement(series) || series.every((p) => p.y.amount === series[0].y.amount)) {
    return null
  }

  return (
    <ChartContainer>
      <SparkLineChart series={series} animateChart animateChartInitialDelay={800} />
    </ChartContainer>
  )
}
