import * as React from "react"
import useRowSelectionContext from "src/frontend/components/Shared/Reports/Report/Components/Statements/RowSelectionContext"
import {
  STATEMENT_HOVERED_COLUMN_CLASSNAME,
  STATEMENT_ROWS_CLASSNAME,
  TotalCell,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import { HeaderStyled } from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementHeader"
import {
  useReportExtraColumnsCount,
  useStatementBreakdownColumnsCount,
} from "src/frontend/components/Shared/Reports/Report/hooks/useReportDocumentOptions"

export function useRowSelectionHandlers() {
  const { pinnedCell } = useRowSelectionContext()
  const columnsCount = useStatementBreakdownColumnsCount() + useReportExtraColumnsCount()
  const rowsSelector = `.${STATEMENT_ROWS_CLASSNAME}`
  const headerSelector = `.${HeaderStyled.styledComponentId}`
  const cellSelector = `.${TotalCell.styledComponentId}`

  const headerIndex = React.useRef<number>()
  const findHeader = React.useCallback(
    (row: HTMLElement, target: EventTarget) => {
      const column = (target as HTMLElement)?.closest?.(cellSelector)
      if (!column || !row) return

      const columns = row.querySelectorAll(cellSelector)
      const index = Array.from(columns).indexOf(column)
      if (index === headerIndex.current) return
      headerIndex.current = index

      const rows = row?.closest(rowsSelector)
      if (!rows) return
      const headers = rows.querySelectorAll(headerSelector)
      headers.forEach((h) => h.classList.remove(STATEMENT_HOVERED_COLUMN_CLASSNAME))
      return headers?.[index]
    },
    [cellSelector, headerSelector, rowsSelector]
  )

  React.useEffect(() => {
    if (!columnsCount) return
    const rows = pinnedCell?.target?.closest(rowsSelector)
    if (!rows) return
    rows.classList.add("pinned-cell")
  }, [columnsCount, pinnedCell, rowsSelector])

  const onMouseMove = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!columnsCount) {
        return
      }

      const { currentTarget, target } = e
      const header = findHeader(currentTarget, target)
      header?.classList.add(STATEMENT_HOVERED_COLUMN_CLASSNAME)
    },
    [columnsCount, findHeader]
  )

  const onMouseLeave = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      headerIndex.current = undefined

      const { currentTarget } = e
      const rows = currentTarget?.closest(rowsSelector)
      if (!rows) return
      const headers = rows.querySelectorAll(headerSelector)
      headers.forEach((h) => h.classList.remove(STATEMENT_HOVERED_COLUMN_CLASSNAME))
    },
    [headerSelector, rowsSelector]
  )

  return { onMouseMove, onMouseLeave }
}
