import { Thread, ThreadDetails } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { v4 as generateUUID } from "uuid"
import { ReportCommentsAction } from "src/frontend/components/Shared/Reports/ReportComments/actions"
import {
  CommentsRegistry,
  RegistryEntry,
  RegistryEntryWithThread,
  REPORT_TOP_LEVEL_THREAD,
  ReportCommentsState,
  RESOLVED_REPORT_TOP_LEVEL_THREAD,
} from "src/frontend/components/Shared/Reports/ReportComments/types"

/*
  REDUCER
*/

export const reducer = (curState: ReportCommentsState, action: ReportCommentsAction) => {
  const newState: ReportCommentsState = { ...curState }
  switch (action.type) {
    case "INIT_REGISTRY": {
      const { threads, deeplink } = action
      newState.initialized = threads ? generateUUID() : undefined

      threads?.filter(isOpenAnchoredThread).forEach((thread) => {
        const contextId = threadContextId(thread.details)
        newState.registry[contextId] = newRegistryEntry(contextId, thread)

        const isActive =
          deeplink &&
          (thread.id === deeplink.threadId ||
            !!thread.comments.find((c) => c.id === deeplink.commentId))

        if (isActive) {
          newState.activeContextId = contextId
        }
      })

      if (!newState.registry[REPORT_TOP_LEVEL_THREAD] || deeplink?.newComment) {
        createTopLevelThread(newState.registry)
      }
      return newState
    }

    case "SET_REGISTRY": {
      const { threads } = action
      if (threads) {
        // remove missing threads
        Object.keys(newState.registry).forEach((contextId) => {
          // if the contextId (object in registry) is a Cover thread (placeholder) or it is active (composing new thread)
          // do not remove it, it could be a new thread that is being composed and polling will remove it
          // similarly, if it is a resolved top level thread, do not remove it because it could have been optimistically resolved and the server response might be stale
          if (
            contextId === REPORT_TOP_LEVEL_THREAD ||
            contextId.startsWith(RESOLVED_REPORT_TOP_LEVEL_THREAD) ||
            contextId === newState.activeContextId
          )
            return

          if (!threads.find(({ details }) => threadContextId(details) === contextId)) {
            delete newState.registry[contextId]
          }
        })
      }

      threads
        ?.filter(
          (thread) =>
            isOpenAnchoredThread(thread) &&
            !isStaleLocallyResolvedTopLevelThread(newState.registry, thread)
        )
        .forEach((thread) => {
          const contextId = threadContextId(thread.details)
          newState.registry[contextId] = newRegistryEntry(contextId, thread)
          removeStaleTopLevelThread(newState.registry, thread)
        })

      if (!newState.registry[REPORT_TOP_LEVEL_THREAD]) {
        createTopLevelThread(newState.registry)
      }

      return newState
    }

    case "CREATE_THREAD":
    case "UPDATE_THREAD": {
      const { thread } = action
      const contextId = threadContextId(thread.details)
      const entry = newState.registry[contextId]
      if (entry) {
        entry.thread = thread
      }
      newState.registry[contextId] = newRegistryEntry(contextId, thread)
      newState.activeContextId = contextId
      return newState
    }

    case "ACTIVATE_THREAD":
    case "DEACTIVATE_THREAD":
    case "ADD_COMMENT": {
      let context: string | undefined
      if (action.type === "ADD_COMMENT") {
        context = action.context
        let entry = newState.registry[context]
        if (!entry || entry.resolved) {
          entry = newRegistryEntry(context)
          newState.registry[context] = entry
        }
      } else if (action.type === "ACTIVATE_THREAD") {
        const { detailsOrContext } = action
        context =
          typeof detailsOrContext === "string"
            ? detailsOrContext
            : threadContextId(detailsOrContext)

        // don't create new state if it is the same comment being reactivated
        if (context && curState.activeContextId === context) return curState
      }

      newState.activeContextId = undefined
      if (context && newState.registry[context]) {
        newState.activeContextId = context
      }

      cleanUpRegistry(newState)
      return newState
    }

    case "RESOLVE_THREAD": {
      const { threadId } = action
      const entry = Object.values(newState.registry).find((e) => e.thread?.id === threadId)
      if (entry) {
        entry.resolved = true
        cleanUpRegistry(newState)
      }
      return newState
    }

    case "REPOSITION_THREADS":
      newState.initialized = generateUUID()
      return newState

    case "UPSERT_ENTITIES":
      newState.entities = action.entities
      return newState
  }
}

/*
  HELPERS
*/

export const threadContextId = (details: ThreadDetails) =>
  details.context || reportTopLevelThreadContextId(details)

function reportTopLevelThreadContextId(details: ThreadDetails) {
  return details.resolvedAt
    ? resolvedReportTopLevelThreadContextId(details)
    : REPORT_TOP_LEVEL_THREAD
}

function resolvedReportTopLevelThreadContextId(details: ThreadDetails) {
  return `${RESOLVED_REPORT_TOP_LEVEL_THREAD}.${details.id}`
}

function newRegistryEntry(context: string, thread?: Thread): RegistryEntry {
  return {
    context,
    thread,
    resolved: Boolean(thread?.details.resolvedAt),
  }
}

function createTopLevelThread(registry: CommentsRegistry) {
  const contextId = REPORT_TOP_LEVEL_THREAD
  registry[contextId] = newRegistryEntry(contextId)
}

function cleanUpRegistry({ registry, activeContextId }: ReportCommentsState) {
  cleanupTopLevelThread(registry)
  cleanupRemainingThreads(registry, activeContextId)
}

/**
 * If the top level thread has been resolved locally, remap it to the correct registry context
 * and replace it with a new top level thread
 */
function cleanupTopLevelThread(registry: CommentsRegistry) {
  // remap the top level thread to the correct context if it has been resolved locally
  const topLevelThreadEntry = registry[REPORT_TOP_LEVEL_THREAD]
  if (topLevelThreadEntry?.resolved) {
    // replace the old top level thread with a new one
    createTopLevelThread(registry)
    if (registryEntryHasThread(topLevelThreadEntry)) {
      addResolvedTopLevelThread(registry, topLevelThreadEntry)
    }
  }
}

/**
 * Takes an existing top level thread and adds a resolved version of it to the registry
 */
function addResolvedTopLevelThread(
  registry: CommentsRegistry,
  topLevelThreadEntry: RegistryEntryWithThread
) {
  const context = resolvedReportTopLevelThreadContextId(topLevelThreadEntry.thread.details)
  registry[context] = {
    ...topLevelThreadEntry,
    context,
    thread: {
      ...topLevelThreadEntry.thread,
      details: {
        ...topLevelThreadEntry.thread.details,
        resolvedAt: dateTimeHelper.unixNowSeconds(),
      },
    },
  }
}

function cleanupRemainingThreads(registry: CommentsRegistry, activeContextId?: string) {
  // Remove all entries that are not active and don't have thread details
  Object.keys(registry)
    .filter((context) => {
      const entry = registry[context]
      if (context === REPORT_TOP_LEVEL_THREAD) {
        // resolved top level threads should be moved to a resolved state already
        // so this path shouldn't execute, but in case we get here we will delete
        // the errant top level thread to be safe
        return entry?.resolved
      }
      return context !== activeContextId && !entry?.thread
    })
    .forEach((context) => {
      delete registry[context]
    })
}

function isOpenAnchoredThread(thread: Thread) {
  return (
    // if there is no thread context it is a top level thread, so we should show it
    !thread.details.context ||
    // if there is thread context it is an anchored thread,
    // so we should only show it if it hasn't already been resolved
    !thread.details.resolvedAt
  )
}

/**
 * if a thread was remotely resolved while viewing the report
 * we will have a record of the thread in its open state
 * therefore we must remove it
 */
function removeStaleTopLevelThread(registry: CommentsRegistry, thread: Thread) {
  if (thread.id === registry[REPORT_TOP_LEVEL_THREAD]?.thread?.id && thread.details.resolvedAt) {
    delete registry[REPORT_TOP_LEVEL_THREAD]
  }
}

/**
 * When a top level thread is resolved locally it is possible
 * that a subsequent server response is stale due to polling/web notifications
 * in this case the server returns the thread as if it wasn't resolved
 * even though we know locally that it was already resolved
 *
 * In these instances we must not add the thread to the registry
 * as it has already been added during local resolution
 */
function isStaleLocallyResolvedTopLevelThread(registry: CommentsRegistry, thread: Thread) {
  const contextId = threadContextId(thread.details)
  if (contextId === REPORT_TOP_LEVEL_THREAD) {
    const resolvedContextId = resolvedReportTopLevelThreadContextId(thread.details)
    const resolvedThread = registry[resolvedContextId]
    return Boolean(resolvedThread)
  }
  return false
}

function registryEntryHasThread(entry: RegistryEntry): entry is RegistryEntryWithThread {
  return Boolean(entry.thread)
}
