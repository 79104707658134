import * as React from "react"
import {
  ListVaultFilesDocument,
  useDeleteDocumentMutation,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { VaultFile } from "src/frontend/components/OS/Springboard/Applications/Vault/types"
import FrontendSession from "src/frontend/session"

export function useDeleteVaultFiles() {
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()

  const [deleteFile, result] = useDeleteDocumentMutation({
    refetchQueries: [ListVaultFilesDocument],
    awaitRefetchQueries: true,
  })

  return {
    deleteFiles: React.useCallback(
      (deleteFiles: VaultFile[] | VaultFile) => {
        const files = Array.isArray(deleteFiles) ? deleteFiles : [deleteFiles]
        const promises = files.map((file) =>
          deleteFile({
            variables: {
              legalEntityId,
              documentId: file.id,
              documentCollectionId: file.collectionId,
            },
          })
        )
        return Promise.all(promises)
      },
      [deleteFile, legalEntityId]
    ),
    result,
  }
}
