import * as React from "react"
import { usePrevious } from "react-use"
import {
  HasPortalDocument,
  useCreatePortalMutation,
  useReadLayoutQuery,
  useReadPortalQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { shallow } from "zustand/shallow"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import {
  usePortalDispatch,
  usePortalStore,
} from "src/frontend/components/Shared/Portals/State/portalStore"
import { PortalMode } from "src/frontend/components/Shared/Portals/State/types"
import FrontendSession from "src/frontend/session"
import { useHasPortal } from "src/shared/hooks/useHasPortal"

export function useFetchPortalAndLayout(canCreatePortal: () => boolean = () => true) {
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()

  const portalDispatch = usePortalDispatch()
  const { portalMode, layoutId } = usePortalStore(
    (state) => ({
      portalMode: state.portalMode,
      layoutId: state.portal?.layoutId,
    }),
    shallow
  )
  const viewId = useViewVersion()
  const prevLegalEntityId = usePrevious(legalEntityId)

  const { hasPortal, loading: hasPortalLoading } = useHasPortal()

  const {
    data: portalData,
    error: portalError,
    loading: portalLoading,
  } = useReadPortalQuery({
    variables: {
      legalEntityId,
    },
    skip: hasPortalLoading || !hasPortal,
  })

  const { data: layoutData, loading: layoutLoading } = useReadLayoutQuery({
    variables: {
      layoutId: layoutId ?? "",
      legalEntityId,
    },
    skip: portalLoading || !layoutId || prevLegalEntityId !== legalEntityId,
  })

  const [createPortal, { loading: createPortalLoading, error: createPortalError }] =
    useCreatePortalMutation()

  // Sync portal data to the store
  React.useEffect(() => {
    portalDispatch({
      type: "layoutLoading",
      loading: hasPortalLoading || portalLoading || layoutLoading,
    })
    if (!portalLoading) {
      portalDispatch({ type: "setPortal", portal: portalData?.readPortal, portalError })
    }
  }, [
    portalData?.readPortal,
    portalLoading,
    portalDispatch,
    portalError,
    layoutLoading,
    hasPortalLoading,
  ])

  // Sync layout data to the store
  React.useEffect(() => {
    if (!layoutLoading && layoutData?.readLayout) {
      portalDispatch({ type: "setLayout", layout: layoutData?.readLayout })
      portalDispatch({ type: "setPortalMode", mode: PortalMode.Ready })
    }
  }, [layoutData?.readLayout, layoutLoading, portalDispatch])

  // Enter bootstrap mode if no portal was found and this is an affiliate user
  React.useEffect(() => {
    if (canCreatePortal() && !hasPortalLoading && hasPortal === false) {
      portalDispatch({ type: "setPortalMode", mode: PortalMode.Bootstrap })
    }
  }, [portalDispatch, canCreatePortal, hasPortalLoading, hasPortal])

  // When we enter bootstrap mode, create the portal and (re)fetch it
  React.useEffect(
    () => {
      if (
        canCreatePortal() &&
        !createPortalLoading &&
        !createPortalError &&
        hasPortal === false &&
        portalMode === PortalMode.Bootstrap
      ) {
        createPortal({
          variables: {
            viewId,
          },
          // Causes ReadPortal to run again once the hasPortal value changes
          refetchQueries: [HasPortalDocument],
          awaitRefetchQueries: true,
        }).catch((e) => {
          TrackJS?.track(e)
        })
      }
    },
    // Avoid re-triggering createPortal if things change which are unrelated to the central logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createPortal, canCreatePortal, portalMode, hasPortal, portalDispatch]
  )
}
