import * as React from "react"
import { SubscriptionHookOptions, useApolloClient } from "@apollo/client"
import * as Apollo from "@apollo/client"
import { OperationVariables } from "@apollo/client/core"

type LazySubscriptionHookOptions<
  TData,
  TVariables extends OperationVariables = OperationVariables,
> = Omit<SubscriptionHookOptions<TData, TVariables>, "skip">

// Portions of this hook are copied from the useSubscription hook from apollo.
// See https://github.com/apollographql/apollo-client/blob/main/src/react/hooks/useSubscription.ts
export function useLazySubscription<
  TData,
  TVariables extends OperationVariables = OperationVariables,
>(
  subscriptionDoc: Apollo.DocumentNode,
  baseOptions: LazySubscriptionHookOptions<TData, TVariables> = {}
) {
  const client = useApolloClient()

  return React.useCallback(
    (callOptions: LazySubscriptionHookOptions<TData, TVariables> = {}) => {
      const options = {
        ...baseOptions,
        ...callOptions,
      }

      const observable = client.subscribe({
        query: subscriptionDoc,
        variables: options.variables,
        fetchPolicy: options.fetchPolicy,
        context: options.context,
      })

      let subscriptionStopped = false
      const subscription = observable.subscribe({
        next(fetchResult) {
          if (subscriptionStopped) return

          options.onData?.({
            client,
            data: {
              loading: false,
              data: fetchResult.data,
              error: undefined,
              variables: options?.variables,
            },
          })
        },
        error(error) {
          if (!subscriptionStopped) options.onError?.(error)
        },
        complete() {
          if (!subscriptionStopped) options.onComplete?.()
        },
      })

      return () => {
        // immediately stop receiving subscription values, but do not unsubscribe
        // until after a short delay in case another useSubscription hook is
        // reusing the same underlying observable and is about to subscribe
        subscriptionStopped = true
        setTimeout(() => {
          subscription.unsubscribe()
        })
      }
    },
    [baseOptions, client, subscriptionDoc]
  )
}
