import * as React from "react"
import {
  AssistantEventKind,
  CategoryType,
  DirectionFromOrigin,
  EntityCategory,
  EntityParty,
  InsightSubjectType,
  Interval,
  PartyRole,
  useListInsightsQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { getRandomElement } from "@digits-shared/helpers/arrayHelper"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import useSession from "@digits-shared/hooks/useSession"
import moment from "moment"
import { v4 as generateUUID } from "uuid"
import { AssistantMessageHeader } from "src/frontend/components/Shared/Assistant/Messages/Header"
import {
  AssistantMessageBubble,
  AssistantMessageGrid,
} from "src/frontend/components/Shared/Assistant/Messages/Shared"
import { Suggestion } from "src/frontend/components/Shared/Assistant/Messages/Suggestion"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import FrontendSession from "src/frontend/session"
import { DIGITS_CANONICAL_USER_ID } from "src/shared/config/identifiers"

/*
  COMPONENTS
*/

export const AssistantWelcomeMessage: React.FC = () => {
  const { user } = useSession<FrontendSession>()
  const { suggestions, loading } = useSuggestions()

  const message = loading
    ? "Taking a look at your books..."
    : "I took a look at your books and think you might find these interesting..."

  const event = {
    comment: {
      id: generateUUID(),
      authorId: DIGITS_CANONICAL_USER_ID,
      text: message,
      timestamp: moment.utc().unix(),
    },
    kind: AssistantEventKind.Unknown,
  }

  return (
    <AssistantMessageGrid>
      <AssistantMessageHeader message={event} isWelcome />
      <AssistantMessageBubble>
        Welcome, {user.givenName}! {message}
        <br />
        {suggestions.map((suggestion, index) => (
          <Suggestion message={suggestion} key={index} />
        ))}
      </AssistantMessageBubble>
    </AssistantMessageGrid>
  )
}

function useSuggestions() {
  const viewKey = useViewVersion()
  const { currentLegalEntity, doppelganger } = useSession<FrontendSession>()
  const skip =
    !viewKey.legalEntityId ||
    !viewKey.viewVersion ||
    !currentLegalEntity?.hasDashboardAccess(doppelganger)
  const { data: categoryInsights, loading: categoryLoading } = useListInsightsQuery({
    variables: {
      ...dateTimeHelper.todayIntervalOrigin(Interval.Month, 6),
      paginationDirection: DirectionFromOrigin.Past,
      paginationLimit: 10,
      paginationOffset: 0,
      filter: {
        viewKey,
        subjectTypes: [InsightSubjectType.Category],
        categoryTypes: [CategoryType.Expenses],
      },
    },
    skip,
  })
  const { data: partyInsights, loading: partyLoading } = useListInsightsQuery({
    variables: {
      ...dateTimeHelper.todayIntervalOrigin(Interval.Month, 6),
      paginationDirection: DirectionFromOrigin.Past,
      paginationLimit: 10,
      paginationOffset: 0,
      filter: {
        viewKey,
        subjectTypes: [InsightSubjectType.Party],
        partyRole: PartyRole.EntityVendorRole,
      },
    },
    skip,
  })
  const loading = categoryLoading || partyLoading

  return React.useMemo(() => {
    const insights = (partyInsights?.listInsights ?? []).concat(
      categoryInsights?.listInsights ?? []
    )
    const periodInsights = insights?.flatMap((insight) => insight)
    const partiesById = periodInsights
      ?.flatMap((i) => i.entities?.parties)
      ?.reduce((acc, entity) => {
        if (entity) {
          acc.set(entity.id, entity)
        }
        return acc
      }, new Map<string, EntityParty>())

    const categoriesById = periodInsights
      ?.flatMap((i) => i.entities?.categories)
      ?.reduce((acc, entity) => {
        if (entity) {
          acc.set(entity.id, entity)
        }
        return acc
      }, new Map<string, EntityCategory>())

    const topVendorInsight = periodInsights
      ?.flatMap((i) => i.insights)
      .sort((a, b) => b.score - a.score)
      ?.find(
        (insight) =>
          insight.subjectType === InsightSubjectType.Party && partiesById?.has(insight.subjectId)
      )

    const topExpenseCategoryInsight = periodInsights
      ?.flatMap((i) => i.insights)
      .sort((a, b) => b.score - a.score)
      ?.find(
        (insight) =>
          insight.subjectType === InsightSubjectType.Category &&
          categoriesById?.get(insight.subjectId)?.type === CategoryType.Expenses
      )

    const insightCategorySuggestions: string[] = []
    const insightVendorSuggestions: string[] = []
    const companyDataSuggestions: string[] = []
    const accountingSuggestions: string[] = []
    if (topVendorInsight) {
      const party = partiesById?.get(topVendorInsight.subjectId)
      insightVendorSuggestions.push(`How much did we pay ${party?.name} over the last 6 months?`)
      insightVendorSuggestions.push(`How much did we pay ${party?.name} last month?`)
    }

    if (topExpenseCategoryInsight) {
      const category = categoriesById?.get(topExpenseCategoryInsight.subjectId)
      insightCategorySuggestions.push(
        `What does our ${category?.name} spend look like so far this year?`
      )
      insightCategorySuggestions.push(`What did our ${category?.name} spend look like last year?`)
    }

    // add some general company data suggestions
    companyDataSuggestions.push("What did my expenses look like last year?")
    companyDataSuggestions.push("What are my top vendors this year to date?")
    companyDataSuggestions.push("What was our net burn last month?")
    companyDataSuggestions.push("What is our net burn so far this year?")
    companyDataSuggestions.push("What is our current ratio?")

    // add some general accounting suggestions
    accountingSuggestions.push("What is the difference between a P&L and a balance sheet?")
    accountingSuggestions.push("What is cost of goods sold? How is it calculated?")
    accountingSuggestions.push("What is the difference between a credit and debit?")

    /* return the following suggestions
     * 1 insight category suggestion
     * 1 insight vendor suggestion
     * 1 general company data suggestion
     * 1 general accounting suggestion
     */
    const suggestions = [
      getRandomElement(insightCategorySuggestions),
      getRandomElement(insightVendorSuggestions),
      getRandomElement(companyDataSuggestions),
      getRandomElement(accountingSuggestions),
    ].filter((suggestion): suggestion is string => Boolean(suggestion))

    return { suggestions: loading ? [] : suggestions, loading }
  }, [categoryInsights?.listInsights, loading, partyInsights?.listInsights])
}
