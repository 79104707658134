import { BillingProductName } from "@digits-graphql/frontend/graphql-bearer"
import { useOrganizationBillingPlan } from "src/frontend/components/OS/Springboard/Applications/Settings/OrganizationBillingPlanContext"

export function useNextPlanName(): BillingProductName | undefined {
  const { planName } = useOrganizationBillingPlan()

  if (planName === BillingProductName.Free) {
    return BillingProductName.Growth
  }
  if (planName === BillingProductName.Growth) {
    return BillingProductName.Premium
  }
  return undefined
}
