import * as React from "react"
import { EntityUser } from "@digits-graphql/frontend/graphql-bearer"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import { RowContent } from "@digits-shared/components/UI/Table/Content"
import userHelper from "@digits-shared/helpers/userHelper"
import styled from "styled-components"
import routes from "src/frontend/routes"
import MatchedText from "./MatchedText"
import { ResultDescription, ResultRow, ResultTitle, RowProps } from "./ResultRow"

/*
  STYLE
*/

const Details = styled.div`
  display: flex;
`

/*
  COMPONENT
*/

const UserResult: React.FC<RowProps<EntityUser>> = ({ entity, hit }) => {
  // TODO: use employee directory route when it exists
  const path = routes.legalEntitySettings.generateFromCurrentPath()

  return (
    <ResultRow to={path}>
      <Details>
        <ColorIcon
          imageUrl={entity.avatarUrl}
          fallbackText={entity.givenName || entity.familyName}
        />
        <RowContent>
          <ResultTitle>
            <MatchedText fieldName="user.familyName" highlights={hit.highlights}>
              {userHelper.displayName(entity)}
            </MatchedText>
          </ResultTitle>
          <ResultDescription>
            <MatchedText fieldName="user.emailAddress" highlights={hit.highlights}>
              {entity.emailAddress}
            </MatchedText>
          </ResultDescription>
        </RowContent>
      </Details>
    </ResultRow>
  )
}

export default UserResult
