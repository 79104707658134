import * as React from "react"
import { ApolloError } from "@apollo/client"
import { AdjacentObjectPermission, ObjectPermission } from "@digits-graphql/frontend/graphql-bearer"
import { PreviewShareQuery } from "@digits-graphql/frontend/graphql-public"
import { SharedObjectInfo } from "@digits-shared/components/Router/DigitsRoute"
import { unique } from "@digits-shared/helpers/filters"
import { useMutateSessionFromShare } from "src/frontend/hooks/useMutateSessionFromShare"
import { useSharedObjectAdjacencyPermission } from "src/frontend/hooks/useSharedObjectAdjacencyPermission"
import { useSharedObjectInfo } from "src/frontend/hooks/useSharedObjectInfo"
import { useSharedObjectPermission } from "src/frontend/hooks/useSharedObjectPermission"
import { useSharedObjectPreview } from "src/frontend/hooks/useSharedObjectPreview"
import { useShareGrantMutation } from "src/frontend/hooks/useShareGrantMutation"
import {
  ObjectSharingStatus,
  useObjectSharingStatus,
} from "src/frontend/session/ObjectSharingStatus"

/*
  INTERFACES
*/

export interface ObjectSharingState {
  objectSharingStatus: ObjectSharingStatus
  sharingStatusHistory: ObjectSharingStatus[]
  preview?: PreviewShareQuery
  previewError?: ApolloError
  noJWTPermission: boolean
  previewLoading: boolean
  permission?: ObjectPermission
  permissionError?: ApolloError
  permissionLoading: boolean
  adjacencyPermission?: AdjacentObjectPermission
  adjacencyPermissionError?: ApolloError
  adjacencyPermissionLoading: boolean
  sharedObjectInfo?: SharedObjectInfo
  grantLoading: boolean
  grantError?: ApolloError
}

export const initialState: ObjectSharingState = {
  objectSharingStatus: ObjectSharingStatus.Unknown,
  sharingStatusHistory: [],
  noJWTPermission: true,
  previewLoading: false,
  permissionLoading: false,
  adjacencyPermissionLoading: false,
  grantLoading: false,
}

/*
  CONTEXT
*/

/** Context object for directly consuming ObjectSharingState */
export const ObjectSharingContext = React.createContext<ObjectSharingState>(initialState)

/*
  PROVIDER
*/

/** Component encapsulating object sharing logic, and providing a value for object sharing context */
export const ObjectSharingProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const sharedObjectInfo = useSharedObjectInfo()

  // Determine if the user has direct access to the shared object
  const {
    needsCheck: noJWTPermission,
    data: permissionData,
    loading: permissionLoading,
    error: permissionError,
    startPolling: permissionStartPolling,
    stopPolling: permissionStopPolling,
  } = useSharedObjectPermission()

  // Determine if the user has indirect access to the shared object via object adjacency
  const {
    data: adjacencyPermissionData,
    loading: adjacencyPermissionLoading,
    error: adjacencyPermissionError,
  } = useSharedObjectAdjacencyPermission({ skip: !permissionError })

  // Get preview data to be used if there is no grant yet but want to upsell sign up or request access
  const {
    data: previewData,
    loading: previewLoading,
    error: previewError,
  } = useSharedObjectPreview({
    action: permissionData?.action,
    // only an error if we fail both permission and adjacency permission checks
    hasError: !!permissionError && !!adjacencyPermissionError,
  })

  // Derived object sharing status based on our understanding of the users state and access
  const objectSharingStatus = useObjectSharingStatus({
    previewError,
    permissionData,
    permissionError,
    adjacencyPermissionData,
    adjacencyPermissionError,
  })

  // Perform the share grant for the current user if that's the next required step
  const { loading: grantLoading, error: grantError } = useShareGrantMutation(
    objectSharingStatus,
    permissionData
  )

  const sharingStatusHistory = React.useRef(initialState.sharingStatusHistory)
  React.useEffect(() => {
    sharingStatusHistory.current = [...sharingStatusHistory.current, objectSharingStatus].filter(
      unique
    )
  }, [objectSharingStatus])

  // Put the shared state into session if available
  useMutateSessionFromShare({ objectSharingStatus, permissionData, adjacencyPermissionData })

  // Poll for access granted if we are waiting for another user to approve
  usePollingWhenAccessPending(objectSharingStatus, permissionStartPolling, permissionStopPolling)

  const objectSharingState: ObjectSharingState = React.useMemo(
    () => ({
      grantLoading,
      grantError,
      objectSharingStatus,
      sharingStatusHistory: sharingStatusHistory.current,
      sharedObjectInfo,
      noJWTPermission,
      permission: permissionData,
      permissionLoading,
      permissionError,
      adjacencyPermission: adjacencyPermissionData,
      adjacencyPermissionError,
      adjacencyPermissionLoading,
      preview: previewData,
      previewError,
      previewLoading,
    }),
    [
      grantError,
      grantLoading,
      objectSharingStatus,
      noJWTPermission,
      permissionData,
      permissionLoading,
      permissionError,
      adjacencyPermissionData,
      adjacencyPermissionError,
      adjacencyPermissionLoading,
      previewData,
      previewError,
      previewLoading,
      sharedObjectInfo,
    ]
  )

  return (
    <ObjectSharingContext.Provider value={objectSharingState}>
      {children}
    </ObjectSharingContext.Provider>
  )
}

/*
  FUNCTIONS
*/

// Manage polling for permission updates while on the request access screen
function usePollingWhenAccessPending(
  objectSharingStatus: ObjectSharingStatus,
  startPolling: (poll: number) => void,
  stopPolling: () => void
) {
  React.useEffect(() => {
    switch (objectSharingStatus) {
      case ObjectSharingStatus.AccessRequestPending:
        startPolling(5000)
        break
      default:
        stopPolling()
        break
    }
    return stopPolling
  }, [objectSharingStatus, startPolling, stopPolling])
}
