import * as React from "react"
import { LayoutComponent, LayoutComponentType } from "@digits-graphql/frontend/graphql-bearer"
import { DocumentsComponent } from "src/frontend/components/Shared/Layout/Components/Vault/DocumentsComponent"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import {
  ComponentContainer,
  StatementComponentContainer,
  TextComponentContainer,
} from "src/frontend/components/Shared/Layout/Shared"
import { ChartType, matchComponent, SizingProps } from "src/frontend/components/Shared/Layout/types"
import { CashSummaryChartComponent } from "src/frontend/components/Shared/Portals/Components/Charts/CashSummaryChartComponent"
import { CategoryPortalComponent } from "src/frontend/components/Shared/Portals/Components/Charts/CategoryPortalComponent"
import { MetricPortalComponent } from "src/frontend/components/Shared/Portals/Components/Charts/MetricPortalComponent"
import { PartyPortalComponent } from "src/frontend/components/Shared/Portals/Components/Charts/PartyPortalComponent"
import { RunwayPortalComponent } from "src/frontend/components/Shared/Portals/Components/Charts/RunwayPortalComponent"
import { SummaryLineItemChartComponent } from "src/frontend/components/Shared/Portals/Components/Charts/SummaryLineItemChartComponent"
import { ComponentControlsWrapper } from "src/frontend/components/Shared/Portals/Components/ComponentControls"
import { KPIComponent } from "src/frontend/components/Shared/Portals/Components/KPI/KPIComponent"
import { TopEntities } from "src/frontend/components/Shared/Portals/Components/Lists/TopEntities"
import { TopTransactions } from "src/frontend/components/Shared/Portals/Components/Lists/TopTransactions"
import { StatementComponent } from "src/frontend/components/Shared/Portals/Components/Statements/StatementComponent"
import { TextComponent } from "src/frontend/components/Shared/Portals/Components/Text/TextComponent"
import { useIsEditLayoutActive } from "src/frontend/components/Shared/Portals/State/useIsEditLayoutActive"

interface MatchComponentProps {
  className?: string
  component: LayoutComponent
  componentSize: ComponentSize
  rowId?: string
  skipAnimations: boolean
  isDraggable?: boolean
  isDragging?: boolean
  isOverlay?: boolean
}

export const MatchComponent: React.FC<SizingProps & MatchComponentProps> = ({
  className,
  component,
  componentSize,
  rowId,
  height,
  skipAnimations,
  isDraggable,
  isDragging,
  isOverlay,
}) => (
  <ComponentControlsWrapper className={className} component={component} isDragging={isDragging}>
    <ComponentByType
      className={className}
      component={component}
      componentSize={componentSize}
      rowId={rowId}
      height={height}
      skipAnimations={skipAnimations}
      isDraggable={isDraggable}
      isDragging={isDragging}
      isOverlay={isOverlay}
    />
  </ComponentControlsWrapper>
)

const ComponentByType: React.FC<SizingProps & MatchComponentProps> = ({
  component,
  componentSize,
  rowId,
  height,
  skipAnimations,
  isDraggable,
  isDragging,
  isOverlay,
}) => {
  const isEditLayoutActive = useIsEditLayoutActive()
  if (matchComponent(component, "summaryLineItem", LayoutComponentType.SummaryLineItemChart)) {
    return (
      <ComponentContainer isDraggable={isDraggable} isDragging={isDragging} isOverlay={isOverlay}>
        <SummaryLineItemChartComponent
          component={component}
          componentSize={componentSize}
          height={height}
          skipAnimations={skipAnimations}
        />
      </ComponentContainer>
    )
  }

  if (matchComponent(component, "metricChart", LayoutComponentType.MetricSummaryChart)) {
    return (
      <ComponentContainer isDraggable={isDraggable} isDragging={isDragging} isOverlay={isOverlay}>
        <MetricPortalComponent
          component={component}
          componentSize={componentSize}
          height={height}
          skipAnimations={skipAnimations}
        />
      </ComponentContainer>
    )
  }

  if (matchComponent(component, "cashSummaryChart", LayoutComponentType.CashSummaryChart)) {
    return (
      <ComponentContainer isDraggable={isDraggable} isDragging={isDragging} isOverlay={isOverlay}>
        <CashSummaryChartComponent
          component={component}
          componentSize={componentSize}
          height={height}
          skipAnimations={skipAnimations}
        />
      </ComponentContainer>
    )
  }

  if (matchComponent(component, "runwayChart", LayoutComponentType.RunwayChart)) {
    return (
      <ComponentContainer isDraggable={isDraggable} isDragging={isDragging} isOverlay={isOverlay}>
        <RunwayPortalComponent
          component={component}
          componentSize={componentSize}
          height={height}
          skipAnimations={skipAnimations}
        />
      </ComponentContainer>
    )
  }

  if (matchComponent(component, "categoryChart", LayoutComponentType.CategoryBalanceSummaryChart)) {
    return (
      <ComponentContainer isDraggable={isDraggable} isDragging={isDragging} isOverlay={isOverlay}>
        <CategoryPortalComponent
          component={component}
          chartType={ChartType.BalanceSummary}
          componentSize={componentSize}
          height={height}
          skipAnimations={skipAnimations}
        />
      </ComponentContainer>
    )
  }

  if (
    matchComponent(component, "categoryChart", LayoutComponentType.CategoryTransactionSummaryChart)
  ) {
    return (
      <ComponentContainer isDraggable={isDraggable} isDragging={isDragging} isOverlay={isOverlay}>
        <CategoryPortalComponent
          component={component}
          chartType={ChartType.TransactionSummary}
          componentSize={componentSize}
          height={height}
          skipAnimations={skipAnimations}
        />
      </ComponentContainer>
    )
  }

  if (matchComponent(component, "partyChart", LayoutComponentType.PartyTransactionSummaryChart)) {
    return (
      <ComponentContainer isDraggable={isDraggable} isDragging={isDragging} isOverlay={isOverlay}>
        <PartyPortalComponent
          component={component}
          chartType={ChartType.TransactionSummary}
          componentSize={componentSize}
          height={height}
          skipAnimations={skipAnimations}
        />
      </ComponentContainer>
    )
  }

  if (matchComponent(component, "partyChart", LayoutComponentType.PartyRoleBalanceSummaryChart)) {
    return (
      <ComponentContainer isDraggable={isDraggable} isDragging={isDragging} isOverlay={isOverlay}>
        <PartyPortalComponent
          component={component}
          chartType={ChartType.BalanceSummary}
          componentSize={componentSize}
          height={height}
          skipAnimations={skipAnimations}
        />
      </ComponentContainer>
    )
  }

  if (matchComponent(component, "topEntities", LayoutComponentType.TopEntities)) {
    return (
      <ComponentContainer isDraggable={isDraggable} isDragging={isDragging} isOverlay={isOverlay}>
        <TopEntities component={component} componentSize={componentSize} height={height} />
      </ComponentContainer>
    )
  }

  if (matchComponent(component, "topTransactions", LayoutComponentType.TopTransactions)) {
    return (
      <ComponentContainer isDraggable={isDraggable} isDragging={isDragging} isOverlay={isOverlay}>
        <TopTransactions component={component} componentSize={componentSize} height={height} />
      </ComponentContainer>
    )
  }

  if (matchComponent(component, "statement", LayoutComponentType.Statement)) {
    return (
      <StatementComponentContainer
        isDraggable={isDraggable}
        isDragging={isDragging}
        isOverlay={isOverlay}
        componentSize={componentSize}
      >
        <StatementComponent component={component} componentSize={componentSize} height={height} />
      </StatementComponentContainer>
    )
  }

  if (matchComponent(component, "text", LayoutComponentType.Text)) {
    const {
      config: {
        text: { body },
      },
    } = component
    const isEmpty = !body.trim().length
    return (
      <TextComponentContainer
        isDraggable={isDraggable}
        isDragging={isDragging}
        isOverlay={isOverlay}
        editing={isEditLayoutActive}
        empty={isEmpty}
      >
        <TextComponent
          component={component}
          height={height}
          toolbarPortalElId={rowId ? `row-${rowId}` : undefined}
        />
      </TextComponentContainer>
    )
  }

  if (matchComponent(component, "staticMetric", LayoutComponentType.StaticMetric)) {
    return (
      <ComponentContainer isDraggable={isDraggable} isDragging={isDragging} isOverlay={isOverlay}>
        <KPIComponent component={component} componentSize={componentSize} height={height} />
      </ComponentContainer>
    )
  }

  if (matchComponent(component, "documentCollection", LayoutComponentType.DocumentCollection)) {
    return (
      <ComponentContainer isDraggable={isDraggable} isDragging={isDragging} isOverlay={isOverlay}>
        <DocumentsComponent component={component} componentSize={componentSize} height={height} />
      </ComponentContainer>
    )
  }

  return null
}
