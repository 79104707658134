import * as React from "react"
import { RunwayChartComponent } from "src/frontend/components/Shared/Layout/Components/Runway/RunwayChartComponent"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigLookbackOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import { MatchedComponent, SizingProps } from "src/frontend/components/Shared/Layout/types"
import { useRunwayLiveData } from "src/frontend/components/Shared/Portals/hooks/useRunwayLiveData"

/*
  STYLES
*/

/*
  INTERFACES
*/

interface RunwayChartComponentProps extends SizingProps {
  component: MatchedComponent<"runwayChart">
  componentSize: ComponentSize
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const RunwayPortalComponent: React.FC<RunwayChartComponentProps> = ({
  component,
  componentSize,
  height,
  skipAnimations,
}) => {
  const config = useConfigLookbackOriginOverride(component.config.runwayChart)
  const { runway, loading } = useRunwayLiveData(config)

  return (
    <RunwayChartComponent
      runway={runway}
      loading={loading}
      origin={config.origin}
      component={component}
      componentSize={componentSize}
      height={height}
      skipAnimations={skipAnimations}
    />
  )
}
