import { SessionPermissions } from "@digits-shared/session/Permissions"
import SessionLegalEntity from "src/frontend/session/LegalEntity"
import { FrontendPermissionModule } from "src/frontend/session/permissionModule"
import { Experience } from "src/frontend/session/personas"
import SessionAffiliationOrganization from "./AffiliationOrganization"

export interface SessionAffiliationAttributes {
  id: string
  entity: SessionLegalEntity
  organization: SessionAffiliationOrganization
  permissions: SessionPermissions<FrontendPermissionModule>
  primaryExperience?: Experience
}

/**
 * Simple wrapper for Organization Affiliations stored on JWT
 *
 * Provides convenience methods, better naming
 * than what is found on the intentionally abbreviated JWT,
 * and clean types.
 */
export default class SessionAffiliation {
  private readonly attributes: SessionAffiliationAttributes

  constructor(rawAffiliation?: SessionAffiliationAttributes) {
    if (!rawAffiliation) {
      this.attributes = {} as SessionAffiliationAttributes
      return
    }

    this.attributes = {
      ...rawAffiliation,
    }
  }

  /*
    ACCESSORS
  */

  get id() {
    return this.attributes.id
  }

  get organization() {
    return this.attributes.organization
  }

  get entity() {
    return this.attributes.entity
  }

  get permissions() {
    return this.attributes.permissions
  }

  get primaryExperience() {
    return this.attributes.primaryExperience
  }
}
