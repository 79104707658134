import * as React from "react"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { Telescope } from "src/frontend/components/OS/Springboard/Applications/Shared/Sprites/Telescope"

const NotFound = styled.div`
  color: ${colors.translucentSecondary70};
  font-family: ${fonts.family.avenir};
  font-weight: ${fonts.weight.medium};
  font-size: 22px;
  text-align: center;
`

/*
 COMPONENTS
*/

export const NoResultsFound: React.FC<{ className?: string }> = ({ className }) => (
  <>
    <Telescope className={className} />
    <NotFound>No results found</NotFound>
  </>
)
