import { ScopeSymbol, TypeSymbol } from "@digits-shared/scope/symbols"

export type ScopeTuple<S> = [Scope<S>, S]

export type UnknownScope = Scope<unknown>

export type UnknownScopeTuple = ScopeTuple<unknown>

export interface Scope<T> {
  defaultValue: T
  displayName?: string
  [TypeSymbol]: typeof ScopeSymbol
}

/**
 * Creates an object that serves as a scope identifier. The referential equality
 * of this object is important the funtioning of the system, so it is important to keep
 * a stable reference to this object.
 */
export function scope<S = undefined>(displayName: string, defaultValue: S): Scope<S> {
  return {
    defaultValue,
    displayName,
    [TypeSymbol]: ScopeSymbol,
  }
}
