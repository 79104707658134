import { Insight, Period } from "@digits-graphql/frontend/graphql-bearer"
import { Variants } from "framer-motion"

export const MAX_SUGGESTION_COUNT = 4
export const SUGGESTION_CLASS_NAME = "suggestion"
// Must be long enough to account for suggestion animation plus largest staggered delay time
export const SUGGESTION_DURATION = 1000

export const SWITCH_CLASS_NAME = "marketing-or-insight"
export const SWITCH_DURATION = 400

/*
  INTERFACES
*/

export type PeriodInsight = Insight & {
  period: Period
}

export interface SuggestionAnimProps {
  fadeOut: boolean
  animIndex: number
}

export interface Chip {
  ref: React.MutableRefObject<HTMLAnchorElement>
  text: string
  index: number
}

export type InsightChip = Chip & {
  insight: PeriodInsight
}

export type ChipHoverCallback = (chip: Chip | undefined) => void

export type InsightChipHoverCallback = (chip: InsightChip | undefined) => void

/*
  STYLES
*/

export const suggestionsVariants: Variants = {
  entering: { opacity: 1, height: "auto" },
  exiting: { opacity: 0, height: 0 },
}

export const suggestionVariants: Variants = {
  entering: (i) => ({ opacity: 1, x: 0, transition: { delay: 0.075 * i } }),
  exiting: { opacity: 0, x: 20 },
}
