import * as React from "react"
import {
  LayoutComponentType,
  MetricChartConfig as Config,
} from "@digits-graphql/frontend/graphql-bearer"
import stringHelper from "@digits-shared/helpers/stringHelper"
import { CASH_METRIC_TYPES, CashBadgedTitle } from "src/frontend/components/Shared/Layout/CashBadge"
import { toSortedTimeseriesValues } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { SizingProps } from "src/frontend/components/Shared/Layout/types"
import { BarChartConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Charts/BarChartConfig"
import { useMetricByTimeLiveData } from "src/frontend/components/Shared/Portals/hooks/useMetricByTimeLiveData"
import { useRemoveConfig } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Drawer/ComponentConfigContext"

/*
  INTERFACES
*/

interface MetricChartConfigProps extends SizingProps {
  config: Config
  componentType: LayoutComponentType
  skipAnimations: boolean
}

/*
  COMPONENTS
*/
export const MetricChartConfig: React.FC<MetricChartConfigProps> = ({
  config,
  componentType,
  height,
  skipAnimations,
}) => {
  const removeConfig = useRemoveConfig()

  const { dimensionalSummary, loading } = useMetricByTimeLiveData(config, componentType)

  const timeseries = React.useMemo(
    () => toSortedTimeseriesValues(dimensionalSummary?.time),
    [dimensionalSummary?.time]
  )

  // remove this component if the results are empty
  React.useEffect(() => {
    if (!loading && !dimensionalSummary?.time?.length) {
      removeConfig()
    }
  }, [dimensionalSummary, loading, removeConfig])

  const name = React.useMemo(() => {
    const title = stringHelper.camelCaseToSpaces(config.item)

    return CASH_METRIC_TYPES.includes(config.item) ? <CashBadgedTitle title={title} /> : title
  }, [config])

  return (
    <BarChartConfig
      name={name}
      timeseries={timeseries}
      skipAnimations={skipAnimations}
      height={height}
    />
  )
}
