import * as React from "react"
import {
  CategoryType,
  LayoutComponentType,
  TopTransactionsConfig as Config,
} from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import stringHelper from "@digits-shared/helpers/stringHelper"
import styled from "styled-components"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { SizingProps } from "src/frontend/components/Shared/Layout/types"
import { useRemoveConfig } from "src/frontend/components/Shared/Portals/ConfigsSidebar/ComponentConfigContext"
import { Title } from "src/frontend/components/Shared/Portals/ConfigsSidebar/Configs/Lists/shared"
import { useTopTransactionsLiveData } from "src/frontend/components/Shared/Portals/hooks/useTopTransactionsLiveData"
import {
  Date,
  Description,
  Title as RowTitle,
  TopTransactionsList,
} from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopTransactionsList"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import { invertValuesForCategoryType } from "src/shared/helpers/categoryHelper"

const StyledTopTransactionsList = styled(TopTransactionsList)`
  ${RowTitle}, ${Amount} {
    color: #0c404b;
  }

  ${Description} {
    color: #0c404b99;
  }

  ${Date} {
    color: #0c404b99;
  }
`

/*
  COMPONENTS
*/

export const TopTransactionsConfig: React.FC<
  SizingProps & { config: Config; componentType: LayoutComponentType }
> = ({ config, componentType }) => {
  const removeConfig = useRemoveConfig()

  const role = React.useMemo(
    () => (config.partyRole ? FrontendPartyRole.findByRole(config.partyRole) : undefined),
    [config.partyRole]
  )

  const invertValues = React.useMemo(() => {
    if (role) {
      return role?.invertValues ?? false
    }

    return invertValuesForCategoryType(config.categoryType ?? CategoryType.UnknownType)
  }, [config.categoryType, role])

  const countDisplay = React.useMemo(() => {
    if (role) {
      return config.limit < 2
        ? `${role.displayName} Transaction`
        : `${role.pluralDisplayName} Transactions`
    }

    if (config.categoryType) {
      const typeName = stringHelper.camelCaseToSpaces(config.categoryType, true)

      return config.limit < 2 ? `${typeName} Transaction` : `${typeName} Transactions`
    }

    return undefined
  }, [config.categoryType, config.limit, role])

  const { data, loading } = useTopTransactionsLiveData(config, componentType)

  // remove this component if the results are empty
  React.useEffect(() => {
    if (!loading && !data?.transactions?.length) {
      removeConfig()
    }
  }, [data, loading, removeConfig])

  if (!countDisplay) return null

  return (
    <InvertValuesContext.Provider value={invertValues}>
      <Title componentSize={ComponentSize.Small}>Top {countDisplay} </Title>
      <StyledTopTransactionsList padding={10} transactions={data?.transactions} loading={loading} />
    </InvertValuesContext.Provider>
  )
}
