import * as React from "react"
import { LayoutRow } from "@digits-graphql/frontend/graphql-bearer"
import colors from "@digits-shared/themes/colors"
import { useDroppable } from "@dnd-kit/core"
import { horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable"
import styled from "styled-components"
import { DropType, TargetKind } from "src/frontend/components/Shared/DragAndDrop/dragAndDropShared"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useDropInfo } from "src/frontend/components/Shared/Portals/DragAndDrop/useDropInfo"
import { ComponentOptionsContextProvider } from "src/frontend/components/Shared/Portals/Layout/ComponentOptionsContextProvider"
import { DraggableComponent } from "src/frontend/components/Shared/Portals/Layout/DraggableComponent"
import { ComponentGutter } from "src/frontend/components/Shared/Portals/Layout/Gutter"
import { useDragAndDropDisabled } from "src/frontend/components/Shared/Portals/State/useDragAndDropDisabled"
import { useIsEditLayoutActive } from "src/frontend/components/Shared/Portals/State/useIsEditLayoutActive"

/*
 STYLES
*/

const DropZone = styled.div<{ dragWithin: boolean }>`
  position: relative;
  background-color: ${({ dragWithin }) =>
    dragWithin ? colors.translucentNeonGreen30 : colors.transparent};
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const Components = styled.div`
  overflow: visible;
  display: flex;
  flex-direction: row;
`

/*
 COMPONENTS
*/

export const Row: React.FC<{ sectionId: string; row: LayoutRow }> = ({ sectionId, row }) => {
  const disabled = useDragAndDropDisabled()
  const { componentWidth, componentsInRow } = useDropInfo({ sectionId, rowId: row.rowId })
  const isEditLayoutActive = useIsEditLayoutActive()

  // Things given to <SortableComponent> must have a field named "id"
  const sortableComponents = React.useMemo(
    () =>
      componentsInRow.map((c) => ({
        ...c,
        id: c.componentId,
      })),
    [componentsInRow]
  )

  const { setNodeRef: droppableRef } = useDroppable({
    id: row.rowId,
    data: {
      id: row.rowId,
      rowId: row.rowId,
      sectionId,
      targetKind: TargetKind.Row,
      type: DropType.Component,
    },
    disabled,
  })

  const componentSize = ComponentSize.componentSizeFor(componentsInRow.length)

  return (
    <DropZone id={`row-${row.rowId}`} ref={droppableRef} dragWithin={false}>
      <SortableContext items={sortableComponents} strategy={horizontalListSortingStrategy}>
        <Components>
          {isEditLayoutActive && (
            <ComponentGutter sectionId={sectionId} rowId={row.rowId} index={0} />
          )}
          {componentsInRow.map((c, i) => (
            <ComponentOptionsContextProvider key={c.componentId}>
              <DraggableComponent
                component={c}
                componentSize={componentSize}
                width={componentWidth}
                sectionId={sectionId}
                rowId={row.rowId}
              />
              {(isEditLayoutActive || i !== componentsInRow.length - 1) && (
                <ComponentGutter sectionId={sectionId} rowId={row.rowId} index={i + 1} />
              )}
            </ComponentOptionsContextProvider>
          ))}
        </Components>
      </SortableContext>
    </DropZone>
  )
}
