import * as React from "react"
import { useResolveThreadMutation } from "@digits-graphql/frontend/graphql-bearer"
import { SvgXCircle } from "@digits-shared/components/SVGIcons/line/XCircle.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { useAssistantContext } from "src/frontend/components/Shared/Assistant/Context"
import { AssistantInput } from "src/frontend/components/Shared/Assistant/Input"
import { AssistantMessages } from "src/frontend/components/Shared/Assistant/Messages"

export const SCREEN_MARGIN = 30
export const ASSISTANT_WINDOW_WIDTH = 430 + SCREEN_MARGIN

/*
  STYLES
*/

const CenterContainer = styled.div`
  min-height: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid ${colors.white};
  border-radius: 16px 16px 0 0;
  background: linear-gradient(rgba(255, 255, 255, 0.74) 12.73%, rgba(255, 255, 255, 0.43) 90.37%);
`

const ScrollContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  padding: 16px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1;
  min-height: 34px;
  align-items: flex-end;
`

/*
  COMPONENTS
*/

export const AssistantChatWindow: React.FC<{ className?: string }> = ({ className }) => {
  const {
    state: { isSubmitting, currentMessageText, messages },
  } = useAssistantContext()

  const scrollRef = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [isSubmitting, messages])

  return (
    <>
      <CenterContainer className={className}>
        <ScrollContainer ref={scrollRef}>
          <AssistantMessages />
          <ButtonContainer>
            <CancelSubmit />
            <Clear />
          </ButtonContainer>
        </ScrollContainer>
      </CenterContainer>
      <AssistantInput key={currentMessageText} />
    </>
  )
}

const CancelSubmit: React.FC = () => {
  const {
    state: { isSubmitting },
    dispatch,
  } = useAssistantContext()

  const onCancel = React.useCallback(() => dispatch({ type: "SUBMIT_CANCEL" }), [dispatch])

  if (!isSubmitting) return null

  return (
    <DigitsButton $variant="secondary-dark" onClick={onCancel} size="medium">
      <SvgXCircle />
      Stop Answering
    </DigitsButton>
  )
}

const Clear: React.FC = () => {
  const { state, dispatch } = useAssistantContext()

  const [resolveThread] = useResolveThreadMutation()

  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      if (state.threadId) resolveThread({ variables: { threadId: state.threadId } })

      // Optimistic update
      dispatch({ type: "CLEAR" })
    },
    [state.threadId, resolveThread, dispatch]
  )

  if (state.isSubmitting || !state.threadId || state.messages.size === 0) return null

  return (
    <DigitsButton $variant="secondary-dark" onClick={onClick} size="medium">
      <SvgXCircle />
      Clear History
    </DigitsButton>
  )
}
