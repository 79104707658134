import * as React from "react"
import { SvgRefreshCw01 } from "@digits-shared/components/SVGIcons/line/RefreshCw01.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import useSession from "@digits-shared/hooks/useSession"
import styled from "styled-components"
import { useAssistantContext } from "src/frontend/components/Shared/Assistant/Context"
import { AssistantMessage } from "src/frontend/components/Shared/Assistant/reducer"
import FrontendSession from "src/frontend/session"

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const AskAgainResponse: React.FC<{ message: AssistantMessage }> = ({ message }) => {
  const { user } = useSession<FrontendSession>()
  const {
    dispatch,
    state: { isSubmitting, lastAssistantMessageId, subscriptionError, messages },
  } = useAssistantContext()

  const userMessages = Array.from(messages.values()).filter((m) => m.comment.authorId === user.id)
  const lastMessageText =
    userMessages.length > 0 ? userMessages[userMessages.length - 1]?.comment.text : undefined

  const onSubmit = React.useCallback(() => {
    dispatch({ type: "SUBMIT", payload: lastMessageText })
    return Promise.resolve()
  }, [dispatch, lastMessageText])

  // do not show the ask again button if
  // 1. there is not a subscription error event
  // 2. we are currently submitting a message
  // 3. the last assistant message id is not the same as the current message id
  if (
    !subscriptionError ||
    isSubmitting ||
    lastAssistantMessageId !== message.comment.id ||
    !lastMessageText
  ) {
    return null
  }

  return (
    <ButtonWrapper>
      <DigitsButton
        size="small"
        $variant="secondary-dark"
        disabled={isSubmitting}
        onClick={onSubmit}
      >
        <SvgRefreshCw01 /> Ask Again
      </DigitsButton>
    </ButtonWrapper>
  )
}
