import * as React from "react"
import {
  TimeseriesBarChartStyle,
  TimeseriesLineChartStyle,
} from "src/frontend/components/OS/Shared/Charts/styles"
import { ParentSizedTimeseriesBreakdownChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesBreakdownChart"
import {
  Timeseries,
  TimeseriesValue,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { TileCard } from "src/frontend/components/Shared/Layout/Components/Tiles/TileCard"
import { TimeseriesCardHeading } from "src/frontend/components/Shared/Layout/Components/Tiles/TimeseriesCardHeading"
import { TileChartContainer } from "src/frontend/components/Shared/Layout/Shared"

/*
 STYLES
*/

/*
 COMPONENTS
*/

interface TileProps {
  breakdownTimeseries: Timeseries[]
  totalTimeseries: Timeseries
  skipAnimations?: boolean
  height: number
  selectedIndex?: number
  onMouseOver: (value: TimeseriesValue, index: number) => void
  onMouseOut: (value?: TimeseriesValue) => void
  onClick?: (value: TimeseriesValue, index: number) => void
  barChartStyle?: TimeseriesBarChartStyle
  lineChartStyle?: TimeseriesLineChartStyle
}

export const TimeseriesBreakdownChartTile: React.FC<TileProps> = ({
  breakdownTimeseries,
  totalTimeseries,
  height,
  skipAnimations,
  selectedIndex,
  onMouseOver,
  onMouseOut,
  onClick,
  barChartStyle,
  lineChartStyle,
}) => (
  <TileCard height={height} skipAnimations={skipAnimations}>
    <TimeseriesCardHeading timeseries={totalTimeseries.values} selectedIndex={selectedIndex} />
    <TileChartContainer height={78}>
      <ParentSizedTimeseriesBreakdownChart
        breakdownTimeseries={breakdownTimeseries}
        totalTimeseries={totalTimeseries}
        noTooltip
        hideAxis
        hideGrid
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onClick}
        barChartStyle={barChartStyle}
        lineChartStyle={lineChartStyle}
      />
    </TileChartContainer>
  </TileCard>
)
