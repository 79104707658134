import * as React from "react"
import { PartyRole, StatementDeltas, Transaction } from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { hasFirstElement } from "@digits-shared/helpers/arrayHelper"
import styled from "styled-components"
import {
  Timeseries,
  toSortedTimeseriesValues,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { TopPartiesList } from "src/frontend/components/Shared/Reports/Report/Components/Parties/PartiesList"
import { OverTimeChart } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/OverTimeChart"
import {
  PopOverTab,
  PopOverTabs,
} from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/PopOverTabs"
import { TopTransactions } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/TopTransactions"
import { toStatementDeltas } from "src/frontend/components/Shared/Reports/Report/Components/Statements/toStatementDeltas"
import { CategoryTitle } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Components/Text/PopOver/EntityPopOverTitle"
import { DetailsPopUp } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Components/Text/PopOver/shared"
import { TextCategoryHover } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/hooks/useTextComponentData"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import { invertValuesForCategory } from "src/shared/helpers/categoryHelper"

/*
  STYLES
*/

const TopParties = styled(TopPartiesList)`
  padding: 15px 20px;
`

/*
  INTERFACES
*/

interface PopOverProps {
  hoverData: TextCategoryHover
}

/*
  COMPONENTS
*/

export const EntityCategoryPopOver: React.FC<PopOverProps> = ({ hoverData }) => {
  const { entity: category, context, topTransactions } = hoverData

  const amountOverTime = React.useMemo<Timeseries | undefined>(() => {
    if (!hoverData) return undefined
    const values = toSortedTimeseriesValues(hoverData.history.time)
    return {
      label: category.name,
      values,
    }
  }, [category.name, hoverData])

  const deltas: StatementDeltas | undefined = React.useMemo(() => {
    if (!hasFirstElement(hoverData?.history?.time)) return undefined
    const { moneyFlow, deltaPrevious, deltaYearAgo } = hoverData.history.time[0].summary.total
    return toStatementDeltas({ moneyFlow, deltaPrevious, deltaYearAgo })
  }, [hoverData])

  const topParties = context?.party
  const transactions = React.useMemo(
    () => (topTransactions as Transaction[]) || [],
    [topTransactions]
  )
  const partySummaries = React.useMemo(
    () => topParties?.map(({ partyObject: party, summary }) => ({ party, summary })) || [],
    [topParties]
  )

  // TODO: AVS doesnt return roles so this is pointless, it will always be unknown
  const partiesTitle = React.useMemo(() => {
    const partiesRole = topParties
      ?.find((topParty) => !!topParty.partyObject.roles?.find((r) => r !== PartyRole.UnknownRole))
      ?.partyObject.roles?.find((r) => r !== PartyRole.UnknownRole)

    return `Top ${
      partiesRole ? FrontendPartyRole.displayStringForRole(partiesRole, true) : "Vendors"
    }`
  }, [topParties])

  const tabs = React.useMemo(() => {
    const data: React.ReactElement[] = []
    data.push(
      <PopOverTab key="overview" title="Overview">
        <OverTimeChart amountOverTime={amountOverTime} deltas={deltas} isBalance={false} />
      </PopOverTab>
    )

    // top parties chart is optional
    if (partySummaries.length) {
      data.push(
        <PopOverTab key="parties" title={partiesTitle}>
          <TopParties partySummaries={partySummaries} category={category} />
        </PopOverTab>
      )
    }

    // top transactions list is optional
    if (transactions.length) {
      data.push(
        <PopOverTab key="top" title="Top Transactions">
          <TopTransactions transactions={transactions} />
        </PopOverTab>
      )
    }
    return data
  }, [amountOverTime, category, deltas, partiesTitle, partySummaries, transactions])

  return (
    <InvertValuesContext.Provider value={invertValuesForCategory(category)}>
      <DetailsPopUp width={420} maxHeight="auto">
        <CategoryTitle category={category} />
        <PopOverTabs>{tabs}</PopOverTabs>
      </DetailsPopUp>
    </InvertValuesContext.Provider>
  )
}
