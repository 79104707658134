import * as React from "react"
import * as ReactDOM from "react-dom"
import { SvgCoinsHand } from "@digits-shared/components/SVGIcons/line/CoinsHand.svg"
import { SvgFile07 } from "@digits-shared/components/SVGIcons/line/File07.svg"
import { SvgWallet01 } from "@digits-shared/components/SVGIcons/line/Wallet01.svg"
import styled from "styled-components"
import { SharedEntities } from "src/frontend/components/OS/Springboard/Applications/SharedWithMe/Shared"
import {
  SIDEBAR_PATH_SVG_STYLES,
  SIDEBAR_SVG_STYLES,
} from "src/frontend/components/Shared/NavSidebar/sidebarConstants"
import { SidebarRow } from "src/frontend/components/Shared/NavSidebar/SidebarExpando"
import routes from "src/frontend/routes"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

/*
  STYLES
*/

const ReportsIcon = styled(SvgFile07)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const CategoriesIcon = styled(SvgWallet01)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const TransactionsIcon = styled(SvgCoinsHand)`
  ${SIDEBAR_PATH_SVG_STYLES};
`

const PartyRow = styled(SidebarRow)`
  svg {
    ${SIDEBAR_SVG_STYLES};
  }
`
/*
  COMPONENTS
*/

export const ObjectKindSelector: React.FC<{
  entities: SharedEntities
}> = ({ entities }) => {
  const modalRootEl = document.querySelector<HTMLElement>("#nav-sidebar-contents")
  if (!modalRootEl) return null

  return ReactDOM.createPortal(
    <>
      {!!entities.reportPackages.length && (
        <SidebarRow Icon={ReportsIcon} route={routes.sharedWithMeReportPackages}>
          Reports
        </SidebarRow>
      )}
      {!!entities.categories.length && (
        <SidebarRow Icon={CategoriesIcon} route={routes.sharedWithMeCategories}>
          Categories
        </SidebarRow>
      )}
      {!!entities.transactions.length && (
        <SidebarRow Icon={TransactionsIcon} route={routes.sharedWithMeTransactions}>
          Transactions
        </SidebarRow>
      )}
      {Array.from(entities.parties.keys()).map((role) => {
        const partyRole = FrontendPartyRole.findByRole(role)

        return (
          <PartyRow
            key={role}
            Icon={partyRole.SearchSvgIcon}
            route={routes.sharedWithMeParties}
            params={{ partyRole: partyRole.urlKey }}
          >
            {partyRole.displayName}
          </PartyRow>
        )
      })}
    </>,
    modalRootEl
  )
}
