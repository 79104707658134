import {
  CategoryType,
  DirectionFromOrigin,
  DoubleEntry,
  Flow,
  IntervalOrigin,
  Pagination,
  PartyRole,
  SortDirection,
  TransactionColumnName,
  TransactionFilter,
  ViewKey,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { TransactionDisplayOptions } from "src/frontend/components/OS/Springboard/Applications/Shared/Transactions/TransactionListContext"

export const INITIAL_TRANSACTION_LIST_PAGINATION: Pagination = {
  limit: 200,
  offset: 0,
}

interface VariableOptions {
  viewKey: ViewKey
  intervalOrigin: IntervalOrigin
  partyRole?: PartyRole
  categoryTypes?: CategoryType[]
  filter: TransactionDisplayOptions
}

export interface TransactionListRequestVariables {
  pagination: Pagination
  paginationDirection: DirectionFromOrigin
  sortColumnName: TransactionColumnName
  sortDirection: SortDirection
  filter: TransactionFilter
  intervalOrigin: IntervalOrigin
  naturalFlow?: Flow
}

export interface PartialTransactionListRequestVariables
  extends Partial<Omit<TransactionListRequestVariables, "filter">> {
  filter?: Partial<TransactionFilter>
}

interface TransactionListVariablesState {
  listRequestVariables?: PartialTransactionListRequestVariables
  transactionIds?: string[]
}

export const transactionListRequestVariables = {
  variables({
    viewKey,
    intervalOrigin,
    partyRole,
    categoryTypes,
    filter: {
      sort,
      expandedTransactionId,
      expandedCommentId,
      minValue,
      maxValue,
      categoryId,
      categoryIds,
      withoutCategoryAncestors,
      otherSideCategoryId,
      withoutOtherSideCategoryAncestors,
      naturalFlow,
      ...transactionURLFilters
    },
  }: VariableOptions): TransactionListRequestVariables {
    const todayIntervalOrigin = dateTimeHelper.todayIntervalOrigin(intervalOrigin.interval)
    const endOfPeriod = dateTimeHelper.endOfIntervalOrigin(todayIntervalOrigin).unix()

    let singleCategory: string | undefined
    let requestWithoutCategoryAncestors: boolean | undefined
    let doubleEntry: DoubleEntry | undefined
    if (categoryId && otherSideCategoryId) {
      doubleEntry = {
        firstEntryCategoryId: categoryId,
        secondEntryCategoryId: otherSideCategoryId,
        withoutFirstEntryAncestors: withoutCategoryAncestors,
        withoutSecondEntryAncestors: withoutOtherSideCategoryAncestors,
      }
    } else if (categoryId) {
      singleCategory = categoryId
      requestWithoutCategoryAncestors = withoutCategoryAncestors
    } else if (otherSideCategoryId) {
      singleCategory = otherSideCategoryId
      requestWithoutCategoryAncestors = withoutOtherSideCategoryAncestors
    } else if (categoryIds?.length) {
      requestWithoutCategoryAncestors = withoutCategoryAncestors
    }

    return {
      sortColumnName: sort.column,
      sortDirection: sort.direction,
      pagination: {
        ...INITIAL_TRANSACTION_LIST_PAGINATION,
      },
      paginationDirection: DirectionFromOrigin.Past,
      filter: {
        viewKey,
        categoryTypes,
        partyRole,
        occurredBefore: endOfPeriod,
        categoryId: singleCategory,
        categoryIds: singleCategory ? undefined : categoryIds,
        withoutCategoryAncestors: requestWithoutCategoryAncestors,
        doubleEntry,
        minValue,
        maxValue,
        ...transactionURLFilters,
      },
      intervalOrigin,
      naturalFlow,
    }
  },

  toLocationState(
    variables?: PartialTransactionListRequestVariables
  ): TransactionListVariablesState {
    return { listRequestVariables: variables }
  },

  fromLocationState(locationState: unknown): PartialTransactionListRequestVariables | undefined {
    return (locationState as TransactionListVariablesState)?.listRequestVariables
  },

  idsToLocationState(ids: string[]): TransactionListVariablesState {
    return { transactionIds: ids }
  },

  idsFromLocationState(locationState: unknown): string[] | undefined {
    return (locationState as TransactionListVariablesState)?.transactionIds
  },
}
