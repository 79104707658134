import React, { ChangeEvent } from "react"
import { ViewType } from "@digits-graphql/frontend/graphql-public"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import {
  useCanToggleViewType,
  useViewTypeContext,
} from "src/frontend/components/Shared/Contexts/ViewTypeContext"
import { ACTIVE_COLOR } from "src/frontend/components/Shared/NavSidebar/sidebarConstants"

const TOGGLE_SIZE = 24

export const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: calc(100% - 24px);
  position: relative;
  margin: 0 12px;
  padding: 10px 12px 10px 9px;
  border-radius: 8px;
  cursor: pointer;
  color: ${colors.secondary80};

  &:hover {
    background: ${colors.translucentSecondary05};
  }
`

const Switch = styled.input`
  width: 160px;
  height: ${TOGGLE_SIZE}px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  position: absolute;
`

const SwitchWrapper = styled.div<{ active: boolean }>`
  width: ${TOGGLE_SIZE + 5} px;
  height: ${TOGGLE_SIZE}px;
  min-width: ${TOGGLE_SIZE + 5}px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${TOGGLE_SIZE - 7}px;
  border: 2px solid ${({ active }) => (active ? colors.transparent : ACTIVE_COLOR)};

  background: ${colors.secondary10};
  backdrop-filter: blur(20px);
`

const SwitchContainer = styled.div<{ active: boolean }>`
  position: relative;
  background: linear-gradient(to right, ${colors.translucentWhite50} 50%, ${ACTIVE_COLOR} 50%);
  background-position-x: ${({ active }) => (active ? 0 : 100)}%;
  border-radius: 10px;
  width: ${TOGGLE_SIZE - 5}px;
  height: ${TOGGLE_SIZE - 10}px;
  min-width: ${TOGGLE_SIZE - 10}px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease-in-out;

  background-size: 200% 100%;

  p {
    color: ${colors.white};
  }

  ${Switch}:checked, ${Switch}:checked + p {
    color: ${ACTIVE_COLOR};
  }
`

const Text = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: ${fonts.weight.heavy};
`

const Label = styled(Text)`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
`

export const SidebarViewType: React.FC = () => {
  const showToggle = useCanToggleViewType()

  const { viewType, setViewType } = useViewTypeContext()
  const isChecked = viewType === ViewType.Ledger
  const text = isChecked ? "L" : "AI"

  const onToggle = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.target.checked ? setViewType(ViewType.Ledger) : setViewType(ViewType.AIBookkeeper)
    },
    [setViewType]
  )

  if (!showToggle) {
    return null
  }

  return (
    <Container>
      <SwitchWrapper active={isChecked}>
        <SwitchContainer active={isChecked}>
          <Switch type="checkbox" defaultChecked={isChecked} onChange={onToggle} />
          <Text>{text}</Text>
        </SwitchContainer>
      </SwitchWrapper>
      <Label>Toggle View Type</Label>
    </Container>
  )
}
