import * as React from "react"
import {
  Insight,
  InsightSubjectType,
  PartyDesignation,
  TransactionParty,
} from "@digits-graphql/frontend/graphql-bearer"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"

const ICON_STYLES = css`
  height: ${IconSize.Medium.pixels}px;
  width: ${IconSize.Medium.pixels}px;
  overflow: visible;
  background: ${colors.translucentSecondary10};
  padding: 4px;
  border-radius: 100%;
  margin: 0;

  ${svgIconStyles(colors.secondary)}
`

const IconWrapper = styled.div`
  height: 34px;
  width: 34px;
  padding: 1px;
  border-radius: 100%;
`

const StyledCategoryIcon = styled(SVGIconComponent)`
  ${ICON_STYLES};
  padding: 5px;
`

/*
  INTERFACES
*/

interface IconProps {
  insight: Insight
}

/*
  COMPONENTS
*/

export const InsightPopUpIcon: React.FC<IconProps> = ({ insight }) => {
  switch (insight.subjectType) {
    case InsightSubjectType.Party:
      return <InsightPartyIcon insight={insight} />
    case InsightSubjectType.Category:
      return <InsightCategoryIcon insight={insight} />
    default:
      return null
  }
}

const InsightPartyIcon: React.FC<IconProps> = ({ insight }) => {
  const party: TransactionParty = {
    id: insight.subjectId,
    name: insight.subjectDisplayKey,
    iconUrl: insight.subjectIconUrl,
    // party designation is only relevant to parties without icons, so it
    // is not required for the party we're composing here
    designation: insight.designation || PartyDesignation.UnknownDesignation,
  }

  return <PartyIcon party={party} size={IconSize.Medium} />
}

const InsightCategoryIcon: React.FC<IconProps> = ({ insight }) => (
  <IconWrapper>
    <StyledCategoryIcon subjectDisplayKey={insight.subjectDisplayKey} />
  </IconWrapper>
)
