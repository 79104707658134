import * as React from "react"
import { Comment, Interval } from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock, LoadingCircle } from "@digits-shared/components/Loaders"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgDigitsLogo } from "@digits-shared/components/SVGIcons/digits/DigitsLogo.svg"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import userHelper from "@digits-shared/helpers/userHelper"
import useLazyInterval from "@digits-shared/hooks/useLazyInterval"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import moment from "moment/moment"
import styled, { css } from "styled-components"
import { useAssistantContext } from "src/frontend/components/Shared/Assistant/Context"
import { AssistantMessageReactions } from "src/frontend/components/Shared/Assistant/Messages/Reactions"
import {
  ASSISTANT_GRID_AREAS,
  ASSISTANT_ICON_SIZE,
  AssistantMessageUser,
} from "src/frontend/components/Shared/Assistant/Messages/Shared"
import { AssistantMessage } from "src/frontend/components/Shared/Assistant/reducer"
import FrontendSession from "src/frontend/session"
import { DIGITS_CANONICAL_USER_ID } from "src/shared/config/identifiers"

/*
  STYLES
*/

const ASSISTANT_ICON_STYLES = css`
  grid-area: ${ASSISTANT_GRID_AREAS.icon};
  ${svgIconStyles(colors.secondary)};
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px ${colors.translucentSecondary10};
  background-color: ${colors.translucentSecondary60};
  width: ${ASSISTANT_ICON_SIZE}px;
  height: ${ASSISTANT_ICON_SIZE}px;
`

const AssistantIcon = styled(SvgDigitsLogo)`
  ${ASSISTANT_ICON_STYLES};
  background-color: ${colors.translucentSecondary20};
`

const AuthorIcon = styled(ColorIcon)`
  ${ASSISTANT_ICON_STYLES};
  margin: 0;
`
const LoadingIcon = styled(LoadingCircle)`
  grid-area: ${ASSISTANT_GRID_AREAS.icon};
`

const OrbIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: ${ASSISTANT_GRID_AREAS.icon};
  width: ${ASSISTANT_ICON_SIZE + 2}px;
  height: ${ASSISTANT_ICON_SIZE + 2}px;
  border-radius: 50%;
  box-shadow:
    0 0 5px ${colors.translucentNeonGreen70},
    0 0 0 1px ${colors.translucentNeonGreen15};
`

const OrbIcon = styled.img`
  width: ${ASSISTANT_ICON_SIZE * 2}px;
  height: ${ASSISTANT_ICON_SIZE * 2}px;
`

const User = styled(AssistantMessageUser)`
  font-size: 10px;
  font-weight: ${fonts.weight.heavy};
  color: ${colors.translucentSecondary50};
  text-transform: uppercase;
`

const SecondaryText = styled.div`
  margin-left: 3px;
  font-weight: ${fonts.weight.normal};
`

/*
  COMPONENTS
*/

export const AssistantMessageHeader: React.FC<{
  message: AssistantMessage
  isWelcome?: boolean
}> = ({ message, isWelcome }) => {
  const { user } = useSession<FrontendSession>()
  const { state } = useAssistantContext()
  const isDigitsUser = message.comment.authorId === DIGITS_CANONICAL_USER_ID
  const currentUser = React.useMemo(() => userHelper.sessionUserToEntity(user), [user])

  const { timeDelta } = useTimestamp(message.comment)

  if (isDigitsUser && state.isSubmitting && state.activeAssistantMessageId === message.comment.id) {
    return <AssistantAuthorDigitsThinking />
  }

  return (
    <>
      {isDigitsUser ? (
        <AssistantIcon />
      ) : (
        <AuthorIcon
          imageUrl={currentUser.avatarUrl}
          fallbackUser={currentUser}
          size={ASSISTANT_ICON_SIZE}
          fontSize={9}
        />
      )}
      <User>
        {isDigitsUser ? "Digits" : "You"}
        <SecondaryText>{timeDelta}</SecondaryText>
      </User>
      {message && isDigitsUser && !isWelcome ? (
        <AssistantMessageReactions message={message} />
      ) : undefined}
    </>
  )
}

export const AssistantAuthorDigitsThinking: React.FC = () => (
  <>
    <OrbIconContainer>
      <OrbIcon alt="Digits Thinking" src={require("static/images/icons/digits-assistant.gif")} />
    </OrbIconContainer>
    <User>
      Digits
      <SecondaryText>is analyzing</SecondaryText>
    </User>
  </>
)

export const AssistantAuthorLoading: React.FC = () => (
  <>
    <LoadingIcon
      width={`${ASSISTANT_ICON_SIZE}px`}
      height={`${ASSISTANT_ICON_SIZE}px`}
      $shineColor={colors.translucentSecondary30}
    />
    <User>
      <LoadingBlock height="15px" width="100px" $shineColor={colors.translucentSecondary30} />
    </User>
  </>
)

function useTimestamp(comment: Comment) {
  const date = moment.unix(comment.timestamp)
  const sameDay = date.isSame(moment(), "day")
  const fromNow = date.fromNow()

  // update timestamp every minute
  const [timeDelta, setTimeDelta] = React.useState(
    sameDay
      ? fromNow.toLowerCase() === "a few seconds ago"
        ? "Now"
        : fromNow
      : dateTimeHelper.displayNameFromMoment(date, Interval.Day)
  )

  const [startInterval, timer] = useLazyInterval(() => {
    setTimeDelta(date.fromNow())
  }, 60 * 1000)

  React.useEffect(() => {
    if (!sameDay) {
      if (timer.current) {
        clearInterval(timer.current)
      }
      return
    }
    startInterval()
  }, [sameDay, startInterval, timer])

  return React.useMemo(() => ({ timeDelta, sameDay }), [timeDelta, sameDay])
}
