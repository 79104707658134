import * as React from "react"
import { ObjectSharingContext } from "src/frontend/session/ObjectSharing"
import { ObjectSharingStatus } from "src/frontend/session/ObjectSharingStatus"

export function useObjectSharingState() {
  return React.useContext(ObjectSharingContext)
}

export function useObjectSharingStatusEquals(status: ObjectSharingStatus): boolean {
  const { objectSharingStatus } = useObjectSharingState()
  return objectSharingStatus === status
}

export function useIsShareUser(): boolean {
  const { objectSharingStatus } = useObjectSharingState()

  // ObjectSharingStatus will be not unknown if we are in a sharing context
  return objectSharingStatus !== ObjectSharingStatus.Unknown
}
