import * as React from "react"
import * as ReactDOM from "react-dom"
import { ProductArea } from "@digits-graphql/frontend/graphql-bearer"
import { chevronStyles, PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import { RowContentDescription } from "@digits-shared/components/UI/Table/Content"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { InsightPopUpChart } from "src/frontend/components/OS/Springboard/Applications/Search/SearchSuggestions/PopUp/InsightPopUpChart"
import { InsightPopUpIcon } from "src/frontend/components/OS/Springboard/Applications/Search/SearchSuggestions/PopUp/InsightPopUpIcon"
import { InsightChip } from "src/frontend/components/OS/Springboard/Applications/Search/SearchSuggestions/Shared"
import { RequiresDigitsEmployee } from "src/frontend/components/Shared/Permissions/Requires"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"
import { InsightItem, Score, SubjectNameHighlight } from "src/shared/components/Insights/Item"
import { AnchorLocation, getAnchorOffsets } from "src/shared/helpers/anchorOffsets"

const POP_UP_Y_OFFSET = 5
const POP_UP_WIDTH = 560
const CHEVRON_SIZE = 11

/*
  INTERFACES
*/

interface InsightPopUpProps {
  className?: string
  insightChip: InsightChip
  onMouseEnterPopUp: (e: React.MouseEvent) => void
  onMouseLeavePopUp: (e: React.MouseEvent) => void
  children?: React.ReactNode
}

interface PopUpPositioningProps {
  chipElRef?: React.RefObject<HTMLAnchorElement>
}

/*
  STYLES
*/

function popOverPositioning({ chipElRef }: PopUpPositioningProps) {
  if (!chipElRef) return undefined

  const windowWidth = window.innerWidth
  let left = windowWidth / 2 - POP_UP_WIDTH / 2
  const startingRight = left + POP_UP_WIDTH

  const chipElWidth = chipElRef.current?.offsetWidth ?? 0

  const { top, left: chipLeft } = getAnchorOffsets(chipElRef, AnchorLocation.BottomLeft)

  const chipRight = chipLeft + chipElWidth

  if (chipLeft < left) {
    left = chipLeft
  } else if (chipRight > startingRight) {
    left += chipRight - startingRight
  }

  return css`
    top: ${top + CHEVRON_SIZE + POP_UP_Y_OFFSET}px;
    left: ${left}px;
  `
}

function chevronPositioning({ chipElRef }: PopUpPositioningProps) {
  if (!chipElRef) return undefined

  const chipElWidth = chipElRef.current?.offsetWidth ?? 0

  const { top, left } = getAnchorOffsets(chipElRef, AnchorLocation.BottomLeft)

  const chevronLeft = left + chipElWidth / 2 - CHEVRON_SIZE

  return css`
    top: ${top + POP_UP_Y_OFFSET}px;
    left: ${chevronLeft}px;
  `
}

const PopUpContainer = styled.div<PopUpPositioningProps>`
  position: absolute;
  cursor: pointer;
  width: ${POP_UP_WIDTH}px;
  min-height: 150px;
  border-radius: 13px;
  padding: 16px 20px 20px 20px;
  z-index: 1;
  background: ${colors.translucentWhite80};
  backdrop-filter: blur(35px);
  box-shadow: 0px 0px 15px ${colors.translucentBlack10};
  ${popOverPositioning};
`

// Keep this z-index above the container so it is above the box-shadow
const Chevron = styled.div<PopUpPositioningProps>`
  position: absolute;
  z-index: 2;
  ${chevronStyles(PointingDirection.Up, `${CHEVRON_SIZE}px`, colors.translucentWhite80)};
  ${chevronPositioning};
`
/* 
  Insights are not currently being generated and displayed. Leaving this for later reference, when they are.

  ${PopUpContainer}, ${Chevron} {
    transition:
      opacity ${SWITCH_DURATION}ms ease-out,
      transform ${SWITCH_DURATION}ms ease-out,
      left 100ms ease-out;
  }

  .${SWITCH_CLASS_NAME}-enter > & {
    ${Chevron}, ${PopUpContainer} {
      opacity: 0;
      transform: translate3d(0, -10px, 0);
    }
  }

  .${SWITCH_CLASS_NAME}-enter-active > & {
    ${Chevron}, ${PopUpContainer} {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .${SWITCH_CLASS_NAME}-exit > & {
    ${Chevron}, ${PopUpContainer} {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .${SWITCH_CLASS_NAME}-exit-active > & {
    ${Chevron}, ${PopUpContainer} {
      transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
      opacity: 0;
      transform: translate3d(0, -10px, 0);
    } 
  }
*/

const AnimationContainer = styled.div``

const TitleArea = styled.div`
  display: flex;
  height: 34px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`

const Title = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: ${fonts.weight.heavy};
  margin-left: 11px;
  color: ${colors.secondary};

  &:first-child {
    margin-left: 0;
  }
`

const DetailsArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledInsightItem = styled(InsightItem)`
  width: 324px;
  padding: 0;
  align-items: flex-start;
  border: none;

  ${RowContentDescription} {
    color: ${colors.secondary};
  }

  ${SubjectNameHighlight} {
    color: ${colors.theme.dark.link};
  }
`

const DebugInfo = styled(Score)`
  position: absolute;
  bottom: 4px;
  right: 8px;
  color: ${colors.translucentBlack20};
`

/*
  COMPONENTS
*/

export const InsightPopUp: React.FC<InsightPopUpProps> = ({
  className,
  insightChip,
  onMouseEnterPopUp,
  onMouseLeavePopUp,
}) => {
  const { history } = useRouter()
  const session = useSession<FrontendSession>()
  const modalRoot = useModalRoot()
  const generatePath = useFrontendPathGenerator()
  const { insight, ref, text: title } = insightChip
  const { period } = insight
  const productArea = insight.productArea ?? ProductArea.None

  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      // Important to clear the hovered pop-up as we're navigating, otherwise it will briefly appear
      // again when we return.
      onMouseLeavePopUp(e)
      history.push(generatePath(routes.search, { q: insightChip.text }), { source: "suggested" })
    },
    [generatePath, history, insightChip.text, onMouseLeavePopUp]
  )

  return ReactDOM.createPortal(
    <AnimationContainer
      className={className}
      onMouseEnter={onMouseEnterPopUp}
      onMouseLeave={onMouseLeavePopUp}
    >
      <Chevron chipElRef={ref} />
      <PopUpContainer chipElRef={ref} onClick={onClick}>
        <RequiresDigitsEmployee>
          <DebugInfo>
            Period: {dateTimeHelper.displayNameFromPeriod(insight.period)}, Score: {insight.score},
            Global Score: {insight.globalScore?.toFixed(4) || "none"}
          </DebugInfo>
        </RequiresDigitsEmployee>
        <TitleArea>
          <InsightPopUpIcon insight={insight} />
          <Title>{title}</Title>
        </TitleArea>
        <DetailsArea>
          <StyledInsightItem
            alwaysShowDate
            insight={insight}
            entities={undefined}
            intervalOrigin={dateTimeHelper.intervalOriginFromPeriod(period)}
            session={session}
            legalEntityId={session.currentLegalEntityId}
            productArea={productArea}
          />
          <InsightPopUpChart insightChip={insightChip} />
        </DetailsArea>
      </PopUpContainer>
    </AnimationContainer>,
    modalRoot
  )
}
