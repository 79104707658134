import * as React from "react"
import colors from "@digits-shared/themes/colors"
import { AnimatePresence, m, Variants } from "framer-motion"
import styled from "styled-components"
import {
  byFeatureKind,
  colorForFeatureKind,
  strokeWidth,
  yOffsetForPage,
} from "src/frontend/components/OS/Shared/DocumentViewer/helpers"
import {
  DisplayedPage,
  DocumentViewerAction,
} from "src/frontend/components/OS/Shared/DocumentViewer/reducer"
import {
  BoundingBox,
  ResolveBoundingBoxes,
} from "src/frontend/components/OS/Shared/DocumentViewer/types"

/*
 * INTERFACES
 */

interface DocumentViewerPageProps<T> {
  svgRef: React.RefObject<SVGSVGElement>
  showDebugColors?: boolean
  index: number
  pages: DisplayedPage[]
  dispatch: React.Dispatch<DocumentViewerAction>
  resolveBoundingBoxes?: ResolveBoundingBoxes<T>
  onBoundingBoxClick?: (box: BoundingBox<T>) => void
  onBoundingBoxMouseEnter?: (box: BoundingBox<T>) => void
  onBoundingBoxMouseLeave?: (box: BoundingBox<T>) => void
}

/*
 * STYLES
 */

const variants: Variants = {
  entering: {
    opacity: 1,
    transform: "scale(1.0)",
    transition: { duration: 0.4, ease: "easeOut" },
  },
  exiting: {
    opacity: 0,
    transform: "scale(1.1)",
    transition: { duration: 0.4, ease: "easeIn" },
  },
}

const StyledBox = styled.rect<{ strokeWidth: number }>`
  transition: stroke 200ms ease;

  &:hover {
    cursor: pointer;
    stroke: ${colors.accentGreen} !important;
    stroke-width: ${({ strokeWidth: sw }) => sw * 2}px !important;
  }
`

const StyledImage = styled(m.image)`
  border-radius: 16px;
`

const PageGroup = styled(m.g)`
  transform-origin: center;
`

/*
 * COMPONENTS
 */

export const DocumentViewerPage: React.FC<DocumentViewerPageProps<unknown>> = ({
  svgRef,
  showDebugColors,
  index,
  pages,
  dispatch,
  resolveBoundingBoxes,
  onBoundingBoxClick,
  onBoundingBoxMouseEnter,
  onBoundingBoxMouseLeave,
}) => {
  const page = pages[index]

  const imageRef = React.useRef<SVGImageElement>(null)

  const onImageLoad = React.useCallback(
    (e: React.UIEvent<SVGImageElement>) => {
      const svgRect = svgRef.current?.getBoundingClientRect()
      if (svgRect) {
        // Ensure that the viewport has the right initial size as the image is loaded
        dispatch({ type: "Resize", width: svgRect.width, height: svgRect.height })
      }
      dispatch({ type: "ImageLoaded", pageIndex: index, imageEl: e.currentTarget })
    },
    [dispatch, index, svgRef]
  )

  const yOffset = React.useMemo(
    () =>
      yOffsetForPage(
        index,
        pages.flatMap((p) => (p.imageEl ? [p.imageEl] : []))
      ),
    [index, pages]
  )

  const pageWidth = React.useMemo(() => page?.imageEl?.getBBox().width ?? 0, [page?.imageEl])

  const boundingBoxes = React.useMemo(
    () =>
      resolveBoundingBoxes?.(
        index,
        yOffset,
        page?.imageEl?.getBBox().width ?? 0,
        page?.imageEl?.getBBox().height ?? 0
      )?.sort(byFeatureKind),
    [resolveBoundingBoxes, index, yOffset, page?.imageEl]
  )

  const imageUrlKey = React.useMemo(() => {
    const imageUrl = new URL(page?.imageUrl || "")
    // This is unique every time the bill is queried
    imageUrl.searchParams.delete("token")
    return imageUrl.toString()
  }, [page?.imageUrl])

  return (
    <AnimatePresence>
      <PageGroup
        key={imageUrlKey}
        variants={variants}
        initial="exiting"
        animate={page?.imageEl ? "entering" : "exiting"}
      >
        <StyledImage
          clipPath="inset(0% round 16px)"
          y={yOffset}
          ref={imageRef}
          href={page?.imageUrl}
          onLoad={onImageLoad}
        />

        {!!page?.imageEl &&
          !!boundingBoxes &&
          boundingBoxes.map((box) => (
            <Box
              key={box.id}
              pageWidth={pageWidth}
              box={box}
              showDebugColors={showDebugColors}
              onBoundingBoxClick={onBoundingBoxClick}
              onBoundingBoxMouseEnter={onBoundingBoxMouseEnter}
              onBoundingBoxMouseLeave={onBoundingBoxMouseLeave}
            />
          ))}
      </PageGroup>
    </AnimatePresence>
  )
}

export const Box: React.FC<{
  pageWidth: number
  showDebugColors: boolean | undefined
  box: BoundingBox<unknown>
  onBoundingBoxClick?: (box: BoundingBox<unknown>) => void
  onBoundingBoxMouseEnter?: (box: BoundingBox<unknown>) => void
  onBoundingBoxMouseLeave?: (box: BoundingBox<unknown>) => void
}> = ({
  pageWidth,
  showDebugColors,
  box,
  onBoundingBoxClick,
  onBoundingBoxMouseEnter,
  onBoundingBoxMouseLeave,
}) => {
  const {
    emphasized,
    kind,
    rect: { x, y, width: w, height: h },
  } = box

  const ref = React.useRef<SVGRectElement>(null)
  const stroke = colorForFeatureKind(kind, emphasized, showDebugColors)

  const onBoxClick = React.useCallback(() => {
    onBoundingBoxClick?.(box)
  }, [box, onBoundingBoxClick])

  const onBoxEnter = React.useCallback(() => {
    onBoundingBoxMouseEnter?.(box)
  }, [box, onBoundingBoxMouseEnter])

  const onBoxLeave = React.useCallback(() => {
    onBoundingBoxMouseLeave?.(box)
  }, [box, onBoundingBoxMouseLeave])

  return (
    <StyledBox
      strokeWidth={strokeWidth(pageWidth)}
      ref={ref}
      x={x}
      y={y}
      rx="3"
      ry="3"
      width={w}
      height={h}
      style={{
        fill: "transparent",
        strokeWidth: strokeWidth(pageWidth, emphasized),
        stroke,
      }}
      onClick={onBoxClick}
      onMouseEnter={onBoxEnter}
      onMouseLeave={onBoxLeave}
    />
  )
}
