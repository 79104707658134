import * as React from "react"
import { TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { ColumnOptionKey } from "src/frontend/components/Shared/Reports/Report/Components/Statements/columnTypes"

/*
  CONTEXTS
*/

export type PinnedCell = {
  rowId: string
  target: HTMLElement
}

export interface SelectedValue {
  optionKey: ColumnOptionKey
  value: TimeseriesValue
}

interface RowSelectionContextProps {
  pinnedCell?: PinnedCell
  setPinnedCell: (value: PinnedCell | undefined) => void
  selectedValue?: SelectedValue
  setSelectedValue: (value: SelectedValue | undefined) => void
}

export const RowSelectionContext = React.createContext<RowSelectionContextProps>({
  setPinnedCell: () => ({}),
  setSelectedValue: () => ({}),
})

export default function useRowSelectionContext() {
  return React.useContext(RowSelectionContext)
}
