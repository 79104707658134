import * as React from "react"
import { Link } from "react-router-dom"
import {
  CategoryDimension,
  IntervalOrigin,
  Party,
  PartyRole,
} from "@digits-graphql/frontend/graphql-bearer"
import { useIsInboundAmount } from "@digits-shared/components/Contexts/InvertValuesContext"
import { LoadingBlock, LoadingCircle } from "@digits-shared/components/Loaders"
import { DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { IconSize } from "@digits-shared/components/UI/Icons/Icon"
import { Amount } from "@digits-shared/components/UI/Table/Content"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import { VibrantBackgroundValues } from "src/shared/components/Elements/VibrantBackground"
import { SVGIconComponent } from "src/shared/components/Icons/SVGIcon"
import {
  PartyAdditionalDetails,
  PartyDetailsDescription,
  PartyDetailsName,
} from "src/shared/components/PartyHover/AdditionalDetails"
import { PartyIcon } from "src/shared/components/PartyHover/PartyIcon"
import customKeyframes from "src/shared/config/customKeyframes"
import transitions from "src/shared/config/transitions"
import { OTHER_CATEGORIES_NAME } from "src/shared/helpers/categoryHelper"

/*
  STYLES
*/

const PartyFlex = styled.div`
  animation: ${customKeyframes.fadeIn} ${transitions.defaultFadeIn.duration};
  width: 100%;
`

const PartyHeader = styled.div<PartyHeaderProps>`
  position: relative;
  display: flex;
  width: 100%;
  padding: 15px;
  overflow: hidden;
  border-top-left-radius: ${borders.theme.dark.radius.modal}px;
  border-top-right-radius: ${borders.theme.dark.radius.modal}px;

  ${(props) => css`
    background-color: ${props.backgroundColor};
    background-image: ${props.backgroundImage};
    color: ${props.textColor};
  `}

  ${(props) =>
    props.hasBottomBorderRadius &&
    css`
      border-radius: ${borders.theme.dark.radius.modal}px;
    `}
`

const PartyTransactionActivity = styled.div`
  width: 100%;
  padding: 15px;
  font-weight: ${fonts.weight.medium};
  font-size: 14px;
`

const SUMMARY_HEADER_STYLES = css`
  display: block;
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-left: 1px;
  font-size: smaller;
`

const CategorySummaryHeader = styled.div`
  ${SUMMARY_HEADER_STYLES};
`

const CategorySummaryHeaderLink = styled(Link)`
  ${SUMMARY_HEADER_STYLES};

  &:hover {
    text-decoration: underline;
  }
`

const CategorySummaryCell = styled.div`
  padding: 5px 0;
`

const CATEGORY_SUMMARY_STYLES = css`
  &:not(:first-child) {
    border-top: 1px solid ${colors.theme.dark.border};
  }

  width: 100%;
  display: flex;
  justify-content: space-between;

  ${CategorySummaryCell}:last-child {
    text-align: right;
  }
`

const CategorySummary = styled.div`
  ${CATEGORY_SUMMARY_STYLES}
`

const CategorySummaryLink = styled(Link)`
  ${CATEGORY_SUMMARY_STYLES}

  &:hover {
    text-decoration: underline;
  }
`

const CategoryNameAndIcon = styled.div`
  display: flex;
  align-items: center;
`

const PartyDetailsStyled = styled.div`
  flex: 1;
  padding-left: 20px;
`

const CategoryIcon = styled(SVGIconComponent)`
  height: 15px;
  width: 15px;
  margin-top: -1px;
  margin-right: 7px;
  ${svgIconStyles(colors.secondary)};
`

const LightCategoryNameAndIcon = styled(CategoryNameAndIcon)`
  padding-left: 22px;
`

/*
  INTERFACES
*/

interface PartyDetailsProps {
  isLoading: boolean
  intervalOrigin?: IntervalOrigin
  isPreview?: boolean
  party?: Party & { iconBlob?: string }
  partyRole?: PartyRole
  partyImageRef?: React.RefObject<HTMLImageElement>
  categorySummaries?: Pick<CategoryDimension, "categoryObject" | "summary">[]
  categorySummaryIntervalOrigin?: IntervalOrigin
  invertValues: boolean
  textColor: string
  backgroundColor: string
  backgroundImage?: string
  partyDetailsPath?: string
  categoryDetailsRoute?: DigitsRoute
  categoriesTotalTransactions: number
}

interface PartyHeaderProps {
  backgroundColor?: string
  backgroundImage?: string
  textColor?: string
  hasBottomBorderRadius?: boolean
}

/*
  COMPONENT
*/

export default class PartyDetails extends React.PureComponent<PartyDetailsProps> {
  render() {
    const { party, isLoading } = this.props
    if (!party || isLoading) return this.renderLoadingParty()

    const { backgroundColor, backgroundImage, textColor, isPreview, partyDetailsPath } = this.props

    const background: VibrantBackgroundValues = {
      backgroundColor,
      backgroundImage,
      textColor,
      isLoading,
    }

    return (
      <PartyFlex>
        <PartyHeader
          backgroundColor={this.props.backgroundColor}
          backgroundImage={this.props.backgroundImage}
          textColor={this.props.textColor}
          hasBottomBorderRadius={!this.props.categoriesTotalTransactions}
        >
          <PartyIcon
            size={IconSize.Large}
            partyImageRef={this.props.partyImageRef}
            party={party}
            background={background}
            disableHoverBorder
          />
          <PartyAdditionalDetails
            party={party}
            isPreview={isPreview}
            partyDetailsPath={partyDetailsPath}
            vibrantBackgroundValues={background}
          />
        </PartyHeader>
        {this.renderCategoryDetails(this.props.categoriesTotalTransactions)}
      </PartyFlex>
    )
  }

  renderLoadingParty = () => {
    const { isPreview, intervalOrigin, backgroundColor, backgroundImage, textColor } = this.props
    const transactionPreview = !isPreview && !!intervalOrigin && <TransactionPreviewLoading />

    return (
      <PartyFlex>
        <PartyHeader
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
          textColor={textColor}
          hasBottomBorderRadius={!intervalOrigin}
        >
          <LoadingCircle width="80px" height="80px" backgroundColor={colors.translucentWhite20} />
          <PartyDetailsStyled>
            <PartyDetailsName>
              <LoadingBlock
                width="80px"
                height="26px"
                backgroundColor={colors.translucentWhite20}
              />
            </PartyDetailsName>
            <PartyDetailsDescription>
              <LoadingBlock
                width="100px"
                height="15px"
                backgroundColor={colors.translucentWhite20}
              />
            </PartyDetailsDescription>
          </PartyDetailsStyled>
        </PartyHeader>
        {transactionPreview}
      </PartyFlex>
    )
  }

  renderCategoryDetails(categoriesTotalTransactions: number) {
    const { categorySummaries, categorySummaryIntervalOrigin, partyRole } = this.props
    if (!categorySummaries) return

    if (categoriesTotalTransactions === 0) return

    const categorySummaryChildren = categorySummaries.map(this.renderCategoryDimension)

    const transactionWord = categoriesTotalTransactions === 1 ? "transaction" : "transactions"
    const partyRolePrefix =
      partyRole && partyRole !== PartyRole.UnknownRole
        ? `${FrontendPartyRole.displayStringForRole(partyRole)} for `
        : ""

    const periodName =
      categorySummaryIntervalOrigin &&
      dateTimeHelper.periodFromIntervalOrigin(categorySummaryIntervalOrigin).name

    let summaryElement: React.ReactNode = `${partyRolePrefix}${categoriesTotalTransactions} ${transactionWord} in ${periodName}`

    summaryElement = this.props.partyDetailsPath ? (
      <CategorySummaryHeaderLink to={this.props.partyDetailsPath}>
        {summaryElement}
      </CategorySummaryHeaderLink>
    ) : (
      <CategorySummaryHeader>{summaryElement}</CategorySummaryHeader>
    )

    return (
      <PartyTransactionActivity>
        {summaryElement}
        {categorySummaryChildren}
      </PartyTransactionActivity>
    )
  }

  renderCategoryDimension = (categoryDimension: CategoryDimension) => {
    if (!this.props.intervalOrigin) return

    const {
      invertValues,
      categoryDetailsRoute,
      partyDetailsPath,
      intervalOrigin: { intervalCount, ...intervalOriginRest },
    } = this.props

    const categorySpend = numberHelper.currency(categoryDimension.summary.total.value, {
      style: CurrencyStyle.Detail,
      invertValues,
    })

    if (categoryDimension.summary.total.value.amount === 0) return

    const route =
      categoryDetailsRoute &&
      categoryDetailsRoute.generateFromCurrentPath({
        categoryId: categoryDimension.categoryId,
        ...intervalOriginRest,
      })

    const categorySummaryComponent = (
      <React.Fragment>
        <CategorySummaryCell>
          <CategoryNameAndIconComponent categoryDimension={categoryDimension} />
        </CategorySummaryCell>
        <CategorySummaryCell>
          <CategoryAmountComponent
            categoryDimension={categoryDimension}
            categorySpend={categorySpend}
          />
        </CategorySummaryCell>
      </React.Fragment>
    )

    const isCurrentPage = route === window.location.pathname

    return categoryDimension.categoryObject.name === OTHER_CATEGORIES_NAME ||
      !route ||
      isCurrentPage ||
      !partyDetailsPath ? (
      <CategorySummary key={categoryDimension.categoryObject.name}>
        {categorySummaryComponent}
      </CategorySummary>
    ) : (
      <CategorySummaryLink to={route} key={categoryDimension.categoryObject.name}>
        {categorySummaryComponent}
      </CategorySummaryLink>
    )
  }
}

const CategoryNameAndIconComponent: React.FC<{
  categoryDimension: CategoryDimension
}> = (props) => {
  const { categoryDimension } = props

  if (categoryDimension.categoryObject.name === OTHER_CATEGORIES_NAME) {
    return (
      <LightCategoryNameAndIcon>{categoryDimension.categoryObject.name}</LightCategoryNameAndIcon>
    )
  }

  return (
    <CategoryNameAndIcon>
      <CategoryIcon subjectDisplayKey={categoryDimension.categoryObject.displayKey} />
      {categoryDimension.categoryObject.name}
    </CategoryNameAndIcon>
  )
}

const LightAmount = styled(Amount)`
  color: ${colors.translucentWhite50};
`

const CategoryAmountComponent: React.FC<{
  categoryDimension: CategoryDimension
  categorySpend: string | string[]
}> = (props) => {
  const { categoryDimension, categorySpend } = props
  const isInbound = useIsInboundAmount(categoryDimension.summary.total.value.amount)

  if (categoryDimension.categoryObject.name === OTHER_CATEGORIES_NAME) {
    return (
      <LightAmount ignoreHover inbound={isInbound}>
        {categorySpend}
      </LightAmount>
    )
  }

  return (
    <Amount ignoreHover inbound={isInbound}>
      {categorySpend}
    </Amount>
  )
}

const TransactionPreviewLoading: React.FC = () => (
  <PartyTransactionActivity>
    <CategorySummaryHeader>
      <LoadingBlock width="50%" height="17px" backgroundColor={colors.translucentWhite04} />
    </CategorySummaryHeader>
    <LoadingBlock width="100%" height="21px" backgroundColor={colors.translucentWhite04} />
  </PartyTransactionActivity>
)
