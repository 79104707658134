import * as React from "react"
import { usePrevious } from "react-use"
import { SearchHit, SearchResult } from "@digits-graphql/frontend/graphql-bearer"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import DatePicker from "src/frontend/components/OS/Springboard/Applications/Search/SearchResults/DatePicker"
import { useHitsCount } from "src/frontend/components/OS/Springboard/Applications/Search/SearchResults/resultType"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import { SearchResultType } from "src/frontend/types/SearchResultType"
import Odometer from "src/shared/components/Elements/Odometer"
import { SvgSearchCategory } from "src/shared/components/SVG/SearchCategory.svg"
import { SvgSearchInvoice } from "src/shared/components/SVG/SearchInvoice.svg"
import { SvgSearchTransaction } from "src/shared/components/SVG/SearchTransaction.svg"

/*
 STYLES
*/

const SectionHeader = styled.div`
  display: flex;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  align-items: center;

  font-weight: ${fonts.weight.black};
  font-size: 16px;
  color: ${colors.secondary};

  padding: 0 25px;

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`

const Count = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  line-height: 19px;
`

const Counter = styled(Odometer)`
  position: relative;
  font-weight: ${fonts.weight.normal};
`

const IconContainer = styled.div`
  width: 35px;
  margin: 4px 10px 0 -2px;
`

/*
 INTERFACES
*/

interface ResultSectionHeaderProps {
  type: SearchResultType
  hits: SearchHit[]
  search: SearchResult
}

/*
 COMPONENTS
*/

export const ResultSectionHeader: React.FC<ResultSectionHeaderProps> = ({ type, hits, search }) => {
  const facetCount = useHitsCount(type, hits, search)
  const count = facetCount?.count ?? 0
  const previousCount = usePrevious(facetCount?.count)

  const typeName = React.useMemo(() => {
    switch (type) {
      case SearchResultType.Lender:
      case SearchResultType.OwedVendor:
      case SearchResultType.UnpaidCustomer:
      case SearchResultType.PrepaidVendor:
      case SearchResultType.Shareholder:
      case SearchResultType.Customer:
      case SearchResultType.Supplier:
      case SearchResultType.Vendor: {
        const feRole = FrontendPartyRole.findBySearchResultType(type)
        return count !== 1 ? feRole.pluralDisplayName : feRole.displayName
      }
      case SearchResultType.Category:
        return stringHelper.pluralize(count, type, "Categories")
      case SearchResultType.Comment:
        return stringHelper.pluralize(count, type, "Comments")
      case SearchResultType.Report:
        return stringHelper.pluralize(count, type, "Reports")
      case SearchResultType.Transaction:
        return stringHelper.pluralize(count, type, "Transactions")
      case SearchResultType.User:
        return stringHelper.pluralize(count, type, "Users")
      case SearchResultType.Invoice:
        return stringHelper.pluralize(count, type, "Invoices")
    }
  }, [type, count])

  const hasInterval = type === SearchResultType.Vendor || type === SearchResultType.Category
  const hasDatePicker = !hasInterval && type === SearchResultType.Transaction

  const duration = Math.log10(count) * 500

  return (
    <SectionHeader>
      <IconContainer>
        <HeaderIcon type={type} />
      </IconContainer>
      <Count>
        <Counter
          key={count}
          startValue={previousCount}
          value={count}
          duration={duration}
          delay={700}
          animation="count"
        />
        <div>&nbsp;{typeName}</div>
      </Count>
      {hasDatePicker && <DatePicker />}
    </SectionHeader>
  )
}

const HeaderIcon: React.FC<{
  type: SearchResultType
}> = ({ type }) => {
  switch (type) {
    case SearchResultType.Lender:
    case SearchResultType.OwedVendor:
    case SearchResultType.UnpaidCustomer:
    case SearchResultType.PrepaidVendor:
    case SearchResultType.Shareholder:
    case SearchResultType.Customer:
    case SearchResultType.Supplier:
    case SearchResultType.Vendor: {
      const { SearchSvgIcon } = FrontendPartyRole.findBySearchResultType(type)
      return <SearchSvgIcon />
    }

    case SearchResultType.Category:
      return <SvgSearchCategory />
    case SearchResultType.Transaction:
      return <SvgSearchTransaction />
    case SearchResultType.Invoice:
      return <SvgSearchInvoice />

    case SearchResultType.User:
    case SearchResultType.Comment:
    case SearchResultType.Report:
      return null
  }
}
