import { Variants } from "framer-motion"

export const MODAL_WIDTH = 430
export const MODAL_HEIGHT = 600
export const MODAL_RADIUS = 16

export const transitionVariants: Variants = {
  entering: { opacity: 1, transition: { duration: 0.2 } },
  exiting: { opacity: 0, transition: { duration: 0.2 } },
}
