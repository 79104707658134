import * as React from "react"
import { Link } from "react-router-dom"
import { DocumentStatus, Interval } from "@digits-graphql/frontend/graphql-bearer"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { usePopOverState } from "@digits-shared/hooks/usePopOverState"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { VaultFile } from "src/frontend/components/OS/Springboard/Applications/Vault/types"
import { FileActionsMenu } from "src/frontend/components/OS/Springboard/Applications/Vault/VaultBrowser/FileActionsMenu"
import { useFilePreview } from "src/frontend/components/OS/Springboard/Applications/Vault/VaultBrowser/useFilePreview"
import { fileTypeFor } from "src/frontend/components/OS/Springboard/Applications/Vault/vaultMime"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import { FileSvgIcon } from "src/shared/components/Icons/FileIcon"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

/*
  STYLES
*/

const FileRow = styled(Link)<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  background: ${colors.translucentWhite60};
  border: 0.5px solid ${colors.white};
  border-radius: 8px;
  padding-right: 25px;

  & .delete-button:not(:active) {
    display: none;
  }

  &:hover {
    border-color: rgba(50, 73, 127, 0.1);

    .delete-button {
      display: block;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`

const IMG_STYLES = css`
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
  flex-shrink: 0;
`

const Cover = styled.img`
  ${IMG_STYLES};
`

const Placeholder = styled.div`
  ${IMG_STYLES};
  background: rgba(50, 73, 127, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
`

const PlaceholderIcon = styled(FileSvgIcon)`
  width: 100%;
  height: 100%;
  padding: 6px;
`

const MetaContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
`

const Name = styled.div`
  font-size: 14px;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Date = styled.div`
  font-size: 12px;
  color: ${colors.secondary70};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const WrappingTitle = styled.div`
  line-break: anywhere;
`

/*
  INTERFACES
*/
interface FileProps {
  file: VaultFile
  className?: string
}

/*
  COMPONENTS
*/
export const VaultFileRow: React.FC<FileProps> = ({ file, className }) => {
  const pathGenerator = useFrontendPathGenerator()
  const disabled = file.status === DocumentStatus.New
  const stopPropagation = useStopClickPropagation()

  return (
    <FileRow
      className={className}
      disabled={disabled}
      onClick={stopPropagation}
      to={pathGenerator(routes.vaultFile, { fileId: file.id })}
    >
      <FilePreview file={file} />
      <FileMeta file={file} />
      <FileActionsMenu file={file} selection={undefined} />
    </FileRow>
  )
}

const FilePreview: React.FC<{ file: VaultFile }> = ({ file }) => {
  const imageUrl = useFilePreview(file)
  if (!imageUrl)
    return (
      <Placeholder>
        <PlaceholderIcon fileType={fileTypeFor(file)} />
      </Placeholder>
    )

  return <Cover src={imageUrl} />
}

const FileMeta: React.FC<{ file: VaultFile }> = ({ file }) => {
  const nameRef = React.useRef<HTMLDivElement>(null)
  const { isPopOverOpen, onMouseEnter, onMouseLeave } = usePopOverState({
    waitToOpen: 300,
  })
  const showTooltipIfOverflows = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!nameRef.current || nameRef.current.scrollWidth <= nameRef.current.offsetWidth) return
      onMouseEnter(e)
    },
    [onMouseEnter]
  )

  const title = file.title ?? file.fileName ?? "Untitled"

  return (
    <div css="display: contents" onMouseLeave={onMouseLeave}>
      <MetaContainer onMouseEnter={showTooltipIfOverflows}>
        <DigitsTooltip content={<WrappingTitle>{title}</WrappingTitle>} open={isPopOverOpen}>
          <Name ref={nameRef}>{title}</Name>
        </DigitsTooltip>
        <Date>
          Uploaded {dateTimeHelper.displayNameFromUnixTimestamp(file.createdAt, Interval.Day)}
        </Date>
      </MetaContainer>
    </div>
  )
}
