import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import SearchBox, { ClearBox, SearchIcon } from "@digits-shared/components/UI/Elements/SearchBox"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import FrontendSession from "src/frontend/session"

/*
  STYLES
*/

const SearchBoxStyled = styled(SearchBox)<{ translateDown: boolean }>`
  &:focus-within,
  &:not(:focus-within) {
    border: none;
  }

  && {
    ${SearchIcon} {
      ${svgPathStyles(colors.secondary, 1.5)};
      margin: 0 8px;
      height: 24px;
      width: 24px;
    }
  }

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.54) 12.73%,
    rgba(255, 255, 255, 0.33) 90.37%
  );
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 16px;

  height: 60px;
  position: relative;
  border: none;
  flex: 1;

  input {
    font-size: 18px;

    &::placeholder {
      text-transform: capitalize;
      font-size: 18px;
    }
  }

  ${ClearBox} {
    padding: 0 12px 4px 0;
    font-size: 30px;
  }
`

const SearchingIcon = styled.img`
  padding: 2px;
  height: 100%;
`

/*
  INTERFACES
*/

interface Props {
  className?: string
  initialValue: string
  isSearchActive: boolean
  isSearchRequestInFlight: boolean
  onSubmit: (term: string) => void
  onClear: () => void
  isDisabled: boolean
}

/*
  COMPONENTS
*/

const DigitsSearchBox: React.FC<Props> = ({
  className,
  initialValue,
  isSearchActive,
  isSearchRequestInFlight,
  onSubmit,
  onClear,
  isDisabled,
}) => {
  const [showSpinner, setShowSpinner] = React.useState(isSearchRequestInFlight)
  const { currentLegalEntity } = useSession<FrontendSession>()

  // Delaying hiding the spinner for no good reason other than we have a spinner so lets use it.
  React.useEffect(() => {
    if (isSearchRequestInFlight) return setShowSpinner(true)

    const timer = setTimeout(() => setShowSpinner(false), 1200)
    return () => {
      clearTimeout(timer)
    }
  }, [isSearchRequestInFlight])

  return (
    <SearchBoxStyled
      className={className}
      translateDown={!isSearchActive}
      initialValue={initialValue}
      placeholder={`Search ${currentLegalEntity.name}`}
      onChange={onSubmit}
      onSubmit={onSubmit}
      onClear={onClear}
      shouldDebounceOnChange
      noTrim
      noClear={showSpinner}
      disabled={isDisabled}
      autoFocus
    >
      {showSpinner && <SearchingIcon src={require("static/images/shared/loader.gif")} />}
    </SearchBoxStyled>
  )
}

export default DigitsSearchBox
