import * as React from "react"
import { AnimatedIcon } from "@digits-shared/components/UI/Icons/AnimatedIcon"
import styled from "styled-components"

const AnimatedTelescope = styled(AnimatedIcon)`
  width: 420px;
  height: 420px;
  animation-duration: 5s;
  animation-timing-function: steps(124);
  flex-shrink: 0;
  margin: 0 auto;
`

export const Telescope: React.FC<{ className?: string }> = ({ className }) => (
  <AnimatedTelescope
    className={className}
    spriteURL={require("static/images/shared/telescope_sprite.png")}
  />
)
