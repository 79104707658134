import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgCheck } from "@digits-shared/components/SVGIcons/line/Check.svg"
import { SvgXClose } from "@digits-shared/components/SVGIcons/line/XClose.svg"
import { InputVariant } from "@digits-shared/components/UI/Elements/Form/Input"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css, keyframes } from "styled-components"

export enum InputSaveStatus {
  Pending = "Pending",
  Succeed = "Succeed",
  Failed = "Failed",
}

/*
  STYLES
*/

const labelColor = themedValue({
  light: colors.translucentSecondary80,
  dark: colors.translucentWhite30,
})
export const Label = styled.label<{ $variant?: InputVariant }>`
  ${({ $variant }) => {
    switch ($variant) {
      case "bordered":
        return css`
          font-size: 11px;
          font-weight: ${fonts.weight.normal};
        `
      case "primary":
      default:
        return css`
          font-size: 9px;
          text-transform: uppercase;
          font-weight: ${fonts.weight.heavy};
        `
    }
  }};

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
  margin-left: 2px;
  width: 100%;
  transition: color 200ms;
  color: ${labelColor};
`

const fadeOutCheckmark = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const SVG_STATUS_STYLES = css<{ show: number }>`
  width: 16px;
  height: 16px;
  margin-left: 7px;
  opacity: 0;

  ${(props) =>
    props.show === 1 && // styled-components workaround
    css`
      animation: ${fadeOutCheckmark} 2.5s forwards;
    `}
`

export const Checkmark = styled(SvgCheck)<{ show: number }>`
  ${SVG_STATUS_STYLES};

  ${svgPathStyles(colors.green, 2)};
`

export const Failed = styled(SvgXClose)<{ show: number }>`
  ${SVG_STATUS_STYLES};

  ${svgPathStyles(colors.orange, 2)};
`

/*
  COMPONENT
*/

export const InputLabel: React.FC<{
  htmlFor?: string
  label?: string
  className?: string
  saveStatus?: InputSaveStatus
  onAnimationEnd?: (event: React.AnimationEvent) => void
  $variant?: InputVariant
}> = ({ htmlFor, label, className, saveStatus, onAnimationEnd, $variant }) => {
  if (!label) return null
  const success = saveStatus === InputSaveStatus.Succeed
  const failed = saveStatus === InputSaveStatus.Failed

  return (
    <Label className={className} $variant={$variant} htmlFor={htmlFor}>
      {label}
      {success && <Checkmark onAnimationEnd={onAnimationEnd} show={1} />}
      {failed && <Failed onAnimationEnd={onAnimationEnd} show={1} />}
    </Label>
  )
}
