import * as React from "react"
import { EffectReducerExec, EffectsMap, useEffectReducer } from "use-effect-reducer"

export interface FormTrackingState {
  fieldsResetAt?: Date
  blurredFields: Record<string, boolean>
  invalidFields: Record<string, boolean>
  fieldValues: Record<string, string>
}

const INITIAL_STATE: FormTrackingState = {
  blurredFields: {},
  invalidFields: {},
  fieldValues: {},
}

export type FormTrackingEvent =
  | { type: "FIELDS_RESET"; resetValues?: Record<string, string> }
  | {
      type: "FIELD_BLURRED" | "FIELD_CHANGED"
      field: string
      value: string
      valid: boolean
    }

type FormTrackingEffect = {
  type: "updatedFormState"
}

type FormTrackingExec = EffectReducerExec<FormTrackingState, FormTrackingEvent, FormTrackingEffect>

function reducer(
  curState: FormTrackingState,
  event: FormTrackingEvent,
  exec: FormTrackingExec
): FormTrackingState {
  switch (event.type) {
    case "FIELDS_RESET":
      exec({ type: "updatedFormState" })

      return {
        ...curState,
        fieldsResetAt: new Date(),
        blurredFields: {},
        invalidFields: {},
        fieldValues: event.resetValues || {},
      }
    case "FIELD_BLURRED":
      return {
        ...curState,
        blurredFields: {
          ...curState.blurredFields,
          [event.field]: true,
        },
        invalidFields: {
          ...curState.invalidFields,
          [event.field]: !event.valid,
        },
        fieldValues: {
          ...curState.fieldValues,
          [event.field]: event.value,
        },
      }
    case "FIELD_CHANGED":
      exec({ type: "updatedFormState" })

      return {
        ...curState,
        invalidFields: {
          ...curState.invalidFields,
          [event.field]: !event.valid,
        },
        fieldValues: {
          ...curState.fieldValues,
          [event.field]: event.value,
        },
      }
    default:
      return curState
  }
}

export interface UseFormTrackingVal<FieldName extends string> {
  fieldsResetAt?: Date
  resetFields: (resetValues?: Record<string, string>) => void
  isFieldInvalid: (field: FieldName) => boolean
  getFieldValue: (field: FieldName) => string | undefined | null
  fieldHasValidValue: (field: FieldName) => boolean
  onFieldBlurred: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onFieldChanged: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void
  updateFieldState: (field: FieldName, value: string, valid: boolean) => void
  hasFieldBlurred: (field: FieldName) => boolean
}

export interface FormTrackingOptions<FieldName extends string> {
  effectsMap?: EffectsMap<FormTrackingState, FormTrackingEvent, FormTrackingEffect>
  initialValues?: Record<string, string | null> | null
  optionalFields?: FieldName[]
}

export function useFormTracking<FieldName extends string>(
  { effectsMap, initialValues, optionalFields }: FormTrackingOptions<FieldName> = {
    initialValues: {},
    optionalFields: [],
  }
): UseFormTrackingVal<FieldName> {
  // TODO: Provide regex or other validation of initial values that have a pattern associated with them.
  const [state, dispatch] = useEffectReducer(
    reducer,
    { ...INITIAL_STATE, fieldValues: { ...initialValues } },
    effectsMap
  )

  const resetFields = React.useCallback(
    (resetValues?: Record<string, string>) => {
      dispatch({ type: "FIELDS_RESET", resetValues })
    },
    [dispatch]
  )

  const hasFieldBlurred = React.useCallback(
    (field: string) => !!state.blurredFields[field],
    [state.blurredFields]
  )

  const isFieldInvalid = React.useCallback(
    (field: string) => Boolean(state.blurredFields[field] && state.invalidFields[field]),
    [state.blurredFields, state.invalidFields]
  )

  const onFieldBlurred = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      const target = event.currentTarget
      dispatch(fieldUpdateEvent("FIELD_BLURRED", target))
    },
    [dispatch]
  )

  const onFieldChanged = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      const target = event.currentTarget
      dispatch(fieldUpdateEvent("FIELD_CHANGED", target))
    },
    [dispatch]
  )

  // Provides a more direct way to update field state. Useful for things like our password
  // field, which pre-validates changes. Thus, we don't use the fieldUpdateEvent helper here.
  // All changes are treated as a blurs, which helps work-around the lack of a blur hook
  // in the other components.
  const updateFieldState = React.useCallback(
    (field: FieldName, value: string, valid: boolean) => {
      dispatch({
        type: "FIELD_CHANGED",
        field,
        value,
        valid,
      })
    },
    [dispatch]
  )

  const getFieldValue = React.useCallback(
    (field: FieldName) => state.fieldValues[field],
    [state.fieldValues]
  )

  const fieldHasValidValue = React.useCallback(
    (field: FieldName) =>
      !state.invalidFields[field] && (optionalFields?.includes(field) || !!getFieldValue(field)),
    [getFieldValue, optionalFields, state.invalidFields]
  )

  return React.useMemo(
    () => ({
      fieldsResetAt: state.fieldsResetAt,
      resetFields,
      isFieldInvalid,
      getFieldValue,
      fieldHasValidValue,
      onFieldBlurred,
      onFieldChanged,
      updateFieldState,
      hasFieldBlurred,
    }),
    [
      resetFields,
      fieldHasValidValue,
      getFieldValue,
      isFieldInvalid,
      onFieldBlurred,
      onFieldChanged,
      state.fieldsResetAt,
      updateFieldState,
      hasFieldBlurred,
    ]
  )
}

// Helper that produces an event of the provided type with the needed field update info
function fieldUpdateEvent(
  type: "FIELD_CHANGED" | "FIELD_BLURRED",
  input: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
) {
  const { name, value } = input
  const valid = input.checkValidity()

  return {
    type,
    field: name,
    value,
    valid,
  }
}
