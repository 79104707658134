import * as React from "react"
import { Link } from "react-router-dom"
import {
  CategoryType,
  ObjectEntities,
  ProductArea,
  SearchHit,
  SearchResult,
} from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import {
  ExpandableContainer,
  useDimensionAnimation,
} from "@digits-shared/hooks/useDimensionAnimation"
import colors from "@digits-shared/themes/colors"
import moment from "moment"
import styled from "styled-components"
import useSearchContext from "src/frontend/components/OS/Springboard/Applications/Search/SearchContext"
import { ResultSectionHeader } from "src/frontend/components/OS/Springboard/Applications/Search/SearchResults/ResultSectionHeader"
import { ProductAreaContext } from "src/frontend/components/Shared/Contexts/ProductAreaContext"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import { SearchResultType } from "src/frontend/types/SearchResultType"
import {
  useSpringControls,
  useSpringControlsKey,
} from "src/shared/components/Contexts/SpringControlsContext"
import CategoryResult from "./CategoryResult"
import PartyResult from "./PartyResult"
import TransactionResult from "./TransactionResult"
import UserResult from "./UserResult"

const SEARCH_RESULT_SECTION_SPRING = "SearchResultSection"

/*
 STYLES
*/

const Section = styled.div`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.324) 12.73%,
    rgba(255, 255, 255, 0.198) 90.37%
  );
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(48.8795px);
  border-radius: 16px;
  margin-bottom: 20px;
`

const ResultsList = styled.div`
  color: ${colors.theme.dark.link};
  pointer-events: auto;
`

const NoResultsSection = styled.div`
  padding: 35px 0;
  text-align: center;
  color: ${colors.translucentSecondary70};

  a {
    display: inline-block;
    margin-top: 8px;
    color: ${colors.secondary};
  }
`

/*
 INTERFACES
*/

interface ResultsProps {
  type: SearchResultType
  hits: SearchHit[]
  search: SearchResult
}

interface ResultProps {
  type: SearchResultType
  hit: SearchHit
  entities: ObjectEntities
}

/*
 COMPONENTS
*/

const ResultSection: React.FC<ResultsProps> = ({ type, hits, search }) => {
  const key = useSpringControlsKey(SEARCH_RESULT_SECTION_SPRING)
  return (
    <Section>
      <ResultSectionHeader type={type} hits={hits} search={search} />
      <Results type={type} hits={hits} search={search} key={key} />
    </Section>
  )
}

export default ResultSection

const Results: React.FC<ResultsProps> = ({ type, search, hits }) => {
  const { entities } = search

  const resultsElements = hits
    .map((hit) => <Result key={hit.objectId.id} type={type} hit={hit} entities={entities} />)
    .filter((r): r is JSX.Element => r !== null)

  if (!resultsElements.length) {
    resultsElements.push(<NoResults key="no-results" type={type} hits={hits} search={search} />)
  }

  const controls = useSpringControls(SEARCH_RESULT_SECTION_SPRING, { friction: 20 }, 1000)
  const { style, ref } = useDimensionAnimation("height", controls)

  return (
    <ExpandableContainer style={style}>
      <ResultsList ref={ref}>{resultsElements}</ResultsList>
    </ExpandableContainer>
  )
}

const Result: React.FC<ResultProps> = ({ type, hit, entities }) => {
  switch (type) {
    case SearchResultType.Transaction: {
      const tEntity = entities.transactions?.find((t) => t.factId === hit.objectId.id)
      if (!tEntity) {
        TrackJS?.console.error("Hit without an entity", hit)
        return null
      }

      const productArea =
        tEntity.displayCategory.type === CategoryType.Expenses
          ? ProductArea.Expenses
          : ProductArea.None

      return (
        <ProductAreaContext.Provider value={productArea}>
          <InvertValuesContext.Provider value={productArea === ProductArea.Expenses}>
            <TransactionResult entity={tEntity} hit={hit} />
          </InvertValuesContext.Provider>
        </ProductAreaContext.Provider>
      )
    }

    case SearchResultType.Lender:
    case SearchResultType.OwedVendor:
    case SearchResultType.UnpaidCustomer:
    case SearchResultType.PrepaidVendor:
    case SearchResultType.Shareholder:
    case SearchResultType.Vendor:
    case SearchResultType.Customer:
    case SearchResultType.Supplier: {
      const pEntity = entities.parties?.find((t) => t.id === hit.objectId.id)
      if (!pEntity) {
        TrackJS?.console.error("Hit without an entity", hit)
        return null
      }
      return <PartyResult entity={pEntity} hit={hit} type={type} />
    }

    // TODO: Convert to Report Package
    case SearchResultType.Report:
      return null
    //   const rEntity = entities.reports?.find((t) => t.id === hit.objectId.id)
    //   if (!rEntity) {
    //     TrackJS?.console.error("Hit without an entity", hit)
    //     return null
    //   }
    //   return <ReportResult entity={rEntity} hit={hit} />
    // }

    case SearchResultType.User: {
      const uEntity = entities.users?.find((t) => t.id === hit.objectId.id)
      if (!uEntity) {
        TrackJS?.console.error("Hit without an entity", hit)
        return null
      }
      return <UserResult entity={uEntity} hit={hit} />
    }

    case SearchResultType.Category: {
      const cEntity = entities.categories?.find((t) => t.id === hit.objectId.id)
      if (!cEntity) {
        TrackJS?.console.error("Hit without an entity", hit)
        return null
      }
      return <CategoryResult entity={cEntity} hit={hit} />
    }

    case SearchResultType.Comment:
      return null

    case SearchResultType.Invoice:
      return null
  }
}

const NoResults: React.FC<ResultsProps> = ({ type }) => {
  const generatePath = useFrontendPathGenerator()
  const { builder } = useSearchContext()

  const { occurredAfter, occurredBefore } = builder

  const clearFiltersParams = builder
    .copy()
    .setOccurredAfter(undefined)
    .setOccurredBefore(undefined)
    .buildUrlParams()

  const path = generatePath(routes.search, clearFiltersParams)

  return (
    <NoResultsSection>
      <div>
        No transactions found
        <NoResultsDates occurredAfter={occurredAfter} occurredBefore={occurredBefore} />
      </div>
      <Link to={path}>Clear Filters</Link>
    </NoResultsSection>
  )
}

const NoResultsDates: React.FC<{
  occurredAfter?: Date
  occurredBefore?: Date
}> = ({ occurredAfter, occurredBefore }) => {
  const after = moment(occurredAfter).utc()
  const before = moment(occurredBefore).utc()

  if (occurredAfter && occurredBefore) {
    return (
      <>
        <span>&nbsp;between</span>
        <br />
        <b>
          {after.format("MMMM D")} - {before.format("MMMM D")}, {before.format("YYYY")}
        </b>
      </>
    )
  }

  if (occurredAfter && !occurredBefore) {
    return (
      <>
        <span>&nbsp;since&nbsp;</span>
        <b>{after.format("MMMM D, YYYY")}</b>
      </>
    )
  }

  return null
}
