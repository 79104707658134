import * as React from "react"
import { DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import { HistoryAction, HistoryType } from "@digits-shared/components/Router/History"
import useRouter from "@digits-shared/hooks/useRouter"
import { AnimatePresence, Variants } from "framer-motion"
import { ChromeAlerts } from "src/frontend/components/Shared/Alerts/ChromeAlerts"
import { ChromeScrollMemory } from "src/frontend/components/Shared/Chrome/ChromeScrollMemory"
import {
  ChromeStructure,
  ChromeStructureContext,
  useBuildChromeStructure,
} from "src/frontend/components/Shared/Chrome/chromeStructure"
import { ContentViewRenderer } from "src/frontend/components/Shared/Chrome/ContentViewRenderer"
import { ContentViewRouter } from "src/frontend/components/Shared/Chrome/ContentViewRouter"
import { ContextualViewRouter } from "src/frontend/components/Shared/Chrome/ContextualViewRouter"
import {
  DetailsChrome,
  DetailsViewComponent,
} from "src/frontend/components/Shared/Chrome/DetailsViewComponent"
import routes, { FrontendStaticRoutes } from "src/frontend/routes"
import { TimeContextURLProvider } from "src/shared/components/Contexts/TimeContext"

/*
 INTERFACES
*/

interface ChromeDetailsProps {
  closeContextualView: (action: HistoryAction) => void
  DetailsView?: React.ComponentType
  hasActiveDetailsView: boolean
  backLabel?: string
}

export interface ChromeRendererProps {
  ContentView: React.ComponentType
  DetailsView?: React.ComponentType
}

export const ChromeRenderer: React.FC<ChromeRendererProps> = ({ ContentView, DetailsView }) => {
  const contentViewRouterRef = React.useRef<ContentViewRouter<FrontendStaticRoutes>>(null)
  const { history: browserHistory } = useRouter()

  const chromeStructure = useBuildChromeStructure()
  const previousModuleName = usePreviousModuleName(contentViewRouterRef, chromeStructure)

  const isReturningFromDetails = useIsReturningFromDetailsView(chromeStructure)
  const { hasActiveDetailsView } = chromeStructure

  const closeContextualView = React.useCallback(
    (action?: HistoryAction) => {
      const { detailsViewStack } = chromeStructure

      // If we have a stack of different details view that are being browsed, close the contextual view
      // should go back to the previous details view.
      //   e.g. party details -> transaction details -> hitting back should go to party details
      //
      // This should not happen when going from a details view in the same module
      //   e.g. transaction details -> a different transaction details
      if (detailsViewStack.length) {
        // use browser goBack() to trigger a POP event in the navigation stack.
        // Removing this will break scrolling as the position will not be restored.
        return browserHistory.goBack()
      }

      // Otherwise, swap to the browser history which should take us back to the application view.
      contentViewRouterRef.current?.switchTo(HistoryType.Browser, action)
    },
    [chromeStructure, browserHistory]
  )

  return (
    <ChromeStructureContext.Provider value={chromeStructure}>
      {/* Due to changes we make to the Application's router location, we must render the */}
      {/* Details and Aside before the application */}
      <ChromeNotificationsView />
      <ContextualViewRouter routes={routes} browserHistory={browserHistory}>
        <TimeContextURLProvider routes={routes}>
          <ChromeDetailsView
            DetailsView={DetailsView}
            closeContextualView={closeContextualView}
            hasActiveDetailsView={hasActiveDetailsView}
            backLabel={previousModuleName}
          />
        </TimeContextURLProvider>
      </ContextualViewRouter>
      <ContentViewRouter
        ref={contentViewRouterRef}
        routes={routes}
        browserHistory={browserHistory}
        // Bootstrap the initial entry of the content router with the current location. This
        // will only get used on first webapp load into a contextual view. The content views
        // should have redirects configured to properly handle what should be shown in this event.
        initialEntries={[browserHistory.location.fullPathname]}
      >
        <ContentViewRenderer
          returningFromDetailsView={isReturningFromDetails}
          hasActiveDetailsView={hasActiveDetailsView}
        >
          <TimeContextURLProvider routes={routes}>
            <ContentView />
          </TimeContextURLProvider>
        </ContentViewRenderer>
      </ContentViewRouter>
    </ChromeStructureContext.Provider>
  )
}

const chromeVariants: Variants = {
  hidden: { opacity: 0, transform: "perspective(400px) translate3d(0, 0, 100px)" },
  visible: { opacity: 1, transform: "perspective(400px) translate3d(0, 0, 0)" },
}

const ChromeDetailsView: React.FC<ChromeDetailsProps> = ({
  DetailsView,
  hasActiveDetailsView,
  closeContextualView,
  backLabel,
}) => {
  if (!DetailsView) return null

  return (
    <ChromeScrollMemory>
      <AnimatePresence>
        {hasActiveDetailsView && (
          <DetailsChrome
            variants={chromeVariants}
            transition={{ duration: 0.35 }}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <DetailsViewComponent
              isActive={hasActiveDetailsView}
              closeView={closeContextualView}
              backLabel={backLabel}
            >
              <DetailsView />
            </DetailsViewComponent>
          </DetailsChrome>
        )}
      </AnimatePresence>
    </ChromeScrollMemory>
  )
}

const ChromeNotificationsView: React.FC = () => (
  <ChromeScrollMemory>
    <ChromeAlerts />
  </ChromeScrollMemory>
)

const useIsReturningFromDetailsView = ({ hasActiveDetailsView }: ChromeStructure) => {
  const { location, previousLocation } = useRouter()
  const locationName = previousLocation?.name || location.name

  return routes.pathIsDetailsView(locationName) && !hasActiveDetailsView
}

/**
 * Helper hook which monitors the content view router and the state of the chrome structure
 * to determine the location name to show in details view back buttons.
 */
function usePreviousModuleName(
  contentViewRouterRef: React.RefObject<ContentViewRouter<FrontendStaticRoutes>>,
  chromeStructure: ChromeStructure
): string | undefined {
  const { history: browserHistory } = useRouter()

  const [previousModuleName, setPreviousModuleName] = React.useState<string | undefined>(() => {
    // can't use browser params because it only parses our params from the url if they are exactly matching
    const allParams =
      routes[browserHistory.location.name]?.getParametersFromPath(location.pathname) || {}
    return routes[browserHistory.location.name]?.moduleName?.(allParams) || undefined
  })

  // Track the content view router location key to detect internal redirects that it does internally,
  // which can change the previous module name we want to display.
  const [locationKey, setLocationKey] = React.useState<string>()

  React.useEffect(
    // listen returns an unregister callback, which we return as the cleanup function of the effect
    () =>
      contentViewRouterRef.current?.history.listen((location: DigitsLocation) => {
        setLocationKey(location.key)
      }) ?? (() => {}),
    [contentViewRouterRef]
  )

  // Keep the previous module name up-to-date
  React.useEffect(
    () => {
      const { detailsViewStack } = chromeStructure
      const goBackToLocation =
        detailsViewStack.length >= 1
          ? detailsViewStack[detailsViewStack.length - 1]
          : contentViewRouterRef.current?.history.location

      const allParams = goBackToLocation
        ? routes[goBackToLocation.name]?.getParametersFromPath(goBackToLocation.pathname) || {}
        : {}

      const moduleName = goBackToLocation
        ? routes[goBackToLocation.name]?.moduleName?.(allParams)
        : undefined

      setPreviousModuleName(moduleName)
    },
    // locationKey is unused within, but we need to reevaluate this effect any time it changes
    [locationKey, chromeStructure, setPreviousModuleName, contentViewRouterRef]
  )

  return previousModuleName
}
