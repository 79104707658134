import * as React from "react"
import { ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import { TopCategories } from "src/frontend/components/Shared/Assistant/Messages/Layout/List/TopCategories"
import { TopParties } from "src/frontend/components/Shared/Assistant/Messages/Layout/List/TopParties"
import { MatchedComponent } from "src/frontend/components/Shared/Layout/types"

/*
  COMPONENTS
*/

export const TopEntities: React.FC<{ component: MatchedComponent<"topEntities"> }> = ({
  component,
}) => {
  const { kind } = component.config.topEntities ?? {}

  if (kind === ObjectKind.Category) return <TopCategories component={component} />
  if (kind === ObjectKind.Party) return <TopParties component={component} />

  return null
}
