import * as React from "react"
import { useClickAway } from "react-use"
import {
  LayoutComponentConfig,
  TextComponentConfigTag,
} from "@digits-graphql/frontend/graphql-bearer"
import { Keys } from "@digits-shared/components/UI/Elements/Keys"
import useForceUpdate from "@digits-shared/hooks/useForceUpdate"
import useSession from "@digits-shared/hooks/useSession"
import styled from "styled-components"
import { MatchedComponent, SizingProps } from "src/frontend/components/Shared/Layout/types"
import {
  INITIAL_STATE,
  textComponentReducer,
} from "src/frontend/components/Shared/Portals/Components/Text/textReducer"
import { usePortalDispatch } from "src/frontend/components/Shared/Portals/State/portalStore"
import { useIsEditLayoutActive } from "src/frontend/components/Shared/Portals/State/useIsEditLayoutActive"
import FrontendSession from "src/frontend/session"
import { ContentDecorator } from "src/shared/components/EditableContent/ContentDecorator"
import { EditableContent } from "src/shared/components/EditableContent/EditableContent"
import EntitiesParser, {
  extractEntitiesAsTags,
} from "src/shared/components/ObjectEntities/EntitiesParser"

/*
  STYLES
*/

const TextContainer = styled.div<SizingProps>`
  min-height: ${({ height }) => height}px;
  width: auto;
  position: relative;

  &:hover div {
    opacity: 1;
  }
`

const EditableComponent = styled(EditableContent)`
  padding: 0;

  &[contenteditable="true"] {
    &:focus-within {
      cursor: text;
    }
    &:empty {
      &::after {
        top: 10px;
        left: 10px;
        position: absolute;
        content: "Add text…";
      }
    }
  }
`

const EmptySummaryStyled = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;

  img {
    width: 125px;
  }

  div {
    font-size: 12px;
    margin-top: 10px;
    opacity: 0.3;
  }
`

/*
  INTERFACES
*/

type Props = SizingProps & {
  component: MatchedComponent<"text">
  toolbarPortalElId: string | undefined
}

/*
  COMPONENTS
*/

export const TextComponent: React.FC<Props> = ({ component, toolbarPortalElId }) => {
  const builderDispatch = usePortalDispatch()
  const isEditLayoutActive = useIsEditLayoutActive()

  const {
    config: {
      text: { body },
    },
  } = component

  const onSubmit = React.useCallback(
    (text: string, tags: TextComponentConfigTag[]) => {
      const updatedConfig: LayoutComponentConfig = {
        ...component.config,
        text: {
          ...component.config.text,
          body: text,
          tags,
        },
      }

      builderDispatch({
        type: "setConfig",
        config: updatedConfig,
        componentId: component.componentId,
        save: true,
      })

      return Promise.resolve()
    },
    [builderDispatch, component]
  )

  const {
    key,
    editableContentRef,
    state: { posting, editing },
    readOnly,
    onFocus,
    submitContent,
    onKeyDown,
    resetState,
  } = useEditorState(onSubmit)

  const startEditing = React.useCallback(() => {
    editableContentRef.current?.focus()
  }, [editableContentRef])

  const saveContent = React.useCallback(() => {
    if (!editing) return
    submitContent()
  }, [editing, submitContent])

  const bodyClicksDisabled = React.useRef(false)
  const onHyperlinkModal = React.useCallback((show: boolean) => {
    bodyClicksDisabled.current = show
  }, [])

  const clickAwayRef = useClickAwayAction(saveContent, bodyClicksDisabled)
  const disabled = posting
  const showEmptyState = isEditLayoutActive && !body.trim().length && !editing
  const minHeight = isEditLayoutActive || showEmptyState ? 150 : 0

  return (
    <TextContainer ref={clickAwayRef} height={minHeight} onClick={startEditing}>
      <EditableComponent
        key={`${body}${key}`}
        ref={editableContentRef}
        readonly={readOnly}
        disabled={disabled}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        toolbarPortalElId={toolbarPortalElId}
        onCancel={resetState}
        onSave={saveContent}
        onHyperlinkModal={onHyperlinkModal}
      >
        <EntitiesParser text={body} entities={null} editMode={editing} />
      </EditableComponent>
      {showEmptyState && <EmptyState />}
    </TextContainer>
  )
}

const EmptyState: React.FC = () => (
  <EmptySummaryStyled>
    <img alt="" src={require("static/images/reports/empty-summary.png")} />
    <div>Click to edit</div>
  </EmptySummaryStyled>
)

function useClickAwayAction(onClickAway: () => void, bodyClicksDisabled: React.RefObject<boolean>) {
  const clickAwayRef = React.useRef<HTMLDivElement>(null)
  const skipClickAway = React.useCallback(() => {
    if (bodyClicksDisabled.current) return
    onClickAway()
  }, [bodyClicksDisabled, onClickAway])

  useClickAway(clickAwayRef, skipClickAway)
  return clickAwayRef
}

function useEditorState(onSubmit?: (text: string, tags: TextComponentConfigTag[]) => void) {
  const builderDispatch = usePortalDispatch()
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const [key, newKey] = useForceUpdate()
  const [state, dispatch] = React.useReducer(textComponentReducer, INITIAL_STATE)
  const editableContentRef = React.useRef<HTMLDivElement | null>(null)
  const isEditLayoutActive = useIsEditLayoutActive()

  React.useEffect(() => {
    if (!isEditLayoutActive) {
      dispatch({ type: "SET_EDITING", value: false })
    }
  }, [isEditLayoutActive])

  const submit = React.useCallback(
    (text: string, tags: TextComponentConfigTag[]) => {
      dispatch({ type: "SET_POSTING", value: true })
      if (!onSubmit) return
      onSubmit(text, tags)
      dispatch({ type: "RESET" })
      return Promise.resolve()
    },
    [onSubmit]
  )

  const setEditingMode = React.useCallback(() => {
    dispatch({ type: "SET_EDITING", value: true })
  }, [])
  const setReadingMode = React.useCallback(() => {
    dispatch({ type: "SET_EDITING", value: false })
  }, [])
  const resetState = React.useCallback(() => {
    builderDispatch({ type: "textInFocus", value: false })
    setReadingMode()
    newKey()
  }, [builderDispatch, newKey, setReadingMode])

  const submitContent = React.useCallback(() => {
    const content = editableContentRef.current
    if (!content) return

    const body = ContentDecorator.encode(content)
    const tags = extractEntitiesAsTags(body, legalEntityId)

    return submit(body, tags)?.then(resetState)
  }, [legalEntityId, resetState, submit])

  const onFocus = React.useCallback(() => {
    builderDispatch({ type: "textInFocus", value: true })
    setEditingMode()
    setTimeout(() => editableContentRef.current?.focus(), 100)
  }, [builderDispatch, setEditingMode])

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()

      const { key: eventKey, metaKey, shiftKey } = event
      const content = editableContentRef.current
      if (!content) return

      if (eventKey === Keys.Tab) {
        // add tab
        document.execCommand("insertHTML", false, "\u00a0\u00a0\u00a0\u00a0")
        event.preventDefault()
        return
      }

      if ((shiftKey || metaKey) && eventKey === Keys.Enter) {
        event.preventDefault()
        submitContent()
      }
    },
    [editableContentRef, submitContent]
  )

  const readOnly = !isEditLayoutActive

  return React.useMemo(
    () => ({
      key,
      editableContentRef,
      state,
      dispatch,
      submitContent,
      readOnly,
      onFocus,
      onKeyDown,
      resetState,
    }),
    [key, onFocus, onKeyDown, readOnly, resetState, state, submitContent]
  )
}
