import * as React from "react"
import useSession from "@digits-shared/hooks/useSession"
import FrontendSession from "src/frontend/session"

export const DocumentTitle: React.FC<{ title?: string }> = ({ title }) => {
  const session = useSession<FrontendSession>()
  const activeOrg = session.currentAffiliatedOrganization || session.currentOrganization

  const setDocumentTitle = React.useCallback((orgName: string, customTitle?: string) => {
    document.title = `${customTitle ? `${customTitle} - ` : ""}${orgName} on Digits`
  }, [])

  const revertDocumentTitle = React.useCallback(() => {
    document.title = process.env.TITLE || "Digits"
  }, [])

  React.useEffect(() => {
    const orgName = activeOrg?.name
    orgName ? setDocumentTitle(orgName, title) : revertDocumentTitle()

    // on unmount restore title
    return () => {
      orgName ? setDocumentTitle(orgName) : revertDocumentTitle()
    }
  }, [activeOrg, setDocumentTitle, revertDocumentTitle, title])

  return null
}
