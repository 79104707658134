import {
  BalanceSummary,
  CategoryType,
  Institution,
  TransactionParty,
  TransactionSummary,
} from "@digits-graphql/frontend/graphql-bearer"
import monetaryValueHelper from "@digits-shared/helpers/monetaryValueHelper"
import numberHelper from "@digits-shared/helpers/numberHelper"

export interface ActiveHierarchicalDimensionNode<D extends HierarchicalDimension>
  extends HierarchicalDimensionNode<D> {
  count: number
  parent?: ActiveHierarchicalDimensionNode<D>
  children: ActiveHierarchicalDimensionNode<D>[]
}

export interface HierarchicalDimensionSummaryNode<D extends HierarchicalDimension>
  extends HierarchicalDimensionNode<D> {
  summary: TransactionSummary
  topParties?: TransactionParty[]
  parent?: HierarchicalDimensionSummaryNode<D>
  children: HierarchicalDimensionSummaryNode<D>[]
  history: TransactionSummary[]
}

export interface HierarchicalDimensionBalanceNode<D extends HierarchicalDimension>
  extends HierarchicalDimensionNode<D> {
  institution?: Institution
  summaries: BalanceSummary[]
  parent?: HierarchicalDimensionBalanceNode<D>
  children: HierarchicalDimensionBalanceNode<D>[]
}

export interface HierarchicalDimensionNode<D extends HierarchicalDimension> {
  depth: number
  dimension: D
  parent?: HierarchicalDimensionNode<D>
  children: HierarchicalDimensionNode<D>[]
}

export interface HierarchicalDimension {
  id: string
  name: string
  displayKey?: string
  parentId?: string | null
  // TODO: Needed until we change Category parentCategoryId to parentId for consistency
  parentCategoryId?: string | null
  type?: CategoryType
}

export interface HierarchicalDimensionPartyTransactionSummary<D extends HierarchicalDimension> {
  dimension: D
  summary: TransactionSummary
  topParties: TransactionParty[]
  history: TransactionSummary[]
}

export function removeAncestorsOf<D extends HierarchicalDimension>(
  dimensionId: string,
  hierarchy: HierarchicalDimensionPartyTransactionSummary<D>[] | undefined,
  dimensionIdToParentId: Map<string, string | undefined | null>
): HierarchicalDimensionPartyTransactionSummary<D>[] | undefined {
  return hierarchy
    ?.filter((node) => isSubDimensionOf(dimensionId, node.dimension.id, dimensionIdToParentId))
    .map((node) =>
      // The specified dimension needs to have its parent dimension ID removed in order
      // to be treated as a root node for display.
      node.dimension.id === dimensionId
        ? {
            ...node,
            dimension: {
              ...node.dimension,
              parentId: undefined,
              parentCategoryId: undefined,
            },
          }
        : node
    )
}

export function isSubDimensionOf(
  targetDimensionId: string,
  currentDimensionId: string,
  parentMap: Map<string, string | undefined | null>
): boolean {
  if (targetDimensionId === currentDimensionId) return true

  const parentId = parentMap.get(currentDimensionId)

  switch (parentId) {
    case null:
    case undefined:
      return false
    case targetDimensionId:
      return true
    default:
      return isSubDimensionOf(targetDimensionId, parentId, parentMap)
  }
}

export const isRootLastLeaf = <D extends HierarchicalDimension>(
  node: HierarchicalDimensionSummaryNode<D>,
  idx: number,
  list: HierarchicalDimensionSummaryNode<D>[]
) => {
  if (!node.parent) return false
  const parentIndex = list.indexOf(node.parent)
  return parentIndex !== -1 && isNodeLastLeaf(node.parent, parentIndex, list)
}

export const isNodeLastLeaf = <D extends HierarchicalDimension>(
  node: HierarchicalDimensionSummaryNode<D>,
  idx: number,
  list: HierarchicalDimensionSummaryNode<D>[]
) => {
  const depth = isRootNode(node) ? 0 : node.depth

  // find if the current node is the last node at its depth. Traverse the subsequent nodes in the flat
  // list until we find a node with a depth smaller or equal than the current. If the depth
  // is the same it means that the current node's root has more nodes after the current node;
  // if the depth is less it means that the current node is the last node of the current node's root.
  let depthCounter = 1
  let nextNode = list[idx + depthCounter]
  while (nextNode && !isRootNode(nextNode) && nextNode.depth > depth) {
    depthCounter += 1
    nextNode = list[idx + depthCounter]
  }

  return !nextNode || isRootNode(nextNode) || nextNode.depth === 0 || nextNode.depth < depth
}

export const isRootNode = <D extends HierarchicalDimension>(
  toBeDetermined: HierarchicalDimensionSummaryNode<D>
): boolean =>
  !getHierarchicalDimensionParentIdField(
    (toBeDetermined as HierarchicalDimensionSummaryNode<D>)?.dimension
  )

export const convertToHierarchicalDimensionTree = <D extends HierarchicalDimension>(
  nodes: D[],
  depth: number,
  parent?: D
) => {
  const directChildren = nodes.filter(
    (node) => getHierarchicalDimensionParentIdField(node) === (parent ? parent.id : null)
  )

  return directChildren.map((dimension) => {
    const dimensionAssociation: HierarchicalDimensionNode<D> = {
      depth,
      dimension,
      children: convertToHierarchicalDimensionTree(nodes, depth + 1, dimension),
    }
    dimensionAssociation.children.forEach((c) => (c.parent = dimensionAssociation))
    return dimensionAssociation
  })
}

export const convertDimensionSummaryTreeToFlatList = <D extends HierarchicalDimension>(
  tree: (HierarchicalDimensionSummaryNode<D> | undefined)[]
): HierarchicalDimensionSummaryNode<D>[] =>
  tree.reduce<HierarchicalDimensionSummaryNode<D>[]>((allValues, element) => {
    if (element) allValues.push(element)
    return allValues.concat(convertDimensionSummaryTreeToFlatList(element?.children ?? []))
  }, [])

export const convertBalanceTreeToFlatList = <D extends HierarchicalDimension>(
  tree: HierarchicalDimensionBalanceNode<D>[]
): HierarchicalDimensionBalanceNode<D>[] =>
  tree.reduce<HierarchicalDimensionBalanceNode<D>[]>((allValues, element) => {
    allValues.push(element)
    return allValues.concat(convertBalanceTreeToFlatList(element.children))
  }, [])

export const convertActiveHierarchicalDimensionTreeToFlatList = <D extends HierarchicalDimension>(
  tree: ActiveHierarchicalDimensionNode<D>[]
): ActiveHierarchicalDimensionNode<D>[] =>
  tree.reduce<ActiveHierarchicalDimensionNode<D>[]>((allValues, element) => {
    allValues.push(element)
    return allValues.concat(convertActiveHierarchicalDimensionTreeToFlatList(element.children))
  }, [])

export const convertHierarchicalDimensionTreeToFlatList = <D extends HierarchicalDimension>(
  tree: HierarchicalDimensionNode<D>[]
): HierarchicalDimensionNode<D>[] =>
  tree.reduce<HierarchicalDimensionNode<D>[]>((allValues, element) => {
    allValues.push(element)
    return allValues.concat(convertHierarchicalDimensionTreeToFlatList(element.children))
  }, [])

export const getHierarchicalDimensionAncestors = <D extends HierarchicalDimension>(
  node: HierarchicalDimensionNode<D>
): HierarchicalDimensionNode<D>[] => {
  const parents: HierarchicalDimensionNode<D>[] = []

  let subject = node
  while (subject.parent) {
    parents.push(subject.parent)
    subject = subject.parent
  }
  return parents.reverse()
}

export const sortByBalanceMagnitudeThenName = <D extends HierarchicalDimension>(
  balanceNodeA: HierarchicalDimensionBalanceNode<D>,
  balanceNodeB: HierarchicalDimensionBalanceNode<D>
) => {
  // Largest balance first.
  const byMagnitude =
    Math.abs(balanceNodeB.summaries[0]?.total.value.amount || 0) -
    Math.abs(balanceNodeA.summaries[0]?.total.value.amount || 0)
  if (byMagnitude !== 0) {
    return byMagnitude
  }

  // A-Z
  return sortHierarchicalDimensionByName(balanceNodeA.dimension, balanceNodeB.dimension)
}

export const sortByTotalMagnitudeThenName = <D extends HierarchicalDimension>(
  nodeA: HierarchicalDimensionSummaryNode<D>,
  nodeB: HierarchicalDimensionSummaryNode<D>
) => {
  // Largest summary first.
  const byMagnitude =
    Math.abs(nodeB.summary.total.value.amount) - Math.abs(nodeA.summary.total.value.amount)
  if (byMagnitude !== 0) {
    return byMagnitude
  }

  // A-Z
  return sortHierarchicalDimensionByName(nodeA.dimension, nodeB.dimension)
}

export const sortHierarchicalDimensionByName = (
  dimensionA: HierarchicalDimension,
  dimensionB: HierarchicalDimension
) => (dimensionA.name > dimensionB.name ? 1 : dimensionA.name < dimensionB.name ? -1 : 0)

export const sortHierarchicalDimensionByNameUglyOtherLast = (
  dimensionA: HierarchicalDimension,
  dimensionB: HierarchicalDimension
) => {
  if (isUglyOtherHierarchicalDimensionId(dimensionA.id)) return 1
  if (isUglyOtherHierarchicalDimensionId(dimensionB.id)) return -1
  return sortHierarchicalDimensionByName(dimensionA, dimensionB)
}

export const synthesizeUglyOtherHierarchicalDimensionId = (dimensionId: string) =>
  `other_${dimensionId}`

export const isUglyOtherHierarchicalDimensionId = (dimensionId: string) =>
  dimensionId.startsWith("other_")

export const extractUglyOtherParentHierarchicalDimensionId = (uglyOtherDimensionIdId: string) =>
  uglyOtherDimensionIdId.replace("other_", "")

// TODO: Change Category parentCategoryId to parentId for consistency
export const getHierarchicalDimensionParentIdField = (
  dimension: HierarchicalDimension
): string | undefined | null => dimension.parentId || dimension.parentCategoryId

export const convertToDimensionSummaryTree = <D extends HierarchicalDimension>(
  nodes: HierarchicalDimensionPartyTransactionSummary<D>[],
  depth: number,
  summaryStrategy?: number | "sumHistories",
  parent?: HierarchicalDimensionPartyTransactionSummary<D>
) => {
  const nodesToProcess = nodes.filter(
    (dimensionSummary) =>
      (depth === 0 && !getHierarchicalDimensionParentIdField(dimensionSummary.dimension)) ||
      nodeHasChildren(dimensionSummary, parent)
  )

  return nodesToProcess.map((dimensionSummary) => {
    const history = dimensionSummary.history
      .slice()
      .sort((s1, s2) => s1.period.startedAt - s2.period.startedAt)

    const summary = (
      summaryStrategy === undefined
        ? dimensionSummary.summary
        : summaryStrategy === "sumHistories"
          ? combineSortedHistorySummaries(history)
          : history[summaryStrategy]
    ) as TransactionSummary

    const children = nodeHasChildren(dimensionSummary, parent)
      ? convertToDimensionSummaryTree(nodes, depth + 1, summaryStrategy, dimensionSummary)
      : []

    const association: HierarchicalDimensionSummaryNode<D> = {
      depth,
      summary,
      dimension: dimensionSummary.dimension,
      topParties: dimensionSummary.topParties,
      children,
      history,
    }
    association.children.forEach((c) => (c.parent = association))

    return association
  })
}

const nodeHasChildren = <D extends HierarchicalDimension>(
  dimensionSummary: HierarchicalDimensionPartyTransactionSummary<D>,
  parent?: HierarchicalDimensionPartyTransactionSummary<D>
) =>
  // Fake roots seem to depend on a null value here (in defiance of possible types?!)
  getHierarchicalDimensionParentIdField(dimensionSummary.dimension) ===
    (parent ? parent.dimension.id : null) ||
  getHierarchicalDimensionParentIdField(dimensionSummary.dimension) ===
    (parent ? parent.dimension.id : undefined)

// Takes an array of history summaries, sorted earliest to latest, and combines
// them into a single representative summary for the whole range.
// TODO: This is a short term fix to unblock party details while
//   we consider how to evolve the summarize* endpoints as a whole.
const combineSortedHistorySummaries = (sortedHistories: TransactionSummary[]) => {
  // Unsupported to get call with an empty array.
  if (sortedHistories.length === 1) return sortedHistories[0]

  const earliestHistory = sortedHistories[0] as TransactionSummary
  const latestHistory = sortedHistories[sortedHistories.length - 1] as TransactionSummary

  const earliestPeriod = earliestHistory.period
  const latestPeriod = latestHistory.period

  const zeroSummary: TransactionSummary = {
    period: {
      startedAt: earliestPeriod.startedAt,
      endedAt: latestPeriod.endedAt,
      interval: latestPeriod.interval,
      name: `${earliestPeriod.name} - ${latestPeriod.name}`,
    },
    isPeriodBookClosed: earliestHistory.isPeriodBookClosed && latestHistory.isPeriodBookClosed,
    total: {
      ...latestHistory.total,
      value: {
        ...latestHistory.total.value,
        ...numberHelper.buildMonetaryAmount(),
      },
      deltaPrevious: null,
      transactionsCount: 0,
    },
  }

  return sortedHistories.reduce((accSummary, transactionSummary) => {
    const accDeltaPrevious = accSummary.total.deltaPrevious
    const summaryDeltaPrevious = transactionSummary.total.deltaPrevious

    return {
      ...accSummary,
      total: {
        ...accSummary.total,
        deltaPrevious:
          accDeltaPrevious && summaryDeltaPrevious
            ? accDeltaPrevious + summaryDeltaPrevious
            : accDeltaPrevious || summaryDeltaPrevious,
        value: monetaryValueHelper.addValues(
          accSummary.total.value,
          transactionSummary.total.value
        ),
        transactionsCount:
          accSummary.total.transactionsCount + transactionSummary.total.transactionsCount,
      },
    }
  }, zeroSummary)
}
