import { Flow, MoneyFlow } from "@digits-graphql/frontend/graphql-bearer"
import deltaHelper from "@digits-shared/helpers/deltaHelper"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"

const DATA_ATTRIBUTE_KIND = "data-attribute-kind"
const DATA_ATTRIBUTE_VALUE = "data-value"
// const DATA_FLOW_ATTRIBUTE_KEY = "data-flow" // unused
const DATA_BUSINESS_FLOW_ATTRIBUTE_KEY = "data-business-flow"
const DATA_AMOUNT_ATTRIBUTE_KEY = "data-amount"
const DATA_CURRENCY_CODE_ATTRIBUTE_KEY = "data-currency-code"
const DATA_CURRENCY_MULTIPLIER_ATTRIBUTE_KEY = "data-currency-multiplier"
// const DATA_IS_NORMAL_ATTRIBUTE_KEY = "data-is-normal" // unused

// Values for DATA_ATTRIBUTE_KIND
const ATTRIBUTE_KIND_MONEY_FLOW = "money-flow"
const ATTRIBUTE_KIND_PERCENTAGE = "percentage"

// If the element has data attributes that provide the raw
// data for this element, return a new node with the
// inner content formatted using our common frontend formatters.
export const formatInsightChildNode = (el: HTMLElement) => {
  if (!el.hasAttribute(DATA_ATTRIBUTE_KIND)) {
    return undefined
  }

  const kind = el.getAttribute(DATA_ATTRIBUTE_KIND)
  switch (kind) {
    case ATTRIBUTE_KIND_MONEY_FLOW:
      return newMoneyFlowChild(el)
    case ATTRIBUTE_KIND_PERCENTAGE:
      return newPercentageChild(el)
    default:
      return undefined
  }
}

const newMoneyFlowChild = (el: HTMLElement) => {
  const amount = el.getAttribute(DATA_AMOUNT_ATTRIBUTE_KEY)
  const iso4217CurrencyCode = el.getAttribute(DATA_CURRENCY_CODE_ATTRIBUTE_KEY)
  const currencyMultiplier = el.getAttribute(DATA_CURRENCY_MULTIPLIER_ATTRIBUTE_KEY)
  const businessFlow = el.getAttribute(DATA_BUSINESS_FLOW_ATTRIBUTE_KEY)

  if (!amount || !iso4217CurrencyCode || !currencyMultiplier || !businessFlow) {
    return undefined
  }

  const moneyFlow: MoneyFlow = {
    businessFlow: businessFlow as Flow,
    // Always normal since the sign is implied by the sentence.
    isNormal: true,
    value: {
      amount: parseInt(amount),
      iso4217CurrencyCode,
      currencyMultiplier: parseInt(currencyMultiplier),
    },
  }

  return moneyFlowHelper.currency(moneyFlow, { style: CurrencyStyle.Summary })
}

const newPercentageChild = (el: HTMLElement) => {
  const value = el.getAttribute(DATA_ATTRIBUTE_VALUE)
  if (!value) {
    return undefined
  }

  const delta = deltaHelper.truncateDelta(parseFloat(value))
  return numberHelper.formatPercentage(delta)
}
