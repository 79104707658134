import * as React from "react"
import useLazyTimeout from "@digits-shared/hooks/useLazyTimeout"
import styled, { css } from "styled-components"

const CopyElement = styled.div<{ hasContentComponent: boolean }>`
  cursor: pointer;

  ${({ hasContentComponent }) =>
    !hasContentComponent &&
    css`
      display: contents;
    `}
`

const HiddenInput = styled.input`
  position: absolute;
  top: 0;
  opacity: 0;
  pointer-events: none;
`

export interface CopyToClipboardComponentProps extends React.RefAttributes<HTMLElement> {
  copiedSuccessfully: boolean
  value: string
}

interface CopyToClipboardProps {
  value: string | null | undefined
  copyDisabled?: boolean
  Content?: React.ComponentType<CopyToClipboardComponentProps>
  children?: (copiedSuccessfully: boolean) => React.ReactNode
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  value,
  Content,
  copyDisabled,
  children,
}) => {
  const [copiedSuccessfully, setCopySuccess] = React.useState(false)
  const mouseMovedRef = React.useRef(false)
  const onMouseMove = React.useCallback(() => {
    mouseMovedRef.current = true
  }, [])

  const onMouseDown = React.useCallback(() => {
    mouseMovedRef.current = false
  }, [])

  const inputRef = React.useRef<HTMLInputElement>(null)
  const contentRef = React.useRef<HTMLElement>(null)
  const [copySuccessTimeout] = useLazyTimeout(() => setCopySuccess(false), 1000)

  React.useEffect(() => {
    if (!copiedSuccessfully) return
    copySuccessTimeout()
  }, [copiedSuccessfully, copySuccessTimeout])

  const copyToClipboard = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      if (!inputRef || !inputRef.current || mouseMovedRef.current) return

      inputRef.current?.select()
      document.execCommand("copy")
      setCopySuccess(true)
      contentRef.current?.focus()
    },
    [mouseMovedRef]
  )

  if (!document.queryCommandSupported?.("copy") || !value || copyDisabled) {
    return (
      <React.Fragment>
        {children && children(false)}
        {Content && <Content value={value || ""} copiedSuccessfully={false} />}
      </React.Fragment>
    )
  }

  return (
    <CopyElement
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onClick={copyToClipboard}
      hasContentComponent={!!Content}
    >
      {<HiddenInput ref={inputRef} readOnly value={value} />}
      {children && children(copiedSuccessfully)}
      {Content && (
        <Content ref={contentRef} value={value} copiedSuccessfully={copiedSuccessfully} />
      )}
    </CopyElement>
  )
}
