import * as React from "react"
import { Layout, ViewType } from "@digits-graphql/frontend/graphql-bearer"

export const LayoutContext = React.createContext<Layout>({
  layoutId: "",
  viewKey: {
    legalEntityId: "",
    viewType: ViewType.Ledger,
    viewVersion: "",
  },
})

export function useLayoutContext() {
  const context = React.useContext(LayoutContext)

  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => context, [context])
}
