import * as React from "react"
import { useSearchEntitiesQuery } from "@digits-graphql/frontend/graphql-bearer"
import { QueryRequest } from "src/frontend/components/OS/Springboard/Applications/Search/QueryBuilder"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"

export default function useSearchQuery(query: QueryRequest, limit = 15, skip = false) {
  const viewKey = useViewVersion()
  const { text, kind, isValid, occurredAfter, occurredBefore } = query

  const response = useSearchEntitiesQuery({
    variables: {
      ...viewKey,
      text: text.trim(),
      kind,
      occurredAfter,
      occurredBefore,
      offset: 0,
      limit,
    },
    notifyOnNetworkStatusChange: true,
    skip: skip || !isValid,
  })

  // keep the previous response to use when a new request is being made,
  // this allows the components to show the existing results while the new response
  // is getting back.
  const previousResponse = React.useRef(response)
  React.useEffect(() => {
    if (response.data) previousResponse.current = response
  }, [response])

  const result =
    response.loading && previousResponse.current?.data ? previousResponse.current : response
  // use the "current" network status otherwise the listener will always get "ready" from the previous response
  const { networkStatus } = response

  return {
    result,
    networkStatus,
  }
}
