import React from "react"

export type GroupView = "Open" | "Resolved"

/**
 * Initialize GroupView state and return group view setters for ActionItemSidebar.
 */
export function useGroupView() {
  const [groupView, setGroupView] = React.useState<GroupView>("Open")
  const setViewOpen = React.useCallback(() => setGroupView("Open"), [])
  const setViewResolved = React.useCallback(() => setGroupView("Resolved"), [])

  return { groupView, setViewOpen, setViewResolved }
}
