import React from "react"
import {
  DirectionFromOrigin,
  Interval,
  useListBoostBadgeCountsQuery,
  WorkItemReviewState,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import { OPEN_STATES } from "src/frontend/components/OS/Accountant/Applications/Boost/WorkItems/hooks/boostTypes"
import FrontendSession from "src/frontend/session/FrontendSession"

export function useActionItemsBadgeCountsQuery(legalEntityId: string, skip?: boolean) {
  const session = useSession<FrontendSession>()
  const hasAuditAspect =
    session.findLegalEntityById(legalEntityId)?.hasAccessToAspect(AspectCode.Audit) ?? false

  const origin = dateTimeHelper.todayIntervalOrigin(Interval.Month, 48)

  const result = useListBoostBadgeCountsQuery({
    variables: {
      origin,
      direction: DirectionFromOrigin.Past,
      filter: {
        itemStates: OPEN_STATES,
        legalEntityIds: [legalEntityId],
        reviewStatesFilter: {
          reviewStates: [WorkItemReviewState.PendingClientResponse],
          includeNullReviewState: false,
        },
      },
    },
    skip: skip || !hasAuditAspect,
  })

  return React.useMemo(() => {
    const count = result?.data?.listBoostBadgeCounts?.[0]?.count ?? 0
    return { count, loading: result.loading }
  }, [result])
}
