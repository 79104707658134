import * as React from "react"
import { MutationBaseOptions } from "@apollo/client/core/watchQueryOptions"
import { FetchResult } from "@apollo/client/link/core"
import {
  CreateEmployeeAffiliationsMutation,
  DeleteEmployeeAffiliationsMutation,
  Employee,
  ListEmployeesAffiliationsDocument,
  Role,
  UpdateEmployeeAffiliationsMutation,
  useCreateEmployeeAffiliationsMutation,
  useDeleteEmployeeAffiliationsMutation,
  useUpdateEmployeeAffiliationsMutation,
} from "@digits-graphql/frontend/graphql-bearer"
import promiseHelper from "@digits-shared/helpers/promises/promiseHelper"
import { UserRoleFullyQualified } from "@digits-shared/helpers/userHelper"
import useSession from "@digits-shared/hooks/useSession"
import { AffiliationEntity } from "src/frontend/components/OS/Accountant/Applications/Settings/Affiliations/Shared"
import { useModifiedAffiliationNotification } from "src/frontend/components/OS/Accountant/Applications/Settings/Shared"
import FrontendSession from "src/frontend/session"

const BASE_REQUEST_OPTIONS: MutationBaseOptions = {
  refetchQueries: [ListEmployeesAffiliationsDocument],
  awaitRefetchQueries: true,
}

interface Props extends RequestHookProps {
  existingAffiliationRole: Role | undefined
}

interface RequestHookProps {
  employee: Employee
  affiliationEntity: AffiliationEntity
}

export function useModifyEmployeeAffiliations({
  employee,
  affiliationEntity,
  existingAffiliationRole,
}: Props) {
  const { createAffiliations, loading: createLoading } = useCreateAffiliations({
    employee,
    affiliationEntity,
  })
  const { updateAffiliations, loading: updateLoading } = useUpdateAffiliations({
    employee,
    affiliationEntity,
  })
  const { deleteAffiliations, loading: deleteLoading } = useDeleteAffiliations({
    employee,
    affiliationEntity,
  })

  const setRole = React.useCallback(
    (newRole: Role) => {
      const deletingRole = newRole.id === UserRoleFullyQualified.NoAccess
      // No-op as there is no role to be assigned and the employee already does not have role
      if (deletingRole && !existingAffiliationRole) {
        return promiseHelper.makeResolved(undefined)
      }

      const request = deletingRole
        ? deleteAffiliations()
        : !existingAffiliationRole
          ? createAffiliations(newRole)
          : updateAffiliations(newRole)

      return (
        request
          .then((response) => {
            // TODO: Display error?
            if (response.errors?.length) {
              console.error(response.errors)
              return
            }

            TrackJS?.console.log("modified employee affiliations successfully")
          })
          // TODO: Display error?
          .catch((reason: string) => console.error(reason))
      )
    },
    [existingAffiliationRole, deleteAffiliations, createAffiliations, updateAffiliations]
  )
  return React.useMemo(
    () => ({ setRole, loading: createLoading || updateLoading || deleteLoading }),
    [setRole, createLoading, updateLoading, deleteLoading]
  )
}

export function useCreateAffiliations({ employee, affiliationEntity }: RequestHookProps) {
  const { currentOrganizationId } = useSession<FrontendSession>()
  const [createAffiliations, { loading }] = useCreateEmployeeAffiliationsMutation()
  const notify = useModifiedAffiliationNotification(employee, affiliationEntity)

  const request = React.useCallback(
    (newRole: Role) => {
      if (!currentOrganizationId) {
        return promiseHelper.makeRejected<FetchResult<CreateEmployeeAffiliationsMutation>>(
          "No organization id"
        )
      }
      if (!employee.role?.id) {
        return promiseHelper.makeRejected<FetchResult<CreateEmployeeAffiliationsMutation>>(
          "No employee role"
        )
      }

      return createAffiliations({
        ...BASE_REQUEST_OPTIONS,
        variables: {
          organizationId: currentOrganizationId,
          employeeId: employee.id,
          organizationRole: employee.role.id,
          emailAddress: employee.emailAddress || "",
          givenName: employee.givenName || "",
          familyName: employee.familyName || "",
          affiliations: {
            legalEntityId: affiliationEntity.legalEntity.id,
            role: newRole.id,
          },
        },
      }).then((response) => {
        notify("create", newRole)
        return response
      })
    },
    [
      currentOrganizationId,
      employee.role?.id,
      employee.id,
      employee.emailAddress,
      employee.givenName,
      employee.familyName,
      createAffiliations,
      affiliationEntity.legalEntity.id,
      notify,
    ]
  )

  return React.useMemo(() => ({ createAffiliations: request, loading }), [request, loading])
}

export function useUpdateAffiliations({ employee, affiliationEntity }: RequestHookProps) {
  const { currentOrganizationId } = useSession<FrontendSession>()
  const [updateAffiliations, { loading }] = useUpdateEmployeeAffiliationsMutation()
  const notify = useModifiedAffiliationNotification(employee, affiliationEntity)

  const request = React.useCallback(
    (newRole: Role) => {
      if (!currentOrganizationId) {
        return promiseHelper.makeRejected<FetchResult<UpdateEmployeeAffiliationsMutation>>(
          "No organization id"
        )
      }
      if (!employee.role?.id) {
        return promiseHelper.makeRejected<FetchResult<UpdateEmployeeAffiliationsMutation>>(
          "No employee role"
        )
      }

      return updateAffiliations({
        ...BASE_REQUEST_OPTIONS,
        variables: {
          employeeIdentifier: {
            organizationId: currentOrganizationId,
            employeeId: employee.id,
          },
          affiliations: {
            legalEntityId: affiliationEntity.legalEntity.id,
            role: newRole.id,
          },
        },
      }).then((response) => {
        notify("update", newRole)
        return response
      })
    },
    [
      currentOrganizationId,
      employee.role?.id,
      employee.id,
      updateAffiliations,
      affiliationEntity.legalEntity.id,
      notify,
    ]
  )

  return React.useMemo(() => ({ updateAffiliations: request, loading }), [request, loading])
}

export function useDeleteAffiliations({ employee, affiliationEntity }: RequestHookProps) {
  const { currentOrganizationId } = useSession<FrontendSession>()
  const [deleteAffiliations, { loading }] = useDeleteEmployeeAffiliationsMutation()
  const notify = useModifiedAffiliationNotification(employee, affiliationEntity)

  const request = React.useCallback(() => {
    if (!currentOrganizationId) {
      return promiseHelper.makeRejected<FetchResult<DeleteEmployeeAffiliationsMutation>>(
        "No organization id"
      )
    }

    return deleteAffiliations({
      ...BASE_REQUEST_OPTIONS,
      variables: {
        employeeIdentifier: {
          organizationId: currentOrganizationId,
          employeeId: employee.id,
        },
        legalEntityIds: [affiliationEntity.legalEntity.id],
      },
    }).then((response) => {
      notify("delete")
      return response
    })
  }, [
    currentOrganizationId,
    deleteAffiliations,
    employee.id,
    affiliationEntity.legalEntity.id,
    notify,
  ])
  return React.useMemo(() => ({ deleteAffiliations: request, loading }), [request, loading])
}
