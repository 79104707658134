import {
  LayoutComponent,
  LayoutComponentConfig,
  LayoutComponentType,
} from "@digits-graphql/frontend/graphql-bearer"
import { v4 as UUID } from "uuid"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { RequiredNonNullable } from "src/frontend/types"

export interface RowIdentifier {
  sectionId: string
  rowId: string
}

export interface PendingDeleteComponent {
  componentId: string
  componentSize?: ComponentSize
  componentWidth?: number
}

export interface DeleteComponentAnimation extends PendingDeleteComponent {
  step: DeleteComponentStep
}

export type Sidebar = {
  [Tab in TabName]: SidebarTabState
}

export interface SidebarTabState {
  configs: SortableComponentConfig[]
}

export enum ChartType {
  BalanceSummary,
  TransactionSummary,
}

export interface SizingProps {
  height: number
}

export enum DeleteComponentStep {
  Slide = "Slide",
  Shrink = "Shrink",
  Poof = "Poof",
}

// Component ID to component
export interface ComponentMap extends Record<string, LayoutComponent> {}

export const TabNames = ["metrics", "statements", "lists", "custom"] as const
export type TabName = (typeof TabNames)[number]

export interface ComponentSizeProps {
  componentSize: ComponentSize
}

export interface HasId {
  id: string
}

export type SortableComponentConfig = LayoutComponentConfig & HasId
export type SortableLayoutComponent = LayoutComponent & HasId
export type MatchedConfig<K extends keyof LayoutComponentConfig> = RequiredNonNullable<
  Pick<LayoutComponentConfig, K | "type">
>

export function matchConfig<T extends keyof LayoutComponentConfig>(
  config: LayoutComponentConfig,
  key: T,
  type: LayoutComponentType
): config is MatchedConfig<T> {
  return type === config?.type && !!config?.[key]
}

export type MatchedComponent<K extends keyof LayoutComponentConfig> = LayoutComponent & {
  config: MatchedConfig<K>
}

export function matchComponent<T extends keyof LayoutComponentConfig>(
  component: LayoutComponent,
  key: T,
  type: LayoutComponentType
): component is MatchedComponent<T> {
  return matchConfig(component.config, key, type)
}

export function configToComponent(
  config: LayoutComponentConfig | null
): SortableLayoutComponent | null {
  if (!config) return null

  const id = UUID()
  return {
    id,
    componentId: id,
    config,
  }
}

export function matchConfigKey<T extends keyof LayoutComponentConfig>(
  config: LayoutComponentConfig,
  key: T,
  type: LayoutComponentType
) {
  return matchConfig(config, key, type) ? key : undefined
}

export function matchConfigWithOrigin(config: LayoutComponentConfig) {
  const configKey =
    matchConfigKey(config, "statement", LayoutComponentType.Statement) ||
    matchConfigKey(config, "summaryLineItem", LayoutComponentType.SummaryLineItemChart) ||
    matchConfigKey(config, "partyChart", LayoutComponentType.PartyTransactionSummaryChart) ||
    matchConfigKey(config, "partyChart", LayoutComponentType.PartyRoleBalanceSummaryChart) ||
    matchConfigKey(config, "topEntities", LayoutComponentType.TopEntities) ||
    matchConfigKey(config, "topTransactions", LayoutComponentType.TopTransactions) ||
    matchConfigKey(config, "categoryChart", LayoutComponentType.CategoryBalanceSummaryChart) ||
    matchConfigKey(config, "categoryChart", LayoutComponentType.CategoryTransactionSummaryChart) ||
    matchConfigKey(config, "runwayChart", LayoutComponentType.RunwayChart) ||
    matchConfigKey(config, "metricChart", LayoutComponentType.MetricSummaryChart) ||
    matchConfigKey(config, "cashSummaryChart", LayoutComponentType.CashSummaryChart)

  const origin = configKey ? config[configKey]?.origin : undefined

  return { configKey, origin }
}
