import {
  EntityCategory,
  EntityParty,
  IntervalOrigin,
} from "@digits-graphql/frontend/graphql-bearer"
import { PartyRole } from "@digits-graphql/frontend/graphql-public"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

export function useCategoryLinkPath(
  category: EntityCategory | undefined,
  intervalOrigin: IntervalOrigin
) {
  const generatePath = useFrontendPathGenerator()
  const { year, index, interval, intervalCount } = intervalOrigin

  return category
    ? generatePath(routes.categoryDetails, {
        categoryId: category.id,
        year,
        index,
        interval,
        intervalCount,
      })
    : undefined
}

export function usePartyLinkPath(
  party: EntityParty | undefined,
  partyRole: PartyRole | undefined | null,
  intervalOrigin: IntervalOrigin | undefined
) {
  const generatePath = useFrontendPathGenerator()
  const { year, index, interval, intervalCount } = intervalOrigin || {}

  return party
    ? generatePath(routes.partyDetails, {
        partyId: party.id,
        partyRole: FrontendPartyRole.roleURLKey(partyRole),
        year,
        index,
        interval,
        intervalCount,
      })
    : undefined
}
