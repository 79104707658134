import * as React from "react"
import { LayoutComponent, LayoutComponentType } from "@digits-graphql/frontend/graphql-bearer"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { CategoryChartComponent } from "src/frontend/components/Shared/Assistant/Messages/Layout/Chart/CategoryChartComponent"
import { PartyChartComponent } from "src/frontend/components/Shared/Assistant/Messages/Layout/Chart/PartyChartComponent"
import { SummaryLineItemChartComponent } from "src/frontend/components/Shared/Assistant/Messages/Layout/Chart/SummaryLineItemChartComponent"
import { SearchResults } from "src/frontend/components/Shared/Assistant/Messages/Layout/List/SearchResults"
import { TopEntities } from "src/frontend/components/Shared/Assistant/Messages/Layout/List/TopEntities"
import { TopTransactions } from "src/frontend/components/Shared/Assistant/Messages/Layout/List/TopTransactions"
import { TextComponent } from "src/frontend/components/Shared/Assistant/Messages/Layout/Text/TextComponent"
import { ChartType, matchComponent } from "src/frontend/components/Shared/Layout/types"
import { CategoryIconStyled } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopCategoriesList"
import { Categories } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopPartiesList"
import { Title } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Shared/TopTransactionsList"

/*
  STYLES
*/

export const GraphContainer = styled.div`
  padding: 16px;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.12);
  border-radius: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  ${Categories} {
    color: ${colors.translucentSecondary80};
  }

  ${Title} {
    color: ${colors.secondary};
  }

  ${CategoryIconStyled} {
    ${svgIconStyles(colors.secondary)}
  }
`

export const TextContainer = styled.div`
  font-weight: ${fonts.weight.medium};

  &:not(:last-child) {
    padding-bottom: 16px;
  }
`

export const MatchComponent: React.FC<{
  component: LayoutComponent
  skipAnimations: boolean
}> = ({ component, skipAnimations }) => {
  if (
    matchComponent(
      component,
      "categoryChart",
      LayoutComponentType.CategoryTransactionSummaryChart
    ) ||
    matchComponent(component, "categoryChart", LayoutComponentType.CategoryBalanceSummaryChart)
  ) {
    const chartType =
      component.config.type === LayoutComponentType.CategoryBalanceSummaryChart
        ? ChartType.BalanceSummary
        : ChartType.TransactionSummary

    return (
      <CategoryChartComponent
        component={component}
        chartType={chartType}
        skipAnimations={skipAnimations}
      />
    )
  }

  if (
    matchComponent(component, "partyChart", LayoutComponentType.PartyTransactionSummaryChart) ||
    matchComponent(component, "partyChart", LayoutComponentType.PartyRoleBalanceSummaryChart)
  ) {
    const chartType =
      component.config.type === LayoutComponentType.PartyRoleBalanceSummaryChart
        ? ChartType.BalanceSummary
        : ChartType.TransactionSummary

    return (
      <PartyChartComponent
        component={component}
        chartType={chartType}
        skipAnimations={skipAnimations}
      />
    )
  }

  if (matchComponent(component, "summaryLineItem", LayoutComponentType.SummaryLineItemChart)) {
    return <SummaryLineItemChartComponent component={component} skipAnimations={skipAnimations} />
  }

  if (matchComponent(component, "text", LayoutComponentType.Text)) {
    return <TextComponent component={component} toolbarPortalElId={undefined} />
  }

  if (matchComponent(component, "topEntities", LayoutComponentType.TopEntities)) {
    return <TopEntities component={component} />
  }

  if (matchComponent(component, "topTransactions", LayoutComponentType.TopTransactions)) {
    return <TopTransactions component={component} />
  }

  if (matchComponent(component, "entitiesList", LayoutComponentType.EntitiesList)) {
    return <SearchResults component={component} />
  }

  return null
}
