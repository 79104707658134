import {
  LegalEntityStatus,
  ReadShareAdjacencyQuery,
  ReadShareQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { SharedObjectInfo } from "@digits-shared/components/Router/DigitsRoute"
import useSession from "@digits-shared/hooks/useSession"
import { useSharedObjectInfo } from "src/frontend/hooks/useSharedObjectInfo"
import FrontendSession from "src/frontend/session"
import SessionBusinessOrganization from "src/frontend/session/BusinessOrganization"
import SessionLegalEntity from "src/frontend/session/LegalEntity"
import { ObjectSharingStatus } from "src/frontend/session/ObjectSharingStatus"

export function useMutateSessionFromShare({
  objectSharingStatus,
  permissionData,
  adjacencyPermissionData,
}: {
  objectSharingStatus: ObjectSharingStatus
  permissionData?: ReadShareQuery["readShare"]
  adjacencyPermissionData?: ReadShareAdjacencyQuery["readShareAdjacency"]
}) {
  const session = useSession<FrontendSession>()
  const sharedObjectInfo = useSharedObjectInfo()

  // Not done in a React effect to make this session mutation immediate for the rest of the logic
  switch (objectSharingStatus) {
    case ObjectSharingStatus.NoAccess: {
      if (!sharedObjectInfo) {
        break
      }

      // Construct org and legal entity based on the route. This may not be a real legal entity, or even a real object,
      // but we want to treat it like it is to avoid leaking the existence of objects. This allows us to show a real
      // error state when access is revoked instead of a potentially confusing redirect.
      const { sharingOrg, sharingLegalEntity } =
        noAccessSharingSessionContextFields(sharedObjectInfo)

      // Overlay them onto the session to set up the sharing context
      session.setSharingContext(sharingOrg, sharingLegalEntity)
      break
    }
    case ObjectSharingStatus.GrantRequired:
      // We need to continue with requesting grant access, so no-op for now
      break
    case ObjectSharingStatus.IntentRevoked:
    case ObjectSharingStatus.AccessRequestRequired:
    case ObjectSharingStatus.AccessRequestPending:
    case ObjectSharingStatus.AccessGranted:
    case ObjectSharingStatus.AccessGrantedViaAdjacency: {
      const unionPermission = permissionData || adjacencyPermissionData

      if (!unionPermission) break

      // If the LE for this object is not already in the session token, we need to set up a sharing context.
      if (!session.findLegalEntityById(unionPermission.legalEntity.id)) {
        // Construct org and legal entity for the minimal info returned from the share read
        const { sharingOrg, sharingLegalEntity, viewType } =
          permissionSharingSessionContextFields(unionPermission)

        // Overlay them onto the session to set up the sharing context
        session.setSharingContext(sharingOrg, sharingLegalEntity, viewType)
      }
      break
    }
    default: {
      session.clearSharingContext()
      break
    }
  }
}

function noAccessSharingSessionContextFields(sharedObjectInfo: SharedObjectInfo) {
  const sharingLegalEntity = new SessionLegalEntity("", {
    aspects: [],
    avvs: {
      a: "",
      l: "",
    },
    mvvs: {},
    id: "",
    name: "",
    status: LegalEntityStatus.Active,
    slug: sharedObjectInfo.legalEntitySlug,
    terms: "",
  })
  const sharingOrg = new SessionBusinessOrganization({
    affiliations: undefined,
    id: "",
    legalEntities: [sharingLegalEntity],
    name: "",
    permissions: {
      hasCreatePermission: () => false,
      hasReadPermission: () => false,
      hasUpdatePermission: () => false,
      hasDeletePermission: () => false,
      hasSensitivePermission: () => false,
      hasGrantPermission: () => false,
      hasCommentPermission: () => false,
    },
    slug: "",
    iconUrl: undefined,
  })
  return { sharingLegalEntity, sharingOrg }
}

function permissionSharingSessionContextFields(
  permissionData: ReadShareQuery["readShare"] | ReadShareAdjacencyQuery["readShareAdjacency"]
) {
  const sharingLegalEntity = new SessionLegalEntity(permissionData.organizationPreview.id, {
    aspects: [],
    avvs: {
      a: "",
      l: "",
    },
    mvvs: {},
    id: permissionData.legalEntity.id,
    name: permissionData.legalEntity.name,
    status: LegalEntityStatus.Active,
    slug: permissionData.legalEntity.slug,
    terms: "",
  })
  const sharingOrg = new SessionBusinessOrganization({
    affiliations: undefined,
    id: permissionData.organizationPreview.id,
    legalEntities: [sharingLegalEntity],
    name: permissionData.organizationPreview.name,
    permissions: {
      hasCreatePermission: () => false,
      hasReadPermission: () => false,
      hasUpdatePermission: () => false,
      hasDeletePermission: () => false,
      hasSensitivePermission: () => false,
      hasGrantPermission: () => false,
      hasCommentPermission: () => false,
    },
    slug: permissionData.organizationPreview.slug,
    iconUrl: undefined,
  })
  return { sharingLegalEntity, sharingOrg, viewType: permissionData.viewType }
}
