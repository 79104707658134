import * as React from "react"
import { LayoutComponentType, ProviderType } from "@digits-graphql/frontend/graphql-bearer"
import { ComponentExpandLink } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentExpandIcon"
import { ComponentSummary } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import { MatchedComponent, SizingProps } from "src/frontend/components/Shared/Layout/types"
import { usePortalStatementLiveData } from "src/frontend/components/Shared/Portals/hooks/usePortalStatementLiveData"
import { displayNameForDocumentKind } from "src/frontend/components/Shared/Reports/Packages/Modify/shared"
import { StatementSize } from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import { Statement } from "src/frontend/components/Shared/Reports/Report/Components/Statements/Statement"
import { useDataSourcePreference } from "src/frontend/hooks/useDataSourcePreference"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

/*
  STYLES
*/

/*
  INTERFACES
*/

type Props = SizingProps & {
  component: MatchedComponent<"statement">
  componentSize: ComponentSize
}

/*
  COMPONENTS
*/

export const StatementComponent: React.FC<Props> = ({ component, componentSize }) => {
  // Historically, some portal components were archived with intervalCount 12.
  // Statements interpret intervalCount as the number of interval to show in the
  // "primary" column, which is not what we want to show on the main portal when unspecified.
  //  We don't want to override the value provided from the TimeContext either
  //  so that details pages display correctly.
  const origin = useIntervalOrigin()
  const config = useConfigOriginOverride(component.config.statement, origin.intervalCount ?? 1)

  const { statement, loading } = usePortalStatementLiveData(config, LayoutComponentType.Statement)
  const statementSize =
    componentSize === ComponentSize.Small ? StatementSize.Condensed : StatementSize.Full
  const dataSourcePreference = useDataSourcePreference(ProviderType.QuickBooks)

  const generatePath = useFrontendPathGenerator()
  const title = React.useMemo(() => {
    const statementPath = generatePath(routes.layoutComponentDetails, {
      configType: component.config.statement.kind,
      ...config.origin,
      intervalCount: undefined,
    })

    return (
      <ComponentExpandLink to={statementPath} componentSize={componentSize}>
        {displayNameForDocumentKind(component.config.statement.kind, dataSourcePreference?.taxForm)}
      </ComponentExpandLink>
    )
  }, [
    component.config.statement.kind,
    componentSize,
    config.origin,
    dataSourcePreference?.taxForm,
    generatePath,
  ])

  return (
    <>
      <ComponentSummary
        css="margin-bottom: 0"
        title={title}
        componentSize={componentSize}
        timeseries={undefined}
      />
      <Statement statement={statement} size={statementSize} loading={loading} />
    </>
  )
}
