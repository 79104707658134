import * as React from "react"
import {
  DocumentPage,
  DocumentViewerAction,
  DocumentViewerState,
  initialState,
  reducer,
} from "src/frontend/components/OS/Shared/DocumentViewer/reducer"

export interface DocumentViewerModelVal {
  dispatch: React.Dispatch<DocumentViewerAction>
  state: DocumentViewerState
}

export function useDocumentViewerModel(width: number, height: number, pages: DocumentPage[]) {
  const [state, dispatch] = React.useReducer(reducer, initialState(width, height))

  React.useEffect(() => {
    if (!!width && !!height) {
      dispatch({ type: "Resize", width, height })
    }
  }, [height, width])

  React.useEffect(() => {
    dispatch({ type: "SetPages", pages })
  }, [pages])

  return React.useMemo(
    () => ({
      dispatch,
      state,
    }),
    [state]
  )
}
