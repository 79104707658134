import { LayoutComponentType, TopEntitiesConfig } from "@digits-graphql/frontend/graphql-bearer"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { ComponentSizeProps } from "src/frontend/components/Shared/Layout/types"

export interface TopEntitiesProps {
  config: TopEntitiesConfig
  componentType: LayoutComponentType
}

export const Title = styled.div<ComponentSizeProps>`
  font-size: ${({ componentSize }) => componentSize.headerSize};
  font-weight: ${fonts.weight.heavy};
  margin-bottom: 16px;
`
