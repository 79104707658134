import * as React from "react"
import { DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import useRouter from "@digits-shared/hooks/useRouter"
import { History } from "history"

export default function useRedirectToApp(
  path: string,
  newState: History.LocationState | undefined = undefined
) {
  const { location, history } = useRouter()
  return React.useCallback(() => {
    let redirectPath: string

    if (location.state?.from) {
      const fromLocation = location.state.from as DigitsLocation
      redirectPath = fromLocation.fullPathname
      TrackJS?.console.info("Redirecting to previous location")
    } else {
      redirectPath = path
      TrackJS?.console.info(`Redirecting to ${path}`)
    }

    history.replace(redirectPath, newState)
  }, [location, history, path, newState])
}
