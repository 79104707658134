import { PartyRole } from "@digits-graphql/frontend/graphql-bearer"

export enum NodeValues {
  WhiteSpace = "&nbsp;",
}

export enum TagNames {
  Anchor = "A",
  Break = "BR",
  Div = "DIV",
  Span = "SPAN",
  UnorderedList = "UL",
  OrderedList = "OL",
}

export enum EntityTagToken {
  Mention = "@",
}

export const DATA_ID = "data-id"
export const DATA_DECORATOR = "data-decorator"
export const DATA_ENTITY_KIND = "data-entity-kind"
export const DECORATOR_CLASS = "object-decorator"

export enum Decorator {
  User = "User",
  Party = "Party",
  Category = "Category",
  Url = "Url",
}

export function mapDecorator(index?: string | null): Decorator | undefined {
  return Decorator[index as keyof typeof Decorator]
}

export function mapPartyRole(index?: string | null): PartyRole | undefined {
  return PartyRole[index as keyof typeof PartyRole]
}

export type DecoratedEntity = PartyRole | Decorator

export function decoratedEntityToEntityKind(type: DecoratedEntity) {
  switch (type) {
    case Decorator.User:
    case Decorator.Category:
    case Decorator.Url:
      return type

    default: {
      const partyEntityRole = type.match("(Entity)?(.*)(Role)")
      const role = partyEntityRole?.[2]
      if (!role || !mapPartyRole(type)) {
        console.error("unsupported type: ", type)
        return undefined
      }
      return role
    }
  }
}
