import * as React from "react"
import {
  PageScrollProps,
  withPageScrollContext,
} from "@digits-shared/components/UI/Elements/PageScrollContext"

export enum ScrollDirection {
  Upwards = "Upwards",
  Downwards = "Downwards",
}

interface Props {
  direction?: ScrollDirection
  onScroll?: (event: UIEvent) => void
  loadMore?: () => boolean | undefined | void
}

/**
 * ScrollListener sets a listen on the page scroll and will call provided function
 * of {@link Props.loadMore} when the scroll position is within 2 window lengths from the top
 * or bottom of the scroll element (depending on what {@link ScrollDirection} is passed. Any
 * {@link Props.onScroll} function will be called for any scroll event that occurs.
 */
class ScrollListener extends React.Component<Props & PageScrollProps> {
  private continueLoadingMore = true
  private scrollElement: Element | Text | null
  private previousScrollPosition: number | null
  private previousScrollHeight: number

  componentDidMount() {
    this.addScrollListener()
  }

  shouldComponentUpdate() {
    return true
  }

  componentDidUpdate() {
    this.addScrollListener()
  }

  componentWillUnmount() {
    this.removeScrollListener()
  }

  render() {
    return null
  }

  handleScroll = (event: UIEvent) => {
    const { onScroll, loadMore, direction } = this.props

    onScroll?.(event)

    // If no load more function passed, then no need to run remaining logic
    if (!loadMore) return

    // loadMore returned false, so we should not try to load more again
    if (!this.continueLoadingMore) return

    // Element must exist
    if (!event.currentTarget) return
    const currentTarget = event.currentTarget as HTMLElement

    switch (direction) {
      case ScrollDirection.Upwards:
        this.handleUpwardsScroll(currentTarget)
        break
      default:
        this.handleDownwardsScroll(currentTarget)
    }
  }

  handleDownwardsScroll = (currentTarget: HTMLElement) => {
    const oldPrevious = this.previousScrollPosition
    this.previousScrollPosition = currentTarget.scrollTop

    // make sure that we only handle scroll while going in downwards direction. If user starts
    // scrolling back up just return.
    if (!oldPrevious || currentTarget.scrollTop < oldPrevious) {
      return
    }

    // If we are 30 or less pixels from the bottom, fetch more
    const distanceScrolled = window.innerHeight + currentTarget.scrollTop

    if (distanceScrolled + 50 > currentTarget.scrollHeight) {
      window.requestAnimationFrame(this.handleLoadMore)
    }
  }

  handleUpwardsScroll = (currentTarget: HTMLElement) => {
    const oldHeight = this.previousScrollHeight
    const oldPrevious = this.previousScrollPosition
    this.previousScrollPosition = currentTarget.scrollTop
    this.previousScrollHeight = currentTarget.scrollHeight

    // make sure that we only handle scroll while going in upwards direction. If user starts
    // scrolling down just return. E.g. while at the top of the page if user scrolls down
    // they will be within the load more threshold but because the scroll is in the opposite
    // direction there is no need to request newer values.
    // Also compare heights to make sure we dont fire loadMore if the viewport changed heights
    // due to some new content but the user didnt actually scrolled.
    if (
      !oldPrevious ||
      oldHeight !== currentTarget.scrollHeight ||
      currentTarget.scrollTop > oldPrevious
    ) {
      return
    }

    // If we are within the top 30px of the window height, we want to begin request for more items.
    if (currentTarget.scrollTop < 30) {
      window.requestAnimationFrame(this.handleLoadMore)
    }
  }

  handleLoadMore = () => {
    const { loadMore } = this.props
    const continueLoadingMore = loadMore && loadMore()
    // Explicitly check for false. True or undefined means continue loading more
    if (continueLoadingMore === false) this.continueLoadingMore = false
  }

  addScrollListener() {
    const { pageScroll, direction } = this.props

    const scrollElement = pageScroll.getScrollElement()
    if (!scrollElement || scrollElement === this.scrollElement) {
      return
    }

    this.removeScrollListener()
    if (direction === ScrollDirection.Upwards && scrollElement.scrollTop === 0) {
      scrollElement.scrollTop = 1
    }

    this.scrollElement = scrollElement
    scrollElement.addEventListener("scroll", this.handleScroll)
    this.previousScrollPosition = null
    this.previousScrollHeight = scrollElement.scrollHeight
  }

  removeScrollListener() {
    this.scrollElement?.removeEventListener("scroll", this.handleScroll)
    this.scrollElement = null
  }
}

export default withPageScrollContext<Props>(ScrollListener)
