import * as React from "react"
import { Interval } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import moment from "moment"
import { toSortedTimeseriesValues } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { Runway } from "src/frontend/types"

export const runwayColors = {
  green: "#72f6bc",
  yellow: "#f8c980",
  red: "#ea3396",
}

/* LCH interpolation, using https://www.learnui.design/tools/gradient-generator.html */
export const RUNWAY_GRADIENT = [
  "#ea3396",
  "#ff6675",
  "#ff9b6b",
  "#f8c980",
  "#f8c980",
  "#d9da7f",
  "#aeea94",
  "#72f6bc",
]

export enum DeltaSign {
  Positive = "↗",
  Negative = "↙",
}

export interface Delta {
  color: string
  sign: DeltaSign
  delta: string
  interval: string
}

export const useRunwayTimeseries = (runway?: Runway) =>
  React.useMemo(() => {
    const currentTimeseries = toSortedTimeseriesValues(runway?.current)
    const futureTimeseries = toSortedTimeseriesValues(runway?.future)
    const fullTimeseries = currentTimeseries.concat(futureTimeseries)
    return { currentTimeseries, futureTimeseries, fullTimeseries }
  }, [runway])

export const useCashOutMonthsLeft = (runway?: Runway) => {
  const { count, interval } = useCashOutTimeLeft(runway)
  return React.useMemo(() => {
    switch (interval) {
      case Interval.Month:
        return count
      case Interval.Year:
        return count * 12
      default:
        return undefined
    }
  }, [count, interval])
}

export const useCashOutTimeLeft = (runway?: Runway) =>
  React.useMemo(() => {
    const cashOutDate = runway?.cashOutDate
    if (!runway || !runway.current) return {} // loading

    const hasAnyActivity = runway.current.some((summary) => summary.total.transactionsCount > 0)
    if (!hasAnyActivity) return {}

    if (!cashOutDate) return { title: "Profitable!", profitable: true } // infinite runway

    const lastCurrentPeriod = runway.current
      .map((summary) => summary.period.endedAt)
      .reduce((prev, curr) => Math.max(prev, curr))

    // If we're in the today period, use the actual timestamp instead of the period's end.
    // e.g. if today is December 8 and Cash Out is Jan 18, we should say
    // 1 month left, not <1 month. (December 22 -> Jan 18 would be < 1 month.)
    const periodEndMoment =
      dateTimeHelper.todayPeriodForInterval(Interval.Month).endedAt === lastCurrentPeriod
        ? moment.utc()
        : moment.unix(lastCurrentPeriod)

    const cashOutMoment = moment.unix(cashOutDate)

    // We benefit from two diff() behaviors here:
    // - diff() truncates, which is desirable since we only want to count whole months
    // - diff() has special behavior for exact day matches when using "months", e.g. Dec 8 to Jan 8 is one month
    const months = cashOutMoment.diff(periodEndMoment, "months")
    let count = months
    let interval = Interval.Month
    if (months > 12 * 5) {
      // Show a single decimal place for years.
      count = Math.round(moment.unix(cashOutDate).diff(periodEndMoment, "years", true) * 10) / 10
      interval = Interval.Year
    }

    // Handle the case where cash out is predicted in the past, e.g.
    // viewing a yearly interval in which cash runs out has negative count.
    const clampedCount = Math.max(0, count)

    // calculate the cash out delta
    let deltaMonths: number | undefined
    if (runway.previousCashOutDate) {
      const previousCashOutMoment = moment.unix(runway.previousCashOutDate)
      deltaMonths = cashOutMoment.diff(previousCashOutMoment, "months")
    }

    let title: string
    switch (true) {
      case interval === Interval.Year && count > 10:
        title = "10+ years"
        break
      case cashOutMoment.isSameOrAfter(periodEndMoment) && count === 0:
        title = `<1 ${dateTimeHelper.displayNameForInterval(interval, 1)}`
        break
      default: {
        title = `${clampedCount} ${stringHelper.pluralize(
          clampedCount,
          interval,
          dateTimeHelper.displayNameForInterval(interval, clampedCount)
        )}`
        break
      }
    }

    return {
      count: clampedCount,
      interval,
      deltaMonths,
      title: stringHelper.titleCase(title),
    }
  }, [runway])

export const useParseDeltaMonths = (deltaMonths: number | undefined) => {
  if (!deltaMonths) return undefined

  // do not display delta months if it is flat
  const absDelta = Math.abs(deltaMonths)
  if (absDelta < 1) return undefined

  const color = deltaMonths >= 0 ? colors.accentTurquoise : colors.errorDark
  const sign = deltaMonths >= 0 ? DeltaSign.Positive : DeltaSign.Negative

  let delta: string, interval: string
  // if delta months is greater than 12 months (1 year), then represent as years with a single decimal place
  if (absDelta > 12) {
    const year = Math.round((absDelta / 12) * 10) / 10
    // if more than 10 years, then just display 10+
    delta = year > 10 ? "10+" : year.toString()
    interval = stringHelper.pluralize(absDelta, "Year", "Years")
  } else {
    delta = absDelta.toString()
    interval = stringHelper.pluralize(absDelta, "Month", "Months")
  }

  return {
    color,
    sign,
    delta,
    interval,
  }
}
