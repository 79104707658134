import * as React from "react"
import {
  Comment,
  Layout,
  LayoutAttachment,
  LayoutComponentType,
  LayoutRow,
  LayoutSection,
  useReadLayoutQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { useAssistantContext } from "src/frontend/components/Shared/Assistant/Context"
import { LayoutContext } from "src/frontend/components/Shared/Assistant/Messages/Layout/Context"
import { DraggableComponent } from "src/frontend/components/Shared/Assistant/Messages/Layout/DraggableComponent"
import {
  GraphContainer,
  MatchComponent,
  TextContainer,
} from "src/frontend/components/Shared/Assistant/Messages/Layout/MatchComponent"

/*
  STYLES
*/

const Break = styled.div`
  height: 16px;
`

/*
 COMPONENTS
*/

export const AssistantLayout: React.FC<{ layout: Layout }> = ({ layout }) => {
  const context = React.useMemo(() => ({ ...layout }), [layout])

  return (
    <LayoutContext.Provider value={context}>
      {layout.sections?.map((s) => <Section key={s.sectionId} section={s} />)}
    </LayoutContext.Provider>
  )
}

export const AssistantLayoutAttachment: React.FC<{
  comment: Comment
  attachment: LayoutAttachment
}> = ({ comment, attachment: { layoutId, legalEntityId } }) => {
  const { dispatch } = useAssistantContext()

  const { error } = useReadLayoutQuery({
    variables: {
      legalEntityId,
      layoutId,
    },
    onCompleted: (data) => {
      dispatch({ type: "MESSAGE", payload: { comment, layout: data.readLayout } })
    },
  })

  return (
    <>
      {!error ? (
        <LoadingBlock height="15px" width="100px" $shineColor={colors.translucentSecondary30} />
      ) : (
        error.message
      )}
    </>
  )
}

const Section: React.FC<{ section: LayoutSection }> = ({ section }) => {
  const { sectionId } = section

  return (
    <>{section.rows?.map((row, i) => <Row key={row.rowId} row={row} sectionId={sectionId} />)}</>
  )
}

const Row: React.FC<{ sectionId: string; row: LayoutRow }> = ({ sectionId, row }) => {
  if (!row.components?.length) return null

  return (
    <>
      {row.components.map((c, i) => {
        switch (c.config.type) {
          case LayoutComponentType.Text: {
            if (!c.config.text?.body) {
              return <Break key={c.componentId} />
            }

            return (
              <TextContainer key={c.componentId}>
                <MatchComponent component={c} skipAnimations={false} />
              </TextContainer>
            )
          }

          case LayoutComponentType.PortalMetric:
          case LayoutComponentType.Insight:
          case LayoutComponentType.EntitiesList: {
            return (
              <GraphContainer key={c.componentId}>
                <MatchComponent component={c} skipAnimations={false} />
              </GraphContainer>
            )
          }

          case LayoutComponentType.SummaryLineItemChart:
          case LayoutComponentType.TopTransactions:
          case LayoutComponentType.TopEntities:
          case LayoutComponentType.CategoryBalanceSummaryChart:
          case LayoutComponentType.CategoryTransactionSummaryChart:
          case LayoutComponentType.PartyRoleBalanceSummaryChart:
          case LayoutComponentType.PartyTransactionSummaryChart:
          case LayoutComponentType.MetricSummaryChart: {
            return <DraggableComponent key={c.componentId} component={c} />
          }
        }
        return null
      })}
    </>
  )
}
