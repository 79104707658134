import * as React from "react"
import { ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import { TopEntitiesProps } from "src/frontend/components/Shared/Portals/Components/Lists/Shared"
import { TopCategories } from "src/frontend/components/Shared/Portals/Components/Lists/TopCategories"
import { TopParties } from "src/frontend/components/Shared/Portals/Components/Lists/TopParties"

/*
  INTERFACES
*/

/*
  COMPONENTS
*/

export const TopEntities: React.FC<TopEntitiesProps> = ({ component, componentSize, height }) => {
  const { kind } = component.config.topEntities

  if (kind === ObjectKind.Category) {
    return <TopCategories component={component} componentSize={componentSize} height={height} />
  }

  if (kind === ObjectKind.Party) {
    return <TopParties component={component} componentSize={componentSize} height={height} />
  }

  return null
}
