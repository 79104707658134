import * as React from "react"
import {
  Input,
  InputElementProps,
  InputStyled,
} from "@digits-shared/components/UI/Elements/Form/Input"
import { PartyEditFormFieldValues } from "@digits-shared/components/UI/Parties/PartyEditForm"
import styled from "styled-components"
import { InputLink } from "./InputLink"

export const TWITTER_URL_PATTERN_REGEX = "^(https?://)?(?:www.)?twitter.com/([a-zA-Z0-9_\\/]+)$"
export const TWITTER_HANDLE_PATTERN_REGEX = "^([a-zA-Z0-9_][^\\/]+)$"

export const INSTAGRAM_URL_PATTERN_REGEX =
  "^(https?://)?(?:www.)?instagram.com/([a-zA-Z0-9_.\\-\\/]+)$"
export const INSTAGRAM_HANDLE_PATTERN_REGEX = "^([a-zA-Z0-9_.][^\\/]+)$"

export const FACEBOOK_URL_PATTERN_REGEX =
  "^(https?://)?(?:www.)?facebook.com/([a-zA-Z0-9_.\\-\\/]+)$"

export const YELP_URL_PATTERN_REGEX = "^(https?://)?(?:www.)?yelp.com/biz/([a-zA-Z0-9_.\\-\\/]+)$"

const TwitterPlaceholder = "https://twitter.com/"
const InstagramPlaceholder = "https://instagram.com/"
const YelpPlaceholder = "https://yelp.com/biz/"
const FacebookPlaceholder = "https://facebook.com/"

/*
  STYLES
*/

const HandleContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  ${InputStyled} {
    padding-left: 30px;
  }
`

/*
  COMPONENTS
*/

export const TWITTER_FIELD_NAME: keyof PartyEditFormFieldValues = "twitterUrl"
export const TwitterInput: React.FC<InputElementProps> = (props) => (
  <HandleContainer>
    <Input
      {...props}
      label="Twitter Page"
      name={TWITTER_FIELD_NAME}
      type="text"
      pattern={TWITTER_URL_PATTERN_REGEX}
      placeholder={TwitterPlaceholder}
      autoComplete="off"
    />
    <InputLink href={props.value ? (props.value as string) : TwitterPlaceholder} />
  </HandleContainer>
)

export const INSTAGRAM_FIELD_NAME: keyof PartyEditFormFieldValues = "instagramUrl"
export const InstragramInput: React.FC<InputElementProps> = (props) => (
  <HandleContainer>
    <Input
      {...props}
      label="Instagram Page"
      name={INSTAGRAM_FIELD_NAME}
      type="text"
      pattern={INSTAGRAM_URL_PATTERN_REGEX}
      placeholder={InstagramPlaceholder}
      autoComplete="off"
    />
    <InputLink href={props.value ? (props.value as string) : InstagramPlaceholder} />
  </HandleContainer>
)

export const FACEBOOK_FIELD_NAME: keyof PartyEditFormFieldValues = "facebookUrl"
export const FacebookInput: React.FC<InputElementProps> = (props) => (
  <HandleContainer>
    <Input
      {...props}
      label="Facebook Page"
      name={FACEBOOK_FIELD_NAME}
      type="text"
      pattern={FACEBOOK_URL_PATTERN_REGEX}
      placeholder={FacebookPlaceholder}
      autoComplete="off"
    />
    <InputLink href={props.value ? (props.value as string) : FacebookPlaceholder} />
  </HandleContainer>
)

export const YELP_FIELD_NAME: keyof PartyEditFormFieldValues = "yelpUrl"
export const YelpInput: React.FC<InputElementProps> = (props) => (
  <HandleContainer>
    <Input
      {...props}
      label="Yelp Page"
      name={YELP_FIELD_NAME}
      type="text"
      pattern={YELP_URL_PATTERN_REGEX}
      placeholder={YelpPlaceholder}
      autoComplete="off"
    />
    <InputLink href={props.value ? (props.value as string) : YelpPlaceholder} />
  </HandleContainer>
)
