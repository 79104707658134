import { StaticRoutes } from "@digits-shared/components/Router/DigitsRoute"
import { HistoryType } from "@digits-shared/components/Router/History"
import routes from "src/frontend/routes"
import {
  SwitchableHistoryDelegate,
  SwitchableRouter,
  SwitchableRouterProps,
} from "src/shared/components/Router"

// Contextual views are components that render in the deeper or details area of the Chrome
export class ContextualViewRouter<SRC extends StaticRoutes> extends SwitchableRouter<SRC> {
  private static inContextualView(locationName: string) {
    return routes.pathIsDetailsView(locationName)
  }

  constructor(props: SwitchableRouterProps<SRC>) {
    super(props)
    this.configureHistory()
  }

  historyDelegate: SwitchableHistoryDelegate = {
    shouldNotifyMemoryListener: (activeHistory, memoryHistory, browserHistory, location) =>
      activeHistory() === memoryHistory,

    shouldNotifyBrowserListener: (activeHistory, memoryHistory, browserHistory, location) =>
      ContextualViewRouter.inContextualView(location.name),
  }

  historyType(locationName: string) {
    return ContextualViewRouter.inContextualView(locationName)
      ? HistoryType.Browser
      : HistoryType.Memory
  }
}
