import * as React from "react"
import { NewComment } from "@digits-graphql/frontend/graphql-bearer"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { useAssistantContext } from "src/frontend/components/Shared/Assistant/Context"
import FrontendSession from "src/frontend/session"
import { CommentEditor, SendProps } from "src/shared/components/Comments/CommentBox/CommentInput"
import { SvgAssistantSend } from "src/shared/components/SVG/AssistantSend.svg"

/*
  STYLES
*/

const AssistantComment = styled(CommentEditor)`
  font-size: 14px;
  font-weight: ${fonts.weight.medium};
  border-radius: 12px 0 0 12px;
  color: ${colors.white};
  padding: 15px;

  background: ${colors.translucentWhite20};
  outline: none;
  backdrop-filter: none;

  &[contenteditable="true"]:empty {
    &::after {
      color: ${colors.white};
    }
  }
`

const InputBox = styled.div`
  display: flex;
  background: linear-gradient(
    84.38deg,
    rgba(44, 146, 190, 0.8) -4.07%,
    rgba(61, 58, 194, 0.54) 95.1%
  );
  box-shadow: 0 4px 34px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 16px 16px;
  padding: 16px;
  border: none;
  margin: 0;
  font-size: 14px;
  font-weight: ${fonts.weight.medium};
`

const SendSVGEnabled = styled(SvgAssistantSend)`
  width: 18px;
  height: 18px;
  ${svgIconStyles(colors.white)};
`

const ActionButton = styled.button`
  border: none;
  background: ${colors.translucentWhite20};
  padding-right: 8px;
  border-radius: 0 12px 12px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: opacity 150ms ease-in-out;

  &:disabled {
    cursor: auto;

    ${SendSVGEnabled} {
      filter: grayscale(0.9);
      opacity: 0.4;
    }
  }
`

/*
  COMPONENTS
*/

export const AssistantInput: React.FC = () => {
  const {
    dispatch,
    state: { isSubmitting, initialThreadFetch },
  } = useAssistantContext()

  const { doppelganger } = useSession<FrontendSession>()

  const onSubmit = React.useCallback(
    (comment: NewComment, payload: string) => {
      dispatch({ type: "SUBMIT", payload })
      return Promise.resolve()
    },
    [dispatch]
  )

  const [sendDisabled, setSendDisabled] = React.useState(true)
  const isDisabled = isSubmitting || !initialThreadFetch || !!doppelganger

  return (
    <InputBox>
      <AssistantComment
        onSubmit={onSubmit}
        SendComponent={AssistantSendButton}
        isDisabled={isDisabled}
        sendDisabled={isDisabled || sendDisabled}
        setSendDisabled={setSendDisabled}
        disableToolbar
        submitOnEnter
        autocomplete={[]}
        placeholder="Ask Digits…"
      />
    </InputBox>
  )
}

const AssistantSendButton: React.FC<SendProps> = ({ disabled, onSend }) => {
  const {
    state: { isSubmitting },
  } = useAssistantContext()

  return (
    <ActionButton disabled={disabled || isSubmitting} onClick={onSend}>
      <SendSVGEnabled />
    </ActionButton>
  )
}
