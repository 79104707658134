import { useRouteMatch } from "react-router-dom"
import routes from "src/frontend/routes"

export enum AssistantDisplay {
  Hidden = "hidden",
  FullWidth = "full-width",
  Inline = "inline",
}

/**
 * Hook to determine which routes should show Ask Digits inline vs full-width
 */
export function useAssistantDisplay() {
  const isExpenses = useRouteMatch(routes.expenses.parameterizedPath)
  const isSales = useRouteMatch(routes.revenue.parameterizedPath)
  const isAssistant = useRouteMatch(routes.assistant.parameterizedPath)
  const isAssistantRedirect = useRouteMatch(routes.assistantRedirect.parameterizedPath)

  return !!isExpenses || !!isSales
    ? AssistantDisplay.Inline
    : !!isAssistant || !!isAssistantRedirect
      ? AssistantDisplay.FullWidth
      : AssistantDisplay.Hidden
}
