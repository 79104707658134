import * as React from "react"
import { ScopeTuple } from "@digits-shared/scope/scope"
import { ScopeContext } from "@digits-shared/scope/ScopeContext"

/** Returns the available scopes and their values */
export function useScopes(): ScopeTuple<unknown>[] {
  return React.useContext(ScopeContext)
}

/**
 * Combines the provided scope-plus-value with the scopes available from parent contexts, returning
 * the scope tuple list which should be provided for children.
 */
export function useProvideScopes(addingScope: ScopeTuple<unknown>): ScopeTuple<unknown>[] {
  const parentScopes = React.useContext(ScopeContext)

  // TODO
  // ComponentScope?
  // Injector storing of tuple values?

  return React.useMemo(() => {
    const existingIndex = parentScopes.findIndex((tup) => tup[0] === addingScope[0])
    // If the added scope is in the parent list, remove it since this lower one will take precedence
    const filteredParentScopes =
      existingIndex >= 0
        ? [...parentScopes.slice(0, existingIndex), ...parentScopes.slice(existingIndex + 1)]
        : parentScopes

    return [...filteredParentScopes, addingScope]
  }, [addingScope, parentScopes])
}
