import * as React from "react"
import { LayoutComponent } from "@digits-graphql/frontend/graphql-bearer"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { findComponentsIn } from "src/frontend/components/Shared/Layout/Shared"
import { usePortalStore } from "src/frontend/components/Shared/Portals/State/portalStore"
import { PORTAL_CONFIG } from "src/frontend/components/Shared/Portals/State/types"
import { useIsEditLayoutActive } from "src/frontend/components/Shared/Portals/State/useIsEditLayoutActive"

export interface DropInfoParams {
  sectionId: string
  rowId: string | null
  additionToRow?: boolean
}

export interface DropInfo {
  activeComponentIndex: number
  changingRows: boolean
  componentsInRow: LayoutComponent[]
  componentSize: ComponentSize
  componentWidth: number
  dropAllowed: boolean
}

/**
 * Hook that returns DropInfo for a particular section and row.
 */
export function useDropInfo({ sectionId, rowId, additionToRow }: DropInfoParams): DropInfo {
  const getDropInfo = useGetDropInfo(additionToRow)

  return React.useMemo(() => getDropInfo(sectionId, rowId), [getDropInfo, sectionId, rowId])
}

/**
 * Helper hook that returns a callback which can be used to get DropInfo for a particular
 * section and row.
 */
export function useGetDropInfo(
  additionToRow?: boolean
): (sectionId: string, rowId: string | null) => DropInfo {
  const layout = usePortalStore((state) => state.layout)
  const isEditLayoutActive = useIsEditLayoutActive()
  const { maxComponentsPerRow, rowWidth } = PORTAL_CONFIG

  const activeComponent = usePortalStore((state) => {
    if (!state.activeComponentId) return null

    return state.componentMap[state.activeComponentId] ?? null
  })

  return React.useCallback(
    (sectionId: string, rowId: string | null) => {
      // If a row ID is not known/specified, we won't find any components, and will create a
      // full-width overlay component.
      const componentsInRow = findComponentsIn(layout, sectionId, rowId ?? "")

      const activeComponentId = activeComponent?.componentId
      const activeComponentIndex = componentsInRow.findIndex(
        (c) => c.componentId === activeComponent?.componentId
      )

      // We exclude the active component from the list of those in the row
      // so that it is possible to move a component from a full row to another
      // spot in that row.
      const components = componentsInRow.filter((c) => c.componentId !== activeComponentId)
      const changingRows = components.length === componentsInRow.length

      const componentCount = additionToRow ? components.length + 1 : componentsInRow.length

      // Subtract an extra 44 pixels to make room for the editable area styling
      const availableComponentWidth = isEditLayoutActive
        ? rowWidth - 12 * 2 - (componentCount - 1) * 22 - 44
        : rowWidth - (componentCount - 1) * 22

      const componentSize = ComponentSize.componentSizeFor(componentCount)

      const componentWidth = componentsInRow.length
        ? availableComponentWidth / componentCount
        : availableComponentWidth

      const dropAllowed = components.length < maxComponentsPerRow

      return {
        activeComponentIndex,
        changingRows,
        componentsInRow,
        componentSize,
        componentWidth,
        dropAllowed,
      }
    },
    [
      layout,
      activeComponent?.componentId,
      additionToRow,
      isEditLayoutActive,
      rowWidth,
      maxComponentsPerRow,
    ]
  )
}
