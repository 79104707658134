import * as React from "react"
import { isNetworkRequestInFlight } from "@apollo/client/core/networkStatus"
import { ObjectKind, SearchEntitiesQueryResult } from "@digits-graphql/frontend/graphql-bearer"
import styled, { css } from "styled-components"
import QueryBuilder, {
  QueryRequest,
} from "src/frontend/components/OS/Springboard/Applications/Search/QueryBuilder"
import { SearchContext } from "src/frontend/components/OS/Springboard/Applications/Search/SearchContext"
import useSearchQuery from "src/frontend/components/OS/Springboard/Applications/Search/SearchQueries/useSearchQuery"
import SearchResults from "src/frontend/components/OS/Springboard/Applications/Search/SearchResults"
import { NoResultsFound } from "src/frontend/components/OS/Springboard/Applications/Search/SearchResults/NoResultsFound"

/*
 STYLES
*/

const Queries = styled.div<{ enable: boolean }>`
  ${({ enable }) =>
    !enable &&
    css`
      display: none;
      pointer-events: none;
    `}
`

/*
  INTERFACES
*/

interface SearchQueriesProps {
  builder: QueryBuilder
  setIsSearchRequestInFlight: (isSearching: boolean) => void
}

interface SearchQueryResult {
  query: QueryRequest
  result: SearchEntitiesQueryResult
}

interface QueryProps {
  builder: QueryBuilder
  queryResult: SearchQueryResult
}

/*
  COMPONENTS
*/

const SearchQueries: React.FC<SearchQueriesProps> = ({ builder, setIsSearchRequestInFlight }) => {
  const enable = builder.build().isValid
  const { loading, total, ...queries } = useSearchQueries(builder)

  React.useEffect(() => {
    setIsSearchRequestInFlight(loading)
  }, [loading, setIsSearchRequestInFlight])

  if (enable && !loading && total === 0) {
    return <NoResultsFound />
  }

  return (
    <Queries enable={enable}>
      <SearchQuery builder={builder} queryResult={queries.categories} />
      <SearchQuery builder={builder} queryResult={queries.parties} />
      <SearchQuery builder={builder} queryResult={queries.transactions} />
    </Queries>
  )
}
export default SearchQueries

const SearchQuery: React.FC<QueryProps> = ({ builder, queryResult: { query, result } }) => (
  <SearchContext.Provider value={React.useMemo(() => ({ builder, query }), [builder, query])}>
    <SearchResults result={result} />
  </SearchContext.Provider>
)

function useSearchQueries(builder: QueryBuilder) {
  const partyQuery = builder.setKind(ObjectKind.Party).build()
  const { result: partyQueryResult, networkStatus: partyNetworkStatus } = useSearchQuery(partyQuery)

  const categoryQuery = builder.setKind(ObjectKind.Category).build()
  const { result: categoryQueryResult, networkStatus: categoryNetworkStatus } =
    useSearchQuery(categoryQuery)

  const transactionQuery = builder.setKind(ObjectKind.Transaction).build()
  const { result: transactionQueryResult, networkStatus: transactionNetworkStatus } =
    useSearchQuery(transactionQuery)

  const loading =
    isNetworkRequestInFlight(partyNetworkStatus) ||
    isNetworkRequestInFlight(categoryNetworkStatus) ||
    isNetworkRequestInFlight(transactionNetworkStatus)

  const total = [partyQueryResult, categoryQueryResult, transactionQueryResult].reduce(
    (sum, result) => sum + (result.data?.search?.total ?? 0),
    0
  )

  return {
    loading,
    total,

    parties: {
      query: partyQuery,
      result: partyQueryResult,
    },
    categories: {
      query: categoryQuery,
      result: categoryQueryResult,
    },
    transactions: {
      query: transactionQuery,
      result: transactionQueryResult,
    },
  }
}
