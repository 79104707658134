import * as React from "react"
import ReactDOM from "react-dom"
import { useEvent } from "react-use"
import { MoneyFlow } from "@digits-graphql/frontend/graphql-bearer"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import objectHelper from "@digits-shared/helpers/objectHelper"
import useConstant from "@digits-shared/hooks/useConstant"
import { useModalRoot } from "@digits-shared/hooks/useModalRoot"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { localPoint } from "@visx/event"
import { Group } from "@visx/group"
import { ParentSize } from "@visx/responsive"
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale"
import { AreaStack, Line } from "@visx/shape"
import { Tooltip } from "@visx/tooltip"
import { max, min, sum } from "d3-array"
import { ScaleBand, ScaleLinear, ScaleOrdinal } from "d3-scale"
import { SeriesPoint } from "d3-shape"
import styled from "styled-components"
import { v4 as generateUUID } from "uuid"
import {
  SharedLineChartStyles,
  TimeseriesAxisStyle,
  TimeseriesLineChartStyle,
} from "src/frontend/components/OS/Shared/Charts/styles"
import {
  ChartContainer,
  SVGContainer,
} from "src/frontend/components/Shared/Layout/Components/Charts/shared"
import {
  ChartGrid,
  ChartXAxis,
  ChartYAxis,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesChartAxis"
import {
  Timeseries,
  TimeseriesValue,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import zIndexes from "src/shared/config/zIndexes"

/*
 STYLES
*/

const TooltipLabel = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
`

const StyledTooltip = styled(Tooltip)<{ $hoverRight?: boolean }>`
  position: fixed;
  z-index: ${zIndexes.modalOverlay};
  padding: 12px 16px;
  background-color: ${colors.translucentWhite80};
  border-radius: 8px;
  backdrop-filter: blur(8px);
  box-shadow: 0 7px 21px 0 ${colors.translucentBlack10};
  pointer-events: none;
  transform: translateX(${({ $hoverRight }) => `${$hoverRight ? "30px" : "-100%"}`});
`

const TooltipRowContainer = styled.div`
  color: ${colors.secondary};
  padding: 0 4px;
  white-space: nowrap;
  text-align: right;

  & > * {
    &:first-child {
      font-size: 11px;
    }
    &:last-child {
      font-size: 12px;
      font-weight: ${fonts.weight.heavy};
      margin-bottom: 6px;
    }
  }
`

const Dot = styled.div<{ $fill: string }>`
  width: 10px;
  height: 10px;
  display: inline-block;
  background: ${({ $fill }) => $fill};
`

/*
  INTERFACES
*/

export type StackedAreaChartData = { timestamp: number } & { [key: string]: MoneyFlow }

type DataByTimestamp = Record<number, Record<string, MoneyFlow>>

interface ChartProps {
  breakdownTimeseries: Timeseries[]
  width: number
  height: number
  lineChartStyle?: TimeseriesLineChartStyle
  className?: string
  hideGrid?: boolean
  hideAxis?: boolean
  noTooltip?: boolean
  onMouseOver?: (value: TimeseriesValue, index: number) => void
  onMouseOut?: (value?: TimeseriesValue) => void
  onClick?: (value: TimeseriesValue, index: number) => void
}

interface AreaProps {
  uuid: string
  breakdownKeys: string[]
  stackData: StackedAreaChartData[]
  xScale: ScaleBand<number>
  yScale: ScaleLinear<number, number>
  zScale: ScaleOrdinal<string, string>
}

interface HoveredValue {
  position: { top: number; left: number }
  data: StackedAreaChartData
}

interface TooltipProps {
  breakdownKeys: string[]
  hoveredValue: HoveredValue | undefined
  xScale: ScaleBand<number>
  zScale: ScaleOrdinal<string, string>
  yAxisWidth: number
  height: number
  axisStyle: TimeseriesAxisStyle
}

/*
  COMPONENTS
*/

const xValue = (v: { timestamp: number }) => v.timestamp

const yValue = (value: MoneyFlow | undefined) => {
  const {
    value: { amount, currencyMultiplier },
    isNormal,
  } = value || moneyFlowHelper.buildZeroMoneyFlow()

  return (Math.abs(amount) * (isNormal ? 1 : -1)) / currencyMultiplier
}

export const ParentSizedTimeseriesStackedAreaChart: React.FC<
  Omit<ChartProps, "width" | "height">
> = ({
  breakdownTimeseries,
  className,
  hideGrid,
  hideAxis,
  noTooltip,
  lineChartStyle,
  onClick,
  onMouseOver,
  onMouseOut,
}) => (
  <ParentSize>
    {(parent) => {
      const { width, height } = parent

      return (
        <TimeseriesStackedAreaChart
          breakdownTimeseries={breakdownTimeseries}
          className={className}
          hideGrid={hideGrid}
          hideAxis={hideAxis}
          noTooltip={noTooltip}
          lineChartStyle={lineChartStyle || SharedLineChartStyles}
          width={width}
          height={height}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        />
      )
    }}
  </ParentSize>
)

export const TimeseriesStackedAreaChart: React.FC<ChartProps> = ({
  breakdownTimeseries,
  className,
  hideGrid,
  hideAxis,
  noTooltip,
  width,
  height,
  lineChartStyle: propLineChartStyle,
  onClick,
  onMouseOut,
  onMouseOver,
}) => {
  const lineChartStyle = propLineChartStyle || SharedLineChartStyles
  const chartId = useConstant(generateUUID)
  const rootTimeseries = breakdownTimeseries?.[0]
  const [hoveredValue, setHoveredValue] = React.useState<HoveredValue | undefined>()

  const breakdownKeys = React.useMemo(
    () => breakdownTimeseries.map((series) => series.label),
    [breakdownTimeseries]
  )

  const stackData = React.useMemo<StackedAreaChartData[]>(() => {
    const groups = breakdownTimeseries.reduce((data, series, currentIndex) => {
      series.values.forEach((val) => {
        const values = data[val.period.startedAt] || {}
        values[series.label] = val.moneyFlow
        data[val.period.startedAt] = values
      })
      return data
    }, {} as DataByTimestamp)

    return objectHelper.keysOf(groups).map(
      (timestamp) =>
        ({
          timestamp: timestamp,
          ...groups[timestamp],
        }) as StackedAreaChartData
    )
  }, [breakdownTimeseries])

  const { xValues, maxYValue, minYValue } = React.useMemo(
    () => ({
      xValues: stackData.map(xValue),

      maxYValue: Math.max(
        max(stackData, (stack) => {
          const values = breakdownKeys.map((key) => yValue(stack[key]))
          // if value is negative ignore it (0) to avoid subtracting it from the stack's total
          // e.g. 10 + 12 + -22
          return sum(values, (val) => (val < 0 ? 0 : val))
        }) || 0,
        0
      ),

      minYValue: Math.min(
        min(stackData, (stack) => {
          const values = breakdownKeys.map((key) => yValue(stack[key]))
          // if value is positive ignore it (0) to avoid subtracting it from the stack's total
          // e.g. -10 + -12 + 22
          return sum(values, (val) => (val > 0 ? 0 : val))
        }) || 0,
        0
      ),
    }),
    [breakdownKeys, stackData]
  )

  const xMax = hideAxis ? width : width - Y_AXIS_WIDTH

  const xScale = scaleBand({
    range: [0, xMax],
    round: true,
    domain: xValues,
    paddingInner: 0.5,
  })

  const onMouseLeave = React.useCallback(() => {
    setHoveredValue(undefined)
    onMouseOut?.(undefined)
  }, [onMouseOut])

  const pointToValue = React.useCallback(
    (event: React.MouseEvent) => {
      const point = localPoint(event)
      const x = point ? point.x : 0
      const yAxisOffset = hideAxis ? 0 : Y_AXIS_WIDTH
      const index = Math.floor((x - yAxisOffset + xScale.bandwidth() / 2) / xScale.step())

      if (index < 0 || index >= xValues.length) {
        return { index: -1, value: undefined }
      }
      return { index, data: stackData[index] }
    },
    [hideAxis, stackData, xScale, xValues.length]
  )

  const onMouseMove = React.useCallback(
    (event: React.MouseEvent) => {
      const { index, data } = pointToValue(event)
      if (index === -1 || !data) return

      const position =
        hoveredValue && data === hoveredValue.data
          ? hoveredValue.position
          : {
              top: event.pageY,
              left: event.pageX,
            }

      setHoveredValue({ data, position })
      onMouseOver?.(rootTimeseries?.values[index] as TimeseriesValue, index)
    },
    [hoveredValue, onMouseOver, pointToValue, rootTimeseries?.values]
  )
  const onMouseClick = React.useCallback(
    (event: React.MouseEvent) => {
      const { index, data } = pointToValue(event)
      if (index === -1 || !data) return
      onClick?.(rootTimeseries?.values[index] as TimeseriesValue, index)
    },
    [onClick, pointToValue, rootTimeseries?.values]
  )
  useEvent("mousewheel", () => setHoveredValue(undefined), document.body)

  if (!rootTimeseries?.values?.length) {
    return null
  }

  if (width === 0 || height === 0) return null

  const svgHeight = hideAxis ? height : height - X_AXIS_HEIGHT
  const yScale: ScaleLinear<number, number> = scaleLinear({
    range: [svgHeight, 0],
    round: true,
    domain: [minYValue, maxYValue],
  })

  const zScale = scaleOrdinal({
    range: ["#AEF2D6", "#89E3E9", "#22C5CF", "#00868C", "#3ADCA5", "#00C87F", "#00A565"],
    domain: breakdownKeys,
  })

  const groupLeft = hideAxis ? 0 : Y_AXIS_WIDTH

  return (
    <ChartContainer className={className} width={width} height={height} onMouseLeave={onMouseLeave}>
      <SVGContainer
        width={width}
        height={svgHeight}
        onMouseMove={onMouseMove}
        onClick={onMouseClick}
      >
        <defs>
          <linearGradient
            id={`line-stroke-gradient-${chartId}`}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
            // Allow gradients to span different paths.
            gradientUnits="userSpaceOnUse"
          >
            {["#22C5CF", "#89E3E9"].map((stop, index) => (
              <stop key={`stop${index}`} offset={`${index * 100}%`} stopColor={stop} />
            ))}
          </linearGradient>
        </defs>
        {!hideGrid && (
          <ChartGrid
            yScale={yScale}
            width={width - Y_AXIS_WIDTH}
            height={svgHeight}
            axisStyle={lineChartStyle}
            yAxisWidth={Y_AXIS_WIDTH}
          />
        )}
        <Group top={0} left={groupLeft}>
          <StackedAreas
            uuid={chartId}
            breakdownKeys={breakdownKeys}
            stackData={stackData}
            xScale={xScale}
            yScale={yScale}
            zScale={zScale}
          />
          {/* <TotalLine */}
          {/*   uuid={chartId} */}
          {/*   breakdownKeys={breakdownKeys} */}
          {/*   stackData={stackData} */}
          {/*   xScale={xScale} */}
          {/*   yScale={yScale} */}
          {/*   zScale={zScale} */}
          {/*   invertValues={invertValues} */}
          {/* /> */}
        </Group>
        {!hideAxis && (
          <>
            <ChartXAxis
              timeseries={rootTimeseries.values}
              xScale={xScale}
              height={svgHeight}
              width={width}
              axisStyle={lineChartStyle}
              yAxisWidth={Y_AXIS_WIDTH}
            />
            <DashedLine
              breakdownKeys={breakdownKeys}
              hoveredValue={hoveredValue}
              height={svgHeight}
              xScale={xScale}
              zScale={zScale}
              axisStyle={lineChartStyle}
              yAxisWidth={Y_AXIS_WIDTH}
            />
            <ChartYAxis
              timeseries={rootTimeseries.values}
              yScale={yScale}
              height={svgHeight}
              width={width}
              axisStyle={lineChartStyle}
              yAxisWidth={Y_AXIS_WIDTH}
            />
          </>
        )}
      </SVGContainer>
      {!noTooltip && (
        <HoverTooltip
          breakdownKeys={breakdownKeys}
          hoveredValue={hoveredValue}
          xScale={xScale}
          zScale={zScale}
          height={svgHeight}
          axisStyle={lineChartStyle}
          yAxisWidth={Y_AXIS_WIDTH}
        />
      )}
    </ChartContainer>
  )
}

const StackedAreas: React.FC<AreaProps> = ({
  uuid,
  breakdownKeys,
  stackData,
  xScale,
  yScale,
  zScale,
}) => {
  const x = React.useCallback(
    (point: SeriesPoint<StackedAreaChartData>) =>
      xScale.bandwidth() / 2 + (xScale(xValue(point.data)) ?? 0),
    [xScale]
  )
  const y0 = React.useCallback(
    (point: SeriesPoint<StackedAreaChartData>, index: number) => yScale(point[0]),
    [yScale]
  )

  const y1 = React.useCallback(
    (point: SeriesPoint<StackedAreaChartData>, index: number) => yScale(point[1]),
    [yScale]
  )

  const value = React.useCallback(
    (data: StackedAreaChartData, key: string) => yValue(data[key]),
    []
  )

  return (
    <AreaStack keys={breakdownKeys} data={stackData} x={x} y0={y0} y1={y1} value={value}>
      {({ stacks, path }) =>
        stacks.map((stack, idx) => {
          const fill = zScale(stack.key)
          return <path key={`stack-${stack.key}`} d={path(stack) || ""} fill={fill} />
        })
      }
    </AreaStack>
  )
}

const HoverTooltip: React.FC<TooltipProps> = ({
  breakdownKeys,
  hoveredValue,
  xScale,
  zScale,
  yAxisWidth,
}) => {
  const modalRoot = useModalRoot()
  const rows = React.useMemo(
    () =>
      !hoveredValue?.data
        ? []
        : breakdownKeys
            .filter((name) => hoveredValue.data[name]?.value.amount)
            .map((name) => ({
              name,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              moneyFlow: hoveredValue.data[name]!,
              fill: zScale(name),
            })),
    [breakdownKeys, hoveredValue, zScale]
  )

  if (!hoveredValue) return null
  if (rows.length === 0) return null

  const { data, position } = hoveredValue
  const isLeftOfCenter = xScale.domain().indexOf(xValue(data)) < xScale.domain().length / 2

  return (
    <>
      {ReactDOM.createPortal(
        <StyledTooltip
          left={position.left}
          top={position.top - 30}
          $hoverRight={isLeftOfCenter}
          unstyled
        >
          {rows.map(({ name, moneyFlow, fill }, i) => (
            <TooltipRow key={name} label={name} moneyFlow={moneyFlow} fill={fill} />
          ))}
        </StyledTooltip>,
        modalRoot
      )}
    </>
  )
}

const TooltipRow: React.FC<{
  label: string
  moneyFlow: MoneyFlow
  fill: string
}> = ({ label, moneyFlow, fill }) => (
  <TooltipRowContainer>
    <TooltipLabel>
      <span>{label}</span>
      <Dot $fill={fill} />
    </TooltipLabel>
    <span>{moneyFlowHelper.currency(moneyFlow)}</span>
  </TooltipRowContainer>
)

const DashedLine: React.FC<TooltipProps> = ({
  hoveredValue,
  xScale,
  height,
  axisStyle,
  yAxisWidth,
}) => {
  if (!hoveredValue) return null

  const left = yAxisWidth + (xScale(xValue(hoveredValue.data)) ?? 0) + xScale.bandwidth() / 2

  return (
    <Line
      from={{ x: left, y: 0 }}
      to={{ x: left, y: height }}
      stroke={axisStyle.tickFill}
      strokeWidth={1}
      strokeDasharray="2,2"
      style={{ pointerEvents: "none" }}
    />
  )
}

// Confusing with small amounts areas on top of the chart (either needs a diff color or remove it)
// const TotalLine: React.FC<AreaProps> = ({
//   uuid,
//   breakdownKeys,
//   stackData,
//   invertValues,
//   xScale,
//   yScale,
// }) => {
//   const x = React.useCallback(
//     (point: StackedAreaChartData) => xScale.bandwidth() / 2 + (xScale(xValue(point)) ?? 0),
//     [xScale]
//   )
//
//   const y = React.useCallback(
//     (point: StackedAreaChartData, index: number) =>
//       yScale(sum(breakdownKeys, (key) => yValue(point[key], invertValues))),
//     [breakdownKeys, invertValues, yScale]
//   )
//
//   return (
//     <LinePath
//       data={stackData}
//       x={x}
//       y={y}
//       stroke={`url(#line-stroke-gradient-${uuid})`}
//       strokeWidth={2}
//     />
//   )
// }
