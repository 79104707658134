import { ReportDocumentOptions, StatementRow } from "@digits-graphql/frontend/graphql-bearer"
import { toTimeseriesValues } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"

const ALWAYS_SHOW_ROW_IDS = ["synthesized_cash_at_beginning_of_period"]

export function omitZeroBalanceLineItems(options?: ReportDocumentOptions) {
  return !(options?.deltaMonthOverMonth || options?.deltaYearOverYear)
}

export function statementRowFiltering(
  statementRows: StatementRow[],
  monthOverMonthColumns: number,
  yearOverYearColumns: number
) {
  return pruneRowsWithNoChildren(
    statementRows.filter((row) =>
      includeStatementRow(row, monthOverMonthColumns, yearOverYearColumns)
    )
  )
}

function includeStatementRow(
  row: StatementRow,
  monthOverMonthColumns: number,
  yearOverYearColumns: number
) {
  if (row.leafCategorySummary) {
    if (ALWAYS_SHOW_ROW_IDS.includes(row.rowId)) {
      return true
    }

    const prevYear = row.deltas?.previousYear
    if (yearOverYearColumns && prevYear && prevYear.moneyFlow.value.amount !== 0.0) {
      return true
    }

    const timeseries = toTimeseriesValues(row.details?.hover.history.time)
    if (!timeseries.length || !monthOverMonthColumns) {
      return row.leafCategorySummary.moneyFlow.value?.amount !== 0.0
    }

    return timeseries
      .slice(0, monthOverMonthColumns + 1)
      .some((tv) => tv.moneyFlow.value.amount !== 0.0)
  }

  return true
}

function pruneRowsWithNoChildren(rows: StatementRow[]) {
  // find the max depth
  const maxDepth = Math.max(...rows.map((row) => row.depth))

  // iterate over each depth, cleaning up the inner most empty categories first
  for (let depth = maxDepth; depth >= 0; depth -= 1) {
    const rowsToDelete: number[] = []
    rows.forEach((row, i) => {
      // if a parent category summary follows a parent category title then there are no leaf nodes in-between
      // if the parent category summary amount is 0, then it can be removed
      if (row.parentCategoryTitle && row.depth === depth) {
        if (rows[i + 1]?.parentCategorySummary?.moneyFlow.value.amount === 0) {
          rowsToDelete.push(i)
          rowsToDelete.push(i + 1)
        }
      }
    })

    // remove rows marked for deletion
    for (let i = rowsToDelete.length - 1; i >= 0; i -= 1) {
      rows.splice(rowsToDelete[i] as number, 1)
    }
  }

  // remove rows collected in rowsToDelete
  return rows
}
