import * as React from "react"
import { Period } from "@digits-graphql/frontend/graphql-bearer"
import { ParentSize } from "@visx/responsive"
import { SharedLineChartStyles } from "src/frontend/components/OS/Shared/Charts/styles"
import { ComponentHeader } from "src/frontend/components/Shared/Assistant/Messages/Layout/Shared/ComponentHeader"
import { ChartContainer } from "src/frontend/components/Shared/Assistant/Messages/Layout/Shared/Styles"
import { TimeseriesLineChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesLineChart"
import { TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"

/*
  INTERFACES
*/

interface LineChartComponentProps {
  name: string | undefined
  timeseries: TimeseriesValue[]
  period: Period | undefined
  skipAnimations: boolean
  onMouseOver: (value: TimeseriesValue, index: number) => void
  onMouseOut: (value?: TimeseriesValue) => void
  selectedIndex?: number
  icon?: React.ReactNode
}

/*
  COMPONENTS
*/

export const LineChartComponent = React.memo<LineChartComponentProps>(
  ({ name, timeseries, period, skipAnimations, onMouseOver, onMouseOut, selectedIndex, icon }) => (
    <>
      <ComponentHeader
        subjectName={name}
        timeseries={timeseries}
        selectedIndex={selectedIndex}
        period={period}
        icon={icon}
      />
      <ChartContainer css="margin: 0 5px">
        <ParentSize>
          {({ width, height }) => (
            <TimeseriesLineChart
              timeseries={timeseries}
              skipAnimations={skipAnimations}
              hideGrid
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              width={width}
              height={height}
              chartStyle={SharedLineChartStyles}
            />
          )}
        </ParentSize>
      </ChartContainer>
    </>
  )
)
