import * as React from "react"
import { useReadAssociatedThreadsQuery } from "@digits-graphql/frontend/graphql-bearer"
import { useAssistantObjectIdentifier } from "src/frontend/components/Shared/Assistant/hooks/useAssistantObjectIdentifier"
import { AssistantAction } from "src/frontend/components/Shared/Assistant/reducer"

const POLL_INTERVAL = 5 * 60 * 1000 // every 5 minutes

export function useFetchAssistantMessages(
  enabled: boolean,
  dispatch: React.Dispatch<AssistantAction>,
  isSubmitting: boolean
) {
  const objectIdentifier = useAssistantObjectIdentifier()

  const { refetch, startPolling, stopPolling } = useReadAssociatedThreadsQuery({
    variables: { ...objectIdentifier, allowResolved: false },
    skip: !enabled,
    context: {
      noBatch: true,
    },
    onCompleted: (data) => {
      const thread = data.response.threads[0]
      const { entities } = data.response

      dispatch({ type: "THREAD_FETCH", payload: thread ? { thread, entities } : undefined })
    },
  })

  useFetchAssistantMessagesPolling(startPolling, stopPolling, !isSubmitting && enabled)

  // enable polling to handle the threadID being resolved between different browser windows

  React.useEffect(() => {
    dispatch({ type: "THREAD_QUERY", payload: refetch })
  }, [dispatch, refetch])
}

function useFetchAssistantMessagesPolling(
  startPolling: (pollInterval: number) => void,
  stopPolling: () => void,
  enabled: boolean
) {
  React.useEffect(() => {
    if (enabled) {
      return startPolling(POLL_INTERVAL)
    }
    stopPolling()
  }, [enabled, startPolling, stopPolling])
}
