import * as React from "react"
import {
  CategoryChartConfig,
  EntityCategory,
  EntityParty,
  Interval,
  LayoutComponent,
  LayoutComponentConfig,
  LayoutComponentType,
  PartyChartConfig,
  ProviderType,
  useReadCategorySummaryByTimeComponentDataLazyQuery,
  useReadCategorySummaryByTimeLiveDataLazyQuery,
  useReadPartySummaryByTimeComponentDataLazyQuery,
  useReadPartySummaryByTimeLiveDataLazyQuery,
  ViewIdentifier,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { getStaticComponentTitle } from "src/frontend/components/Shared/Layout/Components/staticComponentTitle"
import { matchConfig, matchConfigWithOrigin } from "src/frontend/components/Shared/Layout/types"
import { useDataSourcePreference } from "src/frontend/hooks/useDataSourcePreference"

interface LayoutViewVersion {
  layoutId: string
  viewId: ViewIdentifier
}

export function useComponentTitle(component: LayoutComponent, viewVersion?: LayoutViewVersion) {
  const { title, config } = component
  return useComponentConfigTitle(config, viewVersion, title, component.dataId)
}

export function useComponentConfigTitle(
  config: LayoutComponentConfig,
  viewVersion?: LayoutViewVersion,
  title?: string | null,
  dataId?: string | null
) {
  const [fetchCategory, { category }] = useCategoryComponent()
  const [fetchParty, { party }] = usePartyComponent()
  const dataSourcePreference = useDataSourcePreference(ProviderType.QuickBooks)
  const { configKey, origin } = matchConfigWithOrigin(config)

  const periodName = React.useMemo(() => {
    if (!configKey || !origin || origin.interval === Interval.IntervalNone) return ""
    return dateTimeHelper.displayNameForIntervalOriginRange(origin, { delimiter: " - " })
  }, [configKey, origin])

  const { layoutId, viewId } = viewVersion || {}
  React.useEffect(() => {
    if (
      matchConfig(config, "categoryChart", LayoutComponentType.CategoryTransactionSummaryChart) ||
      matchConfig(config, "categoryChart", LayoutComponentType.CategoryBalanceSummaryChart)
    ) {
      fetchCategory(config.categoryChart, config.type, layoutId, viewId, dataId)
    }

    if (
      matchConfig(config, "partyChart", LayoutComponentType.PartyTransactionSummaryChart) ||
      matchConfig(config, "partyChart", LayoutComponentType.PartyRoleBalanceSummaryChart)
    ) {
      fetchParty(config.partyChart, config.type, layoutId, viewId, dataId)
    }
  }, [config, dataId, fetchCategory, fetchParty, layoutId, viewId])

  return React.useMemo(() => {
    if (matchConfig(config, "staticMetric", LayoutComponentType.StaticMetric)) {
      const displayTime = config.staticMetric.updatedAt
        ? dateTimeHelper.displayNameFromUnixTimestamp(config.staticMetric.updatedAt, Interval.Day)
        : undefined
      return {
        title: config.staticMetric.name || "Add Header",
        periodName: displayTime ? `as of ${displayTime}` : "",
      }
    }

    if (title) {
      return { title, periodName }
    }

    if (
      matchConfig(config, "categoryChart", LayoutComponentType.CategoryTransactionSummaryChart) ||
      matchConfig(config, "categoryChart", LayoutComponentType.CategoryBalanceSummaryChart)
    ) {
      return { title: category?.name || "", periodName }
    }

    if (
      matchConfig(config, "partyChart", LayoutComponentType.PartyTransactionSummaryChart) ||
      matchConfig(config, "partyChart", LayoutComponentType.PartyRoleBalanceSummaryChart)
    ) {
      return { title: party?.name || "", periodName }
    }

    const staticTitle = getStaticComponentTitle(config, dataSourcePreference)
    if (staticTitle) {
      return { title: staticTitle, periodName }
    }

    return { title: "Chart", periodName }
  }, [config, title, dataSourcePreference, category?.name, party?.name, periodName])
}

function useCategoryComponent(): [
  (
    config: CategoryChartConfig | undefined,
    type: LayoutComponentType,
    layoutId: string | undefined,
    viewId: ViewIdentifier | undefined,
    dataId: string | undefined | null
  ) => void,
  { category?: EntityCategory; loading: boolean },
] {
  const [fetchArchivedCategory, { data: archivedData, loading: archivedLoading }] =
    useReadCategorySummaryByTimeComponentDataLazyQuery()

  const [fetchLiveCategory, { data: liveData, loading: liveLoading }] =
    useReadCategorySummaryByTimeLiveDataLazyQuery()

  const category = React.useMemo(() => {
    const componentData = archivedData?.readComponentData.data
    if (
      componentData?.__typename === "Chart" &&
      componentData?.entity.__typename === "EntityCategory"
    ) {
      return componentData.entity
    }

    const liveComponentData = liveData?.liveComponentData
    if (
      liveComponentData?.__typename === "Chart" &&
      liveComponentData?.entity.__typename === "EntityCategory"
    ) {
      return liveComponentData.entity
    }
    return undefined
  }, [archivedData, liveData])

  const req = React.useCallback(
    (
      categoryChart: CategoryChartConfig | undefined,
      type: LayoutComponentType,
      layoutId: string | undefined,
      viewId: ViewIdentifier | undefined,
      dataId: string | undefined | null
    ) => {
      if (!layoutId || !viewId) return
      if (dataId) {
        return fetchArchivedCategory({
          variables: {
            viewId,
            layoutId,
            dataId,
          },
        }).catch((error) => TrackJS?.track(error))
      }

      if (!viewId.viewVersion) return

      return fetchLiveCategory({
        variables: {
          viewId,
          config: {
            type,
            categoryChart,
          },
        },
      }).catch((error) => TrackJS?.track(error))
    },
    [fetchArchivedCategory, fetchLiveCategory]
  )

  return [req, { category, loading: liveLoading || archivedLoading }]
}

function usePartyComponent(): [
  (
    config: PartyChartConfig | undefined,
    type: LayoutComponentType,
    layoutId: string | undefined,
    viewId: ViewIdentifier | undefined,
    dataId: string | undefined | null
  ) => void,
  { party?: EntityParty; loading: boolean },
] {
  const [fetchArchivedParty, { data: archivedData, loading: archivedLoading }] =
    useReadPartySummaryByTimeComponentDataLazyQuery()
  const [fetchLiveParty, { data: liveData, loading: liveLoading }] =
    useReadPartySummaryByTimeLiveDataLazyQuery()

  const party = React.useMemo(() => {
    const componentData = archivedData?.readComponentData.data
    if (
      componentData?.__typename === "Chart" &&
      componentData?.entity.__typename === "EntityParty"
    ) {
      return componentData.entity
    }

    const liveComponentData = liveData?.liveComponentData
    if (
      liveComponentData?.__typename === "Chart" &&
      liveComponentData?.entity.__typename === "EntityParty"
    ) {
      return liveComponentData.entity
    }

    return undefined
  }, [archivedData?.readComponentData, liveData?.liveComponentData])

  const req = React.useCallback(
    (
      partyChart: PartyChartConfig | undefined,
      type: LayoutComponentType,
      layoutId: string | undefined,
      viewId: ViewIdentifier | undefined,
      dataId: string | undefined | null
    ) => {
      if (!layoutId || !viewId) return

      if (dataId) {
        return fetchArchivedParty({
          variables: {
            viewId,
            layoutId,
            dataId,
          },
        }).catch((error) => TrackJS?.track(error))
      }

      if (!viewId.viewVersion) return
      return fetchLiveParty({
        variables: {
          viewId,
          config: {
            type,
            partyChart,
          },
        },
      }).catch((error) => TrackJS?.track(error))
    },
    [fetchArchivedParty, fetchLiveParty]
  )

  return [req, { party, loading: archivedLoading || liveLoading }]
}
