import * as React from "react"
import { ShareAction } from "@digits-graphql/frontend/graphql-bearer"
import { ObjectKind, usePreviewShareQuery } from "@digits-graphql/frontend/graphql-public"
import useSession from "@digits-shared/hooks/useSession"
import { useSharedObjectInfo } from "src/frontend/hooks/useSharedObjectInfo"
import { useSharingIntentId } from "src/frontend/hooks/useSharingIntentId"
import FrontendSession from "src/frontend/session"

/**
 * Load shared object preview data if shared object info is available for the
 * current route.
 */
export function useSharedObjectPreview({
  action,
  hasError,
}: {
  action?: ShareAction
  hasError: boolean
}) {
  const sharedObjectInfo = useSharedObjectInfo()
  const sharingIntentId = useSharingIntentId()
  const skip = useShouldSkipPreview(action, hasError)

  const { loading, error, data } = usePreviewShareQuery({
    skip,
    context: { publicAPI: true },
    variables: {
      // The fallback values here won't be used, but are required to keep the compiler happy.
      // We know that sharedObjectInfo will be defined if we make a call here, (based on
      // the skip check) but we can't introduce a conditional before this hook to prove it
      // without upsetting the hooks linter.
      kind: sharedObjectInfo?.kind || ObjectKind.User,
      id: sharedObjectInfo?.id || "",
      intentId: sharingIntentId,
    },
  })

  return React.useMemo(
    () => ({
      data,
      loading,
      error,
    }),
    [data, loading, error]
  )
}

function useShouldSkipPreview(action: ShareAction | undefined, hasError: boolean) {
  const session = useSession<FrontendSession>()
  const sharedObjectInfo = useSharedObjectInfo()
  const sharedObjectLEInSession =
    !sharedObjectInfo || !!session.findLegalEntityBySlugInToken(sharedObjectInfo.legalEntitySlug)

  // We don't need to get a preview if:
  // - we are not on a share route
  // OR
  // - the user has access to the LE
  // OR
  // - the user is signed in
  // - AND there are no errors
  // - AND the permission data is anything but a request status
  //
  // This will mean we skip the preview call while the share object permission request is loading.
  // This logic ensures we are not unnecessarily hitting the object preview endpoint.
  return sharedObjectLEInSession || (session.hasUserData && !hasError && !isRequestStatus(action))
}

function isRequestStatus(action?: ShareAction) {
  return action && (action === ShareAction.RequestAccess || action === ShareAction.RequestPending)
}
