import * as React from "react"
import { DocumentStatus, useReadVaultFileQuery } from "@digits-graphql/frontend/graphql-bearer"
import { LogoLoading } from "@digits-shared/components/Loaders"
import useSession from "@digits-shared/hooks/useSession"
import styled from "styled-components"
import {
  DocumentViewer,
  Viewer,
} from "src/frontend/components/OS/Shared/DocumentViewer/DocumentViewer"
import { documentDownloadUrl } from "src/frontend/components/OS/Shared/DocumentViewer/helpers"
import { BoundingBox } from "src/frontend/components/OS/Shared/DocumentViewer/types"
import FrontendSession from "src/frontend/session"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

/*
  STYLES
*/

const Container = styled.div`
  ${Viewer} {
    box-shadow: none;
  }
`

/*
  INTERFACES
*/

/*
  COMPONENTS
*/
export const DocumentComponentViewer: React.FC<{ fileId: string }> = ({ fileId }) => {
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const stopPropagation = useStopClickPropagation()

  const { data, stopPolling, startPolling } = useReadVaultFileQuery({
    variables: {
      legalEntityId,
      id: fileId,
    },
    fetchPolicy: "cache-and-network",
  })

  const file = data?.readVaultFile

  const pages = React.useMemo(() => {
    // TODO: DOCX, XLSX, etc are returned only as ImageLarge but cant visualize it (not support in backend)
    // If no thumbnail, assume it is a document of this kind (we could also use mimetypes once provided by API)
    if (!file?.thumbnail) return undefined

    const format =
      file.formats.find((f) => f.formatClass === "ImageLarge") ??
      file.formats.find((f) => f.formatClass === "ImageSmall")

    if (!format) {
      return []
    }

    return format.segments
      .slice()
      .sort((s1, s2) => s1.sequence - s2.sequence)
      .map((segment) => ({
        collectionId: segment.collectionId,
        fileId: segment.fileId,
        imageUrl: documentDownloadUrl(segment.collectionId, segment.fileId, segment.token),
      }))
  }, [file?.thumbnail, file?.formats])

  // If there are pages being processed (no thumbnail yet), poll every 3.5s
  React.useEffect(() => {
    if (!file || !pages || [DocumentStatus.New, DocumentStatus.Processing].includes(file.status)) {
      startPolling(3500)
    } else {
      stopPolling()
    }
    return stopPolling
  }, [file, pages, startPolling, stopPolling])

  const resolveBoundingBoxes = React.useCallback(
    (w: number, h: number): BoundingBox<unknown>[] => [],
    []
  )

  if (!file || !pages) {
    return (
      <div>
        <LogoLoading size="60px" css="margin: 80px auto" />
      </div>
    )
  }

  return (
    <Container
      onClick={stopPropagation} // disable view control clicks bubbling up
      onWheelCapture={stopPropagation} // disable scrolling document
      onPointerDown={stopPropagation} // disable dragging component
    >
      <DocumentViewer
        className="landscape-viewer"
        documentId={file.id}
        pages={pages}
        resolveBoundingBoxes={resolveBoundingBoxes}
      />
    </Container>
  )
}
