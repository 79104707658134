import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgAlertTriangle } from "@digits-shared/components/SVGIcons/line/AlertTriangle.svg"
import styled from "styled-components"
import { MODAL_WIDTH } from "src/frontend/components/OS/Springboard/Applications/ClientPortal/InviteFlow/shared"

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: #f5d071;
  width: ${MODAL_WIDTH - 48 * 2}px;
  margin: 0 48px;
  padding: 6px 12px;
  border-radius: 20px 20px 0 0;
  color: #68542c;
  font-size: 13px;
  border: 1px solid rgba(181, 125, 13, 0.42);
`

const WarningIcon = styled(SvgAlertTriangle)`
  flex-shrink: 0;
  height: 15px;
  width: 15px;
  ${svgPathStyles("#68542c", 1.5)};
`

export const FailureBanner: React.FC<{ message: React.ReactNode }> = ({ message }) => {
  if (!message) return null

  return (
    <Banner>
      <WarningIcon />
      {message}
    </Banner>
  )
}
