import * as React from "react"
import { ActionItemKind } from "@digits-graphql/frontend/graphql-bearer"
import colors from "@digits-shared/themes/colors"
import { BodyText, DetailText } from "@digits-shared/themes/typography"
import styled from "styled-components"
import { IconByPrecedence } from "src/frontend/components/Shared/NavSidebar/ActionItemsV2/Row/Icon"
import EntitiesParser from "src/shared/components/ObjectEntities/EntitiesParser"
import { PartyAttributesForIcon } from "src/shared/components/PartyHover/PartyIcon"

/* 
  STYLES
*/

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
  width: 100%;
`

const TitleText = styled(BodyText)`
  color: ${colors.secondary80};
  max-width: 302px;
  overflow: wrap;
`

const SecondLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
  width: 100%;
  justify-content: space-between;

  ${DetailText} {
    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

/* 
  COMPONENTS
*/

export const ActionItemRow: React.FC<
  React.PropsWithChildren<{
    title: string
    iconUrl?: string | null
    iconColor?: string
    actionItemKind: ActionItemKind
    detailText?: string
    party?: PartyAttributesForIcon | null
    categoryName?: string | null
  }>
> = ({ actionItemKind, iconUrl, iconColor, party, categoryName, title, detailText, children }) => (
  <>
    <IconByPrecedence
      party={party}
      iconUrl={iconUrl}
      actionItemKind={actionItemKind}
      iconColor={iconColor}
      categoryName={categoryName}
    />
    <TextContent>
      <SecondLine>
        {children ?? <DetailText color={colors.secondary70}>{detailText}</DetailText>}
      </SecondLine>
      <TitleText weight="normal">
        <EntitiesParser text={title} decode />
      </TitleText>
    </TextContent>
  </>
)
